.packageHero {
  background-color: #e1eeff;
  display: flex;
  /* padding-right: 2em; */
  align-items: flex-end;
  position: relative;
}

.packageHero img {
  width: 35em;
  height: 25em;
}

.packageHeroContent {
  margin-left: 4vw;
  padding-top: 6.25em;
}

.packageHeroTitle {
  font-weight: 700;
  font-size: 2.5em;
}

.packageHeroTitle span {
  color: #1c71e1;
}

.packageHeroDescription {
  font-weight: 300;
  font-size: 1.25em;
  margin-top: 0.8em;
}

.packageHeroButton {
  margin-top: 2.5em;
  background-color: #1c71e1;
  padding: 1.25em 3vw;
  color: white;
  border-radius: 8px;
  margin-bottom: 3.375em;
  /* margin-left: 4vw; */
}

.packageHeroFoot {
  background-color: #0360d9;
  width: 100%;
  height: 8.3em;
  display: flex;
  align-items: center;
  color: white;
  gap: 2vw;
  padding-left: 3vw;
}

.packageHeroFootTitle {
  font-size: 1.5em;
  font-weight: 500;
}

.packageHeroFootMember {
  text-align: center;
}

.packageHeroFootLine {
  transform: rotate(180deg);
  width: 1px;
  height: 100px;
  color: white;
  background-color: white;
}

.packageHeroFootDuration {
  text-align: center;
}

.packageHeroFootPrice {
  text-align: center;
}

.packageHeroPrice {
  display: flex;
  flex-direction: column;
}

.packageHeroDiscountPrice {
  text-decoration: line-through;
}

@media screen and (max-width: 1350px) {
  .packageHero img {
    width: 30em;
    height: 20em;
  }
}

@media screen and (max-width: 1100px) {
  .packageHero img {
    display: none;
  }
}

@media screen and (max-width: 570px) {
  .packageHeroContent {
    padding-top: 2em;
  }
  .packageHeroFootLine {
    display: none;
  }

  .packageHeroButton {
    font-size: 1em;
    padding: 0.8em 3vw;
  }

  .packageHeroFoot {
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: auto;
    row-gap: 1em;
  }
}

@media screen and (max-width: 380px) {
  .packageHeroFoot {
    grid-template-columns: 1fr;
  }
  .packageHeroTitle {
    font-size: 1.3em;
  }
  .packageHeroDescription {
    font-size: 0.9em;
  }
}
