.drawer {
  position: fixed;
  top: 0;
  right: 0;
  width: 400px;
  max-width: 80%;
  height: 100%;
  background-color: #f0f0f0;
  transition: transform 0.3s ease;
  z-index: 1000;
  overflow-y: auto;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transform: translateX(100%);
}

.drawer.open {
  transform: translateX(0);
}

.drawer-content {
  padding: 20px;
}

.close-btn {
  cursor: pointer;
  float: right;
  font-size: 20px;
  font-weight: bold;
}

.close-btn:hover {
  color: red;
}
