.productDesc {
  margin-top: 10vh;
  overflow-y: scroll;
  margin-left: 3vw;
}

.productDescTitle {
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 2.75em;
}

.productDescList {
  color: #7e7e7e;
  font-style: normal;
  font-size: 1.25em;
  list-style: disc;
  margin-right: 4vw;
  line-height: 36px;
  font-weight: 400;
}

.productDescPrice {
  display: flex;
  gap: 2vw;
  align-items: center;
}

.productDescFinalPrice {
  font-size: 3.75em;
}

.productDescPriceRight {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.productCardDiscount {
  width: fit-content;
}

.productCardOriginalPrice {
  font-size: 2.25em;
  color: #545454;
  text-decoration: line-through;
}

.productDescSize {
  margin-top: 1.5em;
  display: flex;
  gap: 2vw;
}

.productDescSizeTitle {
  padding: 1em 1.5vw;
  border: 0.5px solid #e7e7e7;
  border-radius: 4px;
  font-size: 0.75em;
  background-color: #fafafa;
}

.size-button {
  font-size: 0.75em;
  padding: 0.75em 1.5vw;
  background-color: #fafafa;
  border-radius: 4px;
  border: 0.5px solid #9c9c9c;
}

.selected-size {
  background-color: #1c71e1;
  color: white;
}

.productDescFoot {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  margin-top: 1.5em;
}

.quantity-counter {
  padding: 2px 0.8em;
  border: 2px solid #1c71e1;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5em;
}

.quantity {
  margin-top: auto;
  margin-bottom: auto;
  width: 50%;
  margin-right: 1em;
}

.quantity-arrows {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  width: 50%;
  cursor: pointer;
}

.productDescriptionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  gap: 1.7vw;
  border-radius: 4px;
  font-size: 1.125em;
  color: white;
  background-color: #1c71e1;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  cursor: not-allowed;
}

.productDescriptionButtonAdded {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  gap: 1.7vw;
  border-radius: 4px;
  font-size: 1em;
  color: white;
  background-color: #1c71e1;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
}
@media screen and (max-width: 600px) {
  .productDesc {
    margin-left: 5vw;
    margin-right: 5vw;
    margin-top: 6vh;
  }

  .productDescBody {
    margin-left: 5vw;
  }

  .productDescSize {
    gap: 3vw;
  }

  .size-button {
    padding: 0.8em 1.5vw;
  }

  .quantity-counter {
    padding: 10px 25px;
  }

  .quantity {
    margin: 0;
  }

  .quantity-arrows {
    margin: 0;
    margin-left: 20px;
  }
  /* .counter-button-desc {
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .counter-button-asc {
    padding-left: 5vw;
    padding-right: 5vw;
  } */

  .productDescriptionButton {
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 20vw;
    padding-right: 20vw;
  }
}
