.MyPackageBookingMainChild2DivsLower2ItemsMobile {
  background: #ffffff;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  margin-right: 0.7em;
}
.MyPackageBookingMainChild2DivsLower2UpperMobile {
  display: flex;
  height: 20vw;
}
.MyPackageBookingMainChild2DivsLower2UpperChild1Mobile {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1.2em;
  padding-bottom: 1.2em;
}
.MyPackageBookingMainChild2DivsLower2UpperChild2Mobile {
  width: 70%;
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  /* justify-content: space-evenly; */
  align-items: flex-start;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.7em;
  line-height: 150%;
  color: #2d354e;
  position: relative;
}
.MyPackageBookingMainChild2DivsLower2UpperChild2Mobile select {
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 90%;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
  position: absolute;
  /* top: 2.9em; */
}

.MyPackageBookingMainChild2DivsLower2LowerMobile {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.7em;
  line-height: 150%;
  color: #ffffff;
  padding-top: 1em;
  padding-bottom: 1em;
  background: var(--globalColorHeader);
  border-radius: 0px 0px 6px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.MyPackageBookingMainChild2DivsLower2ItemsTestsMobile {
  margin-top: 1em;
  background: #ffffff;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  margin-right: 0.7em;
}
.MyPackageBookingMainChild2DivsLower2LowerTestsMobile {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.7em;
  line-height: 150%;
  color: #ffffff;
  padding-top: 1em;
  padding-bottom: 1em;
  background: var(--globalColorHeader);
  border-radius: 0px 0px 6px 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.mySubscriptionCard {
  height: 12vw;
  width: 25vw;
}

.planMemberContainer {
  margin-top: 3.3vw;
  display: grid;
  gap: 3.3vw;

  grid-template-columns: 1fr 1fr 1fr;
}


.planMemberCard {
  background: #f8f8f8;
  border: 1px solid #f7f7f7;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding-top: 0.6avw;
  /* padding-right: 1.4vw; */
  padding-left: 1.4vw;
  padding-bottom: 1.25vw;
}

.planMemberCardHeadingBox {
  display: flex;
  justify-content: space-between;
}

.planMemberName {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-top: 0.7vw;
  line-height: 150%;
  /* identical to box height, or 24px */

  color: #38466c;
}

.planMemberRelation {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  margin-top: -0.4vw;

  color: #1d2334;
}

.planMemberNo {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  margin-top: 1.2vw;

  color: #1d2334;
}



.newProgramLocked {
  display: flex;
  justify-content: flex-end;
}
.planMemberLockedFirst {
  font-family: "Montserrat";
  /* cursor: pointer; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-right: 12vw;
  line-height: 150%;
  text-align: center;
  /* margin-left: 63.8vw; */
  padding: 1vw;
  width: 11.5vw;
  margin-bottom: 1vh;
  color: #ffffff;
  background: var(--globalColorHeader);
  /* background:#3488adbd; */
  border-radius: 24px;
}

.planMemberContainer3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 5vw;
}





.planMemberHeading {
  margin-top: 10vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 5vw;
  /* identical to box height */

  color: #44576a;
}
.planMemberCardMbl {
  padding-left: 4vw;
  width: 100%;
  height: 18.5vw;
  background: #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  margin-top: 5vw;
}
.planMemeberName12 {
  padding-top: 0.5vw;
}

.planMemberGridBody {
  display: flex;
  /* grid-template-columns: 1fr 1fr 1fr 1fr; */
  flex-direction: row;
  justify-content: space-between;
  /* padding-top: 2.6vw; */
}

.planMemeberName {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  margin-top: 2.5vw;

  color: #44576a;
}
.planMemeberName1 {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  margin-top: 2.5vw;

  /* identical to box height, or 18px */

  text-align: center;

  color: #44576a;
}

.vl {
  float: end;
  /* margin-left: 3vw; */
  border-left: 1px solid #44576a;
  height: 20px;
  margin-top: 2.3vw;
}
.planMemberRelationText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  /* padding-top: 1vw; */
  /* identical to box height, or 21px */

  /* Web_PC */

  color: var(--globalColorHeader);
}

.planMemberContainer3Mbl {
  width: 100%;
}


.planLockIconMbl {
  margin-top: -1.3vw;
  padding-right: 1.2vw;
}
.mySubscriptionCardBody {
  height: 43vw;
  width: 100%;
  border-radius: 12px 12px 2px 2px;

  padding: 4.5vw;
}
.mySubscriptionCardBody2 {
  height: 48vw;
  width: 100%;
  border-radius: 12px 12px 2px 2px;

  padding: 4.5vw;
}

.cardHeadingMbl {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;

  /* secondary_color_3 */
  border: 0.5px solid #d9d9d9;

  color: #f2efea;
  width: 37%;
  padding-top: 1vw;
  padding-bottom: 1vw;
  padding-right: 1vw;
  padding-left: 1vw;
}

.planNameMbl {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: #fffcfc;
  margin-top: 7vw;
}
.planMemberNameMbl {
  margin-top: 2vw;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;

  background: linear-gradient(180deg, #ffddaa 0%, #ffc672 49.48%, #fbd7a2 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.cardNoMblBody {
  display: flex;
  justify-content: row;
  margin-top: 7vw;
  justify-content: space-between;
}

.cardNoMblBody2 {
  display: flex;
  justify-content: row;
  margin-top: 7vw;
  justify-content: space-between;
}

.cardNoMbl {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 9px;
  /* identical to box height */

  letter-spacing: 0.5px;

  color: #ffffff;
}
.cardExpireDate1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 4px;
  line-height: 5px;
  letter-spacing: 0.5px;

  color: #ffffff;
}
.cardExpireDate {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 9px;
  /* identical to box height */

  letter-spacing: 0.5px;
  margin-top: 1vw;

  color: #ffffff;
}

.cardContainer2Text2Mbl {
  margin-right: 2vw;
  margin-top: -2.1vw;
}
.cardContainer2Text2 {
  margin-right: 0vw;
  margin-top: -0.2vw;
}

.usedAndAvailable {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  margin-top: 0.5em;
  line-height: 150%;
  /* identical to box height, or 15px */

  text-align: center;

  /* secondary_color_3_3 */

  color: #b7ac8f;
}
.noMemberMobile {
}
