.HealthCheckupCatagoryParent {
  display: flex;
  flex-direction: column;
  padding-bottom: 2.3em;
  margin-right: 3em;
}

.HealthCheckupCatagoryUpper {
  color: black;
  font-weight: 700;
  padding-bottom: 1em;
  font-size: 36px;
}

.HealthCheckupCatagoryLower {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1em;
}

.HealthCheckupCatagoryChildren {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #021526;
  border-radius: 10px;
  padding-top: 1em;
  padding-bottom: 1em;
  align-items: center;
}

.HealthCheckupCatagoryChildren p {
  color: #000;
  font-size: 1.2em;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
}

.HealthCheckupCatagoryChildren div {
  font-size: 12px;
  font-weight: 300;
}

.HealthCheckupCatagoryChildren button {
  border-radius: 7px;
  font-size: 0.8em;
  font-weight: 400;
  text-transform: capitalize;
  padding: 1em 1em;
  background: #1c71e1;
  color: white;
}

@media screen and (max-width: 980px) {
  .HealthCheckupCatagoryLower {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .HealthCheckupCatagoryLower {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 400px) {
  .HealthCheckupCatagoryLower {
    grid-template-columns: 1fr;
  }
}
