.HraLandingHeading2 {
  margin-top: 5vw;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: var(--globalFontSize28);
  line-height: 150%;
  /* identical to box height, or 36px */

  text-align: center;

  color: var(--globalColorMainHeading);
}

.HraLandingwrapper {
  display: grid;
  grid-template-columns: 2fr 2fr;
  margin-top: 3vw;
}

.HraLandingpoints1 {
  margin-right: 2vw;

  margin-left: 7vw;
}

.HraLandingpoints1 ol {
  width: 37vw;
  list-style-position: inside;
  text-indent: -1.6rem;
}

.HraLandingpoints1 li {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  color: #44576a;
  margin-top: 3vw;
}

.HraLandingb1 {
  margin-top: 3vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  list-style-image: url(../../../assets/common/Tick.png);
}

.HraLandingPara {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: var(--globalFontSize20);
  line-height: 150%;
  width: 72vw;
  text-align: center;
  margin-top: 2vw;
  color: #44576a;
  margin-bottom: 3vw;
}


.hraHeaderMbl {
  height: 63vw;
}

.hraHeaderTextMbl {
  margin-top: 7vw;
  position: absolute;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  margin-left: 6.4vw;
  line-height: 150%;
  color: #ffffff;
}

.hraMask {
  height: 63vw;
  background: rgba(45, 4, 0, 0.47);
}

.hraHeader2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin-top: 6vw;
  line-height: 150%;
  /* or 27px */

  text-align: center;
  width: 50vw;
  color: #e68d36;
}

.hraHeader2Body {
  display: flex;
  justify-content: center;
}

.hraHeader2Content {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  width: 90vw;
  margin-top: 4vw;
  line-height: 150%;
  /* or 21px */

  text-align: center;
  margin-bottom: 7vw;
  color: #44576a;
}

.hraHeader3 {
  margin-top: 6vw;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  /* identical to box height, or 27px */

  text-align: center;

  color: #e68d36;
}

.hraPoints1 {
  margin-right: 10.5em;
}

.hraB1 {
  margin-top: 1.5625em;
  font-family: "Montserrat";
  margin-left: 12vw;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */

  color: #44576a;
  /* list-style-image: url(../../../assets/Tick.png); */
  /* list-style-position: inside;
        text-indent: -1.2em; */
}

.hraPoints1 ol {
  width: 85vw;
  list-style-position: inside;
}

.hraPoints1 li {
  text-indent: -0.7em;
}

.hraHeader3Pic {
  height: 57vw;
}

.hraLastContentText {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  padding: 6vw;
  line-height: 150%;
  /* or 21px */

  text-align: center;

  color: #44576a;
}

.M_androidStore {
  width: 30vw;
}

.M_iosStore {
  width: 33.3vw;
  margin-top: -2.19vw;
}

.M_iconandroidios {
  display: flex;
}

.appStore {
  margin-top: 3vw;
  display: flex;
  justify-content: center;
}

.M_CashlessOPDHeadingImage {
  height: 45vw;
}


.M_CashlessOPD_Heading1 {
  font-size: 4.4vw;
  text-align: center;
  padding-top: 12vw;
  font-weight: 500;
  width: 47vw;
  margin-left: 27vw;
  margin-right: 25vw;
  color: #f2efea;
}

.font_FamilyA {
  font-family: "Montserrat";
  font-style: normal;
  line-height: 150%;
}

.hraMaskingNewBanner {
  height: 63vw;
  background: linear-gradient(180deg,
      rgba(158, 70, 70, 0.6) 0%,
      rgba(21, 29, 51, 0.6) 100%);
}

.HraNewBannerContainer {
  background: #eeffff;
  border-radius: 24px 0px 0px 24px;
  margin-left: 20vw;
  width: 73.95vw;
  height: 36vw;
  margin-top: 3vw;
}

.HraNewBannerContainerText1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 4vw;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  padding-left: 8vw;
  color: #e68d36;
  padding-top: 7vw;
}

.HraNewBannerContainerText2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 3.3vw;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #1d2334;
  padding-left: 20vw;
  padding-top: 2vw;
}

.HraNewBannerContainerButton {
  font-size: 3.3vw;
  margin-left: 30vw;
  outline: none;
  border: none;
  background: var(--globalColorHeader);
  border-radius: 12px 0px 0px 12px;
  color: white;
  /* padding-left: 5vw;
    padding-right: 5vw;
    padding-top: 1vw;
    padding-bottom: 1vw; */
  width: 44vw;
  margin-top: 2vw;
}

.DownlaodOurAppBannerBgColorLandingConatinerHra {
  margin-top: 0vw;
  margin-bottom: 4vw;
}

@media screen and (max-width: 900px) {
  .HraLandingwrapper {
    grid-template-columns: 1fr;
  }

  .HraLandingGridTwo {
    display: none;
  }

  .HraLandingpoints1 ol {
    width: fit-content;
  }
}