.accountSettingBody {
  margin-left: 6vw;
  margin-right: 6vw;
  background: #f7f7f7;
  border-radius: 16px;
  padding-bottom: 3vw;
  position: relative;
}

.accountSettingHeading {
  font-family: "Montserrat";
  margin-left: 6vw;
  font-style: normal;
  font-weight: 500;
  font-size: 2em;
  line-height: 150%;
  margin-top: 4vw;
  margin-bottom: 0.4vw;
  color: var(--globalColorHeader);
}

.profileUpdatevalidationDob {
  padding-top: 0.5em;
  padding-right: 1em;
  margin-left: 14.7vw;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.27vw;
  color: #eb2d2d;
}

.profileUpdatevalidationAdd {
  padding-top: 0.5em;
  padding-right: 1em;
  margin-left: 14.7vw;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  color: #eb2d2d;
  font-size: 1.27vw;
}

.accountSettingSubBody {
  background: #ffffff;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  height: 27vw;
  position: relative;
}

.accountSettingUploadMask {
  height: 27vw;
}
.VerifyButtonArea{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  width: 20%;
  height: 40%;
  position: absolute;
  right: 1em;
  bottom: 1em;
}
.accountSettingEditButton {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 150%;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  border: none !important;
  outline: none !important;
  align-items: center;
  padding: 1em;
  background: #ffffff;
  border-radius: 8px;
  color: #00a3ff;
  gap: 0.5em;
  width: 75%;
}
.accountSettingKYCButton {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 150%;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  width: 75%;
  border: none !important;
  outline: none !important;
  align-items: center;
  padding: 1em;
  background: #ffffff;
  border-radius: 8px;
  color: #00a3ff;
  gap: 0.5em;

}
.accountSettingUpload {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 7vw;
}

.faccountFileUploadContainer {
  color: white;
  display: flex;
  margin-left: 8vw;
  margin-top: 0vw;
  flex-direction: row;
}

.accountSelectFileMessage {
  color: white;
  margin-top: 0vw;
  cursor: pointer;
}
.accountErrorDisableModalValidation1 {
  color: white;
}

.accountSettingSaveButton {
  font-family: "Montserrat";
  font-style: normal;
  right: 10vw;
  font-weight: 400;
  font-size: 1.17vw;
  line-height: 150%;
  display: flex;
  float: right;
  margin-right: 8.4vw;
  border: none !important;
  outline: none !important;
  padding-left: 1vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  padding-right: 1vw;
  background: #ffffff;
  border-radius: 8px;
  color: #00a3ff;
}

/* .accountSettingEditButton .css-i4bv87-MuiSvgIcon-root {
  margin-right: 0.3em !important;
  height: 0.8em !important;
  font-size: 2vw !important;
} */
.accountSettingProfilePic {
  height: 5em;
  width: 5em;
  margin-top: -3em;
  border-radius: 50%;
  position: relative;
}

.accountpicBody {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.inputFieldBody {
  background: #f7f7f7;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  margin-left: 3.3vw;
  align-items: center;
}

.inputFieldGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 1.5vw;
}
.inputFieldTextBody1 {
  padding-left: 1em;
  padding-top: 0.6em;
  padding-right: 6em;
  padding-bottom: 0.6em;
  margin-left: 14.7vw;
  background: #f3f0f0;
  border: 1px solid #e7e5e5;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.27vw;
  line-height: 150%;
  border: none;
  outline: none !important;
  color: #44576a;
  border: none;
  outline: none !important;
  color: #44576a;
}

.inputFieldTextBodyCity {
  padding-left: 1em;
  padding-top: 0.6em;
  padding-right: 6.6em;
  padding-bottom: 0.6em;
  margin-left: 14.7vw;
  background: #f3f0f0;
  border: 1px solid #e7e5e5;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.17vw;
  line-height: 150%;
  border: none;
  outline: none !important;
  color: #44576a;
  border: none;
  outline: none !important;
  color: #44576a;
}
.inputFieldTextBody {
  padding-left: 1em;
  padding-top: 0.5em;
  padding-right: 1em;
  padding-bottom: 0.5em;
  margin-left: 14.7vw;
  background: #f3f0f0;
  border: 1px solid #e7e5e5;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.27vw;
  line-height: 150%;
  /* or 24px */

  color: #44576a;
  width: 15em;
  height: 2em;
}
.inputFieldTextBodyDate {
  padding-left: 1em;
  padding-top: 0.5em;
  padding-right: 5.25em;
  padding-bottom: 0.5em;
  margin-left: 14.7vw;
  background: #f3f0f0;
  border: 1px solid #e7e5e5;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.27vw;
  line-height: 150%;
  color: #44576a;
}
.inputFieldTextBodyCity {
  padding-left: 1em;
  padding-top: 0.5em;
  padding-right: 8em;
  padding-bottom: 0.5em;
  margin-left: 14.7vw;
  background: #f3f0f0;
  border: 1px solid #e7e5e5;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.17vw;
  line-height: 150%;
  color: #44576a;
}
.inputFieldTextAreaBody {
  margin-top: 2.5vw;
  padding-left: 1em;
  padding-top: 0.5em;
  padding-right: 1em;
  padding-bottom: 0.5em;
  margin-left: 14.7vw;
  background: #f3f0f0;
  border: 1px solid #e7e5e5;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.37vw;
  line-height: 150%;
  /* or 24px */
  width: 64.5vw;
  color: #44576a;
}

.inputFieldText {
  position: absolute;
  font-size: 1.27vw;
}

.accountSettingBody3 {
  display: grid;
  margin-left: 6vw;
  margin-right: 6vw;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 1.5vw;
  margin-top: 3vw;
  margin-bottom: 5vw;
}

.accountSettingBody4 {
  display: grid;
  margin-left: 6vw;
  margin-right: 6vw;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 1.5vw;
  margin-top: 3vw;
  background: #f1f3f4;
  border-radius: 12px;
  padding-bottom: 1em;
  /* margin-bottom: 5vw; */
}

.accountSettingBody3Subbody {
  position: relative;
  background: #f1f3f4;
  padding-bottom: 1em;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
}
.accountSettingBody4Subbody2 {
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}

.btnAddFamilyAccountSetting {
  background: var(--globalColorHeader);
  border-radius: 12px;
  padding-left: 1vw;
  padding-top: 0.5vw;
  padding-right: 1vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.37vw;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
  border: none !important;
  outline: none !important;
  padding-bottom: 0.5vw;
  text-align: center;
}

.accountSettingBody3SubbodyHeading {
  font-family: "Montserrat";
  padding-left: 2vw;
  padding-top: 1vw;
  font-style: normal;
  font-weight: 500;
  font-size: 1.17vw;
  line-height: 150%;
  /* identical to box height, or 24px */

  color: #333333;
}

.accountSettingBody3SubbodyText {
  font-family: "Montserrat";
  font-style: normal;
  padding-left: 2vw;
  padding-top: 1vw;
  width: 30vw;
  font-weight: 400;
  font-size: 0.88vw;
  line-height: 150%;
  /* or 18px */

  color: #959595;
}

.accountSettingSwitch {
  position: absolute !important;
  float: right;
  top: 30px;
  right: 10px;
}

::-webkit-file-upload-button {
  display: none;
  margin-left: 3vw !important;
}

.editIcon2 {
  position: absolute;
  bottom: 20px;
  right: 10px;
  cursor: pointer;
}

.ProfileEmailValidationInput {
  color: red;
  margin-left: 15vw;
  font-size: 1.2vw;
}

.NumberCheckValidationProfile {
  margin-left: 15vw;
  position: absolute;
  color: red;
}

.UpdateProfileVerifyButton {
  margin-left: 15vw;
  cursor: pointer;
  font-size: 0.8rem;
  text-decoration: underline;
  color: blue;
}

.UpdateProfileVerifyButtonGreen {
  color: rgb(30, 155, 71);
  margin-left: 15vw;
  font-size: 0.8rem;
  cursor: none;
}
@media screen and (max-width: 1080px) {
  .accountSettingEditButton {
    font-size: 0.7em;
    width: 80%;
  }
  .accountSettingKYCButton {
    font-size: 0.7em;
    width: 80%;
  }
}
@media screen and (max-width: 1000px) {
  .accountSettingEditButton {
    font-size: 0.7em;
    width: 80%;
    padding: 0.8em;
  }
  .accountSettingKYCButton {
    font-size: 0.7em;
    width: 80%;
    padding: 0.8em;
  }
  .VerifyButtonArea{
    width: 20%;
    height: 50%;
  }
}
@media screen and (max-width: 820px) {
  .accountSettingEditButton {
    font-size: 0.7em;
    width: 90%;
    padding: 0.8em;
  }
  .accountSettingKYCButton {
    font-size: 0.7em;
    width: 90%;
    padding: 0.8em;
  }
  .VerifyButtonArea{
    width: 20%;
    height: 50%;
  }
}

@media screen and (max-width: 650px) {
  .accountSettingEditButton {
    font-size: 0.7em;
    width: 95%;
    padding: 0.6em;
  }
  .accountSettingKYCButton {
    font-size: 0.7em;
    width: 95%;
    padding: 0.6em;
  }
 
  .inputFieldText {
    font-size: 1.5vw;
  }
  .inputFieldTextBodyDate {
    font-size: 1.5vw;
    padding-right: 9vw;
  }
  .inputFieldTextBody {
    font-size: 1.5vw;
  }
  .inputFieldTextAreaBody {
    font-size: 1.5vw;
    width: 67vw;
  }

  .accountSettingBody3SubbodyHeading {
    font-size: 1.5vw;
  }
  .accountSettingBody3SubbodyText {
    font-size: 1.2vw;
    width: 32vw;
  }
}
@media screen and (max-width: 550px) {
  .accountSettingEditButton {
    font-size: 0.6em;
    width: 90%;
    padding: 0.7em;
  }
  .accountSettingKYCButton {
    font-size: 0.6em;
    width: 90%;
    padding: 0.7em;
  }
  .VerifyButtonArea{
    width: 25%;
    height: 55%;
  }
}
@media screen and (max-width: 500px) {
  .VerifyButtonArea{
    width: 30%;
    height: 55%;
  }
  .UpdateProfileVerifyButton {
    margin-left: 0px;
    font-size: 3.1vw;
  }
  .UpdateProfileVerifyButtonGreen {
    margin-left: 0px;
    font-size: 3.1vw;
  }
  .NumberCheckValidationProfile {
    margin-left: 0px;
    font-size: 3.1vw;
  }
  .ProfileEmailValidationInput {
    margin-left: 0px;
    font-size: 3.1vw;
  }
  .profileUpdatevalidationDob {
    margin-left: 0px;
    font-size: 3.1vw;
  }
  .profileUpdatevalidationAdd {
    margin-left: 0px;
    font-size: 3.1vw;
  }
  .editIcon2 {
    position: absolute;
    cursor: pointer;
    bottom: 20px;
  }
  .accountSettingHeading {
    font-size: 1.5em;
    margin-bottom: 0.8em;
  }
  .accountSettingSubBody {
    height: 35vw;
  }
  .accountSettingUploadMask {
    height: 35vw;
  }
 

  .inputFieldText {
    position: relative;
    font-size: 3.8vw;
    font-style: normal;
    line-height: 150%;
    margin-top: 3vw;
  }
  .inputFieldTextBodyDate {
    background: #f3f0f0;
    border: 1px solid #e7e5e5;
    border-radius: 6px;
    color: #2d354e;
    font-size: 3.8vw;
    font-style: normal;
    font-weight: 100;
    line-height: 150%;
    padding: 2vw 4vw;
    width: 82vw;
    margin: 0;
  }
  .inputFieldTextBodyCity {
    background: #f3f0f0;
    border: 1px solid #e7e5e5;
    border-radius: 6px;
    color: #2d354e;
    font-size: 3.8vw;
    font-style: normal;
    font-weight: 100;
    line-height: 150%;
    padding: 2vw 4vw;
    width: 82vw;
    margin: 0;
  }
  .inputFieldTextBody1 {
    background: #f3f0f0;
    border: 1px solid #e7e5e5;
    border-radius: 6px;
    color: #2d354e;
    font-size: 3.8vw;
    font-style: normal;
    font-weight: 100;
    line-height: 150%;
    padding: 2vw 4vw;
    width: 82vw;
    margin: 0;
  }
  .inputFieldTextBody {
    background: #f3f0f0;
    border: 1px solid #e7e5e5;
    border-radius: 6px;
    color: #2d354e;
    font-size: 3.8vw;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding: 2vw 4vw;
    width: 82vw;
    margin: 0;
  }
  .inputFieldTextAreaBody {
    background: #f3f0f0;
    border: 1px solid #e7e5e5;
    border-radius: 6px;
    color: #2d354e;
    font-size: 3.8vw;
    font-style: normal;
    font-weight: 100;
    line-height: 150%;
    padding: 2vw 4vw;
    width: 82vw;
    margin: 0;
  }
  .accountSettingBody3SubbodyHeading {
    font-size: 2.5vw;
  }
  .accountSettingBody3SubbodyText {
    font-size: 1.2vw;
    width: 32vw;
  }
  .inputFieldGrid {
    grid-template-columns: 1fr;
  }
  .inputFieldBody {
    flex-direction: column;
    align-items: flex-start;
  }
  .accountSettingBody4 {
    padding-top: 0.8em;
  }
  .accountSettingBody3 {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .accountSettingBody3SubbodyText {
    font-size: 2.8vw;
    width: 50vw;
  }
  .btnAddFamilyAccountSetting {
    font-size: 3vw;
    line-height: inherit;
  }
  .accountSettingSwitch {
    top: 15px;
  }
  .accountSettingBody3Subbody {
    padding-top: 0.8em;
  }
  .accountSettingBody3SubbodyHeading {
    font-size: 3vw;
    width: 60vw;
  }
  .accountSettingBody4Subbody2 {
    margin-right: 0.8em;
  }
}

@media screen and (max-width: 350px) {
  .accountSelectFileMessage {
    color: white;
    margin-top: -3vw;
    cursor: pointer;
    font-size: small;
  }
  .accountSettingEditButton {
    font-size: 0.6em;
    width: 100%;
    padding: 0.7em;
  }
  .accountSettingKYCButton {
    font-size: 0.6em;
    width: 100%;
    padding: 0.7em;
  }
  .VerifyButtonArea{
    width: 30%;
    height: 60%;
    right:0.5em;
  }
}

