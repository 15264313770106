.otpBottomDiv {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-right: 5vw;
  margin-top: 0.8em;
  margin-left: 3vw;
}

.expireOTPMessageMobile {
  color: #ff001f;
  margin-top: 0.8rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 150%;
}

.otpResend {
  flex-direction: row-reverse;
}

.otpResend button {
  background-color: white;
}

.UserLoginCorporateButtonNew {
  margin-top: 1em;
}
.submitbuttonNewEmailNew {
  width: 70vw;
  padding-top: 7vw;
  padding-bottom: 7vw;
  outline: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.6vw;
  height: 3.4vw;
  color: white;
  cursor: pointer;
  gap: 2%;
  background: var(--globalColorHeader);
  border: 1px solid var(--globalColorHeader);
  box-shadow: 0px 4px 19px rgba(119, 147, 65, 0.3);
}
@media screen and (max-width: 900px) {
  .otpBottomDiv {
    margin-left: 11vw;
    margin-right: 11vw;
  }
}
