.CashlessOPDHeadingImage {
  background-color: #e2eefe;
  display: flex;
  padding-top: 3em;
  padding-bottom: 2em;
  padding-right: 2em;
  align-items: center;
  justify-content: space-between;
}

.CashlessOPD_Heading {
  margin-left: 2em;
  margin-bottom: 1em;
  font-family: "Montserrat";
  font-style: normal;
}

.CashlessOPD_HeadingTitle {
  font-weight: 600;
  font-size: 3.75em;
}

.CashlessOPD_BlockTwo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.CashlessOPD_BlockTwoHeading {
  font-style: normal;
  font-weight: 600;
  font-size: 3em;
  text-align: center;
  margin-top: 2.875rem;
}

.CashlessOPD_BlockTwoSubHeading {
  margin: 1.43rem 5rem 1.875rem 5rem;
  font-weight: 500;
  font-size: 1.375em;
  text-align: center;
  color: #44576a;
}

.CashlessOPD_BlockTwoSubHeading_Button {
  width: 100%;
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(217, 217, 217, 0.96) 100%
  );
  padding-bottom: 2.6875em;
  display: flex;
  justify-content: center;
}

.CashlessOPD_BlockTwoSubHeading_Button button {
  border: none;
  border-radius: 6px;
  padding: 0.5625em 2vw;
  background-color: #1c71e1;
  font-weight: 500;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  outline: none !important;
}

.OPDSubHeadingPara {
  font-style: normal;
  font-size: 1em;
}

.CashlessProgramButtonM {
  display: flex;
  justify-content: center;
}

.CashlessProgramBtnM {
  background: var(--globalColorHeader);
  border-radius: 12px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  border: none;
  outline: none !important;
  padding-left: 17vw;
  padding-right: 17vw;
  font-size: 3vw;
  padding-top: 3vw;
  padding-bottom: 3vw;
  margin-bottom: 10vw;
}

.CashlessOPD_BlockFour {
  margin-bottom: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.CashlessOPD_BlockFourHeading {
  font-family: var(--globalFont);
  font-style: normal;
  color: var(--globalColorMainHeading);
  font-weight: 500;
  font-size: var(--globalFontSize28);
  line-height: 150%;
  text-align: center;
  margin-top: 4vw;
}

.CashlessOPD_BlockFourSubHeading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  font-size: var(--globalFontSize20);
  line-height: 150%;
  text-align: center;
  text-transform: capitalize;
  color: #44576a;
  margin-top: 0.98rem;
  margin-bottom: 4.1875rem;
}

.CashlessOPD_BlockFour_grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 1.6875rem;
  margin-left: 6vw;
  margin-right: 6vw;
}

.CashlessOPD_BlockFour_grid-items {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CashlessOpdMasking {
  z-index: 99;
  height: 20vw;
  width: 100vw;
}

.CashlessOPD_BlockFour_grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  width: 10em;
  height: 8em;
  border: 1px solid #e3e3e3;
  border-radius: 12px;
}

.CashlessOPD_BlockFourSubHead {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: var(--globalFontSize18);
  line-height: 150%;
  color: #44576a;
}

.androidStore {
  width: 10em;
}

.iosStore {
  width: 9.2em;
  margin-top: 0.6em;
}

.iconandroidios {
  display: flex;
}

.CashlessOPD_Heading1 {
  padding-top: 1.81vw;
  padding-left: 5.6875vw;
  padding-bottom: 3.81vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.8vw;
  color: #ffffff;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.OPDheading {
  padding-top: 1em;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 2em;
  color: black;
}

@media screen and (max-width: 1100px) {
  .CashlessODPbanner2Image {
    width: 25em;
  }
  .CashlessOPD_BlockFour_grid-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 879px) {
  .CashlessODPbanner2Image {
    display: none;
  }
  .CashlessOPD_BlockFour_grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 680px) {
  .CashlessOPD_BlockFour_grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .CashlessOPD_Heading1 {
    flex-direction: column;
  }

  .androidStore {
    width: 15em;
  }

  .iosStore {
    width: 14em;
    margin-top: 1em;
  }
}

@media screen and (max-width: 450px) {
  .CashlessOPD_BlockFour_grid-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .CashlessOPD_HeadingTitle {
    font-size: 2em;
  }
  .OPDSubHeadingPara {
    font-size: 0.75em;
  }
  .CashlessOPD_BlockTwoHeading {
    font-size: 1.5em;
    text-align: left;
    margin-left: 1em;
  }
  .CashlessOPD_BlockTwoSubHeading {
    margin: 1em 1em 1em 2em;
    font-size: 0.75em;
    text-align: left;
  }
  .CashlessOPD_BlockTwoSubHeading_Button {
    width: 70%;
    background: none;
  }
  .CashlessOPD_BlockTwoSubHeading_Button button {
    font-size: 1em;
  }
}
