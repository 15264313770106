.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(37, 44, 66, 0.5);
  z-index: 1;
}
.otpComponent {
  z-index: 1;
}

.numberError {
  position: absolute;
  margin-top: 4.6vw;
  color: red;
  font-size: 12px;
}
.numberError_M {
  position: absolute;
  margin-top: -6vw;
  margin-left: 14vw;
  color: red;
  font-size: 12px;
}
.emailError {
  color: red;
  font-size: 12px;
  width: 80%;
}

.emailError_M {
  position: absolute;
  margin-top: -6vw;
  margin-left: 14vw;
  color: red;
  font-size: 12px;
}
.mainRegister {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* height: 113vh; */
  /* margin-top: 2vh; */
  /* background-color: crimson; */
  margin-top: 2em;
}
.useregEye {
  cursor: pointer;
}
.registerBody {
  background-color: white;
  /* background-color: chartreuse; */
  border-radius: 0px 24px 24px 0px;
  width: 40vw;
  /* background-color: aqua;  */
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  /* padding-top: 11%; */
  /* padding-bottom: 10%; */
}
.formStartsRegister {
  margin-left: 4vw;
  width: 26vw;
  /* height: 100%; */
  /* background-color: violet; */
}
.registerTitle {
  color: #44576a;
  font-family: Montserrat;
  font-size: 1.7vw;
  font-style: normal;
  font-weight: 500;
  height: 3.2vw;
  line-height: 2.4vw;
  margin-left: 0vw;
  margin-top: 3vw;
  max-width: 22vw;
  text-align: left;
  width: 24vw;
}
.googleRegister {
  /* margin-left: 1.5em; */
  margin-top: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  width: 55%;
  margin-bottom: 1em;
  /* background-color: #4172f6; */
  /* height: 10%; */
  /* border: hidden; */
}
.Register {
  display: flex;
  align-items: center;
  justify-content: center;
  /* text-align: center; */
  /* border/: hidden; */
}
.googleRegister button {
  border-radius: 0.6vw;
  width: 32vw;
  /* margin-left: 4vw; */
  padding-top: 3vw;
  /* padding-bottom: 0.61vw; */
  cursor: pointer;
  border: hidden;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.9vw;
  line-height: 2vw;
  color: #ffffff;
}
.googleRegister img {
  padding-left: 3.05em;
}

.orRegister {
  margin-top: 1vw;
  width: 80%;
  height: 6.5%;
  gap: 5%;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.horizontalLineRegister {
  border-top: 2px solid gray;
  width: 42%;
  width: 92%;
}
form {
  margin-top: 1em;
  width: 100%;
  /* background-color: yellow; */
  height: 100%;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
}
.box0Register {
  display: flex;
  width: 26vw;
  height: 3vw;
  margin-bottom: 2em;
  /* background-color:var(--globalColorButton); */
}
.box0Register label {
  width: 85%;
  height: 100%;
}
.box1Register {
  display: flex;
  width: 26vw;
  flex-direction: column;
  height: 3vw;
  margin-bottom: 2em;
  /* background-color:var(--globalColorButton); */
}
.box1Register label {
  width: 85%;
  height: 100%;
  /* padding-top: 2em; */
  /* padding-bottom: 2em; */
}
.box1Register .anchor {
  /* margin-left: 15%; */
  /* padding-left: 1em; */
  /* background-color: aqua; */
  margin: auto;
}
.box2Register {
  display: flex;
  /* flex-direction: column; */

  justify-content: space-between;
  align-items: center;

  width: 26vw;
  height: 3vw;
  margin-bottom: 2em;
  /* background-color:var(--globalColorButton); */
}
.box2Register label {
  width: 85%;
  height: 100%;
}
.box2Register .anchor {
  /* padding-left: 1em; */
  margin: auto;
}
.box3Register {
  /* display: flex; */
  /* display: flex; */
  width: 26vw;
  height: 3vw;
  margin-bottom: 2em;
  /* background-color:var(--globalColorButton); */
}
.registerIdVerify {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 0.8vw;
  line-height: 0.8vw;
  margin-left: 0vw;
  cursor: pointer;
}
.box3Register label {
  width: 85%;
  height: 100%;
}
.box4Register {
  display: flex;
  width: 26vw;
  height: 3vw;
  margin-bottom: 2em;
  /* background-color:var(--globalColorButton); */
}
.box4Register label {
  width: 85%;
  height: 100%;
}
.UserRegisterButton {
  width: 26vw;
  height: 4vw;
  margin-top: 3vw;
  border: hidden;
  /* background-color: aqua; */
}
.registerTextbox {
  display: flex;
  height: 100%;
  background: #f3f0f0;
  border-radius: 6px;
  width: 100%;
  padding-left: 1.125em;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  border: hidden;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}
.errorMessageRegister {
  color: red;
}
.submitbuttonRegister {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--globalColorHeader);
  border-radius: 0.6vw;
  width: 85%;
  /* height: 100%; */
  border: none;
  color: white;
  padding-top: 1vw;
  padding-bottom: 1vw;
  cursor: pointer;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1vw;
  outline: none !important;
  /* background-color: aqua; */
}
.submitbuttonRegister img {
  /* height: 33%; */
  padding-right: 5%;
}
.registerText {
  margin-top: 3vw;
  /* padding-top: 1em; */
  margin-left: 6vw;
  display: flex;
  justify-content: flex-start;
  width: 28vw;
  height: 2vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  /* line-height: 3em; */
  /* background-color: aqua; */
  /* border: 1px solid black; */
  color: #44576a;
}
.registerLink {
  font-weight: 700;
}
.clickTextRegister {
  margin-left: 6vw;
  width: 28vw;
  height: 2vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  /* line-height: 1.5em; */
  /* background-color: aqua; */
  /* border: 1px solid black; */
  display: flex;
  justify-content: flex-start;
}
.clickLinkRegister {
  font-weight: 700;
}
.registerBottom {
  /* margin-left: 10vw; */
  width: 40vw;
  height: 8vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: flex-end; */
  /* background-color: aqua; */
  /* border: 1px solid black; */
  /* text-align: center; */
  padding-top: 2vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  margin-bottom: -3vw;
  font-size: 1vw;
  line-height: 1.8vw;
}
.registerBottom span {
  padding-left: 0%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 1vw;
}
.registerHelp {
  padding-top: 2vw;
  width: 20vw;
  height: 4vw;
  /* background-color: aqua; */
  /* border: 1px solid black; */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 600px) {
  .orRegister {
    width: 75%;
    gap: 5%;
    margin-bottom: 1em;
  }
  .orTextRegister {
    font-family: var(--globalFont);
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 19px;
    color: #44576a;
  }
  .LoginGoogleButtonMobile {
    /* background-color: aqua; */
    width: 75%;
  }
  .Register {
    align-self: center;
  }
  .errorMessageRegister {
    width: 75%;
    margin-bottom: 1em;
  }
  .errorMessage {
    width: 100%;
    font-size: 0.9em;
  }
  .signupArea{
    width: 95%;
  }
}
.LoginMainWrapperMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.LoginHeaderMobile {
  margin-top: 3.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoginHeaderTextMobile {
  position: absolute;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  color: #44576a;
}

.LoginGoogleButtonMobile {
  margin-top: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoginSeperatorMobile {
  margin-top: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LoginFormFieldMobile {
  background: #f3f0f0;
  border-radius: 6px;
  border: none;
  width: 74.5vw;
  margin-left: 12.75vw;
  margin-right: 12.75vw;
  height: 2.625em;
  padding-left: 1.125em;
  margin-bottom: 1em;
}

.LoginFormFieldFPMobile {
  background: #f3f0f0;
  border-radius: 6px;
  border: none;
  width: 74.5vw;
  margin-left: 12.75vw;
  margin-right: 12.75vw;
  height: 2.625em;
  padding-left: 1.125em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.LoginFormSubmitButtonMobile {
  background: var(--globalColorHeader);
  border-radius: 6px;
  color: #ffffff;
  border: none;
  width: 74.5vw;
  margin-left: 12.75vw;
  margin-right: 12.75vw;
  height: 2.625em;
}

.LoginFormFieldWrapperMobile {
  width: 80vw;
}

.LoginFormSubmitButtonImageMobile {
  margin-right: 10px;
}

.LoginGoogleButtonMobile2 {
  background: #4172f6;
  border-radius: 6px;
  color: #ffffff;
  border: none;
  width: 74.5vw;
  margin-left: 12.75vw;
  margin-right: 12.75vw;
  height: 2.625em;
}

.horizontalLineMobile {
  border-top: 2px solid rgb(143, 7, 7);
}

.LoginForgotPasswordMobile {
  margin-top: 0.5em;
  margin-left: 59.2vw;
}

.LoginForgotPasswordTextMobile {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #44576a;
}

.LoginLinkSection3Mobile {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.LoginImageMobile {
  width: 100vw;
}

.LoginLinkSection1Mobile {
  margin-top: 2em;
}

.RegisterVerifyButtonMobile {
  margin-left: 17.75vw;
  margin-top: -8px;
  display: flex;
}
