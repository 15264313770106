.SearchModalMainContainer {
  position: absolute;
  left: 0px;
  padding-top: 0px;
  margin-top: 3.9vh;
  padding-bottom: 0px;
  margin-bottom: 0px;
  overflow: hidden;
  z-index: 99;
  background-color: white;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: black;
  border-radius: 0px 0px 12px 12px;
  width: 100%;
  padding-bottom: 10px;
}

.SearchModalMainContainerclose {
  display: none;
}

.SearchModalMainContainerGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.SearchModalMainContainerImage {
  border-radius: 0px 0px 0px 12px;
  width: 14.4vw;
}

.SearchModalMainText {
  margin-left: 1vw;
  margin-top: 1vw;
  color: var(--globalColorHeader);
  font-size: 0.75em;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
}

.SearchModalMainSubText {
  color: #44576a;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.625em;
  line-height: 150%;
  margin-top: 1vw;
  margin-left: 1vw;
  cursor: pointer;
  width: 10vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.SearchModalMainSubText:hover {
  color: blue;
}

.SearchModalMainContainerBtn {
  position: absolute;
  display: contents;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background: transparent;
}

.SearchModalMainContainerButton {
  background: transparent;
  margin-right: 1vw;
}

@media screen and (max-width: 1000px) {
  .SearchModalMainContainerImage {
    width: 9em;
    height: 15em;
  }
}

@media screen and (max-width: 680px) {
  .SearchModalMainContainerImageDiv {
    display: none;
  }

  .SearchModalMainContainerImage {
    display: none;
    width: 0px;
    height: 0px;
  }

  .SearchModalMainContainerGrid {
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    row-gap: 10px;
    padding-bottom: 1em;
    padding-left: 1em;
    padding-top: 0.5em;
  }

  .SearchModalMainSubText {
    width: 20em;
  }
}

@media screen and (max-width: 480px) {
  .SearchModalMainContainerGrid {
    grid-template-columns: 1fr;
  }
}
