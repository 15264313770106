.SectionWrapper {
  margin-top: 100px;
}

.FieldsWrapper {
  display: flex;
  flex-direction: column;
}
.errorMessageValidation {
  color: red;
  font-size: 0.8em;
  margin-top: 0.8em;
  margin-left: 0.8em;
}
.inputField {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 17px;
  color: #44576a;
}
.infoMentalHealth {
  display: inline-block;
  margin-left: 0.5em;
}
.infoMentalHealth .myServicesDescriptionText {
  visibility: hidden;
  width: 30vw;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 2px;
  border-radius: 6px;
  position: absolute;
  z-index: 9999;
}
.infoMentalHealth:hover .myServicesDescriptionText {
  visibility: visible;
}

.infoflex {
  display: flex;
  text-align: center;
  align-items: center;
}
.hraDenied {
  background-color: yellowgreen;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2em;
  padding-bottom: 2em;
  background: #e68d36;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1em;
  line-height: 150%;
  color: #ffffff;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10em;
  padding-left: 1em;
  padding-right: 1em;
}

@media screen and (max-width: 980px) {
  .infoMentalHealth .myServicesDescriptionText {
    width: 60vw;
  }
}
