.menuBody {
  position: absolute;
  z-index: 9992;
  right: 0;
  background: #e1eeff;
  padding-bottom: 5vh;
  top: 0;
  width: 21em;
}

a li {
  list-style-type: none;
}

a {
  color: inherit;
}
.lists {
  z-index: 9993;
  margin-left: 2em;
  margin-top: -1em;
}

.menuBtns {
  z-index: 9993;
  margin-top: 1.6em;
}
.menuBtnsName {
  z-index: 9993;
  margin-top: 1.6em;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 3.5vw;
  line-height: 150%;

  display: flex;
  align-items: center;
  text-align: center;

  color: #44576a;
}

.menuBtnss1 {
  z-index: 9993;
  margin-top: 1.6em;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  display: flex;
  align-items: center;

  color: #44576a;

  margin-right: 2vw;
}

.menuBtnss {
  z-index: 9993;
  margin-top: 1.6em;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  display: flex;
  align-items: center;

  color: var(--globalColorHeader);

  margin-right: 2vw;
}
.menuBtns1 {
  z-index: 9993;
  margin-top: 2em;
}
.iconNave {
  margin-right: 3vw;
}

.menuBtns2 {
  margin-left: 2em;
  margin-top: 1em;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  display: flex;
  align-items: center;

  color: #5d7083;
}

.MenuClose {
  display: flex;
  justify-content: space-between;
  margin-right: 2em;
}

.menuBtns3 {
  z-index: 9993;
  margin-top: 1.6em;
  margin-bottom: 1em;
}

.menuCross {
  z-index: 9999;
  margin-left: 20em;
}

.dropdown-itemMbl {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-top: 2em;

  color: #44576a;
}
.dropdown-itemLogout:hover {
  cursor: pointer;
}

.loginNregister {
  display: flex;
  flex-direction: row;
}

.navLine {
  color: #44576a;
  font-size: 0.6em;
}

.MblNavigationBody {
  display: flex;
  flex-direction: column;
}

.iconProfile {
  margin-right: 1em;
}
