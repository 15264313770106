.CareerHeading {
  background-color: #e2eefe;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding: 5em 2em;
}

.CareerHeading p {
  font-size: 3em;
  font-weight: 700;
}

.CareerHeadingImage {
  width: 30em;
  height: 12em;
  border-radius: 10px;
}

.CareerHeading2 {
  text-align: center;
  margin-top: 2em;
  font-style: normal;
  font-weight: 600;
  font-size: 2em;
  padding: 0 2em;
}

.CareerSubHeading {
  display: flex;
  justify-content: center;
  margin-top: 1em;
  font-weight: 400;
  font-size: 1em;
  margin-left: 2em;
  margin-right: 2em;
  text-align: center;
}

.Careerwrapper {
  display: grid;
  margin-top: 2em;
  grid-gap: 2em;
  height: 24vw;
  margin-left: 2em;
  margin-right: 2em;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.Careerwrapper3 {
  display: grid;
  margin-top: 2em;
  grid-gap: 2em;
  height: 24vw;
  margin-left: 2em;
  margin-right: 2em;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.design {
  background-color: grey;
  height: 20px;
  justify-content: flex-end;
}

.careerImg {
  background-color: white;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 1vw;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  flex-direction: column;
}

.CareerImage {
  display: flex;
  height: 5vw;
  width: 5vw;
  margin-bottom: 3vw;
}

.CareerImage2 {
  display: flex;
  position: absolute;
  height: 3vw;
  width: 3vw;
  float: left;
  justify-content: right;
  align-items: right;
}

.vectorName {
  margin-top: 12vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.2vw;
  line-height: 150%;
  width: 19vw;
  position: absolute;
  text-align: center;
  color: #44576a;
}

.CareerSubHeading2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  font-size: 1.2em;
  margin-top: 1em;
  text-align: center;
  color: #44576a;
}

.vectorName1 {
  margin-top: 10vw;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1.2vw;
  line-height: 150%;
  text-align: center;
  color: #44576a;
}

@media screen and (max-width: 700px) {
  .CareerHeadingImage {
    width: 20em;
    height: 10em;
  }

  .Careerwrapper {
    grid-template-columns: 1fr 1fr;
    height: 60vw;
  }

  .Careerwrapper3 {
    grid-template-columns: 1fr 1fr;
    height: 60vw;
  }
}

@media screen and (max-width: 550px) {
  .CareerHeadingImage {
    display: none;
  }
}

@media screen and (max-width: 426px) {
  .Careerwrapper {
    grid-template-columns: 1fr;
    height: 40em;
  }

  .Careerwrapper3 {
    grid-template-columns: 1fr;
    height: 40em;
  }

  .vectorName {
    font-size: 3em;
    width: auto;
  }

  .CareerImage {
    width: 7em;
    height: 7em;
  }

  .CareerImage2 {
    width: 7em;
    height: 7em;
  }

  .vectorName1 {
    margin-top: 12vw;
    font-size: 3em;
    width: auto;
  }
}
