.AddressSelectArea {
  background: #fff;
  border: 1px solid #CFCFCF;
  border-radius: 10px;
  padding-top: 2vw;
  width: 95%;
  height: fit-content;
  max-height: 100%;
  overflow-y: auto;
  padding-bottom: 3em;
  margin: 0 auto;
}

.AddressList {
  display: flex;
  padding-top: 1.1em;
  padding-bottom: 1.1em;
  border: 1px solid #DBDBDB;
  border-radius: 10px;
  margin-left: 2em;
  margin-right: 2em;
  padding-left: 1.1em;
  padding-right: 1.1em;
  margin-top: 1.1em;
  display: flex;
  flex-direction: column;
  background-color: #F8F8F8;
}

.AddressListUpper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AddressListUpperHeading {
  margin-top: auto;
  margin-bottom: auto;
  font-family: var(--globalFont);
  font-size: var(--globalFontSize22);
  font-weight: 700;
  line-height: 33px;
  text-align: center;
color: var(--globalColorHeader);
}
.AddressListUpperHeadingMember{
  margin-top: auto;
  margin-bottom: auto;
  font-family: var(--globalFont);
  font-size: var(--globalFontSize22);
  font-weight: 700;
  line-height: 33px;
  text-align: left;
color: var(--globalColorHeader);
}
.AddressListUpperButton {
  font-family: var(--globalFont);
  font-size: var(--globalFontSize12);
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  background: #1C71E1;
  color: #fff;
  padding: 0.8em 2em 0.8em 2em;
  border-radius: 9px;
}
.AddressListLowerParent{
  display: grid;
  grid-template-columns: 1fr;
}
.AddressListLower {
  display: flex;
  padding-top: 1.1em;
  padding-bottom: 1.1em;
  background-color: #F6F6F6;
  border: 1px solid #DBDBDB;
  border-radius: 10px;
  margin-top: 1.1em;
  cursor: pointer;
}

.AddressListLowerDateAndTime {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.1em;
  padding-bottom: 1.1em;
  padding-left: 1.1em;
  padding-right: 1.1em;
  background-color: #F6F6F6;
  border: 1px solid #DBDBDB;
  border-radius: 10px;
  margin-top: 1.1em;
  cursor: pointer;
}
.AddressListLowerDateAndTimeFPP {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.1em;
  padding-bottom: 1.1em;
  padding-left: 1.1em;
  padding-right: 1.1em;
  background-color: #F6F6F6;
  border: 1px solid #DBDBDB;
  border-radius: 10px;
  margin-top: 1.1em;
  cursor: pointer;
}
.SelectPaymemtLeft {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 40%;
}
.SelectPaymemtLeft input{
  width: 1.1em;
  height: 1.1em;
}
.SelectPaymentTerms {
  font-family: var(--globalFont);
  font-size: var(--globalFontSize16);
  font-weight: 300;
  line-height: 24px;
  margin-top: auto;
  margin-bottom: auto;
}

.SelectPaymemtLeftLabel {
  margin-left: 1.1em;
  margin-top: auto;
  margin-bottom: auto;
}

.DateAndTimeSelectHeading {
  margin-top: auto;
  margin-bottom: auto;
  font-family: var(--globalFont);
  font-size: var(--globalFontSize16);
  font-weight: 500;
  line-height: 24px;

}

.AddressListLower1 {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.AddressListLower1 input{
  width: 1.1em;
  height: 1.1em;
  cursor: pointer;
}
.AddressListLower2 {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.AddressListLower3 {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AddressListLower2Name {
  font-family: var(--globalFont);
  font-size: var(--globalFontSize16);
  font-weight: 400;
  line-height: 24px;

}

.AddressListLower2Address {
  font-family: var(--globalFont);
  font-size: var(--globalFontSize16);
  font-weight: 300;
  line-height: 24px;

}

.AddressListLower3Location {
  font-family: var(--globalFont);
  font-size: var(--globalFontSize12);
  font-weight: 400;
  line-height: 24px;
}
.DateAndTimeStyle{
  width: 60%;
                      border-radius: 12px;
                      background-color: #FFFFFF;
}

@media screen and (max-width:1300px) {
  .AddressSelectArea {
    width: 100%;
  }
}

@media screen and (max-width:800px) {
  .AddressSelectArea {
    width: 100%;
    padding: 0em;

  }

  .AddressList {
    margin-left: .5em;
    margin-right: .5em;
    padding-left: .5em;
    padding-right: .5em;
  }

  .AddressListUpperHeading {
    font-size: var(--globalFontSize20);
  }

  .AddressListUpperButton {
    font-family: var(--globalFont);
    font-size: var(--globalFontSize12);
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    background: #1C71E1;
    color: #fff;
    padding: 0.5em 1em 0.5em 1em;
    border-radius: 9px;
  }

  .AddressListLower2Name {
    font-family: var(--globalFont);
    font-size: var(--globalFontSize10);
    font-weight: 400;
    line-height: 24px;

  }

  .AddressListLower2Address {
    font-family: var(--globalFont);
    font-size: var(--globalFontSize10);
    font-weight: 300;
    line-height: 24px;

  }

  .AddressListLower2 {
    width: 65%;
  }

  .AddressListLower3 {
    width: 25%;
  }

  .AddressListLower3Location {
    font-size: var(--globalFontSize10);

  }

}
@media screen and (max-width:600px) {
  .AddressListLowerParent{
    display: grid;
    grid-template-columns: 1fr ;
    gap: 1em;
  }
  .AddressListLower {
    flex-direction: column;
  }
  .AddressListLower1 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1em;
  }
  .AddressListLower2 {
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    padding-right: 1em;
    padding-left: 1em;
    text-align: left;
/* margin-top: 0.5em; */
  }
  .AddressListLower2Name{
    margin-bottom: -0.6em;
  }
  .AddressListLower3 {
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    padding-right: 1em;
    padding-left: 1em;

margin-top: -0.4em

  }
 .AddressListLower2Address{
  margin-right: 1em;
 }
  .AddressListLower {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .AddressListLower3Member{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}
@media screen and (max-width:500px) {
  .AddressListLowerDateAndTime {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    
  }
  .AddressListLowerDateAndTimeFPP {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    
  }
  .SelectPaymemtLeft {
    width: 80%;
  }
  .SelectPaymemtLeftLabel {
    margin-left: 1.1em;
    margin-top: auto;
    margin-bottom: auto;
  }
  .SelectPaymentTerms {
    align-self: flex-end;
    margin-top: 1em;
    text-align: right;
  }
  .DateAndTimeSelectHeading {
    margin-top: 1em;
    margin-bottom: 2em;
  
  }
  .DateAndTimeStyle{
    width: 80%;
  }
}
@media screen and (max-width:400px) {
  .AddressListUpper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
 
  .AddressListUpperHeading {
   
    text-align: center;
    margin: 0; /* Remove default margin */
}

.AddressListUpperButton {
  align-self: flex-end;
    padding: 0.8em 2em 0.8em 2em;
    margin-top: 1em;
}
}
@media screen and (max-width:300px) {
  .SelectPaymemtLeft {
    width: 100%;
  }
  .SelectPaymentTerms {
    font-size: var(--globalFontSize10);
  }
}