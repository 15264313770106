.NavbarMain {
  position: relative;
}

.NavbarMainUpper {
  display: flex;
  background-color: #e1eeff;
  /* gap: 50px; */
  justify-content: space-between;
}

.NavbarMainUpperChild1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
  flex: 1;
}

.companyLogoNavbarLink {
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.companyLogoNavbar {
  width: 60%;
  height: auto;
  margin-top: 1.15em;
}

.NavbarMainUpperChild2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 5;
}

.NavbarMainUpperChild3 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 1em;
  width: 10%;
  padding-top: 1em;
  flex: 1;
}

.cityNameSelect {
  border: hidden;
  width: 60%;
  background: transparent;
  color: #000000;
  font-family: var(--globalFont);
  font-size: 0.8em;
  font-weight: 500;
  line-height: 24.38px;
}

.NavbarMainUpperChild4 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
  padding-top: 1em;
  flex: 1;
}

.navbarSideButtons {
  display: flex;
  align-items: center;
  width: max-content;
}

.navbarSideButtons button {
  background: transparent;
  border: hidden;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: var(--globalFontSize14);
  color: #44576a;
  outline: none !important;
}

.profile {
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.dropdown-menu {
  text-align: initial !important;
  width: auto !important;
  margin-left: -11em;
  margin-top: 0.6em;
  color: #44576a !important;
  text-align: center;
  background-color: #e1eeff;
}

.dropdown-item {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: var(--globalFontSize12);
  line-height: 17px;
  margin-top: 1em;

  color: var(--globalColorHeader);
}

.dropdown-item:hover {
  cursor: pointer;
}

.iconProfile {
  margin-right: 1em;
}

.accountLine {
  color: #44576a;
  font-size: 10px;

  margin-left: 1em;
  margin-right: 1em;
}

.dropdown-itemLogout:hover {
  cursor: pointer;
}

.dropdown-itemLogout {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: var(--globalFontSize12);
  /* margin-left: 2vw; */
  margin-bottom: 1em;
  padding: .25rem 1.5rem;
  line-height: 17px;
}

.loginButton:hover {
  cursor: pointer;
}

.registerButton:hover {
  cursor: pointer;
}

.NavbarMainLower {
  display: flex;
  align-items: baseline;
  background-color: #e1eeff;
  padding-top: 1em;
  padding-bottom: 1em;
}

.searchBar {
  position: relative;
  width: 82%;
  margin-top: 1em;
  margin-left: 8.6vw;
  background: #e1eeff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid black;
  margin-right: 1vw;
  padding-right: 1em;
}

.searchBar input {
  background-color: #e1eeff;
  width: 90%;
  border: hidden;
  border-radius: 8px;
  padding: 0.6em;
  font-family: var(--globalFont);
  font-size: 1em;
  font-weight: 500;
  line-height: 27px;
  color: #8d8d8d;
}

.NavbarMainLower2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
}

.NavbarMainLower2 button {
  font-family: var(--globalFont);
  font-size: var(--globalFontSize14);
  font-weight: 600;
  line-height: 17.07px;
  width: 100%;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  border-radius: 8px;
  background-color: #1c71e1;
  color: #fff;
}

.NavbarMainLower3 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
}

.shoppingBagButton {
  background: transparent;
  border: hidden;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.87rem;
  color: #44576a;
}

.shoppingBagButton:hover {
  cursor: pointer;
}

.ShoppingCartImageSize {
  width: 1.9vw;
  height: 1.8vw;
}

.dropdown {
  display: flex;
}