.orderDetails {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60vw;
  padding: 1.5em 1.5em;
  background: #ffffff;
  border-radius: 16px;
}
.orderDetailsHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.orderDetailsTitle {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1.25em;
  line-height: 150%;
  text-transform: capitalize;
  color: var(--globalColorHeader);
}
.orderDetailsCoupon {
  background: #eff4f8;
  border-radius: 12px;
  margin-top: 1.5em;
}
.orderDetailsCoupon p {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.6em;
  line-height: 150%;
  color: #44576a;
  padding-top: 0.8em;
  padding-bottom: 0.7em;
  padding-left: 1.1em;
}
.orderDetailsStart {
  border-bottom: 1px solid #d6dcee;
  display: flex;
  justify-content: space-between;
}
.orderDetailsStartLeft {
  width: 30%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1.1em;
}
.orderDetailsStartLeft p {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 17px;
  color: #44576a;
}
.orderDetailsStartRight {
  width: 23%;
  display: flex;
  justify-content: space-between;
  padding-right: 1.1em;
}
.orderDetailsStartRight :nth-child(1) {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 150%;
  text-decoration-line: line-through;
  color: #2667FF;
}
.orderDetailsStartRight :nth-child(2) {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 150%;
  color: #44576a;
}
.orderDetailsDiscount {
  display: flex;
  justify-content: space-between;
  padding-left: 1.1em;
  padding-right: 1.1em;
  margin-top: 1em;
}
.orderDetailsDiscount :nth-child(1) {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 17px;
  color: #44576a;
}
.orderDetailsDiscount :nth-child(2) {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 150%;
  color: #44576a;
}
.orderDetailsTotal {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5em;
}
.orderDetailsTotalLeft {
  padding-left: 1.1em;
}
.orderDetailsTotalLeft p {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 17px;
  color: #44576a;
}
.orderDetailsTotalRight {
  display: flex;
  width: 23%;
  justify-content: space-between;
  padding-right: 1.1em;
}
.orderDetailsTotalRight :nth-child(1) {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 150%;
  text-decoration-line: line-through;
  color: #2667FF;
}
.orderDetailsTotalRight :nth-child(2) {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 150%;
  color: #44576a;
}
.fareBreakup {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 17px;
  color: #44576a;
}
.orderDetailsCross {
  cursor: pointer;
}
@media screen and (max-width: 1000px) {
  .orderDetails {
    width: 80%;
    padding: 1em 1em;
  }
}
@media screen and (max-width: 500px) {
  .orderDetails {
    width: 95%;
    padding: 1em 1em;
  }

  .orderDetailsTotalRight {
    width: 35%;
  }
  .orderDetailsStartRight {
    width: 35%;
  }
  .orderDetailsStartRight :nth-child(1) {
    font-size: 0.7em;
  }
  .orderDetailsStartRight :nth-child(2) {
    font-size: 0.7em;
  }
  .orderDetailsTotalRight :nth-child(1) {
    font-size: 0.7em;
  }
  .orderDetailsTotalRight :nth-child(2) {
    font-size: 0.7em;
  }
  .orderDetailsDiscount :nth-child(1) {
    font-size: 0.7em;
  }
  .orderDetailsDiscount :nth-child(2) {
    font-size: 0.7em;
  }
  .orderDetailsTotalLeft p {
    font-size: 0.7em;
  }
  .orderDetailsStartLeft p {
    font-size: 0.7em;
  }
  .orderDetailsStart {
    padding-top: 0.5em;
  }
}
