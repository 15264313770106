.finalPaymentNewPageWrapper {
  padding: 5vw;
}

.finalPaymentNewPageMain {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.finalPaymentNewPageLeftPartWrapper {
  padding: 1em;
}



.finalPaymentNewPageHeaderText {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 2em;
  line-height: 150%;
  text-transform: capitalize;
  color: var(--globalColorHeader);
}

.finalPaymentNewPageAddressblock {
  width: 100%;
  background: #ffffff;
  border: 1px solid #8b94b2;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 1vw;
}

.finalPaymentNewColumn {
  display: flex;
  justify-content: space-between;
}

.finalPaymentNewAddressName {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1.4vw;
  line-height: 150%;
  color: #001246;
  margin-bottom: 0.2em;
}

.finalPaymentNewAddress {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 1.2vw;
  line-height: 150%;
  color: #001246;
  margin-bottom: 0.2em;
}

.imgPaymentNew {
  height: 1em;
  width: 1em;
}

.CouponsAreaNewPlan {
  max-height: 51vh;
  overflow-y: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 1em;
}

.CartMainSecondContentCouponNew {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
}

.couponHeaderNew {
  margin-top: 1em;
  font-size: 2em;
  color:var(--globalColorHeader);
  font-weight: 500;
}

.finalPaymentNewRightPartWrapper {
  background: #f2f4f7;
  border: 1px solid #bdbbbb;
  border-radius: 12px;
  /* width: 50%; */
  padding: 2vw;
  max-height: 47vw;
  overflow-y: auto;
}

.finalPaymentPlanNewDescriptionCard {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.finalPaymentPlanNewDescriptionCardImage {
  width: 5em;
  height: 3em;
}

.finalPaymentPlanNewDescriptionHeader {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: var(--globalFontSize16);
  line-height: 150%;
  color: var(--globalColorHeader);
  text-transform: capitalize;
  width: 100%;
}

.finalPaymentPlanNewDescriptionQuantity {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: var(--globalFontSize16);
  line-height: 150%;
  text-align: center;
  color: var(--globalColorHeader);
  text-transform: capitalize;
}

.finalPaymentPlanNewDescriptionCardpart1Right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.finalPaymentPlanNewDescriptionCardpart1Heading {
  display: flex;
  align-items: baseline;
}

.finalPaymentPlanNewDescriptionHeaderPrice {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size:var(--globalFontSize16);
  line-height: 150%;
  color: var(--globalColorHeader);
}

.finalPaymentPlanNewDescriptionCardpart1 {
  display: flex;
  width: 80%;
}

.finalPaymentPlanNewDescriptionCardpart1sub2 {
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.finalPaymentPlanNewDescriptionP1 {
  display: flex;
  justify-content: space-between;
}

.finalPaymentPlanNewDescriptionP1New {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.finalPaymentPlanNewDescriptionFixedText2 {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: var(--globalFontSize16);
  line-height: 32px;
  color: #001246;
}
.finalPaymentPlanDescriptionMarketingText {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: var(--globalFontSize16);
  color: #44576a;
  margin-top: auto;
  margin-bottom: auto;
}
.finalPaymentPlanNewDescriptionFixedText4 {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: var(--globalFontSize16);
  line-height: 150%;
  text-align: center;
  color: #319b4b;
  margin-top: auto;
  margin-bottom: auto;
}

.CodeAlertNew {
  display: flex;
  margin-bottom: 1em;
  position: relative;
}

.CodeAlertNewInput {
  width: 100%;
  border: hidden;
}

.CouponCodeCartNew {
  background: #ffffff;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  width: 100%;
  padding: 0.5em;
  border: hidden;
  font-size: var(--globalFontSize16);
}

.CouponApplyButtonNew {
  background: #1d2334;
  border-radius: 6px;
  padding: 0.5em;
  position: absolute;
  width: 15%;
  right: 0;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.25);
  color: #ffffff;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  background: var(--globalColorHeader);
  height: 100%;
}

.finalPaymentPlanNewAppliedCoupon {
  background: #d5d9e7;
  border: 1px solid #213473;
  border-radius: 5px;
  padding: 0.3em;
  display: flex;
  align-items: center;
  text-align: center;
}

.finalPaymentPlanNewAppliedCoupon :nth-child(1) {
  margin-left: 0.5em;
  margin-top: auto;
  margin-bottom: auto;
  height: 95%;
}

.finalPaymentPlanNewAppliedCoupon :nth-child(2) {
  margin-left: 1em;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 1em;
}

.finalPaymentPlanNewAppliedCoupon :nth-child(3) {
  margin-right: 0.5em;
  width: 10%;
  height: 90%;
}

.finalPaymentPlanNewDescriptionMarketing {
  display: flex;
  align-items: center;
  margin-top: 2em;
}

.AppliedcouponNewRight {
  margin-left: 2em;
}

.AppliedcouponNewRight img {
  cursor: pointer;
}

.finalPaymentPlanNewDropdown {
  align-items: center;
  margin-bottom: 1em;
  background: #ffffff;
  border-radius: 6px;
  padding: 0.5em;
  margin-left: 1vw;
}

.buttonBlock {
  width: 50%;
  margin-left: 50%;
}

.buttonBlock button {
  width: 100%;
  padding-top: 1.25em;
  padding-bottom: 1.25em;
  margin-top: 1.3em;
  bottom: 0;
  background: var(--globalColorHeader);
  box-shadow: 0px 4px 16px rgba(35, 221, 237, 0.16), 0px 4px 8px -2px #030c28;
  border-radius: 4px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1em;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  border: hidden;
  cursor: pointer;
}

.CartCardContentNewplans {
  background: #ffffff;
  border: 1px dashed #8b94b2;
  border-radius: 12px;
  width: 45%;
  height: 10em;
  margin-bottom: 1em;
}

.CartCardContentChild1plan {
  margin-top: 1em;
  padding-left: 1em;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: var(--globalFontSize16);
  color: #000000;
  height: 3em;
}

.CartCardContentChild2plan {
  margin-top: 1em;
  margin-bottom: 1em;
  padding-left: 1.5em;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: var(--globalFontSize16);
  line-height: 20px;
  color: #163ed4;
}

.CartCardContentChild3Plan {
  display: flex;
  margin-top: 1.5em;
  padding-bottom: 1em;
  justify-content: flex-end;
padding-right: 1em;
}
.couponIcon{
  margin-right: 0.5em;
}
.CopyCodePlan {
  width: 40%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 0.7em;
}

.CopyCodePlan p {
  margin-top: auto;
  margin-bottom: auto;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.7em;
  line-height: 20px;
  color: #001246;
  cursor: pointer;
}

.ApplyCodeplan {
  width: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1em;
  position: relative;
}
.NoCouponAvaibleTokenPlan{
color: var(--globalColorAddedButton);
font-size: var(--globalFontSize16);

}
.ApplyCodeplan p {
  margin-top: auto;
  margin-bottom: auto;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 600;
  font-size: var(--globalFontSize16);
  line-height: 20px;
  color: #001246;
  cursor: pointer;
}

.CouponsAreaNewPlanMobile {
  display: flex;
  flex-direction: column;
}

.CartCardContentNewplansMobile {
  background: #ffffff;
  border: 1px dashed #8b94b2;
  border-radius: 12px;
  margin-left: 1.2em;
  margin-right: 1.2em;
  margin-top: 1em;
  /* height: 20vh; */
  position: relative;
}

.couponHeaderNewMobile {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 1.2em;
  line-height: 150%;
  text-transform: capitalize;
  color: #e68d36;
  margin-left: 1.2em;
  margin-top: 1em;
}

.CartCardContentChild2planMobile {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.7em;
  line-height: 20px;
  color: var(--globalColorHeader);
  padding-left: 1.2em;
}

.ApplyCodeplanMobile {
  position: absolute;
  top: 20%;
  display: flex;
  left: 70%;
  align-items: center;
  gap: 1em;
}

.ApplyCodeplanMobile p {
  margin-top: auto;
  margin-bottom: auto;
}

.finalPaymentPageWrappermobile3 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.finalPaymentPageWrappermobile3Child {
  background: #ffffff;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 40%;
  padding-top: 1em;
  padding-bottom: 1em;
}

.finalPaymentPlanNewDescriptionFixedText2Mobile {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 150%;
  color: #1d2334;
  margin-left: 1.2em;
  margin-top: 1.5em;
}

.CodeAlertNewMobile {
  width: 92%;
  margin-top: 1em;
  display: flex;
  margin-bottom: 1em;
  position: relative;
  margin-left: 1.2em;
}

.CodeAlertNewInputMobile {
  width: 100%;
  border: hidden;
}

.finalPaymentPlanNewDescriptionP1NewMobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 92%;
  margin-left: 1.2em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.finalPaymentPageWrappermobile5Mobile {
  margin-left: 1.2em;
  margin-right: 1.2em;
  margin-top: 1em;
}

.finalButtonPaymentMobile {
  background: var(--globalColorHeader);
  border-radius: 6px;
  width: 100%;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 1.1em;
  line-height: 150%;
  color: #ffffff;
  margin-bottom: 4em;
}

.finalPaymentPageWrappermobile3Mobile {
  margin-left: 1.2em;
  margin-right: 1.2em;
  display: flex;
  justify-content: center;
  margin-top: 1em;
  border-top: 1px solid #dfe2e4;
  padding-top: 1em;
}

.finalPaymentPageWrappermobile4_4Mobile {
  margin-left: 1.2em;
  margin-right: 1.2em;
}

.OrderSummaryPlanFinalContentMobile {
  margin-top: 2vh;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 550;
  font-size: 16px;
  line-height: 16px;
  color: #2d354e;
  display: flex;
  justify-content: space-between;
  margin-right: 6vw;
  align-items: baseline;
}



@media screen and (max-width : 1300px) {
 
  .finalPaymentPlanNewAppliedCoupon {
    padding: 0.1em;
}
.ApplyCodeplan {
  width: 60%;
  padding-left: 1em;
}
.ApplyCodeplan p {
  font-size: var(--globalFontSize14);
}
.CartCardContentChild1plan {
  font-size: var(--globalFontSize14);
}
.CartCardContentChild2plan {
  margin-top: 1em;
  margin-bottom: 1em;
  padding-left: 1.5em;
  font-size: var(--globalFontSize14);
}
.finalPaymentPlanNewDescriptionHeader {
  font-size: var(--globalFontSize14);
}
.finalPaymentNewPageHeaderText{
font-size: 1.5em;
}
.couponHeaderNew{
font-size: 1.5em;

}
.finalPaymentPlanNewDescriptionHeaderPrice {
  font-size:var(--globalFontSize14);
  
}
.finalPaymentPlanNewDescriptionQuantity {
  font-size:var(--globalFontSize14);
  
}
.finalPaymentPlanNewDescriptionFixedText2 {
  font-size:var(--globalFontSize14);
  
}
.finalPaymentPlanNewDescriptionFixedText4 {
  font-size:var(--globalFontSize14);
  
}
.finalPaymentPlanDescriptionMarketingText {
  font-size:var(--globalFontSize14);
  
}
.CouponCodeCartNew {
  font-size: var(--globalFontSize14);
}
}
@media screen and (max-width : 900px) {
  .finalPaymentNewPageMain {
    grid-template-columns: 1fr;
  }
  .finalPaymentNewPageHeaderText{
    font-size: 1.4em;
  }
  .finalPaymentNewAddressName {
    font-family: var(--globalFont);
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 150%;
    color: #001246;
    margin-bottom: 0.2em;
  }
  .finalPaymentNewAddress{
    font-size: 1em;
  }
  .couponHeaderNew{
    font-size: 1.4em;
  }
  .CartCardContentNewplans {
    background: #ffffff;
    border: 1px dashed #8b94b2;
    border-radius: 12px;
    width: 100%;
    height: 10em;
    margin-bottom: 1em;
  }
  .CouponsAreaNewPlan {
   max-height: 25em;
    overflow-y: auto;
    flex-direction: column;
    margin-top: 1em;
  }
  .finalPaymentNewRightPartWrapper {
    background: #f2f4f7;
    border: 1px solid #bdbbbb;
    border-radius: 12px;
    padding: 2vw;
    max-height: 45em;
  }
  .buttonBlock {
    width: 100%;
    margin-left: 0;
  }
 
  .buttonBlock button {
    width: 100%;
    padding-top: 1.25em;
    padding-bottom: 1.25em;
    margin-top: 1.3em;
    bottom: 0;
    background: var(--globalColorHeader);
    box-shadow: 0px 4px 16px rgba(35, 221, 237, 0.16), 0px 4px 8px -2px #030c28;
    border-radius: 4px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 1.1em;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    border: hidden;
    cursor: pointer;
  }
  .finalPaymentPlanNewAppliedCoupon {
    padding: 0.1em;
}
}
@media screen and (max-width: 800px) {
  .finalPaymentPlanNewDescriptionFixedText4{
    font-size: var(--globalFontSize16);
  }
}
@media screen and (max-width:400px) {
  .finalPaymentPlanNewDescriptionFixedText4{
    font-size: var(--globalFontSize12);
  }
  .finalPaymentPlanNewAppliedCoupon {
    padding: 0.1em;
}
.finalPaymentPlanNewAppliedCoupon :nth-child(2){
  font-size: var(--globalFontSize12);
}
.finalPaymentPlanNewDescriptionHeader {
  font-size:var(--globalFontSize12);
  
}
.finalPaymentPlanNewDescriptionHeaderPrice {
  font-size:var(--globalFontSize12);
  
}
.finalPaymentPlanNewDescriptionQuantity {
  font-size:var(--globalFontSize12);
  
}
.finalPaymentPlanNewDescriptionFixedText2 {
  font-size:var(--globalFontSize12);
  
}
.finalPaymentPlanNewDescriptionFixedText4 {
  font-size:var(--globalFontSize12);
  
}
.finalPaymentPlanDescriptionMarketingText {
  font-size:var(--globalFontSize12);
  
}
.finalPaymentPlanNewDropdown {
  font-size: var(--globalFontSize8);
}
.finalPaymentPlanNewDescriptionCardImage{
  width: 3.5em;
  height: 2.5em;
}
.CouponCodeCartNew {
  font-size: var(--globalFontSize12);
}
.finalPaymentNewPageHeaderText{
  font-size: 1em;
}
.couponHeaderNew{
  font-size: 1em;
}
}

@media screen and (max-width: 300px) {
  .finalPaymentPlanNewDescriptionFixedText4 {
      font-size: var(--globalFontSize8);
  }
  .finalPaymentPlanNewAppliedCoupon :nth-child(2){
    font-size: var(--globalFontSize8);
  }
}