html {
  --subHeadingFont: "0.75em";
  --mainHeadingFont: "0.875em";
}

.everyMain {
  display: flex;
  margin-left: 3vw;
  margin-right: 3vw;
  margin-top: 3.126em;
}

.everyMainLeft {
  box-shadow: 5px 5px 15px 0px #0000000d;
  border-radius: 10px;
  border: 0.5px solid #dadada;
  padding: 1.25em 2vw;
  height: fit-content;
}

.filterHead {
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 1em;
  font-weight: 400;
}

.filterBreak {
  height: 3px;
  width: 7vw;
  background-color: #1c71e1;
  margin-bottom: 1.5em;
}

.filterTest p {
  font-size: 18px;
  margin-bottom: 0.75em;
  color: #000;
  font-weight: 500;
}

.testDiv {
  display: flex;
  align-items: flex-start;
}

.testDiv input {
  height: 22px;
  width: 22px;
}

.testDiv label {
  font-size: 1.125em;
  color: #545454;
  font-weight: 500;
}

.filterTestType {
  display: flex;
  flex-direction: column;
}

.filterTestTitle {
  margin-left: 1.4vw;
  font-size: 1.125em;
  font-weight: 500;
  color: #545454 !important;
}

.priceDiv {
  margin-top: 1.5em;
}

.filterPriceTitle {
  display: flex;
  justify-content: space-between;
}

.filterIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.filterIconName {
  font-size: 20px;
  font-weight: 700;
  margin-top: auto;
  margin-bottom: auto;
}

.filterPriceTitle div {
  color: #7e7e7e;
  font-size: 1em;
  font-weight: 500;
}

.filterPriceTitle span {
  color: #1c71e1;
}

.sortDiv {
  margin-top: 1.5em;
  font-size: 18px;
  margin-bottom: 0.75em;
  color: #000;
  font-weight: 500;
}

.sortContent {
  display: flex;
  align-items: baseline;
}

.sortContent input {
  height: 18px;
  width: 18px;
}

.sortTitle {
  margin-left: 0.5vw;
  font-size: 1em;
  font-weight: 500;
  color: #545454 !important;
}

.everyMainRight {
  margin-left: 3.6vw;
  display: flex;
  flex-direction: column;
}

.everyMainTitle {
  color: #111019;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  text-transform: capitalize;
}

.clearAll:hover {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

.input[type="checkbox"] {
  cursor: pointer;
}

.input[type="radio"] {
  cursor: pointer;
}

.viewEveryBodyRightFilter {
  display: block;
}

.viewEveryLeftFilter {
  display: block;
}

.everyMainRightHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
}

@media screen and (max-width: 477px) {
  .everyMainRightHead {
    flex-direction: column;
    align-items: flex-end;
    justify-content: end;
  }
  .viewEveryBodyRightFilter {
    margin-top: 1em;
  }
}
