.selectDateAndTimeMain {
  padding: 1.875em 6.32vw;
}

.selectDateAndTimeDiv {
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #dfdfdf;
}

.selectDateAndTimeDivBody {
  padding: 2.7vw 2.7vw 0vw 2.7vw;
}

.selectDateAndTimeDivHead {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  text-transform: capitalize;
  color: var(--globalColorHeader);
}

.selectDateAndTimeDivContent {
  background: #f8f8f8;
  border: 1px solid #f7f7f7;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 2.1875em 2.43vw;
}

.selectDateAndTimeDivInputFields {
  display: flex;
  align-items: center;
}
.selectDateAndTimeInputs {
  margin-left: 3.89vw;
}

.selectDateAndTimeInputsDate {
  padding: 0.625em 2vw;
  background: #f3f0f0;
  border: 1px solid #e7e5e5;
  border-radius: 6px;
  min-width: 15vw;
}
.selectDateAndTimeInputsTime {
  min-width: 15vw;
  padding: 0.625em 4.2vw;
  background: #f3f0f0;
  border: 1px solid #e7e5e5;
  border-radius: 6px;
}

.selectDateAndTimeInputsDate::placeholder {
  color: #9a9a9a;
}

.selectDateAndTimeInputsTime::placeholder {
  color: #9a9a9a;
}

.selectDateAndTimeAddress {
  position: relative;
  /* margin-top: 2.94em; */
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}

.selectDateAndTimeAddressContent {
  /* padding-top: 2.4em; */
  display: flex;
  justify-content: space-between;
  margin-left: 1.32vw;
  margin-right: 3.33vw;
}
.selectDateAndTimeUserAddressDet {
  /* display: flex;
  flex-direction: column;
  justify-content: space-around; */
  padding-top: 1.75em;
  /* padding-bottom: 1.75em; */
}

.selectDateAndTimeUser {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 150%;
  color: #1d2334;
}

.timeAndDateConfirmDetails {
  cursor: pointer;
  margin-top: 2.625em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--globalColorHeader);
  box-shadow: 0px 4px 16px rgba(35, 221, 237, 0.16), 0px 4px 8px -2px #030c28;
  border-radius: 4px;
  padding: 1em 8em;
  color: white;
  border: hidden;
  cursor: pointer;
}

.selectDateAndTimeUserAddress {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 150%;
  color: #1d2334;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}

.selectDateAndTimeUserNumber {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 150%;
  color: #1d2334;
  padding-bottom: 1em;
}

.SelectDateAndTimeAddressSelectedAddress {
  position: absolute;
  background: #ffffff;
  border: 1px solid var(--globalColorHeader);
  border-radius: 12px;
  padding: 0.25em 0.8vw;
  top: -1em;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1.1em;
  line-height: 150%;
  color: var(--globalColorHeader);
}
.checkoutButtonCart {
  display: flex;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 1.98vw;
}

.ForcheckoutButtonCart {
  display: flex;
  justify-content: center;
  background: #ffffff;
  border: 1px solid var(--globalColorButton);
  border-radius: 12px;
  box-sizing: border-box;
}

.ForcheckoutButtonCartText {
  padding-top: 1.7vw;
  padding-left: 12.72vw;
  padding-right: 12.72vw;
  padding-bottom: 1.47vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  /* identical to box height, or 27px */

  text-align: center;

  /* pp1 */

  color: #44576a;
}

.SDTNew {
  display: flex;
  padding-top: 2.7vw;
}

.SDTLeft {
  width: 50%;
  padding-inline-end: 11vw;
}

.SDTRight {
  width: 50%;
}

@media screen and (max-width: 600px) {
  .selectDateAndTimeMainMobile1 {
    padding-top: 1em;
    padding-bottom: 1em;
    margin-left: 1.2em;
    margin-right: 1.2em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .selectDateAndTimeMainMobile2 {
    margin-left: 1.2em;
    margin-right: 1.2em;
    display: flex;
    flex-direction: column;
  }
  .selectDateAndTimeMainMobile2Para {
    font-family: var(--globalFont);
    font-style: normal;
    font-weight: 500;
    font-size: 1.2em;
    line-height: 150%;
    text-transform: capitalize;
    color: #44576a;
    padding-top: 1em;
  }
  .selectDateAndTimeMainMobile2Date {
    padding-left: 0.7em;
    padding-right: 0.7em;
    padding-bottom: 1em;
    padding-top: 2em;
  }
  .selectDateAndTimeMainMobile3 {
    margin-left: 1.2em;
    margin-right: 1.2em;
    display: flex;
    flex-direction: column;
    margin-top: 2em;
  }
  .selectDateAndTimeMainMobile3Address {
    display: flex;
    background: #f8f8f8;
    border: 1px solid #f7f7f7;
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  .selectDateAndTimeMainMobile3AddressLeft {
    width: 85%;
    padding-left: 1.1em;
    padding-right: 1em;
  }
  .selectDateAndTimeMainMobile3AddressRight {
    width: 15%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .selectDateAndTimeMainMobile3AddressRightInput {
    margin-top: 1em;
  }
  .selectDateAndTimeMainMobile3AddressRight img {
    padding-bottom: 1em;
    width: 1.3em;
    height: 2.2em;
  }
  .selectDateAndTimeMainMobile3Para {
    font-family: var(--globalFont);
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 150%;
    text-transform: capitalize;
    color: #44576a;
  }
  .selectDateAndTimeMainMobile3AddressLeft :nth-child(1) {
    font-family: var(--globalFont);
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 150%;
    color: var(--globalColorButton);
    padding-top: 1em;
  }
  .selectDateAndTimeMainMobile3AddressLeft :nth-child(2) {
    font-family: var(--globalFont);
    font-style: normal;
    font-weight: 400;
    font-size: 0.8em;
    line-height: 150%;
    color: #44576a;
    padding-top: 1em;
  }
  .selectDateAndTimeMainMobile3AddressLeft :nth-child(3) {
    font-family: var(--globalFont);
    font-style: normal;
    font-weight: 400;
    font-size: 0.8em;
    line-height: 150%;
    color: #44576a;
    padding-top: 1em;
  }
  .selectDateAndTimeMainMobile4 {
    background: #ffffff;
    border: 1px solid var(--globalColorButton);
    border-radius: 6px;
    margin-left: 1.2em;
    margin-right: 1.2em;
    font-family: var(--globalFont);
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    color: var(--globalColorButton);
    padding: 0.3em 0.3em;
    display: flex;
    justify-content: center;
    margin-bottom: 3em;
    margin-top: 4em;
  }
}
