.bookAppointmentFormMain{
    position: absolute;
    border: 2px solid #CCCCCC;
    border-radius: 20px;
    background-color: #f6f6f6;
    width: 50vw;
z-index: 1;
top: 100%;
left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1em;
  max-height: 65vh; /* Keeping your desired max-height */
  overflow-y: auto; /* Add scroll for overflow */
  overflow-x: hidden; /* Ensure no horizontal overflow */
  
}
.bookAppointmentFormMain1{
  position: absolute;
  border: 2px solid #CCCCCC;
  border-radius: 20px;
  background-color: #f6f6f6;
  width: 50vw;
z-index: 1;
top: 0%;
left: 0; 
right: 0; 
margin-left: auto; 
margin-right: auto; 
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
padding-bottom: 1em;
max-height: 65vh; /* Keeping your desired max-height */
  overflow-y: auto; /* Add scroll for overflow */
  overflow-x: hidden; /* Ensure no horizontal overflow */
  
}
.bookAppointmentFormMain2{
  position: absolute;
  border: 2px solid #CCCCCC;
  border-radius: 20px;
  background-color: #f6f6f6;
  width: 50vw;
z-index: 1;
top: 0%;
left: 0; 
right: 0; 
margin-left: auto; 
margin-right: auto; 
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding-bottom: 1em;
}
.bookAppointmentFormMainUpper{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 2em;
  box-sizing: border-box;
}
.bookAppointmentFormMainUpperCheckbox{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 1em;
  box-sizing: border-box;
  padding-right: 5%;

}
.bookAppointmentFormMainUpperCheckbox input{
  margin-right: 1em;
  margin-top: auto;
  margin-bottom: auto;
}
.bookAppointmentFormMainUpperCheckbox p{
  margin-top: auto;
  margin-bottom: auto;
}
.bookAppointmentForm{
    width: 90%;
margin: 0 auto;
  }

.bookAppointmentFormLabels {
  display: flex;
  flex-direction: column;
  margin-top: 0.5em;
}
.bookAppointmentFormLabels label{
  font-family: var(--globalFont);
font-size: var(--globalFontSize20);
font-weight: 500;
line-height: 30px;
}
.bookAppointmentFormLabelsInput{
  border: 1px solid #D7D7D7;
  background: #EFEFEF;
padding: 0.8em 1em 0.8em 1.1em;
border-radius: 10px;
font-family: var(--globalFont);
font-size: var(--globalFontSize16);
font-style: italic;
font-weight: 400;
line-height: 24px;

}
.bookAppointmentFormTerms{
  font-family: var(--globalFont);
font-size: var(--globalFontSize16);
font-weight: 400;
line-height: 24px;
margin-top: 1em;
}
.bookAppointmentFormButton{
  display: flex;
  justify-content: center;
  align-items: center;
}
.bookAppointmentFormButton button{
  font-family: var(--globalFont);
  font-size: var(--globalFontSize16);
  font-weight: 600;
  line-height: 33px;
  text-align: center;
  background: #1C71E1;
border: hidden;
border-radius: 10px ;
cursor: pointer;
color: #fff;
padding: 0.7em 5em;
}
.bookAppointmentFormMainHeader{
  font-family: var(--globalFont);
font-size:var(--globalFontSize20);
font-weight: 700;
line-height: 33px;
margin-top: auto;
  margin-bottom: auto;
}
.bookAppointmentFormMainHeaderCheckbox{
  font-family: var(--globalFont);
font-size:var(--globalFontSize20);
font-weight: 600;
line-height: 33px;
margin-top: auto;
  margin-bottom: auto;
}
.bookAppointmentCloseIcon{
  position: absolute;
  right: 5%;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
}
@media screen and (max-width:1000px) {
  .bookAppointmentFormButton button{
   width: 85%;
  font-size: var(--globalFontSize12);
  display: flex;
  justify-content: center;
  align-items: center;
  }
  .bookAppointmentFormMain , .bookAppointmentFormMain1 , .bookAppointmentFormMain2{
    width: 90vw;
  }
  .bookAppointmentFormTerms{
    font-size: var(--globalFontSize14);
  }
  .bookAppointmentFormLabels label{
    font-family: var(--globalFont);
  font-size: var(--globalFontSize16);
  font-weight: 500;
  line-height: 30px;
  }
  .bookAppointmentFormLabelsInput{
  padding: 0.5em 1em 0.5em 1.1em;
font-size: var(--globalFontSize14);

  }
  .bookAppointmentFormMainHeader{
  font-size:var(--globalFontSize16);
  padding: 1em 1em 0em 1em;
  text-align: center;
  }
  .bookAppointmentFormMainHeaderCheckbox{
    font-size:var(--globalFontSize16);
    text-align: center;
    }
  .bookAppointmentCloseIcon{
 top: 1em;
  }
}