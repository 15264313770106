.registerWithOtp {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.SingleLoginMainWrapper {
  height: 100vh;
  background-image: url("../../../assets/Login/BackgroundImage.png");
  background-size: 100% 120%;
  background-repeat: no-repeat;
}

.SingleLoginPart1 {
  display: flex;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("../../../assets/Login/Background.png");
}

.SingleLoginPart1Image {
  width: 50%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-end;
}

.SingleLoginPart1Image_1 {
  width: 8em;
  height: 8em;
  padding-top: 2.1em;
  padding-right: 2.06em;
}

.SingleLoginPart1Image_2 {
  width: 4em;
  height: 4em;
  margin-bottom: 5.81em;
  margin-right: 3.2em;
}

.errorMessage {
  color: red;
  font-size: 0.9em;
}

.SingleLoginPart1Text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  color: #1d2334;
}

.SingleLoginPart1TextWrapper {
  width: 50%;
  padding-top: 3.6em;
  padding-left: 4.41em;
  padding-bottom: 3.8em;
}

.SingleLoginScreenMain {
  width: 35%;
  position: absolute;
  right: 5.3em;
  top: 12.6em;
  background: #ffffff;
  box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.08);
  border-radius: 40px;
  padding-bottom: 2em;
}

.SingleLoginScreenMainHeader {
  margin-top: 2.7em;
  display: flex;
  justify-content: space-between;
  margin-right: 2vw;
  margin-left: 2em;
}

.SingleLoginScreenMainHeaderSignUp {
  display: flex;
  flex-direction: column;
}

.googleNew {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5em;
  margin-bottom: 1em;
}
#signInDiv {
  width: 100vw;
}
.orRegister {
  margin-top: 1vw;
  width: 80%;
  height: 6.5%;
  gap: 5%;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.horizontalLineRegister {
  border-top: 2px solid gray;
  width: 42%;
  width: 92%;
}
.formStarts1New {
  display: flex;
  flex-direction: column;
}

.SingleLoginForm {
  margin-left: 2vw;
}

.boxNew {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.labelpara {
  width: 80%;
  margin-right: auto;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 24px;
  color: #1d2334;
  align-self: flex-start;
  margin-bottom: 1em;
}

.login_textbox_new {
  height: 3.4vw;
  background: #f3f0f0;
  border-radius: 0.6vw;
  width: 28vw;
  border: hidden;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 0.8em;
  line-height: 21px;
  color: #808080;
  padding-left: 1.125em;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.boxNew2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 2em;
}

.UserLoginButtonNew {
  display: flex;
}

.submitbuttonNew {
  width: 28vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
  outline: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.6vw;
  height: 3.4vw;
  color: white;
  cursor: pointer;
  gap: 2%;
  background: var(--globalColorHeader);
  border: 1px solid var(--globalColorHeader);
  box-shadow: 0px 4px 19px rgba(119, 147, 65, 0.3);
}

.horizontalLineRegister {
  border-top: 2px solid gray;
  width: 42%;
  width: 92%;
}

.forgotPassword {
  display: flex;
  margin-top: 1em;
  margin-left: 2vw;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}

.forgotPasswordText {
  color: #407bff;
  cursor: pointer;
}

.clickHere {
  cursor: pointer;
}

.LoginMainWrapperMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.LoginHeaderMobile {
  margin-top: 3.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5em;
}

.LoginHeaderTextMobile {
  /* position: absolute; */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  color: #44576a;
}

.LoginGoogleButtonMobile {
  margin-top: 2.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoginSeperatorMobile {
  margin-top: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LoginFormFieldMobile {
  position: relative;
  background: #f3f0f0;
  border-radius: 6px;
  border: none;
  width: 74.5vw;
  margin-left: 12.75vw;
  margin-right: 12.75vw;
  height: 2.625em;
  padding-left: 1.125em;
  margin-bottom: 1em;
}

.passwordMobileField {
  position: relative;
}

.LoginFormFieldFPMobile {
  background: #f3f0f0;
  border-radius: 6px;
  border: none;
  width: 74.5vw;
  margin-left: 12.75vw;
  margin-right: 12.75vw;
  height: 2.625em;
  padding-left: 1.125em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.LoginFormSubmitButtonMobile {
  background: var(--globalColorHeader);
  border-radius: 6px;
  color: #ffffff;
  border: none;
  width: 74.5vw;
  margin-left: 12.75vw;
  margin-right: 12.75vw;
  height: 2.625em;
  margin-bottom: 2em;
}

.LoginFormFieldWrapperMobile {
  width: 80vw;
}

.LoginFormSubmitButtonImageMobile {
  margin-right: 10px;
}

.LoginGoogleButtonMobile2 {
  background: #4172f6;
  border-radius: 6px;
  color: #ffffff;
  border: none;
  width: 74.5vw;
  margin-left: 12.75vw;
  margin-right: 12.75vw;
  height: 2.625em;
}

.horizontalLineMobile {
  border-top: 2px solid rgb(143, 7, 7);
}

.LoginForgotPasswordMobile {
  margin-top: 0.5em;
  margin-left: 59.2vw;
}

.LoginForgotPasswordTextMobile {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #44576a;
}

.LoginLinkSection3Mobile {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.LoginImageMobile {
  width: 100vw;
}

.LoginLinkSection1Mobile {
  margin-top: 2em;
}

.RegisterVerifyButtonMobile {
  margin-left: 17.75vw;
  margin-top: -8px;
  display: flex;
}
.LoginFormMobile-singleLogin {
  margin-top: 6vw;
}
.singleLoginerrorMessage {
  color: red;
  margin-left: 14vw;
}
@media screen and (min-width: 1440px) {
  .SingleLoginMainWrapper {
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  /* .SingleLoginPart1 {
    background: url("../../../assets/Login/EllipseMain.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  } */
}
