.PlanServicesDetailsListingWeb {
  margin-top: 3vw;
  list-style-type: disc !important;
}

.PlanTermsAndConditionsSubHeading {
  color: var(--globalColorSubMainHeading);
  margin-bottom: 0.6em;
  font-size: 1.125em;
  display: flex;
  padding-left: 1em;
  list-style-type: inherit;
  padding-top: 0.1em;
}

.PlanTermsAndConditionsHeading {
  color: var(--globalColorSubHeading);
  font-size: 2.125em;
  margin-bottom: 1vw;
  margin-top: 2vw;
}

.PlanTermsAndConditionsButtonDiv {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .PlanTermsAndConditionsSubHeading {
    margin-bottom: 1.6rem;
    margin-top: 1vw;
    margin-left: 2vw;
  }

  .PlanTermsAndConditionsHeading {
    font-size: var(--globalFontSizeMobile18);
  }
}
