.TalkToDactorHeadingImage {
  height: 20em;
  margin-bottom: 5vw;
  background-color: #e1eeff;
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding-right: 2em;
}

.TalkToDactorHeadingImage img {
  width: 40em;
  height: 15em;
  margin-top: 1.5em;
}

.TalkToDactor_Heading {
  padding-top: 2em;
  padding-left: 1em;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: var(--globalFontSize32);
  line-height: 150%;
}

.TalkToDactor_Heading2 {
  font-size: var(--globalFontSize28);
}

.TalkToDactor_SubbHeading {
  padding-left: 5.6875rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.3vw;
  line-height: 150%;
  color: #ffffff;
}

.TalkToDactor_BlockTwo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.TalkToDactor_BlockTwoHeading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5vw;
  line-height: 150%;
  text-align: center;
  color: #e68d36;
  margin-top: 2.875rem;
}

.AppDownload {
  /* display: flex; */
  position: absolute;
  justify-content: flex-end;
  margin-left: 50vw;
  margin-bottom: 8vw;
  width: 30vw;
}

.TalkToDactor_BlockTwoSubHeading {
  margin: 2.43vw 5vw 1.875vw 5vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5vw;
  line-height: 150%;
  text-align: center;
  color: #44576a;
}

.TalkToDactor_Heading1 {
  padding-top: 2.81vw;
  padding-left: 5.6875vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.8vw;
  line-height: 150%;
  color: #ffffff;
  width: 30vw;
}

.LifeAlteringIssuePageHeading {
  font-family: var(--globalFont);
  font-style: normal;
  color: var(--globalColorMainHeading);
  font-weight: 500;
  font-size: var(--globalFontSize28);
  line-height: 150%;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  text-transform: capitalize;
}

.LifeAlteringIssuePageSubHeading {
  font-size: var(--globalFontSize20);
  line-height: 150%;
  text-align: center;
  margin-top: 0.4vw;
  color: var(--globalColorSubHeading);
  width: 90%;
  margin: 0 auto;
  margin-top: 1em;
}

.TalkToDactorImages img {
  width: 3.2vw;
}

.talkcarouselHead1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 2vw;
  margin-right: 10vw;
  /* background-color: #45e636; */
}

.TalkToDactorCarText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */
  /* width: 3vw; */
  margin-left: 6vw;
  color: #5067a7;
}

.MostSearchedBody {
  padding-top: 0.3vw;
  padding-bottom: 6vw;
  background: #eff3f5;
}

.MedicalConditionPageContainer {
  font-family: var(--globalFont);
  font-style: normal;
  color: var(--globalColorMainHeading);
  font-weight: 500;
  font-size: var(--globalFontSize28);
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;
  margin-top: 2vw;
  margin-bottom: 4vw;
  width: 80%;
  margin: 0 auto;
}
.MedicalConditionPageContainerGrid {
  margin-left: 3vw;
  margin-right: 3vw;
  margin-top: 2em;
}

.grid-MedicalConditionPageContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  padding: 10px;
  gap: 10px;
}

.grid-item_grid-MedicalConditionPageContainer {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  font-size: 0.95em;
  color: white;
  padding: 1vw;
  text-align: center;
  background: var(--globalColorHeader);
  border: 1px solid #44576a;
  border-radius: 8px;
  outline: none !important;
  border: none;
  word-break: auto-phrase;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 4em;
}

.BookAppcarouselTitle {
  margin-top: 5vw;
  padding: 3vw;
  background: #3c4d84;
  border-radius: 12px;
}

.BookAppcarouselhead {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.8vw;
  line-height: 150%;
  /* or 42px */

  /* white */

  color: #ffffff;
}

.BookAppcarouselsubhead {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5vw;
  line-height: 150%;
  color: #f2efea;
}

.BookAppcarouseldis {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 150%;
  color: #ffffff;
}

.bookappcarouselDescription {
  margin-top: 2vw;
}

.bookappcarouselTop {
  background: #52608e;
  border-radius: 12px;
}

.bookappcarouselInnerDiv {
  background: #ffffff;
  box-shadow: 0px 0px 2px 2px rgb(0 0 0 / 8%);
  border-radius: 12px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5vw;
  line-height: 150%;
  margin-left: 4vw;
  /* min-width: 44vw; */
  /* max-width: 50vw; */
}

.bookCarcarouselMainTestimonial {
  width: 53vw;
}

.bookAptestimonialsBottomLife {
  margin-top: 5vw;
}

.bookAppointmentTestiomonailCon {
  background: #f0f0f0;
  margin-top: 7vw;
  padding: 0.2vw;
  padding-bottom: 2vw;
}

.TalkToDactorCardBlock4 {
  margin-left: 5vw;
  margin-right: 5vw;
  margin-top: 4vw;

  padding-bottom: 8vw;

  /* filter: blur(2px); */
}

.LifeAlteringIssuePageUnderSubHeading {
  display: flex;
  flex-wrap: wrap;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 2em;
  line-height: 150%;
  justify-content: center;
  margin-top: 2.75em;
  /* height: 3em; */
  /* identical to box height, or 48px */

  color: var(--globalColorButton);
}

.LastBlock {
  /* margin-bottom: 3vw; */
  width: 100%;
  background: rgba(241, 236, 236, 0.2);
  border: 2px solid rgba(173, 171, 171, 0.5);
}

.TalkToDactorCardBlock4Heading {
  font-family: var(--globalFont);
  font-style: normal;
  color: var(--globalColorMainHeading);
  font-weight: 500;
  font-size: 1.2em;
  line-height: 150%;
  /* identical to box height, or 36px */

  text-transform: uppercase;
}

.TalkToDactorCardBlock4SubHeading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 150%;
  /* or 42px */
  width: 76vw;
  margin-top: 2vw;
  color: #44576a;
}

.ButtonTalktodoctor {
  background: var(--globalColorHeader);
  border-radius: 6px;
  color: white;
  border: none;
  padding:0.8em 2em 0.8em 2em;
  margin-top: 4vw;
  font-size: 1em;
  cursor: pointer;
  outline: none !important;
}

.VirtualConsultationPageContainer {
  margin-bottom: 12vw;
}

.VirtualConsultationPageHeading {
  font-family: var(--globalFont);
  font-style: normal;
  color: #44576a;

  font-weight: 500;
  font-size: 1.7vw;
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;
  padding-top: 1em;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.VirtualConsultationPageSubHeading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5vw;
  line-height: 150%;
  text-align: center;
  color: var(--globalColorHeader);

  padding-top: 1em;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.VirtualConsultation_grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.VirtualConsultation_grid-item {
  background-color: rgba(255, 255, 255, 0.8);
}

.VirtualConsultationText1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.2vw;
  line-height: 150%;
  color:black;
  display: flex;
  padding-top: 8.2px;
  margin-left: 5vw;
  margin-top: 2vw;
  align-items: center;
  margin-top: 3.2vw;
}
.VirtualConsultationText2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.2vw;
  line-height: 150%;
  color:black;
  display: flex;
  padding-top: 8.2px;
  margin-left: 5vw;
  margin-top: 2vw;
  align-items: center;
  margin-top:8vw;
}
.VirtualConsultationText3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.2vw;
  line-height: 150%;
  color:black;
  display: flex;
  padding-top: 8.2px;
  margin-left: 5vw;
  margin-top: 2vw;
  align-items: center;
  margin-top: 9vw;
}

.kiok {
  padding-left: 6.3vw;
  /* text-align: left; */
  height: auto;
}

.VirtualConsultation_grid-itemInside {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  display: inline-block;
  margin-top: 3vw;
  margin-left: 2vw;
  display: flex;
  width: 36vw;
  padding-top: 2em;
  padding-bottom: 2em;
}

.VirtualConsultation_grid-itemInside1 {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  display: inline-block;
  margin-top: 5vw;
  margin-left: 9vw;
  display: flex;
  width: 36vw;
  padding-top: 2em;
  padding-bottom: 2em;
}

.VirtualConsultation_grid-itemInside2 {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  display: inline-block;
  margin-top: 5vw;
  margin-left: 2vw;
  display: flex;
  width: 36vw;
  padding-top: 2em;
  padding-bottom: 2em;
}

.lo {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 0.7em;
  line-height: 150%;
  padding-left: 5vw;
  color: #4A5CB3;
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 2em;
  padding-right: 2em;
}

.VirtualConsultationfitsIcon {
  width: 3em;
  height: 3em;
}

.VirtualConsultation_gridpic1 {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.VirtualConsultationBTn {
  background: var(--globalColorHeader);
  border-radius: 6px;
  outline: none !important;
  color: white;
  padding:1em 3.5em 1em 3.5em;
  cursor: pointer;
  border: none;
  font-size: 0.9em;
}
.VirtualConsultationBTn1{
  display: none;
}

.TalkToDoctorCarouselHeading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 2vw;
  line-height: 150%;
  text-align: center;
  margin-top: 2vw;
  /* identical to box height, or 48px */

  color: var(--globalColorButton);
}

.TalkToDoctorCarouseName {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.8vw;
  line-height: 1vw;
  margin-top: 1vw;
  margin-left: 2.5vw;
  /* identical to box height */

  /* pp1 */

  color: #44576a;
}

.bookAppointmentContentGrid {
  padding-left: 4vw;
}

.bookAppointmentBody {
  margin-bottom: 6vw;
}

.bookAppointmentGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 3vw;
}

.bookAppointmentContentGridHeading {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: var(--globalFontSize28);
  line-height: 150%;
  margin-left: -2vw;
  color: var(--globalColorMainHeading);
  text-transform: uppercase;
}

.bookAppointmentContentGridData1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  width: 35vw;
  margin-top: 2vw;
  font-size: 1.5vw;
  line-height: 150%;
  list-style-image: url(../../../assets/common/BAPic4.png);

  /* or 42px */

  color: #000000;
}

.MarkerBook {
  /* margin-top: 2vw; */
  /* display: flex; */
  /* flex-direction: column; */
  /* margin-left: 4vw; */
}

.bookAppointPic1 {
  width: 22vw;
  z-index: 99999;
  margin-left: 17vw;
  margin-top: 5.9vw;
}

.bookAppointPic2 {
  position: absolute;
  width: 16vw;
  margin-left: -28vw;
  z-index: -2;
  margin-top: 1vw;
}

.bookAppointPic3 {
  width: 37vw;
  margin-left: 5vw;
  margin-top: 2vw;
}

.MarkBody {
  display: flex;
  flex-direction: column;
}

.BookAppointBtn {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1vw;
  margin-top: 2vw;
  cursor: pointer;
  line-height: 150%;
  margin-left: 0vw;
  color: var(--globalColorHeader);
  padding-top: 0.4vw;
  padding-bottom: 0.4vw;
  padding-left: 5vw;
  padding-right: 6vw;
  border-radius: 6px;
  border: 1px solid var(--primary_color_2, var(--globalColorHeader));
  background: #fff;
  text-align: center;
  outline: none !important;
}

.ChatLogo_TalkToDoctor {
  margin-left: 2vw;
}

.TalkToDoctorHeadingImage {
  margin-top: 1.875rem;
  color: linear-gradient(180deg,
      rgba(34, 45, 73, 0.65) 0%,
      rgba(49, 60, 91, 0.65) 100%);
  /* height: 20vw; */
}

.TalkToDoctor_Heading1 {
  padding-top: 3.81vw;
  padding-left: 5.6875vw;
  padding-bottom: 3.81vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.8vw;
  line-height: 150%;
  color: #ffffff;
  width: 100vw;
  display: flex;
  flex-direction: row;
}

.TalkToDoctorPoints1 {
  position: absolute;
  width: 3.5vw;
  z-index: 999;
}

.TalkToDoctorPoints2 {
  position: absolute;
  margin-top: 10vw;
  width: 3.5vw;
  z-index: 999;
}

.TalkToDoctorPoints3 {
  position: absolute;
  margin-top: 21vw;
  width: 3.5vw;
  z-index: 999;
}

.TalkToDoctorLines {
  margin-top: 3vw;
  height: 21vw;
  margin-left: 1.6vw;
  position: absolute;
}

.Number1 {
  position: absolute;
  width: 3.5vw;
  z-index: 9999;
  margin-top: 1vw;
  margin-left: 1.4vw;
  color: white;
  font-size: 1.1vw;
}

.Number2 {
  position: absolute;
  margin-top: 11vw;
  font-size: 1.1vw;

  width: 3.5vw;
  margin-left: 1.4vw;
  color: white;
  z-index: 9999;
}

.Number3 {
  position: absolute;
  font-size: 1.1vw;

  margin-top: 22vw;
  color: white;
  width: 3.5vw;
  margin-left: 1.4vw;
  z-index: 9999;
}

.TalkToDoctorText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2vw;
  line-height: 150%;
  margin-top: 2vw;
  /* identical to box height, or 48px */
  text-align: center;

  color: var(--globalColorButton);
}

.TalkToDoctorCarouselText {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: var(--globalFontSize14);
  line-height: 1em;
  text-align: center;
  margin-top: 1em;
  color: #44576a;
}

.TalkToDoctorLastTextComponent {
  background: rgba(241, 236, 236, 0.2);
  border: 2px solid rgba(173, 171, 171, 0.5);
}

.bb1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  margin-top: 2vw;
  color: #44576a;
  line-height: 150%;
  list-style-image: url(../../../assets/TalkToDoctorAssests/point.png);
  
}

.TalkToDoctorTick {
  margin-left: 1vw;
  width: 1.2vw;
}

.points2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  color: #44576a;
  margin-top: 0.5em;
  margin-bottom: 0vw;
  list-style-position: inside;
  text-indent: -1.6rem;
  width: 90%;
}


.chatwithusTTD{
  display: flex;
  justify-content: center;
  align-items: center;
}

.appStore1 {
  margin-top: -2vw;
  display: flex;
  justify-content: center;
  margin-bottom: 6vw;
}

.mblDownloadHeading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 4.4vw;
  line-height: 150%;
  margin-top: 3vw;

  text-align: center;

  color: #e68d36;
}

.talkToDoctorDownloadHeading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 4.1vw;
  line-height: 150%;
  padding-left: 3.8vw;
  padding-right: 4vw;
  text-align: center;
  text-transform: capitalize;
  color: #1d2334;
}
.LifeAlteringIssuePageContainerCorosal{
  margin-top: 2em;
  margin-bottom: 2em;
}
.TalkToDoctorSlider{
  width: 90%;
  margin: 0 auto;
}
.VirtualConsultation_grid-containerLeft{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 2em;
  padding-top: 2em;
}
.VirtualConsultation_grid-containerLeftFirst{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1em;
  width: 90%;
}
.VirtualConsultation_grid-containerLeftFirstLeft{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 20%;
}
.VirtualConsultation_grid-containerLeftFirstLeft img{
  position: absolute;
  height: 2.5em;
  width: 2.5em;
  z-index: -1;
}
.VirtualConsultation_grid-containerLeftFirstLeftSerialNumber{
  z-index: 1;
  margin-top: auto;
  margin-bottom: auto;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: var(--globalFontSize16);
  line-height: 150%;
  color: #fff;
margin-left: 1em;
}
.VirtualConsultation_grid-containerLeftFirstRight{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: var(--globalFontSize14);
  line-height: 150%;
  color: #44576a;
  padding-top:1em;
  padding-bottom: 1em;
  padding-right: 1em;
  width: 80%;
}

@media screen and (max-width : 1100px) {
  .TalkToDactorHeadingImage img {
    width: 30em;
    height: 10em;
    margin-top: 5em;
  }
  .grid-MedicalConditionPageContainer{
    grid-template-columns: repeat(7, 1fr);

  }
}

@media screen and (max-width : 880px) {
  .TalkToDactorHeadingImage img {
    width: 0em;
    height: 0em;
  }
.TalkToDactorHeadingImage{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}
  .grid-MedicalConditionPageContainer {
    grid-template-columns: repeat(7, 1fr);
  }
}
@media screen and (max-width : 800px) {
  .grid-MedicalConditionPageContainer {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media screen and (max-width : 730px) {
  .grid-MedicalConditionPageContainer {
    grid-template-columns: repeat(5, 1fr);
  }
  .bookAppointmentGrid {
    grid-template-columns: 1fr ;
  }
  .VirtualConsultationPageHeading{
font-size: var(--globalFontSize20);
  }
  .VirtualConsultationPageSubHeading{
font-size: var(--globalFontSize16);

  }
  
  .bookappointmentPicGrid{
    display: none;
  }
  .bookAppointmentContentGridHeading{
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
 
  .BookAppointBtn {
    
    font-size: 1em;
    margin-top: 2vw;
    cursor: pointer;
    line-height: 150%;
    padding-top: 0.5em;
    padding-bottom: 0.5m;
    padding-left: 5vw;
    padding-right: 6vw;
  }
  .points2 {
   
   
   
    list-style-position:outside;
    text-indent: -0.1em;
    width: 90%;
    margin-left: 1em;
  }
}
@media screen and (max-width : 620px) {
  .grid-MedicalConditionPageContainer {
    grid-template-columns: repeat(5, 1fr);
  }
  .VirtualConsultation_grid-container {
    display: grid;
    grid-template-columns: 1fr ;
  }
  .VirtualConsultation_grid-containerLeftFirst{
    width: 100%;
  }
  .VirtualConsultation_grid-containerLeftFirstLeft{
    width: 15%;
  }
  .VirtualConsultation_grid-containerLeft {
    padding-left: 1em;
  }
  .VirtualConsultation_grid-containerLeftFirstRight{
    width: 85%;
    padding-left: 1em;
  }
  .VirtualConsultation_grid-item {
    width: 100%;
  }
  .VirtualConsultation_grid-itemInside{
    width: 95vw;
  }
  .VirtualConsultation_grid-itemInside1{
    width: 95vw;
    margin-left: 2vw;
  }
  .VirtualConsultation_grid-itemInside2{
    width: 95vw;
  }
  .VirtualConsultationBTn1 {
    background: var(--globalColorHeader);
    border-radius: 6px;
    outline: none !important;
    color: white;
    padding:1em 3.5em 1em 3.5em;
    cursor: pointer;
    border: none;
    font-size: 0.9em;
    display: block;
    width: 60%;
    margin: 0 auto;
    margin-top: 2em;
  }
  .VirtualConsultationBTn{
    display: none;
  }
  .VirtualConsultationBTnMain{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .TalkToDactorCardBlock4Heading{
text-align: center;
  }
  .TalkToDactorCardBlock4SubHeading {
    text-align: center;
    width: 100%;
  }
  .TalkToDactorCardBlock4{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width : 585px) {
  .grid-MedicalConditionPageContainer {
    grid-template-columns: repeat(4, 1fr);
  }
  .TalkToDactor_Heading{
    font-size: 1.9em;
    padding-left: .5em;
  }
  .TalkToDactor_Heading2{
    font-size: 1.5em;
    
    margin-top: .5em;
  }
  .TalkToDactorHeadingImage {
    padding-right: 1em;
    padding-left: 1em;
  }
}
@media screen and (max-width : 480px) {
  .grid-MedicalConditionPageContainer {
    grid-template-columns: repeat(3, 1fr);
  }
  .slick-prev:before,
  .slick-next:before {
    margin-left: -.5em;
  }
  .slick-prev{
    left: 0px;
  color: black;

  }
}
@media screen and (max-width : 365px) {
  .grid-MedicalConditionPageContainer {
    grid-template-columns: repeat(2, 1fr);
  }
  .slick-prev:before,
  .slick-next:before {
    margin-left: -.5em;
  }
  .slick-prev{
    left: 0px;
  color: black;

  }
}
@media screen and (max-width : 280px) {
  .grid-MedicalConditionPageContainer {
    grid-template-columns: repeat(1, 1fr);
  }
}