.SelectionScreenBoxContainer {
  width: 77%;
  margin: 0 auto;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
  padding: 5em;
  margin-top: 4em;
  
}

.SelectionScreenBoxHeadingFirst {
  color: var(--globalColorMainHeading);
  font-size: var(--globalFontSize32);
  display: flex;
  justify-content: center;
  padding-top: 1em;
  padding-bottom: 1em;
}
.SelectionScreenBoxSecondContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 6em;
  margin-bottom: 3em;
}
.SelectionScreenBoxfirst {
  border: 1px solid var(--globalColorMainHeading);
  border-radius: 12px;
  cursor: pointer;
  padding: 1.5em;
}

.SelectionScreenBoxfirstImage1 {
  display: flex;
  justify-content: center;
  padding: 1.5em;
}
.SelectionScreeneyeImage {
  width: 5em;
}
.SelectionScreenTextHead {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  font-size: 1.35em;
  margin-top: 1em;
}
@media screen and (max-width: 1000px) {
  .SelectionScreenBoxContainer{
padding-left: 0em;
padding-right: 0em;
  }
  .SelectionScreenBoxSecondContainer{
    flex-direction: column;
    align-items: space-around;
    justify-content: center;
  }
  .SelectionScreenBoxfirst {
   margin-top: 1em;
   width: 70%;
    
   padding: 2em;
  }
  
  .SelectionScreenBoxContainer {
    text-align: center;
  }
  
}
@media screen and (max-width: 600px) {
 
  .SelectionScreenBoxfirst {
   width: 90%;
   margin-top: 1em;
   
  }
}