.NetworkPageFormInputField {
  box-sizing: border-box;
  width: 65vw;
  height: 48px;
  font-size: 1.2vw;
  background: #ffffff;
  border: 1px solid #89a9bb;
  border-radius: 12px;
  margin-bottom: 3.125rem;
  padding-left: 2.375rem;
}


.NetworkPageFormInput {
  display: flex;
  flex-direction: column;
  margin-left: 0.5vw;
}

.errorFile {
  color: rgb(163, 45, 24);
}

.NetworkPageFormSubmitButton {
  width: 20vw;
  height: 42px;
  background: var(--globalColorButton);
  border-radius: 6px;
  margin-left: 45.152vw;
  border: none;
  color: #ffffff;
  cursor: pointer;
  border: none;
  outline: none !important;
}

.NetworkPageFormSubmit {
  margin-top: 3.125rem;
  margin-bottom: 3.125rem;
}
input[type="file"] {
  padding: 7px;
}
.tryy {
  background-color: aqua;
  box-sizing: border-box;
  width: 65vw;
  height: 48px;
  background: #ffffff;
  border: 1px solid #89a9bb;
  border-radius: 12px;
  margin-top: 3.125rem;
  padding-left: 2.375rem;
}
.file-upload {
  margin-bottom: 3.125rem;
}

.file-upload .file-upload-select {
  display: flex;
  width: 65vw;
  height: 48px;
  justify-content: space-between;
  color: #dbdbdb;
  cursor: pointer;
  text-align: left;
  background: #ffffff;
  border: 1px solid #89a9bb;
  overflow: hidden;
  position: relative;
  border-radius: 12px;
  /* margin-bottom: 3.125rem; */
  box-sizing: border-box;
}
.file-upload .file-upload-select .file-select-button {
  color: black;
  padding: 0.6rem;
  font-size: 1.2vw;
  padding-left: 2.275rem;

  display: inline-block;
}
.file-upload .file-upload-select .file-select-name {
  display: inline-block;
  padding: 0.6rem;
  color: black;
  font-size: 1.2vw;
  min-width: 5rem;
}
.file-upload .file-upload-select .file-select-name2 {
  display: inline-block;
  padding: 0.6rem;
  color: black;
  font-size: 1.2vw;
  min-width: 5rem;
}
.file-upload .file-upload-select .file-select-name3 {
  display: inline-block;
  padding: 0.6rem;
  color: black;
  font-size: 1.2vw;
  min-width: 5rem;
}

.file-upload .file-upload-select input[type="file"] {
  display: none;
}

.FormErrorMesage {
  color: red;
  font-size: 1.2vw;
  color: rgb(163, 45, 24);

  margin-left: 1%;
  margin-top: -1.875em;
  margin-bottom: 0.4375em;
}
.FileSizedisable {
  margin-top: -2vw;
  margin-bottom: 0.4vw;
  margin-left: 0.7vw;
  color: rgb(163, 45, 24);
  font-size: 1.3vw;
}
@media screen and (max-width: 600px) {
  .NetworkPageFormInputField {
    width: 77vw;
    font-size: 12px;
    padding-left: 1.175rem;
    color: #6e6c6c;
  }
  .file-upload .file-upload-select {
    width: 77vw;
  }
  .file-upload .file-upload-select .file-select-button {
    color: #6e6c6c;
    font-size: 12px;
    margin-top: 0px;
    padding-left: 1.175rem;

    display: inline-block;
  }
  .file-upload .file-upload-select .file-select-name {
    display: inline-block;
    padding: 1rem;
    color: black;
    font-size: 7px;
  }
  .file-upload .file-upload-select .file-select-name2 {
    display: inline-block;
    padding: 1rem;
    color: black;
    font-size: 7px;
  }
  .file-upload .file-upload-select .file-select-name3 {
    display: inline-block;
    padding: 1rem;
    color: black;
    font-size: 7px;
  }

  .NetworkPageFormSubmitButton {
    width: 77vw;
    margin-left: 0px;

    cursor: pointer;
  }
  .FormErrorMesage {
    font-size: 12px;
  }
  .FileSizedisable {
    font-size: 12px;
  }
}
