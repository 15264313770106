.FeaturePackageSecondHeading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  font-size: 1.5vw;
  line-height: 150%;
  text-align: center;
  color: #44576a;
  display: flex;
  justify-content: center;
}
.HealthPackagebtnA {
  background: var(--globalColorHeader);
  border-radius: 12px;
  font-size: 0.8vw;
  padding-top: 0.625em;
  padding-bottom: 0.625em;
  padding-left: 3vw;
  padding-right: 3vw;
  color: white;
  cursor: pointer;
  border: none;
  outline: none !important;
  position: absolute;
  margin-left: 74vw;
  margin-top: -3vw;
}
.FeaturePackagefirstHeadingLanding {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1.7vw;
  line-height: 150%;
  text-align: center;
  color: var(--globalColorHeader);
  margin-bottom: 2vw;
  margin-top: 2vw;
}

.FeaturePackagefirstHeading1 {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1.7vw;
  line-height: 150%;
  text-align: center;
  color: var(--globalColorHeader);
  margin-top: 3.625em;
  margin-bottom: 2vw;
}
.FeaturePackagefirstHeading12 {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1.7vw;
  line-height: 150%;
  text-align: center;
  color: var(--globalColorHeader);
  margin-top: 3vw;
  margin-bottom: -3vw;
}
.FeaturePackage-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2vw;
  margin-left: 3.6vw;
  margin-right: 6.6vw;
  margin-top: 5vw;
  margin-bottom: 4.5vw;
  min-height: 35vh;
}
.LoaderFront {
  height: 75vh !important;
}
.LoaderForFront {
  width: 100vw;
  height: 45vw;
}
.ites {
  width: 19vw;
  /* height: 19.1vw; */
  background: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 12px;
}
.FeaturePackageCardImage {
  padding-top: 1vw;
  padding-left: 1vw;
}
.FeaturePackageCardHeading1 {
  padding-left: 1vw;
  font-weight: 500;
  font-size: 0.8vw;
  color: var(--globalColorButton);
  margin-top: 1.6vw;
  min-height: 3vw;
  max-height: 3vw;
  overflow: hidden;
}
.FeaturePackageCardSecondHeading1 {
  padding-left: 1vw;
  font-weight: 300;
  font-size: 0.7vw;
  color: #acb2c1;
  margin-top: 1vw;
}
.FeaturePackageCardCostConatiner {
  display: flex;
  justify-content: space-between;
  margin-left: 1vw;
  margin-right: 2vw;
  margin-top: 2vw;
}
.FeaturePackageCardCostactual {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1vw;
  line-height: 150%;
  /* identical to box height, or 21px */

  color: #44576a;
}
.FeaturePackageButton {
  background: var(--globalColorHeader);
  border-radius: 0px 0px 12px 12px;
  text-align: center;
  color: #ffffff;
  width: 18.9vw;
  border: none;
  outline: none !important;
  margin-top: 2vw;
  padding-top: 0.7vw;
  padding-bottom: 0.7vw;
  cursor: pointer;
  font-size: 1vw;
}

.FeaturePackageButtonAdded {
  background: #7180ad;
  border-radius: 0px 0px 12px 12px;
  text-align: center;
  color: #ffffff;
  width: 18.9vw;
  border: none;
  outline: none !important;
  margin-top: 2vw;
  padding-top: 0.7vw;
  padding-bottom: 0.7vw;
  cursor: pointer;
}
.FeaturePopularPlansCardButtonAddedCartM {
  margin-top: 7vw;

  border-radius: 6px;
  color: #ffffff;
  border: none;
  padding: 1.7vw 5vw 1.7vw 5vw;
  cursor: pointer;
  width: 70vw;
  background: #7180ad;
  border-radius: 0px 0px 12px 12px;
}
.daiPopularPlansHeaderM {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 4.8vw;
  line-height: 150%;
  color: var(--globalColorHeader);
  text-align: center;
  margin-bottom: 1.5vw;
  margin-top: 41vw;
  /* margin-bottom: 4vw; */
}
.diahealthPackagebtnA {
  background: var(--globalColorHeader);
  border-radius: 12px;
  font-size: 0.8vw;
  padding-top: 0.625em;
  padding-bottom: 0.625em;
  padding-left: 3vw;
  padding-right: 3vw;
  color: white;
  cursor: pointer;
  border: none;
  outline: none !important;
  position: absolute;
  margin-top: -3vw;
  margin-left: 74vw;
}
.PopularDiaCantainer {
  margin-top: -7vw;
}

.FeaturePopularPlansCardButtonBuyNowM {
  margin-top: 7vw;
  background: var(--globalColorButton);
  border-radius: 6px;
  color: #ffffff;
  border: none;
  padding: 1.7vw 5vw 1.7vw 5vw;
  cursor: pointer;
  width: 70vw;
  background: var(--globalColorButton);
  border-radius: 0px 0px 12px 12px;
}
.PopularPlansCardImageMoutterConatiner {
  padding-left: 5vw;
  padding-top: 4vw;
}
.HealthCheckMask {
  height: 30vw;
  background: linear-gradient(
    180deg,
    rgba(34, 45, 73, 0.6) 0%,
    rgba(49, 60, 91, 0.6) 100%
  );
}
.HealthCheckMaskText {
  padding-top: 6vw;
  padding-left: 6vw;
  color: white;
  font-weight: 500;
  font-size: 2vw;
}
.FeaturePackageButtonAdded {
  background: #7180ad;
  border-radius: 0px 0px 12px 12px;
  text-align: center;
  color: #ffffff;
  width: 18.9vw;
  border: none;
  outline: none !important;
  margin-top: 2vw;
  padding-top: 0.7vw;
  padding-bottom: 0.7vw;
  cursor: pointer;
  font-size: 1vw;
}

.couponSticker {
  position: absolute;
  margin-left: 20vw;
  width: 3vw;
  height: 6vw;
  font-size: 0.8vw;
}
.couponStickerText {
  color: white;
  text-align: center;
  padding-top: 0.5vw;
}
.couponStickersearchscreen {
  position: absolute;
  margin-left: 17vw;
  width: 3vw;
  height: 6vw;
  font-size: 0.8vw;
}
@media screen and (max-width: 600px) {
  .FeatureHealthViewAllButtonContainer {
    display: flex;
    justify-content: center;
  }
  .FeatureHealthViewAllButton {
    background: var(--globalColorHeader);
    border-radius: 12px;
    padding-top: 1vw;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 1vw;
    color: white;
    outline: none !important;
    border: none;
    margin-top: 8vw;
  }
  .FeaturePopularPlansHeaderConatinerM {
    margin-top: -25vw;
  }
  .DownlaodOurAppBannerBgColorFeature {
    padding-bottom: 22vw;
    background: linear-gradient(
      185.13deg,
      #63809c -2.03%,
      #6a87a5 23.17%,
      #65798d 48.38%,
      #82a1be 69.53%,
      #a7bdd3 98.79%
    );
  }
  .DownlaodOurAppBannerMainHeading {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 100;
    font-size: 5.7vw;
    line-height: 38px;
    text-align: center;
    color: #ffffff;
    line-height: 150%;
    padding-top: 5vw;
    padding-left: 4vw;
    padding-right: 4vw;
  }
  .couponSticker {
    position: absolute;
    margin-left: 72vw;
    width: 10vw;
    height: 16vw;
    font-size: 3.8vw;
  }
  .DownlaodOurAppBannerFeatureContainer {
    margin-top: 10vw;
  }
  .FeaturePopularPlansHeaderM {
    font-family: var(--globalFont);
    font-style: normal;
    font-weight: 500;
    font-size: 4.8vw;
    line-height: 150%;
    color: var(--globalColorHeader);
    text-align: center;
    margin-bottom: 1.5vw;
    /* margin-top: 28.5vw; */
    margin-top: 10.5vw;
    /* margin-bottom: 4vw; */
  }
  .FeaturePopularHealthPlansHeaderM {
    font-family: var(--globalFont);
    font-style: normal;
    font-weight: 500;
    font-size: 4.8vw;
    line-height: 150%;
    color: var(--globalColorHeader);
    text-align: center;
    margin-bottom: 1.5vw;
    margin-top: 11.5vw;
    /* margin-bottom: 4vw; */
  }
  .FeatureDiaPopularHealthPlansHeaderM {
    font-family: var(--globalFont);
    font-style: normal;
    font-weight: 500;
    font-size: 4.8vw;
    line-height: 150%;
    color: var(--globalColorHeader);
    text-align: center;
    margin-bottom: 1.5vw;
    margin-top: 30.5vw;
    /* margin-bottom: 4vw; */
  }
  .PoppularHealthheckupsubHeader {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 100;
    font-size: 4.3vw;
    line-height: 150%;
    text-align: center;
    color: #44576a;
    padding-left: 4vw;
    padding-bottom: 0vw;
    padding-right: 4vw;
  }
  .FeaturesHealthChekupBodyContainer {
    margin-left: 4.32vw;
    margin-right: 5.625vw;
  }
  .packageCardBodyPrice {
    display: flex;
    margin-bottom: 2vw;
  }
  .itemCostM {
    margin-left: 6vw;
    font-size: 14px;
  }
  .itemFinalCostM {
    margin-left: 1vw;
    font-size: 14px;
  }
  .Lineselectcost {
    font-weight: 500;
    font-size: 14px;
    color: red;
  }
  .PackageSelectDescriptionFeature {
    width: 68vw;
    padding-top: 3vw;
    margin-bottom: 3vw;
    font-size: 14px;
    -webkit-box-orient: vertical;
    overflow: hidden;

    font-family: var(--globalFont);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    display: -webkit-box;
    color: #44576a;
    -webkit-line-clamp: 4;
  }
  .HealthFeaturePackageCarousel2 {
    margin-top: 3vw;
    /* border-radius: 12px; */
    padding-bottom: 7vw;
    background: white;
  }
}
