.networkBody1 {
  height: 15vw;
  background-color: #e2eefe;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.networkMainHeading {
  font-family: "Montserrat";
  font-style: normal;
  margin-top: 1.9vw;
  font-weight: 500;
  font-size: 1.5em;
  line-height: 150%;
  color: #1c71e1;
  margin-left: 2vw;
  margin-right: 2vw;
  text-align: center;
}

.head {
  display: flex;
  margin-left: 5vw;
  margin-top: 2.5vw;
  margin-right: 5vw;
  flex-wrap: wrap;
}

.networkLeftHead {
  display: flex;
  align-items: baseline;
}

.networkLeftHeadDropdown {
  display: flex;
}

.autocompleteNetwork {
  margin-right: 10.6vw;
}

.autocompleteNetwork_select {
  background: #ffffff;
  border: 1px solid #a4a4a4;
  padding: 0.8vw 0vw;
  padding-left: 1vw;
  font-size: 1.1vw;
  outline: none !important;
  align-items: center;
}

.autocompleteNetwork_selects_change {
  background: #ffffff;
  border: 1px solid #a4a4a4;
  padding: 0.8vw 0vw;
  font-size: 1.1vw;
  padding-left: 1vw;
  margin-left: -9vw;
  align-items: center;
  outline: none !important;
  cursor: pointer;
  width: 13vw;
}

.autocompleteNetwork_selects {
  background: #ffffff;
  border: 1px solid #a4a4a4;
  padding: 0.8vw 0vw;
  font-size: 1.1vw;
  padding-left: 1vw;
  margin-left: 2vw;
  align-items: center;
  outline: none !important;
  cursor: pointer;
  width: 13vw;
}

.networkSearchs {
  background: #ffffff;
  margin-left: 1rem;
  display: flex;
  margin-left: 28vw;
  align-items: center;
  border: 1px solid #a4a4a4;
  border-radius: 12px 12px 12px 12px;
}

.networkSearchs input {
  border: none;
  font-size: 1.1vw;
  height: 2vw;
  width: 20vw;
  /* min-width: 28vw;
   */
  padding-left: 0em;
}

.networkSearchs img {
  margin-left: 0.890625em;
  margin-right: 0.890625em;
  height: 0.82em;
  width: 0.82em;
}

.networkSearchBtn {
  background-color: var(--globalColorButton);
  border-radius: 0px 0.6vw 0.6vw 0px;
  padding-left: 3vw;
  cursor: pointer;
  padding-top: 0.7vw;
  padding-right: 3vw;
  padding-bottom: 0.7vw;
  border: none;
  outline: none !important;
  color: white;
}

.bodyBackground {
  background: #f1f4fc;
}

.networkSelect {
  height: 2.855em;
}

textarea:focus,
input:focus {
  outline: none;
}

.networkSearch {
  background: #ffffff;
  margin-left: 1rem;
  display: flex;
  margin-left: 11vw;
  align-items: center;
  border: 1px solid #a4a4a4;
  border-radius: 12px 12px 12px 12px;
}

.networkSearch input {
  border: none;
  font-size: 1.1vw;
  height: 2vw;
  width: 20vw;
  padding-left: 0em;
}
textarea:focus,
input:focus {
  outline: none;
}

.networkSearch img {
  margin-left: 0.890625em;
  margin-right: 0.890625em;
  height: 0.82em;
  width: 0.82em;
}

.networkRightHead {
  display: flex;
  margin-left: 71vw;
  position: absolute;
  justify-content: flex-end;
}
.networkSort {
  background: #ffffff;
  border: 1px solid #333333;
  border-radius: 12px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-left: 1.0125625em;
  padding-right: 1.1875em;
  color: #44576a;
  cursor: pointer;
}
.networkSort img {
  height: 0.5625em;
  width: 0.84375em;
  margin-right: 0.703125em;
}
.networkFilter {
  margin-left: 4.8vw;
  background: #ffffff;
  border: 1px solid #333333;
  border-radius: 1vw;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 3em;
  padding-left: 1vw;
  padding-right: 2vw;
  color: #44576a;
  cursor: pointer;
}
.networkFilter img {
  height: 0.5625em;
  width: 0.84375em;
  margin-right: 0.703125em;
}
.networkCardbody {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3vw;
  margin-left: 7vw;
  margin-right: 7.8vw;
  padding-top: 3vw;
}
.networkCardbody .networkCard {
  position: relative;
  background: #ffffff;
  border: 1px solid #e2e0e0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}
.addressMblBlock {
  margin-left: 2vw;
  color: #e68d36;
  font-family: "Montserrat";
}
.textLeftHead {
  padding-right: 2vw;
  padding-left: 2vw;
  padding-top: 1.8vw;
  padding-bottom: 2.8vw;
}
.polygon {
  position: absolute;
  top: 0px;
  right: 0px;
}
.textLeftHead .title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 1.17em;
  line-height: 150%;
}

.address {
  margin-top: 0.6vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1vw;
  line-height: 150%;
  color: #000000;
  display: flex;
  align-items: baseline;
}

.address1 {
  margin-top: 0.6vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1vw;
  line-height: 150%;
  color: #0863eb;
  display: flex;
  align-items: baseline;
}

.address1 img {
  margin-right: 0.645em;
  width: 0.9em;
  height: 0.9em;
}

.address img {
  margin-right: 0.645em;
  width: 0.583em;
  height: 0.833125em;
}

.networkPagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 3.9375em;
  margin-right: 8vw;
  padding-bottom: 8vw;
}

.css-gdh49b-MuiAutocomplete-listbox .MuiAutocomplete-option {
  font-size: 10px !important;
}

.MapurlCSS {
  white-space: nowrap;
  width: 20vw;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

@media screen and (max-width: 1000px) and (min-width: 769px) {
  .networkBody1 {
    height: 13em;
  }
}

@media screen and (max-width: 768px) {
  .networkBody1 {
    height: 13em;
  }

  .networkCardbody {
    grid-template-columns: 1fr 1fr;
  }

  .networkLeftHead {
    flex-direction: column;
    align-items: center;
  }

  .networkSearch {
    margin-top: 1em;
    margin-left: 0px;
  }

  .networkSearchs {
    margin-top: 1em;
    margin-left: 0px;
  }

  .autocompleteNetwork_select {
    padding: 0.5em 0.5em;
    font-size: 0.75em;
  }

  .autocompleteNetwork_selects {
    padding: 0.5em 0.5em;
    font-size: 0.75em;
    width: 20vw;
  }

  .autocompleteNetwork_selects_change {
    padding: 0.5em 0.5em;
    font-size: 0.75em;
    width: 20vw;
  }

  .networkSearch input {
    font-size: 0.65em;
  }

  .networkSearchs input {
    font-size: 0.65em;
  }
}

@media screen and (max-width: 480px) {
  .networkBody1 {
    height: 15em;
  }

  .bodyBackground {
    padding-top: 1em;
  }

  .networkCardbody {
    grid-template-columns: 1fr;
  }

  .textLeftHead {
    padding-right: 3vw;
    padding-left: 3vw;
    padding-top: 0.75em;
    padding-bottom: 0.75em;
  }

  .address {
    font-size: 0.7em;
  }
  .address1 {
    font-size: 0.7em;
  }
  .networkSearchs input {
    width: 30vw;
  }
  .networkSearch input {
    width: 30vw;
  }
}
