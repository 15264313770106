.ermsMainDiv {
  margin-top: 6.85vh;
  padding-top: 6vh;
  margin-left: 3.97vw;
  margin-right: 3.97vw;
  flex-shrink: 0;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 12px;
  box-shadow: 0px 0px 2px 2px rgb(0 0 0 / 10%);
  padding-bottom: 1vh;
  margin-bottom: 15vh;
}



.ermsText {
  font-size: 1.83em;
  font-family: Montserrat;
  font-weight: 500;
  line-height: 150%;
  color: var(--web-pc, var(--globalColorMainHeading));
  text-align: center;
}
.ErmsTextNameAndAge {
  display: flex;
  justify-content: space-between;
  margin-top: 4.42vh;
  color: #000;
  text-align: center;
  font-size: 1.6em;
  font-family: Montserrat;
  line-height: 150%;
}
.ErmsTextAge {
  margin-right: 5.33vw;
}
.ErmsTextName {
  margin-left: 2.4vw;
}
.ermsForm {
  border-radius: 12px;
  padding-top: 3vh;
  background: #f6f6f6;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  margin-top: 2.38vh;
  margin-bottom: 9.04vh;
  margin-left: 4.85vw;
  margin-right: 4.85vw;
}

.ermsFormHeading {
  margin-top: 2.69vh;
  color: var(--primary-color-1, #1d2334);
  font-size: 32px;
  font-family: Montserrat;
  line-height: 150%;
  margin-left: 4.63vw;
  margin-bottom: 2.77vw;
}
.ErmsNewFormInput {
  display: flex;
  flex-direction: column;
}
.ErmsNewFormInputField {
  color: var(--primary-color-1, #1d2334);
  font-size: 16px;
  font-family: Montserrat;
  line-height: 150%;
  margin-top: 3.5vh;
  border-radius: 12px;
  border: 1px solid #89a9bb;
  background: #fff;
  margin-left: 4.63vw;
  width: 68.6vw;
  padding-top: 0.88vh;
  padding-bottom: 0.88vh;
  padding-left: 2.79vw;
}

.ermsSubmitButton {
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  padding-left: 3em;
  padding-right: 3em;
  border-radius: 6px;
  background: var(--globalColorHeader);
  color: #fff;
  font-size: 0.8em;
  font-family: "Montserrat";
  font-weight: 500;
  line-height: 150%;
}
.ErmsNewFormInputFieldDate {
  display: flex;
  color: #1d2334;
  font-size: 16px;
  font-family: "Montserrat";
  line-height: 150%;
  margin-top: 3.5vh;
  border-radius: 12px;
  border: 1px solid #89a9bb;
  background: #fff;
  margin-left: 4.63vw;
  width: 68.6vw;
  padding-top: 0.88vh;
  padding-bottom: 0.88vh;
  padding-left: 2.79vw;
  align-items: center;
  justify-content: space-between;
}
.ErmsNewFormInputFieldDate p{
  margin-top: auto;
  margin-bottom: auto;
}

.file-upload-ERM  {
  display: flex;
  width: 69vw;
  margin-left: 4.5vw;
  margin-top: 3vh;
  height: 48px;
  color: #dbdbdb;
  cursor: pointer;
  text-align: left;
  background: #ffffff;
  border: 1px solid #89a9bb;
  overflow: hidden;
  position: relative;
  border-radius: 12px;
  box-sizing: border-box;
}

.file-select-button-ERM{
width: 30%;
font-size: 0.8em;
font-family: "Montserrat";
text-align:left;
padding-left: 0.4em;
color: var(--primary-color-111, grey);
padding-left: 2.79vw;

}

.file-upload-select-ERM{
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
}

.file-select-name-ERM {
  display: flex;
  align-items: center;
 justify-content: flex-end;
  padding-right: 0.4em;
  color: #1d2334;
  font-size: 0.8em;
  color: var(--primary-color-111, grey);
  width: 40%;
}

.upladoffile-ERM {
  color: #1d2334;
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.upladoffile-ERMINPUT{
  width: 100%;
text-align: center;
}
.ErmsNewFormInputFieldErrorMessage {
  margin-left: 5vw;
  color: red;
}

.FileSizedisableERM {
  margin-top: -3vw;
  margin-bottom: 0.4vw;
  margin-left: 5.7vw;
  color: red;
  font-size: 1.3vw;
}
.emrmButtonArea{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  padding-bottom: 2em;
}
.DateUploadText{
  margin-right: 2em;
  cursor: pointer;
  z-index: 1;
}
@media screen and (max-width:600px) {
  .file-select-button-ERM{
    font-size: 0.6em;
  }
  .file-select-name-ERM{
    font-size: 0.6em;
    text-align: right;
  }
  .upladoffile-ERM{
    font-size: 0.6em;

  }
  .ErmsNewFormInputField{
    font-size: 0.8em;
  }
  .ErmsNewFormInputFieldDate p{
    font-size: 0.8em;
  }
  .DateUploadText{
    font-size: 0.8em;
  }
}
