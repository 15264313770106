.RentalProductBody1 {
  display: flex;
  justify-content: center;
  flex-direction: column;

  background: #f2f4f8;
}

.RentalProductHeading1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.6vw;
  line-height: 150%;
  color: #44576a;
  margin-left: 4vw;
  margin-top: 0vw;
}
.RentalProductHeading {
  margin-top: 4vw;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 600;
  font-size: var(--globalFontSize28);
  line-height: 150%;
  /* identical to box height, or 36px */

  text-align: center;

  color: var(--globalColorHeader);
}
.WhyRentalProductHeading {
  margin-top: 4vw;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: var(--globalFontSize28);
  line-height: 150%;
  /* identical to box height, or 36px */

  /* text-align: center; */

  color: var(--globalColorMainHeading);
}

.WhyRentalProductHeadingThree {
  width: 100%;
  display: flex;
  justify-content: center;
}
.WhyRentalProductHeadingTwo {
  font-family: "Montserrat";
  margin-top: 2vw;
  font-style: normal;
  width: 35vw;
  font-weight: 100;
  font-size: var(--globalFontSize20);
  line-height: 150%;
  /* identical to box height, or 42px */

  /* text-align: center; */
  text-transform: capitalize;

  color: #44576a;
}
.RentalProductHeadingThree {
  width: 100%;
  display: flex;
  justify-content: center;
}
.RentalProductHeadingTwo {
  font-family: "Montserrat";
  margin-top: 2vw;
  font-style: normal;
  width: 70%;
  font-weight: 400;
  font-size: var(--globalFontSize20);
  line-height: 150%;
  text-align: center;
  text-transform: capitalize;
  color: #44576a;
}

.RentalAllProducts {
  margin-left: 6vw;
  margin-right: 6vw;
}

.RentalGridHeading {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1.8vw;
  line-height: 33px;
  margin-top: 3vw;

  color: #44576a;
}

.RentalProductWrapper {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr 2fr 2fr;
  margin-top: 3vw;
  gap: 2vw;
}
.RentalProductsGridContainer {
  background-color: wheat;
  border-radius: 0.6vw;
  height: 10vw;
}
.RentalProductsGridContainer1 {
  border-radius: 0.6vw;
}

.WhyRentInnerBody {
  margin-left: 6vw;
  margin-right: 6vw;
}
.WhyRentalwrapper {
  display: grid;
  margin-left: 6vw;
  margin-right: 6vw;
  grid-template-columns: 2fr 2fr 2fr;
  margin-top: 6vw;
  gap: 2em;
}

.WhyRentalwrapperBlockOne {
  border-radius: 0.6vw;
  background: #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.WhyRentalwrapperBlockOTwo {
  height: 15vw;
  width: 28vw;
  border-radius: 0.6vw;
  background: #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.WhyRentalBlockThree {
  width: 28vw;
  border-radius: 0.6vw;
  background: #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.WhywarpperInnerBlock {
  display: flex;
  justify-content: center;
  margin-top: 2vw;
}

.WhyRentalWrapperHeading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.4vw;
  line-height: 2vw;
  text-transform: capitalize;
  text-align: center;
  color: #44576a;
  margin-top: 4vw;
  position: absolute;
}

.WhyRentalText {
  margin-top: 7vw;

  text-align: center;

  position: absolute;
  width: 23vw;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: var(--globalFontSize14);
  line-height: 150%;
  text-align: center;
  color: #44576a;
}
.WhyRentBody {
  margin-bottom: 3vw;
}

.WhyRentalGrid {
  margin-bottom: 7vw;
  width: 3.3vw;
  height: 3vw;
}

.RentalGridCantainerName {
  display: flex;
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1vw;
  line-height: 200%;
  color: #f6f6f6;
}

.RentalTextOuterGrid {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 2vw;
  margin-top: 8vw;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #000000 78.65%
  );
}

.RentalProduct_BlockFour_grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 2vw;
  margin-left: 6vw;
  margin-right: 6vw;
  margin-top: 3vw;
}
.RentalImageCard {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.RentalProduct_BlockFour_grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 3vw;
  width: 20vw;
  height: 23vw; /*  */
  border: 1px solid #e3e3e3;
  border-radius: 12px;
}

.RentalProduct_BlockFourSubHead {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: var(--globalFontSize18);
  margin-top: 1vw;
  line-height: 150%;

  color: #44576a;
}

.RentalOutterGrid {
}

.Whelloutline {
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wheeloutlineImage {
  padding-top: 3vw;
}

.suctionImage {
  padding-top: 2vw;
  width: 16vw;
  padding-left: 4vw;
}
.PhototherapyoutlineImage {
  padding-top: 2vw;
  width: 16vw;
  padding-left: 4vw;
}
.RentalWrapperImage {
  height: 20vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.RentalWrapperImage img {
  width: 35%;
  height: 60%;
}
.WhyRentalwrapperBlockOneText {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: var(--globalFontSize18);
  text-align: center;
}
@media screen and (max-width: 1000px) {
  .RentalProduct_BlockFour_grid-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .WhyRentalwrapper {
    display: grid;
    margin-left: 6vw;
    margin-right: 6vw;
    grid-template-columns: 2fr 2fr;
    margin-top: 6vw;
    gap: 2em;
  }
}
@media screen and (max-width: 700px) {
  .RentalProduct_BlockFour_grid-container {
    grid-template-columns: 1fr 1fr;
  }
  .WhyRentalwrapper {
    display: grid;
    margin-left: 6vw;
    margin-right: 6vw;
    grid-template-columns: 2fr;
    margin-top: 6vw;
    gap: 2em;
  }
  .WhyRentalwrapperBlockOne {
    margin-top: 1em;
    width: 80%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 600px) {
  .RentalProduct_BlockFour_grid-container {
    grid-template-columns: 1fr;
  }
  .RentalProduct_BlockFourSubHead {
    font-size: var(--globalFontSize20);
    font-weight: 600;
  }
}
