.myAccountBody {
}
.myAccountHeading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.7vw;
  line-height: 150%;
  margin-top: 4vw;
  margin-bottom: 0.4vw;
  color: #a93c3c;
}

.myAccountBanner {
  height: 35vh;
}
.MyAccountOpdMasking1 {
  z-index: 99;
  height: 100%;
  width: 100vw;
  background: rgba(3, 9, 33, 0.91);
  opacity: 0.56;
}
.myAccountSubHeading {
  color: #44576a;
}
.tagMbl {
  background: #a53f2b;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 3vw;
  width: 30vw;
  margin-top: 1vw;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
}

.tagMbl1 {
  background: #388bb9;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 3vw;
  width: 30vw;
  margin-top: 1vw;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
}

.myAccountSubBody {
  background: #ffffff;
  border-radius: 16px;
  margin-bottom: 3vw;
  margin-top: 3vw;
}

.myAccountGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  margin:0 auto;
  width: 90%;
  column-gap: 1em;
}

.myAccountbox1 {
  margin-top: 3.1vw;
  background: #ffffff;
  border: 1px solid #f7f7f7;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}
.myAccountbox2 {
  margin-top: 3.1vw;
  margin-bottom: 3.3vw;
  height: 8.5vw;

  background: #ffffff;
  border: 1px solid #f7f7f7;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}

.myAccountGridHeading {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: var(--globalFontSize20);
  line-height: 19px;
  padding: 1em;
  color: var(--globalColorButton);
}
.myAccountIconsArea{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1.5em;
}
.myAccountPic {
  display: flex;
  width: 1.5em;
  height: 1.5em;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 16vw;
}

.myAccountBodyMbl {
  margin-left: 5vw;
  margin-right: 5vw;
}
.myAccountPicMbl {
  float: right;
  margin-top: 19vw;
  margin-right: 2vw;
}

.myAccountBodySubBodyMbl {
  height: 35vw;
  margin-top: 6.2vw;
  margin-right: 3vw;
  padding-right: 5vw;
  padding-left: 5vw;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 3.8vw;
  line-height: 19px;
  /* identical to box height */

  color: var(--globalColorButton);
  padding-top: 4vw;
  padding-bottom: 4vw;

  background: #ffffff;
  border: 1px solid #f7f7f7;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}

.myAccountTags {
  background: #a53f2b;
  font-family: var(--globalFont);
  font-style: normal;
  position: absolute;
  margin-top: 2.3vw;
  font-weight: 400;
  font-size: 8px;
  padding-top: 0.1vw;
  padding-bottom: 0.1vw;
  /* padding-right: 0.7vw; */
  width: 5vw;
  margin-left: 1.2vw;
  line-height: 150%;
  /* identical to box height, or 12px */

  text-align: center;

  /* white */

  color: #ffffff;
}
.myAccountTagss {
  background: #388bb9;
  font-family: var(--globalFont);
  font-style: normal;
  position: absolute;
  margin-top: 2.2vw;
  font-weight: 400;
  font-size: 8px;
  padding-top: 0.1vw;
  padding-bottom: 0.1vw;
  /* padding-right: 0.7vw; */
  width: 6vw;
  margin-left: 1.2vw;
  line-height: 150%;
  /* identical to box height, or 12px */

  text-align: center;

  /* white */

  color: #ffffff;
}
@media screen and (max-width:1100px) {
  .myAccountGrid {
    grid-template-columns: 1fr 1fr  ;
  }
}
@media screen and (max-width:600px) {
  .myAccountGrid {
    grid-template-columns: 1fr  ;
  }
}
