.GetExpertGuidance {
  background: #e1eeff;
  margin-top: 2em;
}

.GetExpertGuidanceHeading {
  text-align: center;
  padding-top: 2vw;
  font-size: 2em;
  font-weight: 600;
}

.GetExpertGuidanceHeadingCallGrid {
  display: grid;
  grid-template-columns: 35vw 20vw;
  margin-left: 18vw;
  margin-top: 2vw;
  padding-bottom: 3vw;
}

.GetExpertGuidanceHeadingCallFirst {
  background-color: white;
  border: none;
  outline: none !important;
  padding-left: 1vw;
  font-size: 1em;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.GetExpertGuidanceHeadingCallSecond {
  background: #1c71e1;
  font-size: 1em;
  text-align: center;
  color: #ffffff;
  padding: 1em;
  cursor: pointer;
  border-radius: 10px;
  width: max-content;
}

@media screen and (max-width: 879px) {
  .GetExpertGuidanceHeading {
    font-size: 1.5em;
    margin: 0 1em;
  }

  .GetExpertGuidanceHeadingCallFirst {
    font-size: 0.75em;
  }

  .GetExpertGuidanceHeadingCallSecond {
    font-size: 0.75em;
  }
}

@media screen and (max-width: 480px) {
  .GetExpertGuidanceHeadingCallGrid{
    margin-left: 10vw;
  }
  .GetExpertGuidanceHeading {
    font-size: 1em;
    margin: 0 1em;
  }

  .GetExpertGuidanceHeadingCallFirst {
    font-size: 0.6em;
  }
  
  .GetExpertGuidanceHeadingCallSecond {
    font-size: 0.6em;
  }
}
