.addButton {
  width: 3.5em;
  height: 3.5em;
  background: #1c71e1;
  color: #fff;
  text-align: center;
  border-radius: 10px;
}

.addButton img {
  vertical-align: middle;
  border-style: none;
  display: inline !important;
}