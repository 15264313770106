.productInfo {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 5vw;
    margin-bottom: 2em;
}

.productDescription {
    margin-top: 3em;
    margin-left: 4vw;
    margin-right: 4vw;
    border: 0.5px solid #9C9C9C;
    border-radius: 10px;
    margin-bottom: 2em;
}

.productInfoRelatedProduct {
    margin-left: 4vw;
    margin-top: 2em;
    margin-right: 3vw;
    margin-bottom: 2em;
}

@media screen and (max-width: 1000px) {
    .productInfo {
        display: flex;
        flex-direction: column;
        gap: 0;
        margin-bottom: 0px;
    }

    .productDescription {
        padding-top: 1em;
        margin-right: 4vw;
    }
    .productInfoRelatedProduct{
        margin-left: 5vw;
        margin-right: 5vw;
    }
}