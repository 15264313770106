.HealthCheckupMain {
  background: #fff;
}

.HealthChekupSection3CardWrapper {
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  flex-wrap: wrap;
}
.HealthChekupSection3CardIndividual {
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-top: 1em;
}

.HealthChekupSection3CardImageTextContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.HealthChekupSection3CardText {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: var(--globalFontSize16);
  line-height: 150%;
  text-align: center;
  text-transform: capitalize;
  color: black;
  margin-top: auto;
  margin-bottom: auto;
  padding: 1em 1.4em 1em 1.4em;
}

.arrow {
  width: 5vw;
  z-index: 900;
  position: absolute;
  top: 100%;
}

.HealthCheckupListing {
  margin-top: 3vw;
}
.HCMMain {
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  padding-top: 2em;
}
.HCMMainChild {
  display: flex;
  flex-direction: column;
}
.HCMMainChildUpper {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  background: #f6f6f6;
  padding-top: 2.3em;
  padding-bottom: 2.81em;
  height: 7.75em;
}
.HCMMainChildUpper img {
  position: absolute;
  width: 3.2em;
  height: 3.5em;
  left: 10%;
}
.HCMMainChildUpper p {
  position: absolute;
  left: 50%;
  margin-top: auto;
  margin-bottom: auto;
  color: #44576a;
  text-align: center;
  font-family: Montserrat;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.FHCSectionViewALL {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 2em;
  color: var(--primary_color_2, #e68d36);
  text-align: center;
  font-family: var(--globalFont);
  font-size: var(--globalFontSize18);
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 42px */
  text-transform: capitalize;
  padding-bottom: 2em;
}
.FHCSectionViewALL a {
  cursor: pointer;
  text-decoration: none;
}

@media screen and (max-width : 1182px) {
    
}