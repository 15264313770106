
.TalkToDoctorSliderCard{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;
    margin-left: 2em;
    margin-right: 2em;

}
.TalkToDoctorSliderCardImage{
  background: "#FFFFFF";
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.5);
  border-radius: "1vw";
  text-align: "center";
  width: 100%;
  padding-top: 2.5em;
  padding-bottom: 2.5em;
}
.TalkToDoctorSliderCardImage img{
    width: 3em;
    height: 3em;
    margin: 0 auto;
}
.TalkToDoctorSliderCard p{
    font-size: 1em;
    font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  line-height: 1em;
  text-align: center;
  margin-top: 1em;
  color: #44576a;
}