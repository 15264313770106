.HRAQuestionsMain {
  display: flex;
  flex-direction: column;
}
.HRAQuestionsMainChild1 {
  margin-left: 5.68em;
  margin-right: 6.25em;
  margin-top: 2.7em;
}
.HRAQuestionsMainChild1 p {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 1.5em;
  line-height: 150%;
  color: var(--globalColorHeader);
}

.HRAQuestionsMainChild2 {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-left: 5.68em;
  margin-right: 6.25em;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 32px 12px 12px 32px;
  margin-top: 2.7em;
  margin-bottom: 7em;
  display: flex;
}

.HRAQuestionsMainChild2a {
  display: flex;
  padding-top: 1.7em;
  padding-bottom: 2.7em;
  width: 100%;
}
.HRAQuestionsMainChild2a1 {
  background: var(--globalColorButton);
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  height: 100%;
  font-family: var(--globalFont);
  width: 3.1em;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.HRAQuestionsMainChild2a1 p {
  writing-mode: vertical-lr;
  transform: scale(-1);
  padding-left: 0.6em;
  padding-right: 0.6em;
  margin-top: 3em;
  font-style: normal;
  font-weight: 500;
  font-size: 1.3em;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
}
.HRAQuestionsMainChild2a2 {
  height: 100%;
  background: #ffffff;
  box-shadow: 11px 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 0px 32px 32px 0px;
  padding-left: 2em;
  padding-right: 1.8em;
}
.Q_A {
  width: 20vw;
  margin-top: 2em;
}
.Q_A p {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 150%;
  color: #44576a;
}

.Q_A input {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 17px;
  color: #44576a;
  background: #ffffff;
  border: 1px solid #6b8aa7;
  border-radius: 12px;
  padding: 1em 0.5em 1em 1em;
  width: 100%;
  margin-top: 1em;
}
.hraBackArrow {
  display: flex;
  gap: 5%;
  align-items: center;
  /* background-color: aqua; */
  margin-top: 2.3em;
}
.hraBackArrow p {
  margin-top: auto;
  margin-bottom: auto;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1.3em;
  line-height: 150%;
  color: #44576a;
}
.hraBackArrow img {
  width: 1em;
  height: 1em;
}
.HRAQuestionsMainChild2a2div {
  display: flex;
  flex-direction: column;
}
.SingleQuestion {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1.2em;
  line-height: 19px;
  color: #44576a;
  margin-top: 2em;
}

.SingleQuestioneye {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1.2vw;
  line-height: 19px;
  color: #44576a;
  margin-top: 2em;
}
.HRAQuestionsButton {
  margin-top: 3em;
  background: var(--globalColorHeader);
  border-radius: 12px;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  border: hidden;
  cursor: pointer;
  width: 90%;
  width: 20vw;
  height: 3.5em;
  margin-bottom: 5em;
  border: none;
  outline: none !important;
}
.HRAQuestionsButtonLastScreen {
  margin-top: 3em;
  background: var(--globalColorHeader);
  border-radius: 12px;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  border: hidden;
  cursor: pointer;
  width: 90%;
  width: 20vw;
  height: 3.5em;
  margin-bottom: 5em;
  border: none;
  outline: none !important;
}
.Q_A .select {
  width: 100%;
  height: 3.5em;
  border-radius: 12px;
  margin-top: 1em;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 17px;
  color: #44576a;
  padding-left: 1em;
  background: #ffffff;
  border: 1px solid #6b8aa7;
  border-radius: 12px;
  outline: none !important;
}
.faded {
  background: var(--globalColorButton);
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.HRAQuestionsMainChild2LastScreen {
  background: #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  min-height: 31em;
  margin-left: 5.68em;
  margin-right: 6.25em;
  margin-top: 2.7em;
  align-items: center;
  margin-bottom: 1.5em;
}
.HRAQuestionsMainChild2LastScreen :nth-child(1) {
  margin-top: 3em;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1.2em;
  line-height: 150%;
  text-align: center;
  text-transform: capitalize;
  color: var(--globalColorHeader);
}
.HRAQuestionsMainChild2LastScreen :nth-child(2) {
  margin-top: 1.5em;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  text-align: center;
  text-transform: capitalize;
  color: var(--globalColorHeader);
}

@media screen and (max-width: 900px) {
  .HRAQuestionsButtonLastScreen {
    width: 50%;
  }
  .HRAQuestionsMainChild2a2 {
    height: 100%;
    box-shadow: 11px 0px 2px 2px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    border-radius: 0px;
  }
  .Q_A {
    width: 95%;
  }
  .HRAQuestionsMainChild2a2div {
    width: 100%;
  }
  .HRAQuestionsButton {
    width: 95%;
  }
  .HRAQuestionsMainChild2 {
    background-size: 0% 0%;
    border-radius: 0px;
    box-shadow: 0px 0px 4px 4px #fff;
  }
  .HRAQuestionsMainChild2a1 {
    display: none;
  }
}
@media screen and (max-width: 750px) {
  .HRAQuestionsButtonLastScreen {
    width: 50%;
  }
  .HRAQuestionsMainChild2 {
    margin-left: 1em;
    margin-right: 1em;
    margin-top: 1em;
  }
  .HRAQuestionsMainChild1 {
    margin-left: 1em;
    margin-right: 1em;
  }
  .HRAQuestionsMainChild2LastScreen {
    margin-left: 1em;
    margin-right: 1em;
    padding-left: 1em;
    padding-right: 1em;
  }
}
@media screen and (max-width: 500px) {
  .HRAQuestionsButtonLastScreen {
    width: 95%;
  }
}
