.Cartmain {
  margin-left: 6.69vw;
  margin-top: 5.58vw;
  margin-right: 5.29vw;
  border-radius: 12px;
  display: flex;
  gap: 1.3vw;
  /* height:70em; */
  margin-bottom: 4em;
}

.emptyCart {
  margin-top: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CartMainContent {
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #ad5454;
  border-radius: 12px;
  padding-top: 2vw;
  padding-bottom: 1em;
  width: 70%;
  height: fit-content;
}

.smallLineCart {
  margin-left: 58px;
  margin-right: 49px;
}

.CartMainSecondContent {
  background: #f8f8f8;
  border-radius: 12px;
  border: 1px solid #cfcfcf;
  min-width: 26vw;
  display: flex;
  flex-direction: column;
  max-width: 10vw;
  height: fit-content;
  max-height: 100%;
}

.NoCoupons {
  margin-top: 2vh;
  margin-bottom: 2vh;
  color: var(--globalColorButton);
  width: 100%;
  text-align: center;
  margin-bottom: 3em;
}

/* identical to box height, or 27px */

.CouponCodeNew {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  /* identical to box height, or 27px */

  color: #000000;
}

.secondCartTextProcessingCharge {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: var(--globalFontSize14);

  line-height: 150%;
  color: #000000;
  margin-left: 1.17vw;
  margin-top: 1.98vh;
}

.secondCartTextsmallProcessingCharge {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: var(--globalFontSize12);
  line-height: 150%;
  margin-left: 1.31vw;
  text-align: left;
  color: #000000;
}

.secondCartTextgst {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: var(--globalFontSize12);
  line-height: 150%;
  margin-left: 1.31vw;
  text-align: center;
  color: #000000;
}

.buttonOfSecondCart {
  cursor: pointer;
  background: var(--globalColorHeader);
  border: 1px solid var(--globalColorHeader);
  border-radius: 10px;
  padding-top: 1.1em;
  padding-bottom: 1.1em;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: var(--globalFontSize16);
  line-height: 24px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85%;
  margin: 0 auto;
  margin-top: 4em;
  margin-bottom: 2em;
  height: 3.5em;
}

.SecondCartLineCss {
  border: 1px solid #dfe2e4;
  margin-top: 1em;
  margin-left: 1.91vw;
  margin-right: 1.91vw;
}

.CartMainSecondContentCoupon {
  display: flex;
  flex-direction: column;
  margin-left: 1.47vw;
  margin-top: 1.1em;
}

.CouponText {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: var(--globalFontSize20);
  line-height: 150%;
  margin-top: 1.31em;
  color: #000000;
}

.CouponCodeCartMobileView {
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border: 1px solid #b5b5b5;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  margin-right: 0.3vw;
  padding-right: 3vw;
  padding-left: 0.73vw;
  padding-top: 0.68em;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 150%;
  padding-bottom: 0.68em;
  width: 100%;
  padding-top: 0.68em;
  padding-bottom: 0.5em;
}

.CouponApplyButtonMobileView {
  background: var(--globalColorHeader);
  padding-left: 7.98vw;
  padding-right: 7.98vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.17vw;
  line-height: 150%;
  color: #ffffff;
  border-radius: 6px;
  position: absolute;
  right: 2px;
  padding-top: 0.68em;
  padding-bottom: 0.48em;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.CouponCodeCart {
  display: flex;
  justify-content: space-between;
  background: #f6f6f6;
  border: 1px solid #dbdbdb;
  font-style: italic;
  color: #686868;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  margin-right: 0.3vw;
  font-family: var(--globalFont);
  font-weight: 400;
  font-size: var(--globalFontSize12);
  line-height: 150%;
  width: 85%;
  padding: 1.2em 1.1em 1.2em 1em;
}

.CodeAlert {
  display: flex;
  align-items: flex-end;
  position: relative;
  margin-right: 1.25em;
  margin-top: 1.31em;
  margin-bottom: 1.31em;
}

.CouponCodeCartText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.02vw;
  line-height: 150%;
  margin-left: 0.73vw;
  margin-top: 0.73vh;
  margin-bottom: 0.63vh;
  color: #86939f;
}

.CartCardContent {
  margin-right: 1.47vw;
  display: flex;
  background: #ffffff;
  border: 1px solid #44576a;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  margin-top: 1.5em;
  position: relative;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.CartCardContentHeading {
  margin-left: 0.73vw;
  padding-top: 0.75em;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1.17vw;
  line-height: 150%;
  color: #44576a;
}

.cartCartdContentSubHeading {
  margin-left: 0.73vw;
  padding-top: 0.3em;
  padding-bottom: 1.75em;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 0.73vw;
  line-height: 150%;
  /* or 15px */

  color: #44576a;
}

.UseCouponCart {
  background: #44576a;
  border: 1px solid #44576a;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 8px 8px 0px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 0.88vw;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2em;
  padding-right: 2em;
  position: absolute;
  right: 0;
  height: 100%;
  width: 30%;
}

.CouponApplyButton {
  background: var(--globalColorHeader);
  padding-left: 1.5em;
  padding-right: 1.5em;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: var(--globalFontSize16);
  line-height: 150%;
  color: #ffffff;
  border-radius: 6px;
  position: absolute;
  right: 0;
  padding-top: 0.78em;
  padding-bottom: 0.78em;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.PriceCartTextPaid {
  margin-left: 1.91vw;
  margin-right: 1.8vw;
  margin-top: 1.98vh;
  font-family: "Montserrat";
  margin-left: 5.47vw;
  font-style: normal;
  font-weight: 400;
  font-size: 1.27vw;
  line-height: 150%;
  /* identical to box height, or 27px */

  text-align: right;

  color: #44576a;
}
.secondCartText {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: var(--globalFontSize20);
  line-height: 150%;
  color: #000000;
  margin-left: 1.17vw;
  margin-top: 1.98vh;
  width: 70%;
}
.PriceCartText {
  margin-left: 1.91vw;
  margin-right: 2vw;
  margin-top: 1.98vh;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: var(--globalFontSize20);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #000000;
  width: 30%;
}

.PriceCartTextProcessingCharge {
  margin-left: 1.91vw;
  margin-right: 2vw;
  margin-top: 1.98vh;
  font-family: "Montserrat";
  margin-left: 9.47vw;
  font-style: normal;
  font-weight: 400;
  font-size: 0.9vw;
  text-align: right;
  color: #000000;
}

.CartLine {
}

.myCartSubBody {
  background: #ffffff;
  border-radius: 16px;

  margin-top: 3vw;
}

.PriceCartText2 {
  margin-left: 1.91vw;
  margin-right: 2vw;
  margin-top: 1.98vh;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: var(--globalFontSize20);
  text-align: right;
  color: #319b4b;
  width: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.CartMainSecondContentText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1em;
}

.carttext {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 550;
  font-size: 1.53vw;
  line-height: 150%;
  color: var(--globalColorHeader);
}

.Carttextandbuttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  /* margin-bottom: 1.5em; */
  padding-left: 2vw;
  padding-right: 2vw;
  padding-bottom: 1vh;
}

.cartdropdownandbutton {
  display: flex;
  justify-content: space-between;
}

.removeIconCart {
  margin-right: 0.3em;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  cursor: pointer;
}

.dropdownCart {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.15vw;
  line-height: 150%;
  color: #1d2334;
  background: #ffffff;
  /* border: 1px solid #AD5454; */
  border-radius: 12px;
  padding-left: 3.9em;
  padding-right: 3.9em;
  border: 0;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  border-right: 24px solid transparent;
  outline: 1px solid #ad5454;
}

.dropdownCartText {
  padding-left: 1.82vw;
  padding-right: 1.61vw;
}

.buttonCart {
  background: var(--globalColorHeader);
  margin-left: 2.86vw;
  border-radius: 12px;
  display: flex;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.27vw;
  line-height: 150%;
  flex-direction: row-reverse;
  text-align: center;
  justify-items: center;
  align-items: center;
  color: #ffffff;
}

.buttonCartText {
  padding-left: 0.73vw;
  padding-right: 1.76vw;
}

.buttonCartTextAddButton {
  padding-left: 1.91vw;
}

.addbuttoncart {
  padding-left: 0.91vw;
}

.MyCartMainGrid {
  display: flex;
  /* row-gap: 3.67vw; */
  /* margin-left: 3.82vw; */
  margin-top: 3.11vw;
  /* justify-content: space-between; */
}

.Photocss {
  background: #ffffff;

  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding-left: 2.27vw;
  padding-right: 2.27vw;
  padding-bottom: 1.27vw;
}

.photocart {
  padding-top: 1.47vw;
}

.ForTextCart {
  display: flex;
  /* justify-content: space-between; */
  margin-left: 3.3vw;

  width: 100%;
}

.TextCartHeading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 590;
  font-size: 1.31vw;
  line-height: 24px;
  color: #171212;
  /* margin-right: 2.64vw; */
  /* display: flex;
    justify-content: space-between; */
}

.TextCardHeadingAndPrice {
  display: flex;
  justify-content: space-between;
  margin-right: 2vw;
}

.ForTextCartMain {
  display: flex;
  justify-content: space-between;
  margin-left: 3vw;
  flex-direction: column;
  min-width: 45vw;
}

.ForPriceCart {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.32vw;
  line-height: 150%;
  color: #44576a;
  /* margin-right: 11.76vw; */
  display: flex;
  justify-content: space-between;
}

.ForQuantityAndAdd {
  display: flex;
  flex-direction: row;
  margin-top: 1.25vw;
  align-items: center;
}

.quantityCart {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.02vw;
  line-height: 150%;
  color: #44576a;
}

.ViewDetails {
  cursor: pointer;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.02vw;
  line-height: 150%;
  text-decoration-line: underline;
  color: #44576a;
  margin-left: 1.54vw;
}

.buttonCartAddMinus {
  /* width: 6.69vw;
    height: 2.27vw; */
  /* margin-left: 2.13vw; */
  display: flex;
  margin-right: 18.6vw;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.leftbuttonCart {
  background: var(--globalColorHeader);
  border: 1px solid var(--globalColorButton);
  border-radius: 12px 0px 0px 12px;
}

.leftButtonIconCart {
  text-align: center;
  padding-left: 0.95vw;
  /* padding-top: 0.5vw; */
  padding-right: 0.58vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.02vw;
  line-height: 150%;

  color: #ffffff;
}

.centertextnumberButtonCart {
  padding-left: 1.02vw;
  /* padding-top: 0.5vw; */
  padding-right: 1.02vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.02vw;
  line-height: 150%;
  text-align: center;
  /* identical to box height, or 21px */
  /* 
text-align: center;

color:var(--globalColorButton); */
}

.rightButtonCart {
  background: var(--globalColorHeader);
  border: 1px solid var(--globalColorButton);
  border-radius: 0px 12px 12px 0px;
  align-items: center;
}

.RightButtonIconCart {
  padding-left: 0.58vw;
  padding-right: 0.88vw;
  /* padding-top: 0.5vw; */
  align-items: center;
}

.ForTestTextCart {
  margin-top: 0.66vw;
  width: 48.89vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.02vw;
  line-height: 150%;
  color: #44576a;
}

.DeleteCartAndIcon {
  margin-top: 1.1vw;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: min-content;
}

.DeleteCartText {
  font-family: "Montserrat";
  margin-left: 1.1vw;
  font-style: normal;
  font-weight: 400;
  font-size: 1.02vw;
  line-height: 150%;
  /* identical to box height, or 21px */

  color: #44576a;
}

.BottomLineCart {
  margin-top: 1.9vw;
  display: flex;
  justify-content: center;
}

.BottomLineCartDesktop {
  margin-top: 2.13vw;
  display: flex;
  justify-content: center;
}

.LineCartItem {
  width: 82.79vw;
}

.TotalAmountCart {
  margin-top: 1.1vw;
  display: flex;
  justify-content: flex-end;
  margin-right: 9.76vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.47vw;
  line-height: 150%;
  color: #44576a;
}

.InclusiveGstCart {
  margin-top: 0.29vw;
  display: flex;
  justify-content: flex-start;

  /* margin-right: 14.9vw; */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 0.73vw;
  line-height: 150%;
  /* identical to box height, or 15px */
  text-align: center;
  color: #44576a;
}

.checkoutButtonCart {
  display: flex;
  justify-content: center;
  margin-top: 10.44vw;
  margin-bottom: 1.98vw;
}

.ForcheckoutButtonCart {
  /* width: 38.75vw; */
  /* height: 4.85vw; */
  display: flex;
  justify-content: center;
  background: #ffffff;
  border: 1px solid var(--globalColorButton);
  border-radius: 12px;
  box-sizing: border-box;
}

.ForcheckoutButtonCartText {
  padding-top: 1.7vw;
  padding-left: 12.72vw;
  padding-right: 12.72vw;
  padding-bottom: 1.47vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.32vw;
  line-height: 150%;
  /* identical to box height, or 27px */

  text-align: center;

  /* pp1 */

  color: #44576a;
}

.MobileCartFirst {
  margin-left: 5.38vw;
  margin-top: 3.81em;
}

.subTotalMobile {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  /* identical to box height, or 30px */

  color: #44576a;
}

.Relationmodalageinput {
  width: 100%;
  height: 3vw;
  outline: none !important;
  margin-right: 2vw;
  padding: 1em;
  background: #ffffff;
  border: 1px solid #b5b5b5;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}

.Relationmodalselect {
  width: 100%;
  height: 3vw;
  outline: none !important;
  margin-right: 2vw;
  /* margin-left: 7.3vw; */
  background: #ffffff;
  border: 1px solid #b5b5b5;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}

.RealtionshipModalSubmit {
  display: flex;
  justify-content: center;
}

.RealtionshipModalSubmitBtn {
  padding-top: 1vw;
  padding-bottom: 1vw;
  padding-left: 5vw;
  border-radius: 12px;
  padding-right: 5vw;
  color: white;
  background: var(--globalColorHeader);
  margin-top: 3vw;
}

.RealtionshipModalSubmitBtnMbl {
  padding-top: 2vw;
  padding-bottom: 2vw;
  padding-left: 5vw;
  border-radius: 12px;
  padding-right: 5vw;
  color: white;
  background: var(--globalColorHeader);
  margin-top: 8vw;
}

.AddRealtionModalConatiner {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.RealtionshipmodalDetails {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 47vw;
  padding: 1.5em 3.5em;
  background: #ffffff;
  border-radius: 16px;
  z-index: 8888;
}

.RealtionshipMainHeadingText {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  padding-bottom: 0.5vw;
  /* or 24px */

  color: #44576a;
}

.RealtionshipMainValidation {
  font-size: 1em;
  color: rgb(163, 45, 24);
  margin-top: 0.2vw;
  /* margin-left: 7.3vw; */
}

.InclusiveMobile {
  margin-top: 0.25em;
  display: flex;
  justify-content: flex-start;
  margin-left: 5.89vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 150%;
  /* identical to box height, or 15px */

  text-align: center;

  color: #44576a;
}

.checkoutButtonMobileCart {
  margin-top: 1.31em;
  width: 88.97vw;
  height: 2.62em;
  background: #ffffff;
  border: 1.8px solid var(--globalColorButton);
  background: var(--globalColorHeader);
  border-radius: 6px;
  box-sizing: border-box;
}

.checkoutButtonMobileText {
  padding-top: 0.1em;
  display: flex;
  justify-content: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  /* Gray/00 */

  color: #ffffff;
}

.MobileCartSecond {
  margin-top: 1em;
}

.MobileCartSecondDiv {
  margin-top: 0.93em;
  margin-left: 1.31em;
}

.MobileCartText {
  display: flex;
  justify-content: flex-start;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  /* identical to box height, or 30px */

  color: var(--globalColorHeader);
}

.MobileCartLine {
  border: 1px solid #dfe2e4;
  width: 67.17vw;
  margin-left: 3vw;
}

.allItemsSecondDiv {
  margin-top: 1.37em;
  display: flex;
}

.PhotoIconMobileCart {
  background: #ffffff;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PhotoIconDesktopCart {
  background: #ffffff;
  border-radius: 16px;
}

.ImageTextPhotoIconMobileCart {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 150%;
  color: #44576a;
  color: #44576a;
  padding-top: 1.1em;
}

.doctorCartDesktopImage {
  padding-left: 3.67vw;
  padding-right: 3.97vw;
  padding-bottom: 1.69vw;
}

.ImageTextPhotoIconDesktonCart {
  padding-top: 1.32vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.47vw;
  line-height: 150%;
  text-align: center;
  color: #44576a;
}

.doctorCartImage {
  height: 60px;
  width: 60px;
}

.AllTextMobileCart {
  margin-left: 3.58vw;
  width: 60%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  padding-left: 0.8em;
}

.MobileBasicCartText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 150%;
  color: #44576a;
  padding-top: 1em;
}

.quantityMobileText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.7em;
  line-height: 150%;
  /* identical to box height, or 18px */

  color: #44576a;
}

.deleteIconAndTextMobile {
  display: flex;
  align-items: center;
  height: 1.2em;
  /* margin-top: 0.5em; */
}

.TextIconMobile {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.7em;
  line-height: 150%;
  color: #44576a;
  margin-left: 0.5em;
  margin-top: auto;
  margin-bottom: auto;
}

.deleteiconMobile {
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
}

.PriceMobileCart {
  margin-top: 1.125em;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.92vw;
  line-height: 150%;
  /* identical to box height, or 21px */

  color: #44576a;
}

.AddMemberMobileCart {
  background: #ffffff;
  border: 1px solid var(--globalColorButton);
  border-radius: 12px;
  /* margin-left: 2.6em; */
  margin-right: 2.6em;

  margin-top: 1.18em;
  margin-bottom: 2.4375em;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 150%;
  color: var(--globalColorButton);
  padding-left: 35vw;
  padding-top: 0.6vh;
  padding-bottom: 0.6vh;
  display: flex;
  justify-content: center;
  width: 90%;
}

.AddMemberMobileCartText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 150%;
  color: var(--globalColorButton);
  padding: 0.6em 0.6em;
  display: flex;
  justify-content: center;
  gap: 5%;
}

.downArrow {
  width: 0.9em;
  height: 0.9em;
  margin-top: auto;
  margin-bottom: auto;
}

.BottomContentDesktop {
  margin-top: 2.35vw;
  margin-left: 3.26vw;
  margin-right: 6.02vw;
  margin-bottom: 2.5vw;
}

.BottomContentPriceMain {
  display: flex;
  justify-content: space-between;
}

.BottomContentDiscountMain {
  margin-top: 0.95vw;
  display: flex;
  justify-content: space-between;
}

.AmountContentPrice {
  margin-top: 0.95vw;
  display: flex;
  justify-content: space-between;
}

.BottomContentPrice {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.32vw;
  line-height: 150%;
  /* identical to box height, or 27px */

  color: #44576a;
}

.AmountContentPriceRate {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.32vw;
  line-height: 150%;
  /* identical to box height, or 27px */

  color: #44576a;
}

.AmountContentPriceRateFinal {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.32vw;
  line-height: 150%;
  /* identical to box height, or 27px */

  color: #44576a;
}

.BottomContentPriceRate {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.32vw;
  line-height: 150%;
  /* identical to box height, or 27px */

  text-align: right;

  color: #44576a;
}

.removeMobileCart {
  margin-top: 1.1vw;
  display: flex;
  align-items: center;
}

.removetextcart {
  cursor: pointer;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.02vw;
  line-height: 150%;
  /* identical to box height, or 21px */
  margin-left: 1.54vw;
  color: #e7073c;
}

.FirstLineCart {
  display: flex;
}

.CartMainSecondContentCouponMobile {
  margin-right: 1.31em;
  background: rgba(245, 245, 245, 0.5);
  border-radius: 6px;
  padding-bottom: 1.3em;
  margin-bottom: 2em;
}

.CouponTextMobile {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  /* identical to box height, or 27px */

  color: #000000;
  padding-top: 1.1em;
}

.CodeAlertMobile {
  height: 4em;
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-left: 1.31em;
  margin-right: 1.6em;
}

.FinalCouponCart {
  display: flex;
  justify-content: space-between;
  margin-left: 1vw;
  margin-right: 8vw;
  margin-top: 6vh;
}

.FinalCouponCartText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  /* identical to box height, or 27px */

  color: #000000;
}

.FinalCouponCartAmount {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  /* identical to box height, or 27px */

  color: #000000;
}

.CodeAlertMobileView {
  display: flex;
  align-items: flex-end;
  position: relative;
  margin-left: 1vw;
  /* height: 4em; */
  margin-right: 1.25em;
  margin-top: 1.31em;
  margin-bottom: 1.31em;
}

.CouponCodeCartMobile {
  width: 90%;
  height: 80%;
  background: #ffffff;
  border: 0.5px solid #999999;
  border-radius: 12px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  color: #86939f;
  padding-left: 0.6em;
  padding-right: 1em;
}

.CouponApplyButtonMobile {
  height: 80%;
  background: #e68d36;
  border-radius: 12px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  color: #ffffff;
  position: absolute;
  right: 0;
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.CartCardContentMobile {
  display: flex;
  margin-top: 1em;
  margin-left: 1.31em;
  margin-right: 1.6em;
  background: #ffffff;
  border: 1px solid #44576a;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

  margin-bottom: 1.56em;
}

.CardContentSecondMobile {
  width: 70%;
}

.UseCouponCartMobile {
  width: 30%;
  background: #44576a;
  border: 1px solid #44576a;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 8px 8px 0px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CartCardContentHeadingMobile {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 150%;
  padding-left: 0.6em;
  color: #44576a;
  padding-top: 0.75em;
  padding-bottom: 0.3em;
}

.cartCartdContentSubHeadingMobile {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.6em;
  line-height: 150%;
  color: #2d354e;
  padding-left: 1em;
  padding-right: 0.3em;
  padding-bottom: 1.75em;
}

.Appliedcoupon {
  display: flex;
  background: #d5d9e7;
  border-radius: 12px;
  margin-left: 1.3em;
  margin-right: 1.3em;
  margin-top: 1.31em;
}

.AppliedcouponMobile {
  display: flex;
  background: #eff4f8;
  border-radius: 12px;
  /* margin-left: .1em; */
  margin-right: 2em;
  margin-top: 1.31em;
  margin-bottom: 1.31em;
  padding-left: 1em;
}

.AppliedcouponLeft {
  width: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AppliedcouponLeft p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 0.6em;
  line-height: 150%;
  color: #44576a;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  padding-left: 0.7em;
}

.AppliedcouponRight {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CouponsArea {
  max-height: 40vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.CouponsAreaMobile {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.CouponsAreaMobile:hover {
  overflow-y: scroll;
  margin-right: -1.27vw;
}

.CouponsArea:hover {
  overflow-y: scroll;
}

.CouponApplyButtonGreen {
  background-color: green;
  padding-left: 1.98vw;
  padding-right: 1.98vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.17vw;
  line-height: 150%;
  color: #ffffff;
  border-radius: 12px;
  position: absolute;
  right: 0;
  padding-top: 0.68em;
  padding-bottom: 0.48em;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.CardContentSecond {
  width: 70%;
}

.priceAndAll {
  margin-right: 2.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.priceAndAllRows {
  display: flex;
  margin-top: 1.1em;
}

.priceAndAllRows2 {
  display: flex;
}

.priceAndAllRowsLeft {
  width: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 150%;
  color: #000000;
}

.priceAndAllRowsRight {
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 150%;
  color: #000000;
}

.cartMobileLine {
  margin-top: 2vh;
  margin-left: 2vw;
  margin-right: 2vw;
}

.priceAndAllRowsRightDiscount {
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 150%;
  color: #319b4b;
}

.gstText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 0.6em;
  line-height: 150%;
  color: #000000;
}

.couponareamobile {
  height: 42vh;
  overflow: scroll;
  margin-top: 1.1em;
}

.cartItemList {
  overflow-y: auto;
}

.CartListItemsNew {
  display: flex;
  margin-top: 2em;
  margin-left: 1em;
  margin-bottom: 3em;
  padding-left: 1.05vw;
  justify-content: space-between;
}

.CartListItemsNewLeft {
  display: flex;
  width: 58%;
}

.CartListItemsNewRight {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-right: 3vw;
  margin-right: 1.54vw;
}

.CartListItemsNewRightp1 {
  color: #44576a;
  /* width: 22%; */
  display: flex;
  justify-content: flex-end;
  font-size: 1vw;
}

.removeIconAndtext {
  display: flex;
}

.CartListItemsNewRightp101 {
  color: #44576a;
  /* width: 22%; */
  display: flex;
  justify-content: flex-end;
  font-size: 1vw;
  margin-top: 0.3vh;
}

.CartListItemsNewRightp2 {
  width: 33%;
}

.CartListItemsNewRightp2-1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  color: #44576a;
}

.CartListItemsNewRightp2-2 {
  width: 33%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  text-decoration-line: underline;
  color: #44576a;
  cursor: pointer;
}

.CartListItemsNewRightp2-3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  text-decoration-line: underline;
  color: #e7073c;
  cursor: pointer;
}

.CartListItemsNewRightp3 {
  width: 33%;
}

.CartListItemsNewLeftP1 {
  background: #ffffff;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding-left: 1.8vw;
  padding-right: 1.8vw;
  padding-top: 1.47vh;
  padding-bottom: 1.47vh;
}

.CartListItemsNewLeftP1 img {
  height: 50px;
  width: 50px;
}

.finalPaymentPlanNewDescriptionFixedText4Mobile {
  font-family: "Montserrat";
  font-style: normal;
  margin-right: 15vw;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #319b4b;
}


.finalPaymentPlanNewAppliedCouponMobile :nth-child(1) {
  padding-left: 1em;
  margin-top: auto;
  margin-bottom: auto;
}

.finalPaymentPlanNewAppliedCouponMobile :nth-child(2) {
  margin-left: 1em;
  margin-top: auto;
  margin-bottom: auto;
}

.finalPaymentPlanNewAppliedCouponMobile :nth-child(3) {
  position: absolute;
  right: 5%;
}

.finalPaymentPlanNewAppliedCouponMobile {
  background: #d5d9e7;
  border: 1px solid #213473;
  border-radius: 5px;
  padding: 0.3em;
  display: flex;
  align-items: center;
  text-align: center;
  width: 58%;
  position: relative;
}

.CartListItemsNewLeftP2 {
  width: -webkit-fill-available;
  margin-left: 2vw;
  font-weight: 400;
  font-size: 16px;
  font-family: "Montserrat";
  font-style: normal;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.CartListItemsNewLeftP2Bottom {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  position: relative;
}

.productQty {
  display: flex;
}

.productQtyMobile {
  font-style: normal;
  font-weight: 400;
  font-size: 0.7em;
  line-height: 150%;
  color: #44576a;
}

.productQtyMobile select {
  padding-left: 0.3em;
  margin-left: 0.5em;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

.productQtyMobile select:focus {
  border: none;
  outline: none;
}

.productQty select {
  padding-left: 0.3em;
  margin-left: 0.5em;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

.productQty select:focus {
  border: none;
  outline: none;
}

.StrikeThroughText {
  text-decoration-line: line-through;
  color: #fd5e5a;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.3vw;
  margin-left: 1.1em;
  margin-right: 1em;
}

.StrikeThroughTexter {
  text-decoration-line: line-through;
  color: #fd5e5a;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.3vw;

  margin-right: 0.9em;
}

.discountedpriceText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.3vw;
  color: #171212;
}

.CartMainSecondContentHeaderNew {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 700;
  font-size: var(--globalFontSize22);
  color: #000000;
  padding-left: 1.17vw;
  padding-top: 2vw;
  padding-bottom: 1vw;
}

.finalPaymentPlanNewDescriptionLineSeperator2 {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 10px;
  margin-right: 20px;
}

.lineseparatorcart {
  margin-right: 2em;
}

.finalPaymentPlanNewDescriptionCartPage {
  display: flex;
  margin-top: 1em;
  margin-left: 0.91vw;
  margin-right: 1.91vw;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.3em;
}

.finalPaymentPlanNewDescriptionCartPageMobile {
  display: flex;
  margin-left: 1vw;
  margin-right: 1.91vw;
  align-items: flex-start;
  justify-content: space-between;
}

.CartCardContentCoupon {
  background: #ffffff;
  border: 1px dashed #8b94b2;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  /* margin-right: 1.77vw; */
  margin-bottom: 1.5em;
  width: 23vw;
}

.CartCardContentCouponMobile {
  background: #ffffff;
  border: 1px dashed #8b94b2;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  /* margin-right: 1.77vw; */
  margin-bottom: 0.5em;
}

.CartCardContentChild1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 40px;
  color: #000000;
  padding-left: 1.1em;
  padding-top: 1em;
}

.CartCardContentChild2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 20px;
  color: var(--globalColorHeader);
  padding-left: 1.5em;
}

.CartCardContentChild3 {
  display: flex;

  padding-top: 1em;
  margin-left: 1.1em;
  padding-bottom: 2em;
}

.CopyCode {
  width: 40%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.CopyCode p {
  margin-top: auto;
  margin-bottom: auto;
}

.ApplyCode {
  margin-right: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: #001246;
}

.ApplyCode p {
  margin-top: auto;
  margin-bottom: auto;
}

.ApplyCodeMobile {
  width: 27%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.ApplyCodeMobile p {
  margin-top: auto;
  margin-bottom: auto;
}

.OrderSummaryCart {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #e68d36;
  margin-top: 2em;
}

@media screen and (max-width: 600px) {
  .RealtionshipmodalDetailsMobile {
    position: absolute;
    top: 36%;
    left: 51%;
    transform: translate(-50%, -50%);
    width: 89vw;
    padding: 1.5em 1.5em;
    background: #ffffff;
    border-radius: 16px;
  }

  .SelectDetailModal {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1rem;
    font-weight: 400;
    font-size: 16px;
  }

 
.Relationmodalageinput{
  width: 100%;
}
  .CouponAvalableMobile {
    color: red;
    display: flex;
    justify-content: center;
  }
}

.errorMessageCart {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  color: red;
}

.appliedPara {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 0.7em;
  line-height: 20px;
  color: #319b4b;
}

.applyPara {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 0.7em;
  line-height: 20px;
  color: #1d2334;
}

.errorMessageCartMobile {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  color: red;
}
@media screen and (max-width: 1300px) {
  .Cartmain {
    height: auto;
  }
  .RealtionshipmodalDetails {
    width: 80vw;
  }
  .CartMainSecondContent {
    min-width: 100%;
    max-width: 100%;
    display: flex;
    margin-top: 1em;
    height: fit-content;
  }
  .CouponsArea {
    overflow-y: auto;
    overflow: scroll;
    height: 30vh;
  }
  .CartCardContentCoupon {
    width: 95%;
  }

  .CouponApplyButton {
    width: 20%;
  }
  .finalPaymentPlanNewDescriptionCartPage {
    margin-top: 2em;
  }
  .buttonOfSecondCart {
    width: 95%;
  }
}
@media screen and (max-width: 800px) {
  .secondCartText {
    font-size: var(--globalFontSize16);
  }
  .PriceCartText {
    font-size: var(--globalFontSize16);
  }
  .PriceCartText2{
    font-size: var(--globalFontSize16);

  }
  .CartMainSecondContentHeaderNew {
    font-size: var(--globalFontSize18);
  }
  .CartMainSecondContent {
    padding: 1em;
  }
  .RealtionshipmodalDetails {
    width: 90vw;
  }
  .Relationmodalageinput {
    padding: 1em;
  }

  .Relationmodalselect {
    width: 100%;
    color: black;
  }
  .RealtionshipModalSubmitBtn {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .Relationmodalselect {
    height: 5.5vh;
  }
  .CouponApplyButton {
    width: 30%;
}
.CartMainSecondContentHeaderNew {
  font-size: var(--globalFontSize14);
}
}
@media screen and (max-width:400px) {
  .secondCartText {
    font-size: var(--globalFontSize12);
  }
  .PriceCartText {
    font-size: var(--globalFontSize12);
  }
  .PriceCartText2{
    font-size: var(--globalFontSize12);

  }
  .secondCartTextgst{
    font-size: var(--globalFontSize12);

  }
}
@media screen and (max-width:350px) {
  .secondCartText {
    font-size: var(--globalFontSize10);
  }
  .PriceCartText {
    font-size: var(--globalFontSize10);
  }
  .PriceCartText2{
    font-size: var(--globalFontSize10);

  }
  .secondCartTextgst{
    font-size: var(--globalFontSize10);

  }
  .secondCartTextsmallProcessingCharge{
    font-size: var(--globalFontSize10);

  }
  .CouponCodeCart{
    font-size: .6em;

  }
  .CouponApplyButton {
    width: 20%;
    font-size: .6em;

}
}
@media screen and (max-width:300px) {
  .secondCartText {
    font-size: var(--globalFontSize8);
  }
  .PriceCartText {
    font-size: var(--globalFontSize8);
  }
  .PriceCartText2{
    font-size: var(--globalFontSize8);

  }
  .secondCartTextgst{
    font-size: var(--globalFontSize8);

  }
  .secondCartTextsmallProcessingCharge{
    font-size: var(--globalFontSize8);

  }
}