* {
  margin: 0;
  padding: 0;
}
.mainLocked {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3em;
}
.breadcrumbsLocked {
  width: 80%;
  height: 5%;
  margin-top: 2%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 14px;

  color: #44576a;
}
.lockedTitle {
  width: 80%;
  height: 10%;
  margin-top: 1.5em;
  margin-bottom: 2.75em;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.3em;
  line-height: 150%;
  color: var(--globalColorHeader);
}
.lockedCard {
  width: 80%;
  height: 90%;
  display: flex;
  position: relative;
}
.getStarted {
  height: 10vh;
  margin-top: 3%;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 5em;
}
.lockedCardRoomParagraph {
  width: 80%;
  margin-top: 5em;
}
.lockedCardRoomParagraph p {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 1.3em;
  text-align: center;
  line-height: 150%;
  color: var(--globalColorHeader);
  padding: 1em 2em 1em 2em;
}
.Hexagon {
  height: 50%;
  position: absolute;
  bottom: 0.15rem;
  left: 0.15rem;
  border-bottom-left-radius: 16px;
}
.getStarted button {
  width: 70%;
  height: 70%;
  position: absolute;
  bottom: 0;
  background: var(--globalColorButton);
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 150%;
  border: hidden;
  text-align: center;
  color: #ffffff;
}
.getStarted img {
  width: 8%;
  height: 45%;
  position: absolute;
  top: 0;
  z-index: 1;
}
.getStartedLogin {
  height: 6vh;
  margin-top: 3%;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10em;
  z-index: 1;
  outline: none !important;
  border: none;
}
.getStartedLoginStart {
  background: var(--globalColorHeader);
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  border: hidden;
  text-align: center;
  color: #ffffff;
  padding: 0.7vw 2vw 0.7vw 2vw;
  outline: none !important;
  border: none;
}

.getStartedLoginResume {
  margin-top: 2vw;
  background: #00a300;
  border-radius: 6px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  border: hidden;
  text-align: center;
  color: #ffffff;
  padding: 0.7vw 2vw 0.7vw 2vw;
}
.lockedCardRoom {
  max-width: 40vw;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 32px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 0;
}
.lockedCardImage {
  width: 99%;
  height: 100%;
  background-image: url("../../../assets/hra/hraStart.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  right: 0;
  z-index: -1;
}

@media screen and (max-width: 980px) {
  .lockedCard {
    width: 95%;
  }
  .Hexagon{
    display: none;
  }
  .lockedCardRoom {
    width: 100%;
    box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 32px;
  }
  .lockedCardImage {
    background-size: 0% 0%;
  }
  .lockedCardRoom {
    max-width: 100%;
  }
  .getStartedLogin {
    width: 60%;
  }
  .getStartedLoginStart {
   
    width: 50%;
    padding-top: 1em;
padding-bottom: 1em;
  }
  .getStartedLoginResume {
    width: 50%;
padding-top: 1em;
padding-bottom: 1em;
  }
  .lockedTitle {
    width: 95%;
    height: 10%;
    margin-top: 1.5em;
    margin-bottom: 2.75em;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 1.3em;
    line-height: 150%;
    color: var(--globalColorHeader);
  }
}
@media screen and (max-width: 500px) {
  .getStartedLoginResume {
    width: 70%;
padding-top: 1em;
padding-bottom: 1em;
  }
  .getStartedLoginStart {
   
    width: 70%;
    padding-top: 1em;
padding-bottom: 1em;
  }
}
