.PopularPlansMain {
  display: flex;
  justify-content: center;
  width: 90%;
  margin: auto;
  position: relative;
}

.PopularPlansMainIndividual {
  flex: 1;
  border-radius: 20px;
  padding: 2em;
  background-color: #eef5ff;
  transition: transform 0.3s ease, box-shadow 0.3s ease,
    background-color 0.3s ease;
  margin: 0.2em;
  box-shadow: 0px 2px 6px 0px #0000000a;
  display: flex;
  flex-direction: column;
}

.PopularPlansMainIndividual:hover {
  background-color: #fff;
  transform: scale(1.1);
  box-shadow: 0px 24px 83px 0px #0000001a;
}

.PopularPlansMainIndividualTop {
  margin-top: 1em;
}

.PopularPlansMainIndividualHeading {
  font-family: var(--globalFont);
  font-size: var(--globalFontSize28);
  font-weight: 700;
  line-height: 50.47px;
  letter-spacing: -2.07px;
  text-transform: capitalize;
}

.PopularPlansMainIndividualSubHeading {
  font-family: var(--globalFont);
  font-size: var(--globalFontSize16);
  font-weight: 400;
  line-height: 30.67px;
  margin-top: 1em;
}

.PopularPlansMainPrice{
  display: flex;
  gap: 1em;
  align-items: baseline;
}

.PopularPlansMainFinalPrice{
  margin-top: 1em;
  font-family: var(--globalFont);
  font-size: var(--globalFontSize32);
  font-weight: 400;
  line-height: 41.37px;
  letter-spacing: -1.31px;
  color: #1c71e1;
}

.PopularPlansMainIndividualPrice {
  margin-top: 1em;
  font-family: var(--globalFont);
  font-size: var(--globalFontSize28);
  font-weight: 400;
  line-height: 41.37px;
  letter-spacing: -1.31px;
  text-decoration: line-through;
}

.PopularPlansMainIndividualBuyButton {
  margin-top: 1em;
  font-family: var(--globalFont);
  font-size: var(--globalFontSize16);
  font-weight: 400;
  line-height: 26.76px;
  letter-spacing: 0.08px;
  background: #1c71e1;
  color: #eff0ff;
  padding: 1em 2.9em;
  border-radius: 12px;
  cursor: pointer;
  border: none;
}

.PopularPlansMainLower {
  margin-top: 2.5em;
  width: 100%;
}

.PopularPlansMainLowerHeading {
  font-family: var(--globalFont);
  font-size: var(--globalFontSize14);
  font-weight: 500;
  line-height: 26.76px;
  letter-spacing: 0.08px;
}

.PopularPlansMainLowerRows {
  display: flex;
  align-items: center;
  margin-top: 0.5em;
}

.PopularPlansMainLowerRowsCol1 {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PopularPlansMainLowerRowsCol2 {
  width: 60%;
  font-family: var(--globalFont);
  font-size: var(--globalFontSize14);
  font-weight: 500;
  line-height: 26.76px;
  letter-spacing: 0.08px;
}

.PopularPlansMainLowerRowsCol3 {
  width: 30%;
  font-family: var(--globalFont);
  font-size: var(--globalFontSize10);
  font-weight: 500;
  line-height: 26.76px;
  letter-spacing: 0.08px;
  text-align: right;
  color: #00a611;
}

@media screen and (max-width: 1220px) {
  .PopularPlansMain {
    flex-direction: column;
  }
  .PopularPlansMainIndividual {
    margin: 1em 0;
    transform: none;
    box-shadow: 0px 2px 6px 0px #0000000a;
  }
  .PopularPlansMainIndividual:hover {
    transform: none;
    box-shadow: 0px 2px 6px 0px #0000000a;
  }
}

@media screen and (max-width: 350px) {
  .PopularPlansMainIndividualHeading {
    line-height: normal;
  }
}