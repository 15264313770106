.TalkToDoctorModalRelationsBox {
  width: 44vw;
  background-color: #ffffff;
  margin-top: 5rem;
  border-radius: 16px;
}
.TalkToDoctorModalBoxOuter {
  background-color: rgb(255, 255, 255);
  margin-top: 6.875rem;
  margin-bottom: 5.875rem;
}

.TalkToDoctorBtnSaveRealtions {
  display: flex;
  margin-bottom: 4vw;
  justify-content: space-around;
}
.TalktodocmodalHeading {
  position: relative;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5vw;
  line-height: 150%;
  text-transform: capitalize;
  color: #000000;
  margin-left: 2vw;
  text-align: center;
  margin-right: 2vw;
}
.TalktodocmodalHeadingConatiner {
  position: relative;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  text-transform: capitalize;
  color: var(--globalColorHeader);
  margin-left: 2vw;
  text-align: center;
  margin-right: 2vw;
}
.TTDButtonSaveRealtions {
  background: var(--globalColorHeader);
  border-radius: 6px;
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.7em;
  line-height: 150%;
  border: hidden;
  color: #ffffff;
  outline: none !important;
  border: none;
  width: 40%;
  padding-top: 1em;
  padding-bottom: 1em;
}
@media screen and (max-width:900px) {
  .TalkToDoctorModalRelationsBox{
    width: 60vw;
  }
}
@media screen and (max-width: 600px) {
  .TalkToDoctorModalRelationsBox {
    width: 91vw;
    margin-top: 8rem;
  }
  .TalktodocmodalHeading {
    font-size: var(--globalFontSizeMobile18);
  }

 
}
