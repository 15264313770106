.navbarMobile {
  display: none;
}


.navbarWeb{
  display: block;
}

@media screen and (max-width: 1050px) {
  .navbarMobile {
    display: block;
  }

  .navbarWeb{
    display: none;
  }
}


.css-yg4x35-MuiAvatar-root{
  margin-top: auto !important;
  margin-bottom: auto !important;
}