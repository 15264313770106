.myprescriptionmaindiv {
  margin-top: 3.23vh;
  margin-left: 2em;
  margin-right: 2em;
}

.myprescriptiontext {
  margin-top: 1.31vh;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1.5em;
  line-height: 150%;
  color: var(--globalColorHeader);
}

.myprescriptionalltexts {
  background: #ffffff;
  margin-top: 0.44vh;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  /* border: 1px solid #1D2334; */
  border-radius: 16px;
  padding-bottom: 3vw;
  margin-right: 2vw;
}

.myprescriptionalltextbtn {
  /* margin-top:2.94vw; */
  padding-top: 2.94vh;
  margin-left: 1.98vh;
  margin-right: 1.98vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.myprescriptionalltexts .myprescriptionalltextbtnViewAll {
  margin-left: 1.8vw;
  padding: 0.5vw 1vw 0.5vw 1vw;
  background-color: var(--globalColorHeader);
  border-radius: 12px;
  font-size: var(--globalFontSize14);
  color: #ffffff;
}

.myprescriptionalltextget {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  margin-left: 1.8vw;
  line-height: 28px;
  color: #44576a;
}

.myprescriptionalltextgetbtn {
  background: var(--globalColorHeader);
  border-radius: 12px;
  margin-right: 3.67vw;
}

.templateNamesPres {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 1.75vh;
  margin-left: 2em;
  margin-right: 2em;
}

.buttonprescriptiontext {
  padding-left: 1.55vw;
  padding-top: 1.5vh;
  padding-right: 1.52vw;
  padding-bottom: 1.5vh;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #ffffff;
}

.prescriptionBoxTexts {
  margin-left: 1.51vw;
  background: linear-gradient(
    98.59deg,
    #f8f8f8 0%,
    rgba(248, 248, 248, 0) 100%
  );
  border: 1px solid #f7f7f7;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  margin-top: 2.75vh;
}

.prescriptionBoxTextsStarts {
  display: flex;
  margin-left: 1.25vh;
  margin-top: 1.11vh;
}

.presc2 {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.85em;
  line-height: 150%;
  text-align: center;
  color: #44576a;
}

.prescriptionFile {
  margin-top: 0.58vh;
}

.prescriptionpee {
  margin-right: 10vw;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #44576a;
}

.prescriptionPre {
  margin-left: 1.91vh;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: var(--globalFontSize16);
  line-height: 150%;
  color: #44576a;
}

.presText {
  margin-left: 2.17vw;
}

.presTexting {
  margin-left: 1.17vw;
}

.DownloadPresc {
  margin-top: 1.69vh;
  margin-left: 1.25vw;
  display: flex;
  justify-content: space-between;
  margin-right: 1.91vw;
  flex-direction: row-reverse;
}

.genDate {
  align-items: center;
  display: flex;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #a0a0a0;
}

.downloadIcon {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  margin-left: 2vw;
  cursor: pointer;
  color: #44576a;
}

.prescript2 {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1.47vw;
  line-height: 150%;
  color: #44576a;
}

.myPrescriptionDropdown {
  border-radius: 2vw;
  padding: 1vw 1.5vw 1vw 2.5vw;
  outline: none !important;
  cursor: pointer;
  font-size: 1em;
}

@media screen and (min-width: 1400px) {
  .downloadIcon {
    margin-left: 6vw;
  }
}

@media screen and (max-width: 1000px) {
  .templateNamesPres {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 680px) {
  .templateNamesPres {
    grid-template-columns: 1fr;
    margin-left: 1em;
  }
  .prescript2 {
    font-size: 0.7em;
  }
}
