.ContactUsMain {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contactText1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5vw;
  margin-left: 11vw;
  line-height: 150%;
  margin-top: 1vw;
  text-align: center;
  color: #7180ad;
}

.ContactUsInforCardImage img {
  width: 3vw;
  height: 3vw;
}

.ContactUsPart3InfoCardImage img {
  width: 8vw;
  height: 7vw;
}

.ContactUsPart1 {
  background-color: #ffffff;
  width: 90vw;
  display: flex;
  flex-direction: column;
}
.ContactUsPart2 {
  width: 90%;
  display: flex;
  flex-direction: column;
}
.ContactUsPart3 {
  margin-top: 3em;
  width: 90%;
  display: flex;
  flex-direction: column;
}

.ContactUsHeader {
  width: 100%;
  background-color: #ffffff;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 3vw;
  line-height: 150%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: var(--globalColorMainHeading);
  margin-top: 2em;
}

.ContactUsSubHeader {
  font-weight: 300;
  font-size: 1.75vw;
  line-height: 150%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  color: #44576a;
  margin-top: 1em;
}

.ContactUsSubHeader2 {
  font-weight: 300;
  font-size: 1.75vw;
  line-height: 150%;
  text-align: center;
  margin-top: 11px;
  color: #44576a;
}

.ContactUsInforCardsWrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 31px;
}

.ContactUsInforCard {
  width: 27vw;
  height: 264px;
  height: 18.5vw;
  background: #ffffff;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ContactUsInforCardInformation {
  margin-top: 1.6875em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 17vw;
  font-size: 1vw;
  color: #44576a;
}

.WriteToUsWrapper {
  width: 100%;
  height: 60%;

  display: flex;
}
.WriteToUsImageWrapper {
  width: 35vw;
  display: flex;
  justify-content: center;
  margin-top: 3.3vw;
}
.WriteToUsImageWrapper img {
  width: 32vw;
  height: 27vw;
}
.WriteToUsForm {
  width: 53vw;
}
.WriteToUsForm form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.WriteToUsFormRow {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.contactUsButton {
  height: 10%;
  display: flex;
  justify-content: flex-end;
  padding-right: 3%;
}

.WriteToUsFormInputFields1 {
  width: 24vw;
  border-radius: 6px;
  background: #f3f0f0;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.17);
  border-radius: 6px;
  padding: 1em;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.25vw;
  line-height: 150%;
  border: hidden;
  color: #44576a;
}

.WriteToUsFormInputMessage {
  width: 49.7vw;
  height: 10.8vw;
  background: #f3f0f0;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding-top: 1em;
  padding-left: 1em;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 150%;
  border: hidden;

  color: #8d919f;
}

.WriteToUsFormSubmitButton {
  width: 23.2vw;
  height: 3.2vw;
  background: var(--globalColorHeader);
  border-radius: 6px;
  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1vw;
  line-height: 150%;
  border: hidden;
  cursor: pointer;
  color: #ffffff;
  outline: none !important;
}
.contactusvalidation {
  color: rgb(163, 45, 24);
  font-size: 1vw;
  margin-left: 0.2rem;
  margin-top: 0.3rem;
}
.WriteToUsImage {
  width: 33rem;
  height: 23.1875rem;
}

.ContactUsPart3InfoCard {
  display: flex;
}

.ContactUsPart3InfoCardsWrapper {
  display: flex;
  justify-content: space-around;

  height: 24vw;
}
.ContactUsPart3InfoCard {
  width: 30vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.ContactUsPart3InfoCard3 {
  border-left: 2px solid #eeeeee;
}
.ContactUsPart3InfoCard2 {
  border-left: 2px solid #eeeeee;
}
.ContactUsHeader3 {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1.7vw;
  line-height: 150%;
  padding-left: 1em;
  color: var(--globalColorMainHeading);
}

@media screen and (max-width: 500px) {
  .ContactUsHeader {
    font-size: 5vw;
  }
  .ContactUsSubHeader {
    font-size: 3vw;
  }
  .ContactUsSubHeader2 {
    font-size: 3vw;
  }
  .ContactUsInforCardsWrapper {
    flex-direction: column;
    align-items: center;
    gap: 1em;
  }
  .ContactUsInforCard {
    align-items: center;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
    flex-direction: column;
    height: 16.5rem;
    justify-content: center;
    width: -webkit-fill-available;
  }
  .ContactUsInforCardImage img {
    height: auto;
    width: auto;
  }
  .ContactUsInforCardInformation {
    align-items: center;
    display: flex;
    font-size: 14px;
    justify-content: center;
    margin-top: 1.6875em;
    text-align: center;
    width: auto;
  }
  .WriteToUsWrapper {
    justify-content: center;
  }
  .WriteToUsImageWrapper {
    display: none;
  }
  .WriteToUsForm form {
    gap: 0.5em;
  }
  .WriteToUsForm {
    width: 100%;
  }
  .WriteToUsFormInputFields1 {
    width: 36vw;
    font-size: 2.5vw;
  }
  .WriteToUsFormInputMessage {
    width: 77vw;
    font-size: 2.5vw;
  }
  .contactUsButton {
    height: auto;
  }
  .WriteToUsFormSubmitButton {
    font-size: 2vw;
    height: auto;
    padding: 0.8em;
  }
  .ContactUsHeader3{
    font-size: 3vw;
  }
  .contactText1{
    font-size: 2vw;
  }
}
