.footer {
  display: flex;
  flex-direction: column;
  background-color: #1c71e1;
  margin-top: 2em;
}

.upperFooter {
  margin-left: 6vw;
  margin-right: 6vw;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-bottom: #f3f0f0;
  align-items: baseline;
}

.upperChild2Footer a {
  text-decoration: none;
}

.upperChild3Footer a {
  text-decoration: none;
}

.emailContact a {
  text-decoration: none;
}

.downFooter {
  width: 90%;
  height: 20%;
  align-items: center;
  border-top: 1px solid #8e8e8e;
  display: flex;
  gap: 1%;
  justify-content: center;
}

.downFooter .copyrightText {
  height: 100%;
  display: flex;
  padding-top: 1em;
  align-items: center;
  color: var(--globalColorFooterSubHeader);
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
}

.downFooter .copyrightSign {
  display: flex;
  align-items: center;
}

.upperChild1 {
  margin-top: 2.81em;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 3em;
}

.upperChild2Footer {
  margin-top: 3.9em;
  margin-bottom: 3vw;
  padding-left: 2em;
}

.upperChild3Footer {
  margin-top: 3.9em;
  margin-bottom: 3vw;
  padding-left: 1em;
}

.upperChild4 {
  margin-top: 3.9em;
  margin-bottom: 5vw;
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
}

.upperChild1 img {
  width: 0.83em;
  height: 0.66em;
}

.upperChild2Footer p {
  font-family: var(--globalFont);
  font-style: normal;
  margin-left: -0.1vw;
  font-weight: 700;
  font-size: var(--globalFontSize24);
  line-height: 1.5em;
  margin-bottom: 2.6vw;
  color: var(--globalColorFooterHeader);
}

.upperChild2Footer li {
  list-style: none;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: var(--globalFontSize16);
  color: var(--globalColorFooterSubHeader);
  padding-bottom: 2em;
}

.upperChild3Footer p {
  font-family: var(--globalFont);
  font-style: normal;
  margin-left: -0.1vw;
  font-weight: 700;
  font-size: var(--globalFontSize24);
  line-height: 1.5em;
  margin-bottom: 2.6vw;
  color: var(--globalColorFooterHeader);
}

.upperChild3Footer li {
  list-style: none;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: var(--globalFontSize16);
  color: var(--globalColorFooterSubHeader);
  padding-bottom: 2em;
}

.upperChild4 p {
  font-family: var(--globalFont);
  font-style: normal;
  margin-left: -0.1vw;
  font-weight: 700;
  font-size: var(--globalFontSize24);
  line-height: 1.5em;
  margin-bottom: 2vw;
  color: var(--globalColorFooterHeader);
}

.upperChild4HeadingWeb {
  color: var(--globalColorHeader);

  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 900;
  font-size: var(--globalFontSize24);
}

.upperChild4Bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

.upperChild4Description {
  width: 100%;
}

.upperChild4Description span {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: var(--globalFontSize16);
  line-height: 1vw;
  color: var(--globalColorFooterSubHeader);
}

.emailInput {
  margin-top: 0vw;
  height: 2.62em;
  min-width: 80%;
}

.footerValidation {
  color: red;
}

.emailInput input {
  height: 100%;
  border: none;
  min-width: 100%;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding-left: 1.3em;
  background-color: #1c71e1;
  border: 1px solid white;
  color: #fff;
}

.newsLetterInput::placeholder {
  color: #fff;
}

.signup {
  cursor: pointer;
  margin-top: 5em;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: var(--globalFontSize16);
  line-height: 1.5vw;
  height: 3.62vw;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 0.37vw;
  border: 1px solid var(--globalColorButton);
  border: none;
  color: black;
  outline: none !important;
}

.signup img {
  padding-right: 3%;
}

.addressFooterCompanyName {
  margin-top: 1em;
  font-size: var(--globalFontSize16);
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  text-align: left;
  color: var(--globalColorFooterSubHeader);
}

.addressFooter {
  margin-top: 2em;
  font-size: var(--globalFontSize16);
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  text-align: left;
  color: var(--globalColorFooterSubHeader);
}

.logoImage {
  text-align: left;
  margin-top: 0.5em;
  margin-bottom: 1em;
}

.logoImage img {
  width: 7.8em;
  height: 2.3em;
}

.emailContact {
  display: flex;
  text-align: center;
  width: 50%;
  color: #ffffff;
  gap: 4%;
  align-items: baseline;
  text-wrap: nowrap;
}

.phoneContact {
  display: flex;
  text-align: center;
  margin-top: 0.62em;
  width: 50%;
  color: #ffffff;
  gap: 2%;
  align-items: baseline;
  text-wrap: nowrap;
}

.emailContact img {
  margin-top: 0.3em;
  width: 1.3em;
  height: 1em;
}

.phoneContact img {
  margin-top: 0.3em;
  width: 1em;
  height: 1em;
}

.emailContact p {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: var(--globalFontSize16);
  line-height: 1.5em;
  width: 0.83em;
  height: 0.7em;
  color: var(--globalColorFooterSubHeader);
}

.phoneContact p {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: var(--globalFontSize16);
  line-height: 1.5em;
  color: var(--globalColorFooterSubHeader);
}

.socialmediaContact {
  display: flex;
  width: 7em;
  justify-content: space-between;
  gap: 4%;
  margin-bottom: 0.5vw;
}

.socialmediaContact img {
  width: 1.09em;
  height: 1.06em;
}

@media screen and (max-width: 879px) {
  .upperFooter {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .upperChild2Footer {
    padding-left: 0;
    margin-top: 1em;
    padding-bottom: 0em;
    margin-bottom: 0em;
  }

  .upperChild1 {
    margin-bottom: 1em;
  }

  .upperChild3Footer {
    padding-left: 0;
    margin-top: 1em;
    padding-bottom: 0em;
    margin-bottom: 0em;
  }

  .upperChild4 {
    padding-left: 0;
    margin-top: 1em;
    padding-bottom: 0em;
    margin-bottom: 0em;
  }

  .signup {
    height: 2em;
    margin-top: 0.85em;
  }
}

@media screen and (max-width: 480px) {
  .upperFooter {
    grid-template-columns: 1fr;
  }

  .upperChild2Footer {
    padding-left: 0;
    margin-top: 1em;
    padding-bottom: 0em;
    margin-bottom: 0em;
  }

  .upperChild1 {
    margin-bottom: 1em;
  }

  .upperChild3Footer {
    padding-left: 0;
    margin-top: 1em;
    padding-bottom: 0em;
    margin-bottom: 0em;
  }

  .upperChild4 {
    padding-left: 0;
    margin-top: 1em;
    padding-bottom: 0em;
    margin-bottom: 0em;
  }

  .signup {
    height: 2em;
    margin-top: 0.85em;
    margin-bottom: 0.85em;
  }
}