.hraInfoScreens {
}

.hraInfoScreensChild1 {
  margin-left: 5.68em;
  margin-right: 6.25em;
  margin-top: 2.7em;
}

.hraInfoScreensChild1 p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.3em;
  line-height: 150%;
  color: #44576a;
}

.hraInfoScreensChild2 {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-left: 5.68em;
  margin-right: 6.25em;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 32px 12px 12px 32px;
  margin-top: 2.7em;
  margin-bottom: 7em;
  display: flex;
}

.hraInfoScreensChild2a {
  display: flex;
  padding-top: 1.7em;
  padding-bottom: 2.7em;
}

.hraInfoScreensChild2a1 {
  width: 3.1em;
  background: var(--globalColorButton);
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  height: 100%;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.hraInfoScreensChild2a1 p {
  writing-mode: vertical-lr;
  transform: scale(-1);
  padding-left: 0.6em;
  padding-right: 0.6em;
  margin-top: 3em;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5vw;
  line-height: 150%;
}

.hraInfoScreensChild2a2 {
  width: 20em;
  height: 100%;
  background: #ffffff;
  box-shadow: 11px 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 0px 32px 32px 0px;
  display: flex;
  flex-direction: column;
}

.hraInfoBackArrow {
  width: 70%;
  display: flex;
  margin-top: 2.3em;
  gap: 10%;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.hraInfoBackArrow p {
  margin-top: auto;
  margin-bottom: auto;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.2em;
  line-height: 150%;
  color: #44576a;
}

.hraInfoBackArrow a {
  margin-top: auto;
  margin-bottom: auto;
}

.hraInfoBackArrow img {
  width: 1em;
  height: 1em;
}

.hraInfo {
  width: 70%;
  margin-top: 5em;
  text-align: center;
  color: #44576a;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.9em;
  margin-left: auto;
  margin-right: auto;
}

.hraInfoScreensChild2a2 button {
  margin-top: 2em;
  background: var(--globalColorHeader);
  border-radius: 12px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  border: hidden;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  margin-bottom: 5em;
  cursor: pointer;
  border: none;
  outline: none !important;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width:980px) {
  .hraInfoScreensChild2a {
   width: 100%;
  }
  .hraInfoScreensChild2a2 {
    width:100%;
    box-shadow: 11px 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 0px ;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  .hraInfoScreensChild2a1 {
    
    display: none;
  }
  .hraInfoScreensChild2{
    background-size: 0% 0%;
    box-shadow: 0px 0px 4px 4px #fff;

  }
  .hraInfoBackArrow {
    width: 95%;
    display: flex;
    margin-top: 2.3em;
    gap: 10%;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    position: relative;
  }
  .hraInfoBackArrow img {
    position: absolute;
    width: 1em;
    height: 1em;
    left:1%;
  }
  .hraInfoScreensChild2a2 button {
    width: 40%;
  }
  .hraInfoScreensChild2 {
    margin-left: 1em;
    margin-right: 1em;
  }
  .hraInfoScreensChild1 {
    margin-left: 1em;
    margin-right: 1em;
  }
}
@media screen and (max-width:450px) {
  .hraInfoScreensChild2a2 button {
    width: 60%;
  }
  .hraInfoBackArrow img {
   top: -5%;
  }
  .hraInfoBackArrow p {
    font-size: 1em;
    margin-top: 3em;
  }
}
