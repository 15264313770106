.FHCMain {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FHCSection2_Children:hover {
  background-color: #fff;
  transform: scale(1.01);
  box-shadow: 0px 24px 83px 0px #0000001a;
}

.FHCMainTest {
  display: flex;
  flex-direction: column;
}

.FHCSection1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.FHCSection1 :nth-child(1) {
  padding-top: 20px;
  color: var(--globalColorMainHeading);
  text-align: center;
  font-family: var(--globalFont);
  font-size: var(--globalFontSize60);
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-transform: capitalize;
}

.FHCSection1.health-checkup :nth-child(1) {
  padding-top: 70px;
  color: var(--globalColorMainHeading);
  font-family: var(--globalFont);
  font-size: var(--globalFontSize60);
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-transform: capitalize;
}

.FHCSection2 {
  width: 90%;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FHCSection2Test {
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2em;
  margin-top: 1em;
}

.FHCSection2_Children {
  display: flex;
  width: 100%;
  margin-top: 2em;
  margin-bottom: 0.8em;
  position: relative;
  background-color: #f6f6f6;
  border: 10px;
}

.FHCSectionContainerFirst {
  display: flex;
}

.discountPercentageContainer {
  font-size: var(--globalFontSize10);
  color: #fff;
  padding-left: 1vw;
  padding-top: 0.2vw;
}

.couponStickerListing {
  width: 3.7vw;
  height: 3vw;
  margin-left: 1vw;
  font-size: 0.8vw;
}

.FHCSection2_Children1 {
  width: 60%;
  padding-left: 1em;
  padding-right: 1em;
  border-right: 1px solid #bfbfbf;
  cursor: pointer;
}

.FHCSection2_ChildrenName {
  color: #1c71e1;
  font-family: var(--globalFont);
  font-size: var(--globalFontSize24);
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-transform: capitalize;
  margin-top: 1em;
}

.FHCSection2_Children2 {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #bfbfbf;
  text-align: center;
}

.FHCSection2_Children2OldPrice {
  color: #000000;
  text-align: center;
  font-family: var(--globalFont);
  font-size: var(--globalFontSize20);
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration-line: line-through;
  text-transform: capitalize;
}

.FHCSection2_Children2OldDiscountedPrice {
  color: #1c71e1;
  text-align: center;
  font-family: var(--globalFont);
  font-size: var(--globalFontSize20);
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-transform: capitalize;
}

.FHCSection2_Children2 p {
  color: #000;
  font-family: var(--globalFont);
  font-size: var(--globalFontSize18);
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-transform: capitalize;
}

.FHCSection2_Children3 {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.FHCSection3 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1em;
  padding-bottom: 1em;
}

.FHCSection3 p {
  color: #1c71e1;
  text-align: center;
  font-family: Montserrat;
  font-size: var(--globalFontSize20);
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  text-transform: capitalize;
  cursor: pointer;
}

.logoImageListing {
  width: 3.4em;
  height: 2.4em;
  position: absolute;
  top: -1.6em;
  left: -1em;
}

.logoImageListingMobile {
  width: 3.4em;
  height: 2.4em;
  position: absolute;
  top: 0;
  left: -1em;
}

.HCMMainChildImage {
  width: 2.99vw;
  height: 2.91vw;
}

@media screen and (max-width: 680px) {
  .FHCSection2_Children {
    display: flex;
    flex-direction: column;
    padding-bottom: 1em;
  }

  .FHCSection2_Children1 {
    width: auto;
    border-right: 0;
  }

  .FHCSection2_Children2 {
    width: auto;
    display: flex;
    border-right: 0;
    align-items: center;
    justify-content: flex-start;
    margin-left: 1em;
    position: relative;
  }

  .FHCSection2_Children3 {
    position: absolute;
    bottom: 1em;
    right: 0;
  }
}

@media screen and (max-width: 400px) {
  .FHCSection1.health-checkup :nth-child(1) {
    font-size: 2em;
  }

  .FHCSection1 :nth-child(1) {
    font-size: 2em;
  }
}
