.HraHealthRiskHeadingImage {
  background-color: #e2eefe;
  height: 20em;
}

.HraHealthRisk_Heading {
  font-style: normal;
  font-weight: 500;
  font-size: 3em;
  line-height: 150%;
}

.HraHealthRisk_BlockTwo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hraOpdMaskingGridContainer {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 2em;
  align-items: center;
  padding-top: 3em;
  padding-left: 2em;
  padding-right: 2em;
}

.hraOpdMaskingGriditem2 {
  position: relative;
  background: #eeffff;
  border-radius: 24px 0px 0px 24px;
  width: 30em;
  height: 12em;
}

.hraOpdMaskingGriditem2Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.hraOpdMaskingContainerText1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5em;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--globalColorHeader);
}

.hraOpdMaskingContainerText2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2em;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #1d2334;
}

.HraHealthRisk_BlockTwoHeading {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: var(--globalFontSize28);
  line-height: 150%;
  text-align: center;
  color: var(--globalColorMainHeading);
  margin-top: 2.875rem;
}

.HealthDoingImage {
  position: absolute;
  z-index: -10;
  bottom: 0;
}

.hraOpdMaskingGriditem2ContainerButton {
  background: var(--globalColorHeader);
  border-radius: 12px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.85em;
  line-height: 150%;
  color: #ffffff;
  outline: none !important;
  border: none;
  padding-top: 1em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-bottom: 1em;
}

.HraHealthRisk_BlockTwoSubHeading {
  margin: 2.43vw 5vw 4.875vw 5vw;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: var(--globalFontSize20);
  line-height: 150%;
  text-align: center;
  color: #44576a;
}

.HraHealthRisk_Heading1 {
  padding-top: 2.81vw;
  padding-left: 5.6875vw;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1.8vw;
  line-height: 150%;
  color: #ffffff;
  width: 30vw;
}

@media screen and (max-width : 880px) {
  .HraHealthRiskHeadingImage {
    height: fit-content;
    padding-bottom: 2em;
  }

  .hraOpdMaskingGridContainer {
    grid-template-columns: 1fr;
    gap: 2em;
  }
}

@media screen and (max-width: 500px) {
  .hraOpdMaskingGriditem2 {
    width: fit-content;
    padding-right: 0.5em;
    padding-bottom: 0.5em;
    height: fit-content;
  }
}

@media screen and (max-width:380px) {
  .HealthDoingImage{
    width: 20em;
  }
}