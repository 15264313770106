.productCard {
  border-radius: 20px;
  border: 1px solid #807f7f;
  background-color: white;
  width: 21vw;
  display: flex;
  flex-direction: column;
  align-items: normal;
  cursor: pointer;
  height: 23em;
}

.productCard:hover {
  box-shadow: 2px 2px 2px #c2c2c2;
}

.productCardImg {
  margin-top: 1em;
  width: 9vw;
  height: 12em;
}

.productCard:hover .productCardImg {
  transform: scale(1.02);
  transition-timing-function: ease-in;
}

.productCardDesc {
  display: flex;
  flex-direction: column;
  height: 11em;
  margin-left: 1.7vw;
  margin-right: 2vw;
  margin-top: 0.68em;
}

.productCardDescContent {
  height: 90%;
}

.fullProductName {
  position: absolute;
  top: 65%;
  background-color: #9c9c9c;
  color: white;
  z-index: 100;
  padding-left: 1em;
  padding-right: 1em;
}

.productCardTitle {
  color: #000;
  font-size: 1.25em;
  font-style: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.productCardDescBody {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.productCardBody {
  display: flex;
  align-items: center;
  margin-top: 0.7em;
}

.productCardMrp {
  color: #6b6b6b;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: line-through;
}

.productCardDiscount {
  font-size: 0.625em;
  background-color: #a5ccff;
  padding: 0.25em 0.69vw;
  margin-left: 0.46vw;
  border-radius: 4px;
}

.productCardPrice {
  font-size: 2em;
  font-weight: 400;
}

.productCardUnit {
  color: var(--globalColorButton);
  font-style: normal;
}

.productCardButton {
  border-radius: 12px;
  background: var(--globalColorButton);
  color: white;
  width: 3.5em;
  height: 3.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
}

.productCardButtonAdded {
  margin-top: 1em;
  margin-bottom: 1em;
  border-radius: 12px;
  background: var(--globalColorButton);
  color: white;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  background: #0a7b9e;
  color: #fff;
}

.productCardButtonAddedDiv {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

@media screen and (max-width: 480px) {
  .productCard {
    width: 80vw;
  }

  .productCardImg {
    width: 25vw;
    height: 12em;
  }
}

@media screen and (min-width: 481px) and (max-width: 679px) {
  .productCard {
    width: 37vw;
  }

  .productCardImg {
    margin-top: 1em;
    width: 20vw;
    height: 12em;
  }
}

@media screen and (max-width: 1000px) and (min-width: 680px) {
  .productCard {
    width: 30vw;
  }
}

@media screen and (min-width: 1500px) {
  .productCard {
    width: 21vw;
    height: 26em;
  }
}
