.SearchPageBanner {
  height: 21.9vw;
  background-color: #e1eeff;
}

.SearchBannerGroup {
  display: flex;
  padding-left: 4vw;
  padding-right: 11.6vw;
  padding-top: 2.3vw;
  padding-bottom: 2vw;
  justify-content: space-between;
  align-items: center;
}

.searchBanner2 {
  height: 17.27vw;
  width: 31vw;
}

.searchHeadingText2 {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 150%;
  color: #44576a;
}

.searchHeadingText1 {
  font-style: normal;
  font-weight: 600;
  font-size: 2em;
  line-height: 150%;
  color: var(--globalColorButton);
  text-transform: capitalize;
}

.SearchTestGrid {
  display: grid;
  gap: 2vw;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.SearchTestGridMbl {
  /* display: grid; */
  gap: 2vw;
  /* grid-template-columns: 1fr 1fr 1fr 1fr; */
}

.grid-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  transition: height 0.3s ease;
  overflow: hidden;
}

.grid-row.active {
  height: 100px; /* Adjust the initial height of the active row */
}

.grid-row.expanded {
  height: auto;
}

.grid-column {
  /* Add styles for grid columns */
}

.view-more {
  margin-top: 10px;
}

.SearchCardBodyButtonView {
  background: #ffffff;
  padding: 0.6875em 3.3vw;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 700;
  font-size: 1vw;
  line-height: 150%;
  color: #44576a;
  outline: none !important;
  border: 1px solid #d2d2d2;
  border-radius: 0px 0px 0px 12px;
  /* width: 14vw; */
}

.SearchpackageCards {
  background: #ffffff;
  border: 1px solid #ebeaea;
  box-shadow: 1px 5px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  transition: transform 0.2s ease;
  /* height: 22vw; */
  /* min-width: 27vw; */
}

.SearchpackageCards:hover {
  transform: scale(1.06);
}

.searchTestBlock {
  margin-top: 2em;
  margin-left: 4vw;
  margin-right: 4vw;
  margin-bottom: 5vw;
}

.SearchTestHeading {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 150%;
  text-transform: capitalize;
  margin-bottom: 3vw;
  color: black;
}

.SearchCardBodyButtonAdded {
  border: none;
  background: var(--globalColorAddedButton);
  border-radius: 0px 0px 12px 0px;
  /* padding: 1.2vw 5vw; */
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 700;
  font-size: 1vw;
  line-height: 150%;
  color: #ffffff;
  width: 12.6vw;
  outline: none !important;
}

.noRecords {
  margin-top: 5vw;
  display: flex;
  font-family: var(--globalFont);
  font-size: 1.5vw;

  font-family: var(--globalFont);
  justify-content: center;
  align-items: center;
  margin-bottom: 10vw;
}

.packageCardBodyButtonAdded1 {
  border: none;
  background: var(--globalColorAddedButton);
  border-radius: 0px 0px 12px 0px;
  padding: 1.2vw 3.79vw;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 700;
  font-size: 1vw;
  line-height: 150%;
  color: #ffffff;
  /* width: 12.6vw; */
  outline: none !important;
}

.viewMore {
  /* margin-top: 3vw; */
  background-color: transparent;
  border: 2px solid #fdc9a4;
  color: #f27d27;

  transition: transform 0.2s ease;
  border-radius: 12px;
}

.viewMore:hover {
  transform: scale(1.06);
}

.loader {
  margin-top: -8vw;
  margin-bottom: 10vw;
}

.searchPackageListing {
  padding-top: 2em;
}

@media screen and (max-width: 480px) {
  .searchBanner2 {
    display: none;
  }
  .SearchPageBanner {
    height: 8em;
    background-color: #e1eeff;
  }
  .searchHeadingText1 {
    font-size: 1.5em;
  }
  .searchHeadingText2 {
    font-size: 1.5em;
  }
}
