.productCategoryHero {
  background-color: #e2eefe;
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: space-between;
  padding-left: 4vw;
  padding-bottom: 2em;
}

.productCategoryHeroText {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.productCategoryHeroTextTitle {
  font-size: 3.75vw;
}

.productCategoryHeroTextTitle span {
  color: #1c71e1;
}

.productCategoryHeroTextDesc {
  font-size: 2vw;
}

.productCategoryHeroTextInput {
  display: flex;
  background-color: white;
  width: fit-content;
}

.productCategoryHeroTextInput div {
  padding: 1.5vh 0 1.5vh 2vw;
  display: flex;
  align-items: center;
  gap: 1.7vw;
  border-radius: 10px;
  border: 0.5px;
}

.productCategoryHeroTextInput img {
  height: auto;
  width: auto;
}

.productCategoryHeroTextInput button {
  border-radius: 10px;
  padding: 1.5em 2vw;
  background-color: #1c71e1;
  color: white;
}

@media screen and (max-width: 1000px) and (min-width: 880px) {
  .productCategoryHeroLeft {
    margin-top: 2em;
  }

  .productCategoryHeroRight img {
    width: 45vw;
  }
}

@media screen and (min-width: 680px) and (max-width: 879px) {
  .productCategoryHeroTextInput button {
    padding: 0.8em 1vw;
  }

  .productCategoryHeroTextInput img {
    height: 18px;
    width: 18px;
  }

  .productCategoryHeroRight img {
    margin-top: 5em;
    width: 35vw;
  }
}

@media screen and (max-width: 679px) {
  .productCategoryHeroTextInput button {
    padding: 0.01em 1vw;
    font-size: 0.75em;
  }
  .productCategoryHeroTextTitle {
    font-size: 2em;
  }
  .productCategoryHeroTextDesc {
    font-size: 1.5em;
  }

  .productCategoryHeroTextInput div {
    padding: 0.5em 0 0.5em 1em;
  }

  .productCategoryHeroTextInput img {
    height: 18px;
    width: 18px;
  }

  .productCategoryHeroRight {
    display: none;
  }

  .productCategoryHeroRight img {
    display: none;
  }

  .productCategoryHeroLeft {
    margin-top: 2em;
  }
}

@media screen and (max-width: 480px) {
  .productCategoryHeroTextTitle {
    font-size: 1.25em;
  }
  .productCategoryHeroTextDesc {
    font-size: 0.7em;
  }

  .productCategoryHeroTextInput input {
    font-size: 0.7em;
  }

  .productCategoryHeroTextInput div {
    padding: 0.7em 0 0.7em 0.7em;
  }

  .productCategoryHeroTextInput button {
    padding: 0.5em;
  }

  .productCategoryHeroTextInput img {
    height: 12px;
    width: 12px;
  }
}
