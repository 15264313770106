* {
  font-family: "Montserrat", sans-serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button {
  cursor: pointer;
  border: none;
  outline: none !important;
}

html {
  --globalFont: "Montserrat", sans-serif;
  --globalColorButton: #2667FF;
  --globalColorHeader: #1B71E1;
  --globalColorAddedButton: #5e1a20;
  --globalColorMainHeading: #111019;
  --globalColorSubMainHeading: #2d354e;
  --globalColorSubHeading: #2667FF;
  --globalColorFooterHeader:#FFFFFF;
  --globalColorFooterSubHeader:#FFFFFF;
  --globalFontSize60: 3.1em; /*60px*/
  --globalFontSize40: 2.5em; /*32px*/
  --globalFontSize38: 2.3em; /*32px*/
  --globalFontSize36: 2.5em;
  --globalFontSize32: 2.2em; /*32px*/
  --globalFontSize28: 1.7em; /*28px*/
  --globalFontSize26: 1.6em; /*28px*/
  --globalFontSize24: 1.5em; /*24px*/
  --globalFontSize22: 1.3em;
  --globalFontSize20: 1.2em; /*20px*/
  --globalFontSize18: 1.1em; /*18px*/
  --globalFontSize16: 1em; /*16px*/
  --globalFontSize14: 0.9em;
  --globalFontSize12: 0.8em;
  --globalFontSize10: 0.7em;
  --globalFontSize8: 0.6em;


  /* Mobile */
  --globalFontSizeMobile40: 9.6vw; /*32px*/
  --globalFontSizeMobile32: 7.7vw; /*32px*/
  --globalFontSizeMobile28: 6.7vw; /*28px*/
  --globalFontSizeMobile24: 5.7vw; /*24px*/
  --globalFontSizeMobile36: 8.6vw;
  --globalFontSizeMobile20: 4.8vw; /*20px*/
  --globalFontSizeMobile18: 4.3vw; /*20px*/
  --globalFontSizeMobile16: 3.8vw; /*16px*/
  --globalFontSizeMobile14: 3.3vw;
  --globalFontSizeMobile12: 2.8vw;
}

input {
  border: none;
  outline: none !important;
}

div {
  border: none;
  outline: none !important;
}
