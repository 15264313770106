.mobileNavbar {
  background-color: #e1eeff;
}

.mobileMainBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1em;
  padding-right: 2em;
  padding-bottom: 0.5em;
}

.MnavbarTitle {
  width: 10%;
  height: 100%;
}

.MnavbarTitle img {
  width: 8em;
  margin-left: 1em;
  height: auto;
}

.navLogo {
  display: inline-block;
  z-index: 999;
}

.mblCarts {
  z-index: 0;
  display: flex;
  align-items: center;
}

.shoppingBagButton {
  background: transparent;
  border: hidden;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 0.87rem;
  color: #44576a;
}
.shoppingBagButton img{
  height: 1.6em;
  width: 1.6em;
}
.shoppingBagButton:hover {
  cursor: pointer;
}

.mblMenu {
  margin-left: 3.2em;
  z-index: 999;
}

.searchBarMobile {
  position: relative;
  width: 82%;
  height: 2.4em;
  margin-top: 1em;
  margin-left: 8.6vw;
  background: #e1eeff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid black;
}

.searchBarMobile input {
  height: 100%;
  width: 90%;
  border: hidden;
  background-color: #e1eeff;
  border-radius: 8px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 0.87rem;
  padding-left: 1em;
  color: black;
}

.searchBar a {
  margin-right: 4%;
}