.AvertisementBannerMain {
}

.AvertisementBannerSection1 {
  display: flex;
}

.AvertisementBannerSection1p2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 1rem;
}

.AvertisementBannerSection1p2Header2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 150%;
  text-align: right;
  color: #0d1144;
  width: 45%;
}

.AvertisementBannerSection1p1Header {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 150%;
  color: #ffffff;
}

.AdvertisementBannerFormButton {
  background: var(--globalColorHeader);
  border-radius: 6px;
  color: #ffffff;
  padding: 0.5rem 3rem;
}

.AvertisementBannerSection1p1 {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 9rem;
}

.AdvertisementBannerFormTAC {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 150%;
  color: var(--globalColorHeader);
  margin-top: -2rem;
}

.AdvertisementBannerFormInput {
  background: #ffffff;
  border-radius: 6px;
  padding: 0.5rem 3rem;
}

.AvertisementBannerMainLogo {
  width: 9.13vw;
  margin-top: -3vw;
  margin-bottom: 2vw;
}

.AvertisementBannerSection1p2HeaderPlus {
  color: #a53f2b;
}

.AvertisementBannerSection1p2Header3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: right;
  color: #a53f2b;

  width: 48%;
}
.avp2-Wrapper {
  display: flex;
  align-items: flex-start;
}
.avp2-Wrapper2 {
  width: 28%;
  display: flex;
  align-items: flex-end;
}

.AvertisementBannerSection2 {
  display: flex;
  flex-direction: column;
  background-color: #52608e;
  align-items: center;
  padding: 1rem 0rem;
}

.AvertisementBannerSection2Header {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 150%;
  text-transform: capitalize;
  color: #ffffff;
}

.AvertisementBannerSection2Button {
  margin-top: 0.5rem;
  background: #ffffff;
  border-radius: 6px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 150%;
  text-align: center;
  color: var(--globalColorHeader);
  padding: 0.25rem 2rem;
  cursor: pointer;
}

.AvertisementBannerSection3 {
  padding: 3rem 7.63vw;
  background: #f2efea;
}

.AvertisementBannerSection3Header {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 150%;
  text-align: center;
  text-transform: capitalize;
  color: var(--globalColorHeader);
}

.AvertisementBannerSection3SubHeader {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 150%;
  text-align: center;
  text-transform: capitalize;
  color: #1d2334;
}

.AvertisementBannerSection3CardWrapper {
  display: flex;
  justify-content: space-between;
}

.AvertisementBannerSection3CardIndividual {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0.5rem;
  width: 15.48vw;
  background: #52608e;
  border-radius: 6px;
  color: #ffffff;
  flex-wrap: wrap;
  cursor: pointer;
  height: 26.5vh;
}

.AvertisementBannerSection3CardIndividual2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0.5rem;
  width: 15.48vw;
  background: #52608e;
  border-radius: 6px;
  color: #ffffff;
  flex-wrap: wrap;
  cursor: pointer;
  height: 35vh;
  clip-path: polygon(
    0% 0%,
    100% 0%,
    100% 75%,
    60% 75%,
    50% 90%,
    40% 75%,
    0% 75%
  );
}
.AvertisementBannerSection3CardText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  text-align: center;
  text-transform: capitalize;
  color: #f2efea;
  width: 11vw;
  margin-top: 1rem;
}

.AvertisementBannerSection3Info {
  margin-top: 2rem;
  background: #52608e;
  border-radius: 10px;
  display: flex;
  padding: 1rem 2rem;
}

.AvertisementBannerSection3InfoHeader {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 150%;
  text-transform: capitalize;
  color: #ffffff;
}

.AvertisementBannerSection3InfoPara {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  text-transform: capitalize;
  color: #ffffff;
}

.AvertisementBannerSection3InfoButton {
  background: #ffffff;
  border-radius: 5px;
  padding: 0.5rem 4rem;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--globalColorHeader);
}

.AvertisementBannerSection3Infopart2 {
  width: 60%;
  margin-left: 6vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}

.AvertisementBannerSection4 {
  padding: 3rem 7.63vw;
  display: flex;
}

.AvertisementBannerSection4Part1Header {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 150%;
  text-transform: capitalize;
  color: var(--globalColorHeader);
}

.AvertisementBannerSection4Part1SubHeader {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 150%;
  text-transform: capitalize;
  color: #1d2334;
  margin-bottom: 1rem;
}

.AvertisementBannerSection4Part1Points {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  text-transform: capitalize;
  color: #1d2334;
}

.AvertisementBannerSection4Part1Button {
  margin-top: 1rem;
  background: #52608e;
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  padding: 0.5rem 4rem;
}

.AvertisementBannerSection4Part2 {
  display: flex;
  width: 50%;
  justify-content: space-around;
}

.AvertisementBannerSection4CardIndividual {
  background: #ffffff;
  box-shadow: 2px 0px 2px 2px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding: 0.5rem;
  width: 18.05vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  height: 14rem;
}

.AvertisementBannerSection4CardHeader {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 150%;
  text-transform: capitalize;
  color: #44576a;
}

.AvertisementBannerSection4CardDescription {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 150%;
  text-transform: capitalize;
  color: #1d2334;
}

.AvertisementBannerSection4SubPart {
  display: flex;
  padding: 3rem 10.63vw;
}

.AvertisementBannerSection4SubPart2 {
  width: 55%;
  margin-left: 10vw;
}

.AvertisementBannerSection5 {
  padding: 3rem 7.63vw;
}

.AvertisementBannerSection5Card1Wrapper {
  display: flex;
  justify-content: center;
  gap: 5vw;
  margin-bottom: 3rem;
}

.AvertisementBannerSection5Card1Individual {
  background: linear-gradient(180deg, #fff8f8 0%, rgba(250, 250, 250, 0) 100%);
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 80px;
  padding: 1.5rem 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AvertisementBannerSection5Card2Wrapper {
  display: flex;
  background: #fffbfa;
  border-radius: 12px;
  justify-content: space-around;
  padding: 1rem;
}

.AvertisementBannerSection5Card1Header {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  text-align: center;
  color: #1d2334;
}

.AvertisementBannerSection5Card2Number {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 150%;
  text-align: center;
  color: var(--globalColorHeader);
}

.AvertisementBannerSection5Card2Header {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 150%;
  text-align: center;
  color: #1d2334;
}

.Section5Card1WrapperMobile {
  padding: 1vw 2vw;
}
.AvertisementBannerLimitedTime {
  left: 9vw;
  position: absolute;
  color: white;
  font-size: 1.7vw;
  top: 17vw;
  z-index: 999;
  width: 28vw;
}
.CollectionChargeApplicationUi {
  position: absolute;
  font-size: 1vw;
  top: 54vw;
  left: 49vw;
  color: wheat;
  color: white;
  font-size: 2vw;
}

@media screen and (min-width: 1370px) {
  .AvertisementBannerLimitedTime {
    left: 9vw;
    position: absolute;
    color: white;
    font-size: 1.7vw;
    top: 13vw;
    z-index: 999;
    width: 28vw;
  }
  .CollectionChargeApplicationUi {
    position: absolute;
    font-size: 1vw;
    top: 39.3vw;
    left: 49vw;
    color: wheat;
    color: white;
    font-size: 2vw;
  }
}
