.finalPaymentPageWrapper {
  margin: 4vw 6.31vw 4vw 6.31vw;
  border-radius: 0.8vw;
  background: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 12px;
}

.finalPaymentPageHeader {
  padding: 2.38vw;
}

.paymentConetent1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 550;
  font-size: 1.12vw;
  margin-bottom: 1.4vw;
  line-height: 17px;
  color: #2d354e;
}

.paymentBlockrow {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-right: 12vw;
}

.paymentConetent2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  margin-bottom: 1.4vw;
  font-size: 1.02vw;
  line-height: 17px;
  color: #2d354e;
}

.paymentView {
  cursor: pointer;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.2vw;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  color: #229ffa;
}

.paymentB1 {
  padding-left: 2.38vw;
  padding-top: 1vw;
  max-height: 10vw;
  overflow-y: auto;
  background: #f2efea;
  border: 1px solid #dfdfdf;
  border-radius: 0px;
}

.paymentHeadingss {
  font-family: "Montserrat";
  font-style: bold;
  font-weight: 500;
  font-size: 1.7vw;
  line-height: 150%;
  text-transform: capitalize;
  color: #44576a;
  margin-top: -5.5vw;
  margin-bottom: 2.2vw;
  margin-left: 2.38vw;
}

.finalPaymentPageHeaderText {
  font-family: "Montserrat";
  font-style: bold;
  font-weight: 500;
  font-size: 1.7vw;
  line-height: 150%;
  text-transform: capitalize;
  color: #44576a;
}

.finalPaymentPageMain {
  padding: 0vw 0vw 0vw 2.38vw;
  display: flex;
}

.finalPaymentPageLeftPartWrapper {
  width: 35%;
}

.vrfinalPaymentPage {
  border-left: 1px solid #dfe2e4;
  height: 83vh;
  /* margin-bottom: 3vw; */
  margin-left: 10vw;
  position: absolute;
  top: 25%;
  left: 34%;
}

.finalPaymentPageRightPartWrapper {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.PrimeComprehensiveMainHeading {
  font-weight: 500;
  font-size: 1.47vw;
  text-align: center;

  color: #2d354e;
}

.PrimeComprehensiveMainContainer {
  width: 65%;
  margin-left: 8.5vw;
}

.PrimeComprehensiveMaincon {
  text-align: center;
}

.PrimeComprehensiveQuantity {
  font-weight: 400;
  font-size: 1vw;
  color: #44576a;
  margin-top: 1vw;
}

.PrimeCompPriceDetailOrder {
  display: flex;
  justify-content: space-between;
}

.PrimeCompPriceDetailOrderCollectionCharge {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5em;
}

.PrimeCompPriceDetails {
  margin-left: 2.38vw;
  margin-right: 2.38vw;
  margin-top: 2vw;
}

.PrimeCompPriceDetailsHeading {
  font-weight: 500;
  font-size: 1vw;
  margin-bottom: 1.2vw;
  color: #2d354e;
}

.PrimeCompPriceDetailsHeading2 {
  font-weight: 500;
  font-size: 1.1vw;
  margin-bottom: 1vw;
  color: #2d354e;
}

.PrimeCompPriceDetailOrderhead {
  font-weight: 550;
  font-size: 1.1vw;
}

.PrimeCompPriceDetailOrderheadPrice {
  font-weight: 500;
  font-size: 1.1vw;
  color: #2d354e;
}

.PrimeCompPriceDetailOrderheadPrice {
  font-weight: 400;
  color: #2d354e;
  font-size: 1.1vw;
}

.PrimeCompPriceAMountDetail {
  font-weight: 500;
  font-size: 1.1vw;
  color: #2d354e;
}

.paymentMethod {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
}

.paymentMethodRadio {
  display: flex;
  align-items: baseline;
  margin-top: 1em;
}

.paymentMethodRadio label {
  margin-left: 1vw;
}

.paymentMethod input {
  cursor: pointer;
}

.paymentMethodRadioInput {
  margin-left: 1vw;
}

.paymentMethodRadioInputMobile {
  margin-left: 5vw;
}

.TermsPrime {
  font-weight: 400;
  font-size: var(--globalFontSize14);
  padding-bottom: 1vw;
  padding-top: 1vw;
}

.TermsPrimeBlock {
  margin-top: 2vw;
}

.PrimeLabmanPic {
  padding: 1vw;
  box-shadow: 0px 0px 2px 2px rgb(0 0 0 / 10%);
  border-radius: 1vw;
  width: 3.7vw;
  margin-left: 2vw;
  margin-bottom: 1vw;
}

/* .PrimeComprehensiveImageDisplay{
  display: flex;
  justify-content: space-around;
} */
.vrfinalPaymentPageVline {
  border-top: 1px solid #dfe2e4;
  margin-top: 1vw;
  padding-bottom: 1vw;
}

.css-ahj2mt-MuiTypography-root {
  font-size: var(--globalFontSize14) !important;
}

.finalPaymentPageAddressblock {
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding-right: 2vw;
  margin-bottom: 3vw;
  padding-left: 2vw;
  padding-top: 2vw;
  padding-bottom: 1vw;
}

.finalPaymentColumn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.finalPaymentColumn2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
}

.finalPaymentAddressName {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.17vw;
  line-height: 150%;
  /* identical to box height, or 24px */

  color: #1d2334;
}

.finalPaymentAddress {
  margin-top: 0.5vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.02vw;
  line-height: 150%;
  /* or 21px */

  color: #1d2334;
}

.finalPaymentNumber {
  margin-top: 0.5vw;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.02vw;
  line-height: 150%;
  /* identical to box height, or 21px */

  color: #44576a;
}

.selectAddressText {
  background: #ffffff;
  border: 1px solid var(--globalColorHeader);
  border-radius: 12px;
  position: absolute;
  margin-top: -3.4vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.32vw;
  padding-bottom: 0.2vw;
  padding-right: 1vw;
  padding-left: 1vw;
  padding-top: 0.2vw;
  line-height: 150%;
  /* identical to box height, or 27px */

  color: var(--globalColorHeader);
}

.finalPaymentAddressDate {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.17vw;
  line-height: 150%;
  /* identical to box height, or 24px */
  margin-top: 0.5vw;

  color: #1d2334;
}

.finalPaymentAddressTime {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.2vw;
  line-height: 150%;
  /* identical to box height, or 21px */
  margin-top: 0.5vw;

  color: #1d2334;
}

.imgPayment {
  height: 1vw;
  width: 1.2vw;
  margin-top: 0.8vw;
}

.imgPayment1 {
  height: 1vw;
  width: 1vw;
  margin-top: 0.8vw;
}

.buttonBlockCart {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 4vw;
  margin-left: 6.31vw;
  margin-right: 6.31vw;
  padding-right: 10%;
}

.finalButtonPaymentCart {
  text-align: center;
  color: #44576a;
  background: #ffffff;
  border: 1px solid var(--globalColorHeader);
  border-radius: 12px;
  outline: none !important;
  width: 40%;
  padding-top: 1.2em;
  padding-bottom: 1.2em;
  background: var(--globalColorHeader);
  box-shadow: 0px 4px 16px rgba(35, 221, 237, 0.16), 0px 4px 8px -2px #030c28;
  border-radius: 4px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1em;
  line-height: 150%;
  color: #ffffff;
}

.SelectCHeckBoxPrime {
  margin-top: 1.25rem;
  margin-bottom: 2vw;
}

.css-6z8jno {
  border-radius: 16px !important;
  outline: none !important;
  width: 55vw !important;
  height: auto !important;
  border: none !important;
}

.modalBlock {
  margin-left: 2vw;
  margin-right: 2vw;
  margin-top: 0.9vw;
  margin-bottom: 6vw;
}

.modalCloseBlock {
  display: flex;
  justify-content: space-between;
}

.modalHeading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  margin-bottom: 2vw;
  /* identical to box height, or 36px */

  text-transform: capitalize;

  color: #44576a;
}

.modalContent {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding-top: 1vw;
  /* identical to box height */

  color: #44576a;
}

.verticalHieghtModal {
  border-top: 1px solid #dfe2e4;
  margin-top: 0.5vw;
}

/* .css-i4bv87-MuiSvgIcon-root {
  height: 1.5vw !important;
  width: 2vw !important;
  
} */
.finalPaymentPageWrappermobile1 {
  margin-left: 1.2em;
  margin-right: 1.2em;
  padding-top: 1em;
  padding-bottom: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.finalPaymentPageWrappermobile2 {
  margin-left: 1.2em;
  margin-right: 1.2em;
  margin-top: 2em;
  background: #f8f8f8;
  border: 1px solid #f7f7f7;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}

.finalPaymentPageWrappermobile2Upper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
}

.finalPaymentPageWrappermobile2Upper :nth-child(1) {
  margin-right: 2.3em;
  width: 1.5em;
  height: 1.2em;
}

.finalPaymentPageWrappermobile2Upper :nth-child(2) {
  margin-right: 1.1em;
}

.finalPaymentPageWrappermobile2Down {
  display: flex;
  flex-direction: column;
  padding-left: 1.1em;
  padding-right: 1.1em;
}

.finalPaymentPageWrappermobile2Down :nth-child(1) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 150%;
  color: var(--globalColorButton);
}

.finalPaymentPageWrappermobile2Down :nth-child(2) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 150%;
  color: #44576a;
}

.finalPaymentPageWrappermobile2Down :nth-child(3) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 150%;
  color: #44576a;
}

.paymentPara {
  margin-left: 1.2em;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.2em;
  line-height: 150%;
  text-transform: capitalize;
  color: #e68d36;
  margin-top: 1em;
}

.finalPaymentPageWrappermobile301 {
  display: flex;
}

.finalPaymentPageWrappermobile301 {
  display: flex;
  flex-direction: column;
  color: #38466c;
  align-items: flex-start;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  margin-top: 2em;
  margin-left: 1.2em;
  margin-right: 1.2em;
  padding-top: 0.8em;
}

.boxFinalPay {
  margin-left: 1em;
}

.finalPaymentPageWrappermobile3 :nth-child(1) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  color: var(--globalColorButton);
}

.finalPaymentPageWrappermobile3 :nth-child(2) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 150%;
  color: var(--globalColorButton);
  margin-top: 0.7em;
}

.finalPaymentPageWrappermobile4 {
  display: flex;
  flex-direction: column;
  margin-left: 1.2em;
  margin-right: 1.2em;
}

.finalPaymentPageWrappermobile4_1 {
  display: flex;
  border-top: 1px solid #dfe2e4;
  margin-top: 2em;
}

.finalPaymentPageWrappermobile4_1Left {
  width: 50%;
  padding-top: 1.1em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding-left: 1em;
}

.finalPaymentPageWrappermobile4_1Left :nth-child(1) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.7em;
  line-height: 150%;
  color: #44576a;
}

.finalPaymentPageWrappermobile4_1Left :nth-child(2) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 0.7;
  line-height: 150%;
  color: #2d354e;
}

.finalPaymentPageWrappermobile4_1Right {
  width: 50%;
  padding-top: 1.1em;
  display: flex;
  justify-content: flex-end;
  padding-right: 1.1em;
}

.finalPaymentPageWrappermobile4_1Right p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.7em;
  line-height: 150%;
  color: #44576a;
}

.finalPaymentPageWrappermobile4_2 {
  display: flex;
  margin-left: 1.2em;
  margin-right: 1.2em;
  border-top: 1px solid #dfe2e4;
  padding-top: 1em;
}

.finalPaymentPageWrappermobile4_2Left {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.finalPaymentPageWrappermobile4_2Left :nth-child(1) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  color: #2d354e;
}

.finalPaymentPageWrappermobile4_2Left :nth-child(2) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  color: #2d354e;
}

.finalPaymentPageWrappermobile4_2Right {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* background-color: #229ffa; */
}

.finalPaymentPageWrappermobile4_2Right :nth-child(1) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  color: #2d354e;
}

.finalPaymentPageWrappermobile4_2Right :nth-child(2) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.7em;
  line-height: 150%;
  color: #2d354e;
}

.finalPaymentPageWrappermobile4_3 {
  display: flex;
  margin-left: 1.2em;
  margin-right: 1.2em;
  border-top: 1px solid #dfe2e4;
  padding-top: 1em;
}

.finalPaymentPageWrappermobile4_3Left {
  width: 50%;
  display: flex;
  justify-content: flex-start;
}

.finalPaymentPageWrappermobile4_4 {
  margin-left: 1.2em;
  margin-right: 1.2em;
}

.finalPaymentPageWrappermobile4_3Left p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 150%;
  color: #2d354e;
}

.finalPaymentPageWrappermobile4_3Right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

.finalPaymentPageWrappermobile4_3Right p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 150%;
  color: #2d354e;
}

.newpaymentcontent {
  margin-top: 2vh;
}

.newpaymentcontents {
  margin-top: 0.3vh;
  font-weight: 400 !important;
  margin-right: 0.5vw;
  font-size: 0.9em !important;
}

.paymentMethodMobile {
  display: flex;
  flex-direction: column;
  margin-left: 1.2em;
  margin-right: 1.2em;
  border-top: 1px solid #dfe2e4;
  padding-top: 1em;
}

.TermsPrimeMobile {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 2em;
  line-height: 200%;
  color: #44576a;
}

.finalPaymentPageWrappermobile5 {
  text-align: center;
  background: var(--globalColorHeader);
  border: 1px solid var(--globalColorButton);
  border-radius: 6px;
  margin-left: 1.2em;
  margin-right: 1.2em;
  margin-top: 4em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1em;
  line-height: 150%;
  color: #ffffff;
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  margin-bottom: 3em;
}

.OrderSummaryFinalMobile {
  margin-left: 6.15vw;
  margin-top: 7.23vh;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  text-transform: capitalize;
  color: #e68d36;
}

.OrderSummaryFinalContentMobile {
  margin-top: 2vh;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 550;
  font-size: 16px;
  line-height: 16px;
  color: #2d354e;
}

.buttonInPayment {
  display: flex;
  justify-content: flex-end;
  margin-left: 74vw;
  color: #229ffa;
  margin-top: -4vh;
  background: white;
}

.finalPaymentRightPartWrapper {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.finalPaymentPlanDescriptionCard {
  background: #ffffff;
  border-radius: 16px;
  padding: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.finalPaymentPlanDescriptionHeader {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #44576a;
  text-transform: capitalize;
}

.finalPaymentPlanDescriptionCardImage {
  width: 10vw;
  height: 6.76vw;
}

.FInalPaymentPlanImageMobile {
  width: 14.87vw;
  height: 16.16vw;
}

.finalPaymentPlanDescriptionFixedText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #2d354e;
  padding: 1vw 1vw 1vw 1vw;
}

.finalPaymentPlanDescriptionFixedText1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #2d354e;
  padding: 2.5vw 1vw 1vw 1vw;
}

.finalPaymentPlanDescriptionFixedText2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #2d354e;
  padding: 1vw 1vw 1vw 1vw;
}

.finalPaymentPlanDescriptionFixedText3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #2d354e;
  padding: 1vw 1vw 1vw 1vw;
}

.finalPaymentPlanDescriptionP1 {
  display: flex;
  justify-content: space-between;
}

.finalPaymentPlanDescriptionLineSeperator {
  margin-top: 0px;
  margin-bottom: 1.8vh;
  border-top: 2px solid #f2efea;
}

.finalPaymentPlanDescriptionMarketing {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1vw 1vw 2vw 1vw;
}

.finalPaymentPlanDescriptionMarketingText {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: var(--globalFontSize16);
  color: #44576a;
  margin-top: auto;
  margin-bottom: auto;
}

.couponApplyDiv {
  display: flex;
  margin-bottom: 1vw;
}

.couponButton {
  background: #e68d36;
  border-radius: 12px;
  border: none;
  color: #ffffff;
  padding: 0.5vw 1vw 0.5vw 1vw;
  margin-left: -15px;
}

.couponButtonMobile {
  background: #e68d36;
  border-radius: 12px;
  border: none;
  color: #ffffff;
  padding: 1vw 2vw 1vw 2vw;
  margin-left: 4vw;
}

.couponApplyInput {
  background: #ffffff;
  border: 0.5px solid #999999;
  border-radius: 12px;
  padding: 10px;
}

.FinalPAymentButton {
  font-family: "Montserrat";
  font-style: normal;
  background: #fbfbfb;
  border: 1px solid var(--globalColorButton);
  border-radius: 12px;
  padding: 1.5vw 8vw 1.5vw 8vw;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.FinalPAymentButtondiv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 4vw;
}

.finalPaymentAddressCardMobile {
  background: #f8f8f8;
  border: 1px solid #f7f7f7;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  margin-top: 10vw;
  margin-bottom: 10vw;
}

.finalPaymentWrapperMobile {
  padding-top: 1vw;
  padding-left: 5vw;
  padding-right: 7vw;
  background: #fbfbfb;
}

.finalPaymentPlanDescriptionCardMobile {
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  padding: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45vw;
  height: 40vw;
}

.finalPaymentPlanDescriptionCardMobileWrapper {
  padding-top: 4vw;
  padding-bottom: 4vw;
  display: flex;
  justify-content: center;
}

.QuantityMobile {
  padding-top: 4vw;
  padding-bottom: 4vw;
  display: flex;
  justify-content: center;
}

.FinalPAymentButtondivMobile {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 12vw;
  padding-top: 10vw;
}

.finalPaymentPlanDropdown {
  background: #ffffff;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  width: 100%;
  padding: 0.7em 1em 0.7em 1em;
  margin-top: 2vw;
  cursor: pointer;
}

.itemViewbutton {
  color: #229ffa;
  cursor: pointer;
}

.itemViewbutton:hover {
  text-decoration: underline !important;
}

@media screen and (max-width: 600px) {
  .css-ahj2mt-MuiTypography-root {
    font-size: var(--globalFontSizeMobile14) !important;
  }
}
