.MentalHealthMemberContainerMain {
  position: absolute;
  width: 30em;
  background-color: white;
  z-index: 1000;
  border: 10px;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  padding: 3em;
}

.MentalWellnessmodalHeading {
  display: flex;
  justify-content: space-between;
  font-size: 1.5em;
}

.wellnessinputselect {
  padding: 0.5em;
  border: 10px;
}

.mentalWellnessSaveRealtions {
  background: var(--globalColorHeader);
  border-radius: 6px;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 150%;
  border: hidden;
  padding: 0.8em 6.0625em;
  color: #ffffff;
  outline: none !important;
  border: none;
}

@media screen and (max-width: 500px) {
  .MentalHealthMemberContainerMain {
    width: auto;
  }
}
