* {
  font-family: var(--globalFont);
}
.ViewPlanDetailMainContainer {
  margin-top: 3vw;
}
.ViewPlanDetailGridContainer {
  display: grid;
  grid-template-columns: 45% 55%;
}
.ViewPlanDetailGridMainContainer {
  background-color: #f5f6fa;
  box-shadow: 0px 15px 10px -15px #111;
  /* padding-bottom: 6vw; */
  /* padding-top: 2vw; */
}
.ViewPlanDetailGridIMage {
  margin-left: 3vw;
  width: 39vw;
  margin-bottom: 2vw;
}

.ViewPlanRelationAllowed {
  margin-left: 4vw;
  margin-top: 1vw;
  font-size: var(--globalFontSize24);
  color: var(--globalColorSubHeading);
}
.ViewPlanDetailSubGridContainer {
  display: grid;
  grid-template-columns: 18vw 18vw 16vw;
}
.ViewDetailScreenHeading {
  color: var(--globalColorButton);
  font-size: var(--globalFontSize40);
  margin-top: 1vw;
  margin-left: 7vw;
  margin-bottom: 1vw;
}
.ViewPlanPriceTagContainer {
  position: absolute;
  right: 0;

  left: 0;
}
.ViewPlanPriceTagContainerBtn {
  position: absolute;
  right: 0;
  left: 84%;
  margin-right: 9vw;
}
.ViewPlanDetailsSecondGridContainer {
  margin-bottom: 1vw;
}
.ViewPlanDetailSubGridName {
  color: var(--globalColorHeader);
  font-size: var(--globalFontSize20);
  font-weight: 500;
  text-transform: uppercase;
}
.ViewPlanDetailSubGridDescription {
  font-size: var(--globalFontSize16);
  color: #a53f2b;
}
.ViewPlanDetailSubGridDescriptionAvailable {
  font-size: var(--globalFontSize16);
  color: #319b4b;
}
.ViewDetailDisplayRelationConatiner {
  display: grid;
  grid-template-columns: 39vw 30vw 28vw;
  background: #ececec;
  padding-left: 4vw;
  padding-left: 3vw;
}
.ViewDetailyearlyFinalCost {
  color: #fff;
  font-family: Montserrat;
  font-size: var(--globalFontSize36);
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.ViewDetailyearlyPrice {
  color: var(--secondary_color_2, #a53f2b);
  font-family: Montserrat;
  font-size: var(--globalFontSize24);
  font-style: normal;
  padding-right: 1.5vw;
  font-weight: 500;
  line-height: 150%; /* 36px */
  text-decoration: line-through;
}
.ViewDetailPriceList {
  background-color: var(--globalColorHeader);
  padding-top: 1vw;
  padding-bottom: 1vw;
  padding-left: 3vw;
  color: #44576a;
  /* font-size: var(--globalFontSize24); */
}
.ViewDetailMemberList {
  font-size: var(--globalFontSize24);
  padding-top: 1vw;
  padding-bottom: 1vw;
  padding-left: 1vw;
  color: #44576a;
  border-right: 1px solid #d0d0d0;
}



@media screen and (max-width: 600px) {
  .ViewPlanDetailsContainerMobileApp {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 3vw;
  }

  .ViewDetailsMainHeadingMobile {
    color: white;
    padding-top: 1rem;
    padding-left: 1rem;
    font-size: var(--globalFontSizeMobile24);
    padding-bottom: 1rem;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .ViewPlanDetailSubGridName {
    color: white;
    font-size: var(--globalFontSizeMobile18);
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    margin-left: 5vw;
  }
  .ViewPlanDetailSubGridDescription {
    font-size: var(--globalFontSizeMobile16);
    margin-left: 5vw;
    margin-bottom: 2vw;
  }
  .ViewPlanDetailSubGridDescriptionAvailable {
    font-size: var(--globalFontSizeMobile16);
    margin-left: 5vw;
    margin-bottom: 2vw;
    color: #319b4b;
  }

  .plansdots {
    width: 2vw;
  }

  .ViewDetailPlanDetailDisplay {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border: 1px solid #e2e2e2;
    background: #f6f6f6;
  }
  .ViewDetailPlanDetailDisplayItems {
    padding: 6vw 3vw 6vw 5vw;
    color: var(--pp1, #44576a);
    font-size: 16px;
    border: 1px solid #e2e2e2;
  }
  .ViewDetailPriceListMobileBackground {
    background-color: var(--globalColorHeader);
    padding: 6vw 3vw 6vw 5vw;
    color: var(--pp1, #44576a);
    font-size: 16px;
  }
  .ViewDetailsFinalCostMobile {
    color: #ffffff;
  }
  .ViewDetailyearlyPrice {
    font-size: var(--globalFontSizeMobile16);
  }
  .ViewDetailyearlyFinalCost {
    font-size: var(--globalFontSizeMobile16);
  }
  .PlanServicesDetailListingConatinerMobile {
    margin-top: 7vw;
  }
}
