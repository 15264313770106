.PlanDetailContainer {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 17px;
  /* identical to box height */

  /* primary_color_1 */

  color: #1d2334;
  display: grid;
  grid-template-columns: 3vw auto;
  justify-content: flex-start;
  margin-left: 3.3vw;
  padding-right: 5vw;
  /* margin-top: 3.5vw; */
  margin-bottom: 2vw;
}

.PlanDetailsHeading {
  font-weight: 500;
  font-size: 1.7vw;
  color: #44576a;
  margin-left: 3.3vw;
  margin-top: 5vw;
  margin-bottom: 3vw;
}
.PlanDetailContent {
  font-weight: 500;
  /* margin-left: 2vw; */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 18px;

  /* primary_color_1 */

  color: #1d2334;
}

.BasicPLanImageDoc {
  display: flex;
  justify-content: center;
}
.BasicPLanImageDocIMg {
  width: 34vw;
  height: 23vw;
}
.OpdLineFlow {
  display: flex;
  /* justify-content: space-around; */
  background: #c4dedc;
  width: 51vw;
  align-items: center;
  text-align: center;
  padding-top: 0.6vw;
  padding-bottom: 0.6vw;
  margin: auto;
  border-radius: 4px;
}
.OpdLineFlow2 {
  display: flex;
  justify-content: space-around;
  background: #f7f8f7;
  width: 51vw;
  align-items: center;
  text-align: center;
  padding-top: 0.6vw;
  padding-bottom: 0.6vw;
  border-radius: 4px;
  margin: 3vw auto;
}
.OpdLineFlowFirst {
  font-weight: 500;
  font-size: 1vw;
  color: #44576a;
  text-align: justify; /* For Edge */
  -moz-text-align-last: justify; /* For Firefox prior 58.0 */
  text-align-last: justify;
  margin-left: 2vw;
}
.OpdLineFlowSecond {
  color: #44576a;
  font-weight: 400;
  font-size: 1vw;
  position: absolute;
  margin-left: 31vw;
}
.OpdLineFlowButton {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  position: relative;
  margin-top: 5vw;
}
.OpdLineFlowButton1 {
  background: #ffffff;
  border: 1px solid var(--globalColorButton);
  border-radius: 12px 0px 0px 12px;
  padding-top: 1vw;
  padding-bottom: 1vw;
  cursor: pointer;
  justify-content: center;
  flex-direction: row;
  display: flex;
  font-size: 1vw;
}

.finalcost {
  margin-left: 1vw;
}
.OpdLineFlowButton2 {
  background: var(--globalColorHeader);
  border-radius: 0px 12px 12px 0px;
  color: #ffffff;
  padding-top: 1vw;
  padding-bottom: 1vw;
  cursor: pointer;
  font-size: 1vw;
}
.PLanPageCarouselMainBody {
  margin-top: -10vw;
}

.iconTick {
  margin-left: 6vw;
  width: 1vw;
}
@media screen and (max-width: 600px) {
  .PlanTick {
    padding-left: 3.3vw;
    width: 7vw;
    padding-right: 1vw;
  }
  .PlanDetailContainer {
    display: grid;
    grid-template-columns: 3vw auto;
    justify-content: flex-start;
    margin-left: 10vw;
    padding-right: 5vw;
    margin-top: 3.5vw;
    margin-bottom: 2vw;
  }

  .PlanDetailContent {
    font-weight: 500;
    margin-left: 2vw;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 3.3vw;
    line-height: 14px;

    /* primary_color_1 */

    color: #1d2334;
  }

  .PlanDetailContainer {
    display: grid;
    grid-template-columns: 3vw auto;
    justify-content: flex-start;
    margin-left: 10vw;
    padding-right: 5vw;
    /* margin-top: 3.5vw; */
    margin-bottom: 2vw;
  }
  .PlanDetailsHeading {
    display: flex;
    justify-content: center;
    margin: 5vw auto;
    font-size: 3.8vw;
  }
  .PlanDetailsTermsHeading {
    display: flex;
    margin: 5vw auto;
    font-size: 4.3vwvw;
    margin-left: 9vw;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    /* margin-top: 2vw; */
    padding-top: 4vw;
    /* font-size: 16px; */
    line-height: 150%;
    /* identical to box height, or 24px */

    /* primary_color_2 */

    color: #e68d36;
  }
  .PlanDetailContainer {
    font-size: 3.3vw !important;
    /* gap: 7vw; */
  }
  /* .PlanDetailContent {
    font-Weight: 500;
    font-size: 12px;
    color: #44576a;
  } */
  .css-i4bv87-MuiSvgIcon-root {
    font-size: 1rem !important;
  }
  .PLanDEtailMainHeading {
    margin: 4vw auto;
    display: flex;
    justify-content: center;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    color: #44576a;
  }
  .DocbannerPLanMobile {
    width: 63vw;
    margin-top: -5vw;
  }
  .DocbannerPLanMobileQurately {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 150%;
    text-align: center;
    margin-top: 2vw;
    color: var(--globalColorButton);
  }
  .DocbannerPLanMobileyear {
    font-size: 20px;
  }
  .collapsePPLan {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #6f6f6f;
    margin-top: 1vw;
    margin-bottom: 3vw;
  }
  .OpdLineFlowFirst {
    font-size: 12px;
  }
  .OpdLineFlowSecond {
    font-size: 12px;
  }
  .OpdLineFlowq {
    width: 61vw;
    display: flex;
    background: #c4dedc;

    padding-top: 0.6vw;
    padding-bottom: 0.6vw;
    margin: auto;
    border-radius: 4px;
  }
  .OurPlanCarousel2 {
    margin-top: -39vw;
  }
  .OpdLineFlowFirstheading {
    width: 30vw;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 1vw;
  }
  .BuyNowMPButtonmobile {
    width: 62vw;
    border: none;
    background: var(--globalColorHeader);
    border-radius: 12px;
    text-transform: capitalize;
    margin-top: 1vw;
    margin-bottom: 2vw;
    padding: 1vw;
    position: relative;
    top: -2vw;
    color: #ffffff;
    cursor: pointer;
    box-shadow: 0px 0px 4px 4px rgb(80 103 167 / 22%);
    font-size: 4vw;
  }

  .OurPlanContainer {
    margin-right: 3vw;
    margin-left: -2vw;
    padding-bottom: 2vw;
  }
}

.ourPlanHeading1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 2vw;
  padding-left: 6.3vw;
  line-height: 150%;
  position: absolute;
  padding-top: 6.1vw;
  color: #ffffff;
}

.ourPlanBanner {
  /* margin-top: 1em; */
  /* height: 71vw; */
}

.maskingBlock1Plan {
  height: 71vw;
  background: #1d2334;
  opacity: 0.5;
  z-index: 0;
}
.ourPlanBanner2 {
  width: 42vw;
  height: 22vw;
  margin-top: 5vw;
  margin-left: 51vw;
  position: absolute;
}

.popularPlanGrid {
  display: grid;
  grid-template-columns: 3fr 1fr;
  padding-left: 6.6vw;
  padding-right: 27.6vw;
  position: relative;
  padding-top: 12.5vw;
}

.planGrid1Heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 2vw;
  line-height: 150%;
  color: var(--globalColorHeader);
  padding-left: 3.3vw;
  padding-top: 1vw;
}
.planGrid1Heading1 {
  padding-left: 3.3vw;
  padding-top: 1vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 2vw;
  line-height: 150%;
  /* or 36px */

  /* primary_color_2 */

  color: #e68d36;
}

.planGridContent {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 17px;
  padding-top: 2vw;
  padding-bottom: 2vw;

  /* primary_color_1 */

  color: #1d2334;
}

.planGrid2 {
  background: #1d2334;
  border-bottom: 0.5px solid #e4e4e4;
  border-radius: 0px 12px 0px 0px;
}
.planGrid3 {
  background: #1d2334;
  border-bottom: 0.2px solid #e4e4e4;
  /* border-radius: 0px 12px 0px 0px; */
}
.PlanTick {
  padding-left: 3.3vw;
  /* width: 7vw;
  padding-right: 1vw; */
}

.spanContent1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  padding-left: 3.3vw;
  padding-top: 1vw;
  line-height: 17px;
  font-size: 1vw;
  /* identical to box height */

  /* Web_PC */

  color: var(--globalColorHeader);
}
.spanContent {
  padding-left: 1vw;
}

.planGrid2Content2 {
  /* padding-left: 7vw; */
  margin-top: 2vw;
  align-items: center;
  text-align: center;
  font-family: "Montserrat";
  display: flex;
  font-style: normal;
  /* width: 10vw; */
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* text-align: right; */
  color: #ffffff;
}
.planGrid2Content3 {
  padding-left: 7vw;
  margin-top: 2vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* text-align: right; */

  /* white */

  color: #ffffff;
}

.planGrid2DropDown {
  background: #ffffff;
  box-shadow: 0px 0px 2px 2px rgb(0 0 0 / 10%);
  border-radius: 6px;
  /* border: none; */
  outline: none !important;
  margin-left: 2.6vw;
  margin-top: 1.2vw;
  padding-left: 1.5vw;
  padding-right: 1.4vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
}

.planGrid11 {
  background: #fbfcff;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
  /* border-radius: 12px 12px 0px 0px; */
}

.planGrids1 {
  background: #fbfcff;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 12px 0px 0px 0px;
}
.planGrid22 {
  background: var(--globalColorHeader);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */
  cursor: pointer;
  text-align: center;
  color: #ffffff;
}

.planGridContent1 {
  padding-bottom: 1vw;
}

.planBlock3 {
  margin-left: 6.6vw;
  margin-right: 27.6vw;
  background: #fbfcff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px 0px 12px 12px;
  /* padding-top: 7.5vw; */
}

.planBlock3Heading {
  padding-left: 3.3vw;
  padding-top: 2.5vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.8vw;
  line-height: 150%;
  /* identical to box height, or 30px */

  /* primary_color_2 */

  color: #e68d36;
}

.block3planGrid {
  margin-top: 1vw;
  padding-left: 3.3vw;
  padding-right: 3.3vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2vw;
}
.block3planGrid1 {
  display: grid;
  padding-left: 3.3vw;
  padding-right: 3.3vw;
  text-align: center;
  font-size: 1vw;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2vw;
}

.planIncludes {
  width: 18vw;
  height: 7.6vw;
}

.block3Content {
  padding-left: 2vw;
  padding-right: 2vw;
  padding-top: 0.5vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 18px;
  text-align: center;

  /* primary_color_1 */

  color: #1d2334;
}

.planBlock4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 2vw;
  line-height: 150%;
  /* identical to box height, or 42px */

  text-transform: capitalize;

  /* primary_color_2 */

  color: #e68d36;
}

.planBlock5 {
  margin-top: 3vw;
  margin-left: 6.6vw;
  margin-right: 15vw;
}

.planBlock4Content {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 150%;
  margin-top: 1vw;
  /* or 24px */

  color: #44576a;
}

.planGrid5 {
  margin-top: 2vw;
  display: grid;
  gap: 10vw;
  grid-template-columns: 1fr 1fr 1fr;
  padding-right: 6.6vw;
  padding-left: 6.6vw;
  padding-bottom: 1vw;
}

.planGridBlock1Content {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 150%;
  /* identical to box height, or 21px */

  display: flex;
  align-items: center;
  text-align: center;

  color: #44576a;
  margin-top: 1vw;
}

.planGrid5Block1 {
  background: #ffffff;
  border: 1px solid #e8e9e9;
  border-radius: 12px;
  height: 8.8vw;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.planIcon {
  width: 4.7vw;
}
.iconMui {
  width: 0.5vw !important;
}
iconMuiMbl {
  width: 1vw !important;
}

.planBlock6 {
  justify-content: center;
  display: flex;
  align-items: center;
  height: 19.7vw;
  z-index: -2;
  width: 100%;
  /* position: absolute; */
  /* margin-top: -22vw; */
  flex-direction: column;
}
.popularPlanBlock5 {
  /* height: 69vw; */
}

.planBlock6Content {
  font-family: "Montserrat";
  font-style: normal;
  /* margin-top: 4vw; */
  font-weight: 400;
  font-size: 2vw;
  line-height: 150%;
  /* identical to box height, or 42px */

  text-transform: capitalize;

  /* primary_color_2 */

  color: #e68d36;
}

.planBlock6Button {
  background: var(--globalColorHeader);
  border-radius: 12px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding-left: 3.3vw;
  margin-top: 4vw;
  padding-right: 3.3vw;
  padding-top: 0.7vw;
  padding-bottom: 0.7vw;
  line-height: 150%;
  /* identical to box height, or 24px */

  text-align: center;

  color: #ffffff;
}
.maskingPlan {
  background: #1d2334;
  opacity: 0.96;
  height: 19.7vw;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.planMblBanner {
  height: 62vw;
}

.planMblBanner2 {
  height: 38.6vw;
  width: 75vw;
  margin-left: 20vw;
  margin-top: 2vw;
}

.planBannerMblHeader {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 4.8vw;
  line-height: 150%;
  /* identical to box height, or 27px */

  color: #ffffff;
  margin-left: 7vw;
  padding-top: 9vw;
}

.planGridMbl {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: 5vw;
  margin-right: 5vw;
  position: relative;
  margin-top: -22vw;

  background: #fbfcff;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 12px 12px 0px 0px;
}

.planGridBlock2 {
  background: #1d2334;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 12px 12px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid #e4e4e4;
}

.planGridMblBlock1 {
  border-bottom: 1px solid #e4e4e4;
}

.planGridBlock22 {
  background: #1d2334;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
  /* border-radius: 12px 12px 0px 0px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid #e4e4e4;
}

.planGridMblBlock1Content1 {
  font-family: "Montserrat";
  font-style: normal;
  margin-top: 3.3vw;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  /* identical to box height, or 27px */

  text-align: center;

  /* Web_PC */

  color: var(--globalColorHeader);
}

.planGridMblBlock1Content22 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 2.8vw;
  margin-top: 7vw;
  padding-bottom: 4.3vw;
  line-height: 14px;

  /* identical to box height */

  /* text-align: center; */
  /* primary_color_1 */

  color: #1d2334;
}

.planGridMblBlock1Content2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 2.8vw;
  margin-top: 7vw;
  padding-bottom: 4.3vw;
  line-height: 14px;
  display: flex;
  /* identical to box height */

  /* text-align: center; */
  /* primary_color_1 */

  color: #1d2334;
}

.planGridBlock2Button {
  border: none;
  outline: none !important;
  background: #ffffff;
  box-shadow: 0px 0px 2px 2px rgb(0 0 0 / 10%);
  border-radius: 6px;
  padding-left: 7vw;
  padding-right: 7vw;
  padding-top: 2vw;
  padding-bottom: 2vw;
}

.planGridBlock2Content1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 2.8vw;
  line-height: 14px;
  margin-top: 7vw;

  /* identical to box height */

  text-align: right;

  /* white */

  color: #ffffff;
}

.planGridBlock2Content12 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 2.8vw;
  line-height: 14px;
  margin-top: 1vw;

  /* identical to box height */

  text-align: right;

  /* white */

  color: #ffffff;
}

.planTextMbl {
  margin-left: 1vw;
}

.mblBlock2 {
  margin-left: 5vw;
  margin-right: 5vw;
}

.mblBlock2Container1 {
  background: #fbfcff;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 0px;
}

.mblBlock2Container1Heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  margin-left: 4vw;
  margin-top: 4.3vw;
  /* identical to box height, or 24px */

  /* primary_color_2 */

  color: #e68d36;
}
.gridContainer2Mbl {
  grid-column: 1 / 3;
}

.mblBlock2Container1Heading1 {
  font-family: "Montserrat";
  font-style: normal;
  margin-left: 4vw;
  margin-top: 2vw;

  font-weight: 400;
  margin-bottom: 6vw;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  /* Web_PC */

  color: var(--globalColorHeader);
}

.gridButtonMbl {
  grid-column: 1 / 3;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */

  text-align: center;
  padding-bottom: 3.8vw;
  padding-top: 3.8vw;

  color: #ffffff;
  background: var(--globalColorHeader);
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 0px;
}

.planMblCarousel {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 4.3vw;
  line-height: 150%;
  /* identical to box height, or 24px */

  /* primary_color_2 */

  color: #e68d36;
  margin-left: 5vw;
  margin-right: 5vw;
  margin-top: 4.3vw;
  margin-bottom: 4.3vw;
}

.imagePlanMblCarousel {
  padding-right: 5vw;
  padding-left: 5vw;
  /* width:50vw */
}
.planMblCarouselText {
  /* width:40vw; */
  margin-top: 2vw;

  padding-left: 2vw;
  padding-right: 2vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 15px;
  line-height: 18px;
  text-align: center;

  /* primary_color_1 */

  color: #1d2334;
}

.planBlock7 {
  margin-left: 5vw;
  margin-right: 5vw;
  margin-top: 2vw;
}
.planBlock8 {
  /* margin-left: 6vw; */
  margin-right: 6.5vw;
  margin-top: 8vw;

  background: #ffffff;
  border: 1px solid #e8e9e9;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}

.planBlock7Heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 4.8vw;
  line-height: 150%;
  /* identical to box height, or 24px */

  text-transform: capitalize;

  /* primary_color_2 */

  color: #e68d36;
}

.planBlock7Content {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 3.3vw;
  margin-top: 1vw;
  line-height: 150%;
  /* or 21px */

  color: #44576a;
}

.planBlock8Image {
  margin-top: 4.8vw;
}

.planBlock8Text {
  margin-top: 4vw;
  margin-bottom: 5vw;
  width: 60vw;
  display: flex;
  justify-content: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 3.8vw;
  line-height: 150%;
  /* identical to box height, or 21px */

  display: flex;
  align-items: center;
  text-align: center;

  color: #44576a;
}

.mblDots {
  width: 2vw;
}

.mblDots1 {
  width: 1vw;
}

.planBlock9Text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 4.8vw;
  line-height: 150%;
  /* identical to box height, or 27px */

  text-transform: capitalize;

  /* primary_color_2 */

  color: #e68d36;
}

.planBlock9Button {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 4.3vw;
  line-height: 150%;
  /* identical to box height, or 24px */

  text-align: center;
  background: var(--globalColorHeader);
  border-radius: 12px;
  color: #ffffff;
}

.planBlock9 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.planBlock9Text {
  margin-top: 12vw;
}

.planBlock9Button {
  margin-top: 7vw;
  margin-bottom: 6vw;
}

.planBlock9Button {
  padding-right: 11vw;
  padding-left: 11vw;
  padding-top: 2.4vw;
  padding-bottom: 2.4vw;
}
