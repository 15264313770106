.filtersBody{
  position: relative;
}

.filtersBody p {
  font-size: 18px;
  margin-bottom: 0.75em;
  color: #000;
  font-weight: 500;
}

.filtersBodyDiv {
  display: flex;
  align-items: flex-start;
}

.filtersBodyDiv input {
  height: 22px;
  width: 22px;
  cursor: pointer;
}

.filtersBodyDiv label {
  font-size: 1.125em;
  color: #545454;
  font-weight: 500;
  margin-left: 0.5em;
}