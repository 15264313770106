.signupMain{
  display: flex;
  height: 100vh;
}
.signupLeftBackground{
  width: 100%;
  height: 100%;
}
.signupMainLeft{
  position: relative;
  width: 50%;
}
.signupMainRight{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LogoSignUp{
  position: absolute;
  width:50%;
height:13%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.signupArea{
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.LogoAreaLOGIN_SIGNUP{
  display: flex;
  justify-content: center;
  align-items: center; 
  display: none;
}
.signupForm{
  width: 90%;
}
.signupAreaFirstHeading{
  font-family: var(--globalFont);
  font-size: var(--globalFontSize38);
  font-weight: 700;
  line-height: 46.32px;
  text-align: center;
  color: #1C71E1;

}
.signupAreaSecondHeading{
  font-family: var(--globalFont);
  font-size: var(--globalFontSize26);
  font-weight: 500;
  line-height: 31.69px;
  text-align: center;
  color: #000000;
}
.SingleRegisterForm{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.signupInputField{
  padding: 0.8em 1em 0.8em 1em;
  border: 1px solid #1C71E1;
  border-radius: 10px;
  font-family: var(--globalFont);
font-size: var(--globalFontSize18);
font-weight: 500;
line-height: 24.68px;
width: 100%;
background-color: #F1F4FF;
color:#626262;

}

.signUpBox{
  margin-top: 1em;
  width: 80%;
}
.signupInputFieldButton{
  padding: 0.8em 1em 0.8em 1em;
  background-color: #1C71E1;
  width: 80%;
  color: #fff;
  font-family: var(--globalFont);
font-size: var(--globalFontSize22);
font-weight: 600;
line-height: 30.85px;
text-align: center;
margin-top: 1em;
border-radius: 10px;
}
.signupSignInTag{
  font-family: var(--globalFont);
font-size:var(--globalFontSize16);
font-weight: 600;
line-height: 21.6px;
text-align: center;
margin-top: 1em;

}
.otpArea{
  display: flex;
  justify-content: center;
}
.otpRegisterTimer{
  margin-top: 2em;
  font-family: var(--globalFont);
font-size: var(--globalFontSize18);
font-weight: 500;
line-height: 26.82px;

}
.otpRegisterBottomDiv{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width:1000px) {
  .signupMainLeft{
    position: relative;
    width: 40%;
  }
  .signupMainRight{
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2em;
    padding-bottom: 2em;
  }
  .signupInputFieldButton{
   width: 100%;
  }
  .signUpBox{
    width: 100%;
  }
}
@media screen and (max-width:880px) {
  .signupArea{
    margin-top: 4em;
  }
  .signupMainLeft{
    display: none;
  }
  .signUpBox{
    width: 90%;
  }
  .signupInputFieldButton{
    width: 90%;
   }
  .signupMainRight{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .LogoAreaLOGIN_SIGNUP{
    padding-bottom: 2em;
    display: flex;
    width: 100%;
  }
  .LogoAreaLOGIN_SIGNUP img{
   width: 50%;
  }
  .signupAreaFirstHeading{
    font-size: var(--globalFontSize32);
  
  }
  .signupAreaSecondHeading{
    display: none;
  }
}
@media screen and (max-width:500px) {
  .signupMainLeft{
    display: none;
  }
  .signUpBox{
    width: 100%;
  }
  .signupInputFieldButton{
    width: 100%;
   }
  .signupMainRight{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
