.MyAddressIconsMainBox {
  display: flex;
  justify-content: center;
  margin-top: 3.69vh;
  background: #ffffff;
  border-radius: 16px;
  align-items: center;
  margin-right: 4vw;
}

.MyAddressIconsMainBox2 {
  margin-top: 18.69vh;

  display: flex;
  justify-content: center;
  flex-direction: column;
}

.MyAddressIconPrescription {
}

.ExtraClass {
  align-items: center;
  display: flex;
  justify-content: center;
}

.addressTextPrescription {
  font-family: var(--globalFont);
  margin-top: 1.54vh;
  font-style: normal;
  font-weight: 500;
  font-size: 0.9em;
  line-height: 150%;
  margin-bottom: 25vh;
  /* identical to box height, or 24px */
  text-align: center;
  color: #44576a;
}

.AddressButton {
  margin-top: 3.57vh;
  margin-bottom: 19vh;

  background: var(--globalColorHeader);
  text-align: center;
  border-radius: 6px;
}

.AddressButtonText {
  padding-top: 0.66vh;
  padding-bottom: 0.66vh;
  padding-right: 2.64vw;
  padding-left: 3.25vw;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  color: #ffffff;
}

.myprescriptionmaindiv {
  margin-bottom: 8vh;
}

@media screen and (max-width: 600px) {
  .MyAddressIconsMainBox {
    box-shadow: 0px 0px;
  }
  .MyAddressIconsMainBox2 {
    margin-top: 5vh;
  }

  .addressTextPrescription {
    margin-bottom: 5vh;
  }
}
