.corporateWellnessMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 90vh;
}
.blueShade {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.youngPeople {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -2;
}
.corporateWellnessMain :nth-child(3) {
  font-family: var(--globalFont);
  font-size: 4em;
  font-weight: 700;
  text-align: center;
  color: #fff;
}
.corporateWellnessMain :nth-child(4) {
  font-family: var(--globalFont);
  font-size: 2em;
  font-weight: 400;
  text-align: center;
  color: #fff;
}
.corporateWellnessMain button {
  font-family: var(--globalFont);
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  background: #d9d9d9;
  padding: 1em;
  border-radius: 14px;
  margin-top: 2.5em;
  margin-bottom: 10em;
}

@media screen and (max-width: 800px) {
  .corporateWellnessMain {
    height: 60vh;
  }
  .corporateWellnessMain :nth-child(3) {
    font-size: 2em;
  }
  .corporateWellnessMain :nth-child(4) {
    font-size: 1em;
  }
  .corporateWellnessMain button {
    font-size: 1em;
    padding: 0.8em;
  }
}
