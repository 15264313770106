.SelectMemberDiv {
  margin-left: 6.32vw;
  margin-right: 7vw;
  background: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 12px;
}

.SelectMemberTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5em;
  margin-left: 2.7vw;
  margin-right: 3.75vw;
}
.SelectMemberHead {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  text-transform: capitalize;
  color: var(--globalColorHeader);
}

.SelectMemberBtn {
  display: flex;
  align-items: center;
  background: var(--globalColorHeader);
  border-radius: 12px;
  color: white;
  padding: 1.1875em 1em;
}

.SelectMemberBody {
  display: flex;
  margin-top: 3.1875em;
  padding-left: 2.7vw;
  padding-right: 3.75vw;
}

.SelectMemberBodyLeft {
  width: 50%;
  padding-inline-end: 11vw;
}

.SelectMemberBodyRight {
  width: 50%;
}

.SelectMemberRelation {
  display: flex;
  flex-direction: column;
}

.SelectMemberRelationCard {
  margin-bottom: 2em;
  padding: 1.1875em 3.125vw 1.75em 3.195vw;
  background: #ffff;
  border: 1px solid #f7f7f7;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}

.SelectMemberRelationCardOdd {
  margin-bottom: 2em;
  padding: 1.1875em 3.125vw 1.75em 3.195vw;
  background: #f8f8f8;
  border: 1px solid #f7f7f7;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}

.SelectMemberRelationCardTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SelectMemberRelationCardName {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #1d2334;
}

.SelectMemberRelationCardRelation {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #1d2334;
  margin-top: 1em;
}

.SelectMemberRelationCardNumber {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #1d2334;
  margin-top: 1em;
}

.SelectMemberRelationMobile {
  display: flex;
  flex-direction: column;
  margin-left: 5vw;
  margin-right: 5vw;
  margin-top: 2vh;
}

.selectAddressBlock {
  margin-top: 2vh;
  margin-left: 5vw;
}
