.subscriptionBody {
  margin-left: 2em;
  margin-top: 2.5em;
  margin-right: 2em;
}

.subscriptionHead {
  font-size: 3em;
  font-weight: 600;
}

.subscriptionDesc {
  font-size: 1.25em;
  font-weight: 500;
  margin-top: 1em;
}

.popularPlanBody {
  margin-top: 2em;
}

.popularCardWrapper {
  margin-bottom: 1em;
  background: #f8f8f8;
  border-radius: 10px;
  border: 1px solid #cfcfcf;
  cursor: pointer;
}

.popularCardWrapper:hover{
  transform: scale(1.01);
}

.popularPlanCard {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  padding: 2em 1.3vw;
  gap: 1vw;
  font-size: 1.25em;
  font-weight: 500;
}

.popularPlanCardBody {
  display: grid;
  grid-template-columns: 35vw 10vw 10vw 30vw;
}

.popularPlanCardRelation {
  word-wrap: wrap;
}

.popularPlanCardInfo {
  margin-top: 2.5em;
  padding-bottom: 2.5em;
  grid-gap: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-left: 8vw;
}

.popularPlanCardInfoTitle {
  font-size: 1.25em;
  font-weight: 500;
}

.popularPlanCardInfoAvailable {
  font-size: 1em;
  font-weight: 500;
  color: #1c71e1;
}

.OurPLanYearlyPricedel {
  color: red;
}

.popularPlanCardInfoButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.popularPlanCardInfoButton button {
  padding: 1.37em 8em;
  background: #1c71e1;
  color: white;
  margin-bottom: 2em;
  border-radius: 10px;
}

@media screen and (max-width: 879px) {
  .popularPlanCardBody {
    grid-template-columns: 2fr 2fr;
    row-gap: 10px;
  }

  .popularPlanCardInfo {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 480px) {
  .subscriptionHead {
    font-size: 1.5em;
  }

  .subscriptionDesc {
    font-size: 0.75em;
  }

  .popularPlanCardInfoTitle {
    font-size: 0.75em;
  }

  .popularPlanCardBody {
    grid-template-columns: 1fr;
    row-gap: 10px;
  }

  .popularPlanCard {
    font-size: 1em;
    align-items: center;
  }

  .popularPlanCardInfo {
    grid-template-columns: 1fr;
  }

  .popularPlanCardInfoButton button {
    padding: 1.37em 2em;
  }
}
