.myAddressMainDiv {
  margin-top: 3.23vh;
  margin-left: 6vw;
  margin-right: 6vw;
}

.templateNamesPres1 {
  display: flex;
  margin-top: 1.75vh;
  margin-left: 2vw;
  padding-top: 2.4375em;
}

.myAddresstext {
  margin-top: 1.31vh;
  font-style: normal;
  font-weight: 500;
  font-size: 1.7vw;
  line-height: 150%;
  color: var(--globalColorHeader);
}

.myAddressalltexts {
  background: #ffffff;
  margin-top: 0.44vh;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding-bottom: 3vw;
  margin-right: 2vw;
  min-height: 50vh}

.addressBoxTexts {
  width: 26vw;
  margin-left: 1.51vw;

  background: linear-gradient(
    98.59deg,
    #f8f8f8 0%,
    rgba(248, 248, 248, 0) 100%
  );
  border: 1px solid #f7f7f7;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  margin-top: 1.75vh;
  margin-bottom: 1.75em;
  display: flex;
  flex-direction: column;
  padding: 0.875em 4.5vw 0.5em 1.3vw;
}
.addressBoxTextsStarts {
  display: flex;
  flex-direction: row-reverse;
}

.addressBoxTitle {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #38466c;
}

.addressBoxBodyText {
  margin-top: 0.625em;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #44576a;
}

.addressBoxPhone {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #44576a;
}
.NoAddressAvilable{
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: var(--globalFontSize16);
  color: var(--globalColorHeader);
  line-height: 150%;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .addressBoxTexts {
    width: 70vw;
    padding-left: 5vw;
  }
  .myAddresstext {
    margin-top: 1.31vh;
    font-family: var(--globalFont);
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    color:var(--globalColorHeader);
    ;
  }
}
