
.RentalSearch {
  margin-top: 1.6vw;
  background: #ffffff;
  width: 30.8vw;
  height: 2.8vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #a4a4a4;
  margin-left: 44.8vw;
  border-radius: 12px 12px 12px 12px;
}

.RentalSearch input {
  border: none;
  /* height: 2.855em; */
  width: 28vw;
  font-size: 1vw;
  padding-left: 0.4375em;
}

textarea:focus,
input:focus {
  outline: none;
}

.RentalSearch img {
  margin-left: 3vw;
  margin-right: 0vw;
  height: 0.82em;
  width: 0.82em;
}

.RentalSearchBtn {
  background-color: var(--globalColorHeader);
  border-radius: 0px 0.6vw 0.6vw 0px;
  padding-left: 0.2vw;
  font-size: 1.5vw;
  cursor: pointer;
  margin-left: 1vw;
  padding-top: 0.25vw;
  padding-right: 0.2vw;
  width: 10vw;
  padding-bottom: 0.28vw;
  color: white;
  border: none;
  outline: none !important;
}





.RentalwrapperBlockOTwo {
  height: 9.8vw;
  width: 28vw;
  border-radius: 0.6vw;
  background: #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.RentalGrid {
  display: flex;
  margin-top: 1.4vw;
  height: 3.8vw;
  justify-content: center;
}










.RentalHeading {
  margin-top: 4vw;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1.6vw;
  line-height: 150%;
  /* identical to box height, or 36px */

  text-align: center;

  color: #e68d36;
}

.RentalHeadingTwo {
  font-family: var(--globalFont);
  margin-top: 2vw;
  font-style: normal;

  font-weight: 500;
  font-size: 2vw;
  line-height: 150%;

  text-align: center;
  text-transform: capitalize;

  color: #44576a;
}

.RentalCarouselHead {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 1.85em;
  margin-right: 1.69em;
}

.RentalImagee {
  height: 80%;
  width: 70%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.carouselImageRental {
  width: 11vw;
  height: 9vw;
}

.RentalcarouselsName {
  font-size: 0.9vw;
  margin-top: 0.4em;
  height: 10%;
  padding-top: 0.2em;
}
.RentalcarouselPrice {
  height: 10%;
  font-size: 0.9vw;
}




/* New Css */

.RentalBody1 {
  display: flex;
  background-color: #E1EEFF;
  align-items: center;
  padding-top: 1em;
  padding-bottom: 1em;
}
.RentalHeading1 {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 600;
  font-size: var(--globalFontSize26);
  line-height: 150%;
  color: var(--globalColorHeader);
  margin-left: 4vw;
  margin-top: auto;
  margin-bottom: auto;
}
.Rentalwrapper{
  background-color: #E1EEFF;
  padding-top: 2em;
  padding-bottom: 2em;
display: flex;
justify-content: space-around;
align-items: center;
}
.RentalwrapperBlockOne {
  width: 30%;
  border-radius: 0.6vw;
  background: #ffffff;
  box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}
.warpperInnerBlock {
  margin-top: 2vw;
  display: flex;
  justify-content: center;
}
.RentalWrapperHeading {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size:var(--globalFontSize16);
  line-height: 21px;
  text-transform: capitalize;
  color: var(--globalColorHeader);
  margin-top: auto;
  margin-bottom: auto;
}
.RentalPageHeadinMain{
  display: flex;
  justify-content: space-between;
  padding-left: 2em;
  padding-right: 2em;
  align-items: center;
}
.RentalPageStep1 {
  display: flex;
  flex-direction: column;
}
.bulleyeRental{
  width: 1.5em;
  height: 1.5em;
}
.RentalText {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size:var(--globalFontSize12);
  color: black;
  padding-left: 2.2em;
  padding-top: 1em;
}
.RentalImage {
  width: 5em;
  padding-bottom: 2vw;
}
.WhyRentInnerBody{
  padding-top: 1em;
  padding-bottom: 1em;
}
.WhyRentInnerBody :nth-child(1){
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 600;
  font-size: var(--globalFontSize28);
  color: var(--globalColorHeader);
}
.WhyRentInnerBody :nth-child(2){
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: var(--globalFontSize24);
}
@media screen and (max-width:700px) {
  .Rentalwrapper{
    padding-top: 2em;
    padding-bottom: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  }
  .RentalwrapperBlockOne {
    min-height: 12em;
    width: 70%;
    border-radius: 0.6vw;
    background: #ffffff;
    box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.1);
    margin-top: 2em;
  }
  .warpperInnerBlock {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .RentalPageHeadinMain{
    padding-left: 1em;
    padding-right: 1em;
  }
  .RentalText {
    padding-left: 1em;
    padding-right: 1em;
    text-align: center;
  }
}