.frontPage {
  background-color: #e1eeff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
}

.frontPageUpper {
  width: 95%;
  float: right;
  padding-top: 5%;
  position: relative;
}

.halfShade {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 60%;
}

.frontPageUpper :nth-child(1) {
  font-family: var(--globalfont);
  font-size: 3em;
  font-weight: 700;
  line-height: 1em;
  color: #1c71e1;
}

.frontPageUpper :nth-child(2) {
  font-family: var(--globalfont);
  font-size: 3em;
  font-weight: 700;
  position: relative;
  margin-bottom: 0px;
}

.frontPageUpper :nth-child(3) {
  width: 18vw;
  position: absolute;
  margin-bottom: 366px;
}

.frontPageUpper :nth-child(4) {
  font-family: var(--globalfont);
  font-size: 3em;
  font-weight: 700;
}

.frontPageUpper :nth-child(5) {
  font-family: var(--globalfont);
  font-size: var(--globalFontSize18);
  font-weight: 300;
  line-height: 1.2em;
  margin-top: 3em;
}

.frontPageUpper :nth-child(6) {
  background-color: #1c71e1;
  border-radius: 8px;
  padding: 1.3em 3.2em 1.3em 3.2em;
  margin-top: 2em;
  color: #fff;
  font-family: var(--globalfont);
  font-size: var(--globalFontSize14);
  font-weight: 600;
  line-height: 17.07px;
  text-align: left;
  margin-bottom: 6.1em;
}

.frontPageLower {
  background-color: #0360d9;
  width: 100%;
  position: relative;
  padding-top: 1.7em;
  padding-bottom: 1.7em;
}

.ladyDoctor {
  position: absolute;
  bottom: 0em;
  right: 0em;
  /* left: 50%; */
}

.frontPageLowerButtonArea {
  display: flex;
  width: 95%;
  float: right;
}

.frontPageLowerButtonAreaDiv {
  border-right: 1px solid #fff;
  padding-right: 1em;
}

.frontPageLowerButtonAreaDiv2 {
  border-right: 1px solid #fff;
  padding-left: 1em;
  padding-right: 1em;
}

.frontPageLowerButtonAreaDiv3 {
  padding-left: 1em;
  padding-right: 1em;
}

.frontPageLowerButtonAreaButton {
  border: 1px solid #ffffff;
  padding: 1.06em 1.8em 1.06em 1.8em;
  color: #fff;
  background-color: #0360d9;
  border-radius: 8px;
  font-family: var(--globalfont);
  font-size: 1em;
  font-weight: 400;
  text-align: left;
}

@media screen and (min-width : 1700px) {
  .ladyDoctor {
    height: 45em;
    right: 0em;
  }

  .halfShade {
    height: 70%;
  }
}

@media screen and (max-width : 1700px) {
  .ladyDoctor {
    height: 45em;
    right: 0em;
  }

  .halfShade {
    height: 65%;
  }

  .frontPageUpper :nth-child(5) {
    font-size: 1em;
  }
}

@media screen and (max-width : 1400px) {
  .ladyDoctor {
    height: 40em;
    right: 0em;
  }

  .halfShade {
    height: 55%;
  }

  .frontPageUpper :nth-child(5) {
    font-size: 1em;
  }
}

@media screen and (max-width: 1250px) {
  .ladyDoctor {
    height: 36em;
    right: 0em;
  }

  .halfShade {
    height: 50%;
  }

  .frontPageUpper :nth-child(5) {
    font-size: 1em;
  }
}

@media screen and (max-width: 1150px) {
  .ladyDoctor {
    display: none;
  }

  .halfShade {
    display: none;
  }

  .frontPageUpper img {
    display: none;
  }
}

@media screen and (max-width : 400px) {
  .frontPageLowerButtonAreaButton {
    font-size: 0.5em;
    padding: 0.8em;
  }
}

@media screen and (max-width : 680px) {
  .frontPageLowerButtonAreaButton {
    font-size: 0.7em;
  }
}

@media screen and (max-width: 900px) {
  .frontPageUpper :nth-child(1) {
    font-size: 3.4em;
  }

  .frontPageUpper :nth-child(2) {
    font-size: 2.3em;
    margin-bottom: 0px;

  }

  .frontPageUpper :nth-child(3) {
    margin-top: -2em;
    width: 22vw;
  }

  .frontPageUpper :nth-child(4) {
    font-size: 2.3em;

  }

  .frontPageUpper :nth-child(5) {
    font-size: 1em;
    line-height: 1.5em;
  }
}