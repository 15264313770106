.linkExpiredmainWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 2vh; */
  background-color: #e7eaed;
}
.linkExpiredHeader {
  margin-top: 5.625em;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.75em;
  line-height: 1.5em;
  color: #44576a;
}
.linkExpiredBottom {
  margin-top: 2.58em;
  margin-bottom: 7.625em;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.25em;
  line-height: 1.5em;
  color: #44576a;
}
