.pharmacyTextsPhoto {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e2eefe;
  padding: 2em;
  gap: 0.75em;
}

.pharmacyText {
  color: #000;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.pharmacyImage {
  width: 30em;
}

.pharmacyText2 {
  margin-left: 2em;
  margin-right: 2em;
  margin-top: 2em;
  margin-bottom: 2em;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 500;
}

.textAndCard {
  margin-top: 2em;
}

.chooseSubscription {
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
}

.cardContentDiv {
  border-radius: 12px;
  border: 1px solid black;
}

.allCards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  margin-left: 2em;
  margin-right: 2em;
  margin-top: 1em;
}

.cardText {
  margin-top: 0.5em;
  text-align: center;
  font-size: 2em;
  font-style: normal;
  font-weight: 600;
  color: var(--globalColorHeader);
}

.subCardText {
  margin-top: 1em;
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 1em;
  color: #38466c;
  text-align: center;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

@media screen and (max-width: 900px) {
  .pharmacyImage {
    display: none;
  }
  .pharmacyText {
    font-weight: 700;
    text-align: center;
  }
}

@media screen and (max-width: 550px) {
  .allCards {
    grid-template-columns: 1fr;
  }
  .pharmacyText {
    font-size: 1.5em;
    font-weight: 700;
    text-align: center;
  }
}
