.bannerContainer1 {
  padding-left: 3vw;
  padding-right: 3vw;
}

.bannerHeadingImage2 {
  width: 100vw;
  margin-top: 3vw;
  height: 18em;
}

.bannerGridBlock {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 3vw;
  height: 100%;
  align-items: center;
  padding-left: 2vw;
  padding-right: 2vw;
}

.bannerHeading {
  margin-top: 3vw;
  font-family: var(--globalFont);
  font-weight: 500;
  font-size: 1.5em;
  color: var(--globalColorButton);
}

.bannerGridContainer {
  display: flex;
  align-items: center;
  height: 9vw;
  text-align: center;
  flex-direction: column;
  background: #1c71e1e0;
  border: 2px solid #b1d3ff;
  border-radius: 10px;
}

.bannerContent1 {
  margin-top: 0.5em;
  font-family: "Montserrat";
  font-size: 1.25em;
  line-height: 150%;
  color: black;
  margin-right: 5vw;
}

.bannerGridContent2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.2vw;
  line-height: 1vw;
  color: #ffffff;
}

.networkBannerButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
}

.bannerButton {
  padding-right: 4.77vw;
  padding-left: 4.77vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
  font-family: "Montserrat";
  font-size: 1vw;
  background: #1c71e1;
  border-radius: 10px;
  color: white;
  font-size: 1.25em;
  outline: none !important;
}

.bannerGridContent1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 2.9vw;
  margin-top: 0.95vw;
  line-height: 150%;
  color: #ffffff;
}

@media screen and (max-width: 879px) {
  .bannerGridBlock {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1px;
  }

  .bannerGridContainer {
    height: 7em;
    justify-content: center;
  }

  .bannerGridContent1 {
    font-size: 2em;
  }

  .bannerGridContent2 {
    font-size: 1em;
  }
}

@media screen and (max-width: 480px) {
  .bannerHeadingImage2 {
    height: 35em;
  }

  .bannerGridBlock {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1px;
  }
}
