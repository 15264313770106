.productHub {
  margin: 2.5em 5% 2.5em 5%;
}

.productHubUpper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.productHubTitle {
  font-weight: bold;
  font-size: var(--globalFontSize40);
}

.productHubUpper :nth-child(2) {
  margin-right: 5%;
  margin-top: auto;
  margin-bottom: auto;
  font-family: var(--globalFont);
  font-size: var(--globalFontSize16);
  font-weight: 500;
  line-height: 36px;
  color: #1c71e1;
  cursor: pointer;
}

.productHubSubTitleHead {
  display: flex;
  gap: 1.3vw;
  align-items: center;
  margin-top: 2.5em;
  margin-bottom: 2.5em;
}

.productHubSubTitle {
  background-color: #fff;
  color: #bdbdbd;
  border: 1px solid #bdbdbd;
  border-radius: 16px;
  padding: 1em 2vw;
  font-size: 16px;
}

.productHubSubTitle.clicked {
  background-color: #1c71e1;
  color: #fff;
}

.productHubBody {
  padding: 0;
}

@media screen and (max-width: 500px) {
  .productHubUpper {
    flex-direction: column;
    align-items: flex-end;
    gap: 6px;
  }
  .productHubTitle {
    width: 100%;
  }
}