.AddFamilyMemberCardsWrapper {
  padding-top: 4vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 3vw;
}

.AddFamilyMemberWrapper {
  padding: 5vw 8vw 5vw 8vw;
  background-image: url("../../../assets/Package/package_bg.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.AddFamilyMemberSection1 {
  background: #ffffff;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 2vw;
}

.AddFamilyMemberHeader {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1.7vw;
  color: var(--globalColorHeader);
  margin-bottom: 4vw;
}

.AddFamilyMemberInputRow1 {
  display: grid;
  grid-template-columns:
    1fr 2fr
    1fr 2fr;
  gap: 3vw;
  margin-top: 3vw;
  align-items: baseline;
}
.AddFamilyMemberInputDiv2 {
  display: flex;
  margin-left: 4vw;
}
.AddFamilyMemberInputDiv {
  display: flex;
}

.AddFamilyMemberInputDiv3 {
  display: flex;
  margin-top: 1vw;
}

.AddFamilyMemberInputName {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 150%;
  color: #44576a;
}

.AddFamilyMemberCards {
  background: #f8f8f8;
  border: 1px solid #f7f7f7;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  padding: 1vw;
  cursor: pointer;
}

.AddFamilyMemberTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.2vw;
  line-height: 150%;
  color: var(--globalColorButton);
  text-transform: capitalize;
  margin-bottom: 1.3vw;
}

.AddFamilyMemberDescription {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1vw;
  margin-bottom: 1vw;
  color: #44576a;
}

.EllipsisFR {
  display: flex;
  justify-content: flex-end;
}

.AddFamilyMemberInputField {
  background: #f3f0f0;
  border: 1px solid #e7e5e5;
  border-radius: 6px;
  padding: 0.3vw 1vw 0.3vw 1vw;
  font-size: 1vw;
}
.AddFamilyMemberInputFieldD {
  background: #f3f0f0;
  border: 1px solid #e7e5e5;
  border-radius: 6px;
  padding: 0.3vw 1vw 0.3vw 1vw;
  font-size: 1vw;
  width: 17.5vw;
}

.submitButtonAddMember {
  background: var(--globalColorHeader);
  border-radius: 6px;
  padding: 0.3vw 1vw 0.3vw 1vw;
  color: #ffffff;
  border: none;
  outline: none !important;
}

.submitButtonAddMemberWrapper {
  display: flex;
  justify-content: flex-end;
  padding-right: 5vw;
  padding-bottom: 1vw;
}

.AddFamilyMemberError {
  color: red;
  font-size: 1vw;
  margin-top: 0.3vw;
  /* margin-left: 1vw; */
}

@media screen and (max-width: 1024px) {
  .AddFamilyMemberHeader {
    font-size: 2vw;
  }

  .AddFamilyMemberInputName {
    font-size: 1.5vw;
  }

  .AddFamilyMemberInputField {
    font-size: 1.5vw;
  }
  .AddFamilyMemberInputFieldD {
    font-size: 1.5vw;
    width: 23vw;
  }
}

@media screen and (max-width: 768px) {
  .AddFamilyMemberCardsWrapper {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .AddFamilyMemberTitle {
    font-size: 1.8vw;
  }
  .AddFamilyMemberDescription {
    font-size: 1.5vw;
  }
}

@media screen and (max-width: 425px) {
  .AddFamilyMemberWrapper {
    padding: 5vw 2vw;
  }
  .AddFamilyMemberHeader {
    font-size: 4vw;
  }
  .AddFamilyMemberInputName {
    font-size: 2.5vw;
  }
  .AddFamilyMemberInputField {
    font-size: 2vw;
  }
  .AddFamilyMemberCardsWrapper {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .AddFamilyMemberTitle {
    font-size: 2.2vw;
  }
  .AddFamilyMemberDescription {
    font-size: 2.2vw;
  }
  .AddFamilyMemberInputDiv2 {
    margin-left: 0;
  }
  .AddFamilyMemberInputRow1 {
    grid-template-columns: 1fr 2fr;
    gap: 10px;
  }
  .submitButtonAddMember {
    font-size: 2.5vw;
  }
  .AddFamilyMemberInputFieldD {
    font-size: 2vw;
    width: auto;
  }
}

@media screen and (max-width: 425px) {
  .AddFamilyMemberCardsWrapper {
    grid-template-columns: 1fr 1fr;
  }
}
