.PlanLanding {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 65px;
}
.PlanLandingHeading {
  font-family: var(--globalFont);
  font-size: var(--globalFontSize60);
  font-weight: 700;
  line-height: 65.1px;
  letter-spacing: -2.9553799629211426px;
  text-align: left;
  margin-bottom: 46px;
}

.PlanLandingUpper {
  margin-left: 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2em;
  margin-left: 5%;
}
.PlanLandingUpper :nth-child(1) {
  margin-top: auto;
  margin-bottom: auto;
}
.PlanLandingUpper :nth-child(2) {
  margin-right: 5%;
  margin-top: auto;
  margin-bottom: auto;
  font-family: var(--globalFont);
  font-size: var(--globalFontSize16);
  font-weight: 500;
  line-height: 36px;
  color: #1c71e1;
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .PlanLandingUpper {
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  }
  .PlanLandingHeading {
    margin-bottom: 0.6em;
  }
}

@media screen and (max-width: 400px) {
  .PlanLandingHeading {
    font-size: 2em;
    line-height: normal;
  }
}