.MyPackageBookingMain {
  margin-top: 2em;
}

.MyPackageBookingMainChild1 {
  margin-left: 5.6em;
  margin-right: 5.6em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.MyPackageBookingMainChild1 select {
  height: 2em;
  width: 25%;
  background: #f9f9f9;
  border: 1px solid #44576a;
  border-radius: 8px;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  text-align: left;
  color: #44576a;
  padding-left: 1em;
}

.planserviceName {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;

  color: #e68d36;
}

.MyPlanGrid3 {
  display: grid;
  grid-gap: 9vw;
  margin-bottom: 2vw;
  margin-top: 2vw;
  grid-template-columns: 1fr 1fr 1fr;
}

.MyPlanGridBlock {
  background: #ffffff;
  box-shadow: 0px 0px 3px 3px rgb(0 0 0 / 5%);
  border-radius: 6px;
  /* width: 26%; */
}

.MyPackageBookingMainChild1 p {
  /* padding: 1em 1em; */
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1.5em;
  line-height: 150%;
  color: var(--globalColorHeader);
}

.MyPackageBookingMainChild2 {
  margin-left: 5.6em;
  margin-right: 5.6em;
  background: #ffffff;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding-top: 1em;
  margin-bottom: 5em;
}

.MyPackageBookingMainChild2Subs {
  margin-left: 5.6em;
  margin-right: 5.6em;
  background: #ffffff;
  border-radius: 12px;
  margin-bottom: 5em;
}

.MyPackageBookingMainChild2Divs {
  margin-left: 1.5em;
  margin-right: 1.5em;
  border-bottom: 1px solid #dfe2e4;
  margin-top: 2em;
}

.MyPackageBookingMainChild2DivsSubs {
  padding-left: 1.5em;
  padding-right: 1.5em;
  margin-top: 2em;
  background: #ffffff;
  border: 1px solid #dfdfdf;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding-top: 2em;
  padding-bottom: 1em;
}

.MyPackageBookingMainChild2DivsUpper {
  display: flex;
  position: relative;
  justify-content: flex-start;
}

.MyPackageBookingMainChild2DivsUpperChild1 {
  width: 15%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.MyPackageBookingMainChild2DivsUpperChild1 :nth-child(1) {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  color: #38466c;
  text-wrap: nowrap;
}

.MyPackageBookingMainChild2DivsUpperChild1 :nth-child(2) {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 17px;
  color: #44576a;

  padding-top: 1em;
}

.MyPackageBookingMainChild2DivsUpperChild4 {
  /* width: 30%; */
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  height: 100%;
  justify-content: space-between;
}

.MyPackageBookingMainChild2DivsUpperChild4ss {
  width: 30%;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  height: 100%;
  justify-content: space-between;
}

.MyPackageBookingbutton {
  display: flex;
  /* flex-direction: row; */
  justify-content: flex-end;
  padding-top: 4vw;

  padding-right: 4vw;
}

.MyPackageBookingViewPackageBtn {
  background: var(--globalColorHeader);
  border-radius: 6px;
  color: white;
  font-size: 1.17vw;
  padding-right: 3vw;
  padding-left: 3vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  border: none;
  outline: none !important;
  cursor: pointer;
}

.MyPackageBookingInvoice {
  /* border: 1px solid #c0c0c0; */
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3em;
  padding-bottom: 2em;
}

.MyPackageBookingMainChild2DivsUpperChild4Para {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  margin-right: 1em;
  line-height: 14px;
  color: #38466c;
}

.MyPackageBookingMainChild2DivsLowerChild1 {
  display: flex;
  width: 37%;
}

.YuvaHealthCard {
  width: 100%;
  height: 100%;
}

.planMemberAddMember {
  font-family: var(--globalFont);
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 150%;
  text-align: center;
  padding: 1vw;
  width: 37vw;
  color: #ffffff;
  background: var(--globalColorHeader);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5em;
  gap: 10px;
}
.planMemberAddMember p {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0.5em;
}
.noMember {
  display: flex;
  justify-content: center;
  padding: 3vw;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
}
.planMemberLockedButton {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 150%;
  text-align: center;
  padding: 1em 3em 1em 3em;
  color: #ffffff;
  background: var(--globalColorHeader);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.planMemberLockedButton p {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1em;
}
.MyPackageBookingMainChild2DivsLowerChild1_1 {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 12px;
}

.MyPackageBookingMainChild2DivsLower {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3em;
  margin-bottom: 2em;
}

.MyProgramMainChild2DivsLower {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -2.6em;
  margin-bottom: 1em;
}

.MyPackageBookingMainChild2DivsLowerChild1_1 img {
  padding-top: 0.8em;
  padding-bottom: 1em;
}

.MyPackageBookingMainChild2DivsLowerChild1_2 {
  margin-top: 1vw;
  /* padding-left: 2em; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 1rem;
}

.MyPackageBookingMainChild2DivsLowerChild1_2 :nth-child(1) {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 150%;
  color: #44576a;
}
.planLockIcon {
  width: 1em;
  height: 1em;
}
.MyPackageBookingMainChild2DivsLowerChild1_2 :nth-child(2) {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 0.8em;
  color: #38466c;
}

.MyPackageBookingMainChild2DivsLowerChild2 {
  background: var(--globalColorHeader);
  border-radius: 6px;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 150%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 3.3em 0.5em 3.3em;
  border: hidden;
}

.MyPackageBookingMainChild2DivsUpperChild4AnchorDivss {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.MyPackageBookingMainChild2DivsUpperChild4Anchor {
  width: 0%;
  /* border-right: 1px solid #44576a; */
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 17px;
  color: #44576a;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.MyPackageBookingMainChild2DivsUpperChild4AnchorTests {
  width: 33%;
  /* border-right: 1px solid #44576A; */
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 17px;
  color: #44576a;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.MyPackageBookingMainChild2DivsUpperChild4Anchorlast {
  width: 100%;

  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 17px;
  color: #44576a;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  padding-right: 1em;
}

.MyPackageBookingMainChild2DivsUpperChild4AnchorTestslast {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  margin-right: 2.7em;
  font-size: 0.8em;
  line-height: 17px;
  color: #44576a;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.unpaid {
  background: #d64d66;
  border-radius: 4px;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 0.5em;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  padding-left: 0.7em;
  padding-right: 0.7em;
}

.paid {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 0.5em;
  line-height: 150%;
  text-align: center;
  background: #388bb9;
  border-radius: 4px;
  color: #ffffff;
  padding-left: 0.7em;
  padding-right: 0.7em;
}

.plzloginFirst {
  border: 1px solid #e68d36;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 2em;
  line-height: 150%;
  color: #e68d36;
  border-radius: 16px;
  margin-left: 2em;
  margin-right: 2em;
  margin-bottom: 3em;
  text-align: center;
  margin-top: 2em;
}

.plzloginFirstMobile {
  border: 1px solid var(--globalColorHeader);
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 2em;
  line-height: 150%;
  color: var(--globalColorHeader);
  border-radius: 16px;
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 3em;
  text-align: center;
  margin-top: 2em;
}

.MyPackageBookingMainChild2DivsLower2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10%;
  row-gap: 2em;
  padding-bottom: 2em;
  margin-top: 2.2em;
}

.viewAllBookingLink {
  text-align: right;
  margin-right: 5.68em;
  margin-bottom: 5em;
  margin-top: 1em;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #44576a;
}

.MyPackageBookingMainChild2DivsLower2Items {
  background: #ffffff;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  /* width: 26%; */
}

.MyPackageBookingMainChild2DivsLower2ItemsTests {
  background: #ffffff;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  width: 26%;
  position: relative;
}

.MyPackageBookingMainChild2DivsLower2Upper {
  display: flex;
}

.MyPackageBookingMainChild2DivsLower2UpperChild1 {
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.4vw;
}

.MyPackageBookingMainChild2DivsLower2UpperChild12 {
  /* width: 25%; */
  height: 100%;
  display: flex;
  margin-left: 1vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  /* justify-content: center; */
  align-items: center;
}

.MyPackageBookingMainChild2DivsLower2UpperChild2 {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  position: relative;
  /* height: 8vw; */
}

.MyPackageBookingMainChild2DivsLower2UpperChild2 :nth-child(1) {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 150%;
  color: #2d354e;
  padding-top: 1.2em;
  padding-left: 1em;
}

.MyPackageBookingMainChild2DivsLower2UpperChild2 :nth-child(2) {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 0.7em;
  line-height: 150%;
  color: black;
  padding-left: 1em;
  padding-top: 0.5em;
}

.MyPackageBookingMainChild2DivsLower2Lower {
  background: var(--globalColorHeader);
  border-radius: 6px;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  padding-top: 0.9em;
  padding-bottom: 0.9em;
  margin-top: 1em;
  cursor: pointer;
}

.MyPackageBookingMainChild2DivsLower2LowerTests {
  position: absolute;
  bottom: 0;
  background: var(--globalColorHeader);
  border-radius: 6px;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  padding-top: 0.9em;
  padding-bottom: 0.9em;
  margin-top: 1em;
  width: 100%;
  cursor: pointer;
}

.subsOpener {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1vw;
}

.subsOpener img {
  cursor: pointer;
}

.subsOpener1 {
  border: 1px solid #dfdfdf;
  width: 45%;
}

.subsOpener2 {
  border: 1px solid #dfdfdf;
  width: 45%;
}

.subsCoupon {
  background: #eff4f8;
  border-radius: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0.8em 1.1em 0.8em 1.1em;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.6em;
  line-height: 150%;
  color: #44576a;
  margin-bottom: 2em;
}

.lockMessage {
  background: #e1eeff;
  border-radius: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 1em 1.4em 1em 1.4em;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1vw;
  line-height: 150%;
  color: var(--globalColorHeader);
  margin-bottom: 2.5vw;
  margin-top: 2.5vw;
}

.MyPackageBookingMainChild2DivsLower2UpperChild2 select {
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 90%;
  padding-bottom: 1em;
  padding-top: 1em;
  margin-top: 0.5em;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 0.7em;
  line-height: 150%;
  color: #000000;
  position: absolute;
  top: 0;
}

.subsTotalAmount {
  text-decoration-line: line-through;
}

.expire {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  margin-top: -0.2vw;
  line-height: 150%;
  color: red;
}

.orderPageHeaderMobile {
  margin-left: 1.31em;
  margin-right: 1.31em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orderPageHeaderMobile p {
  width: 50%;
  margin-top: auto;
  margin-bottom: auto;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1.2em;
  line-height: 150%;
  color: var(--globalColorMainHeading);
}

.orderPageHeaderMobile div {
  width: 50%;
  border: 1px solid #dfe2e4;
}

.listOfOrders {
  margin-left: 1.31em;
  margin-right: 1.31em;

  margin-top: 2em;
}

.listOfOrdersItems {
  background: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 0px 0px 12px 12px;
  display: flex;
  flex-direction: column;
  padding-left: 1.2em;
  padding-right: 1.2em;
  padding-bottom: 1.1em;
  /* margin-top: 1em; */
  margin-bottom: 2em;
}

.listOfOrdersItemsUpper {
  display: flex;
  /* height: 20%; */
}

.listOfOrdersItemsLower {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.7em;
  position: relative;
}

.arrowImageorder {
  position: absolute;
  left: 95%;
}

.listOfOrdersItemsLowerImg {
  width: 30%;
  background: #ffffff;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listOfOrdersItemsLowerImg img {
  padding: 0.9em 1.6em 0.9em 1.6em;
}

.listOfOrdersItemsLower p {
  margin-top: auto;
  margin-bottom: auto;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.7em;
  line-height: 150%;
  color: #1d2334;
  margin-left: 1em;
}

.listOfOrdersItemsUpperChild1 {
  width: 70%;
  height: 100%;
  border-right: 1px solid rgba(68, 87, 106, 0.1);
  padding-top: 1.1em;
}

.listOfOrdersItemsUpperChild1 p {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 0.6em;
  line-height: 12px;
  color: #1d2334;
}

.listOfOrdersItemsUpperChild2 {
  width: 30%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.listOfOrdersItemsUpperChild2 img {
  width: 1.3em;
  height: 1.3em;
}

.listOfOrdersItemsUpperChild2 p {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 0.6em;
  line-height: 12px;
  text-align: center;
  color: var(--globalColorHeader);
}

.listOfOrdersItemsLowerDetails {
  display: flex;
  flex-direction: column;
}

.listOfOrdersItemsLowerDetailsChild1 {
  margin-top: 2em;
}

.listOfOrdersItemsLowerDetailsChild1 :nth-child(1) {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 0.7em;
  line-height: 14px;
  color: #1d2334;
}

.listOfOrdersItemsLowerDetailsChild1 :nth-child(2) {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 17px;
  color: #1d2334;
}

.listOfOrdersItemsLowerDetailsChild2 {
}

.listOfOrdersItemsLowerDetailsChild2 :nth-child(1) {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 150%;
  color: #1d2334;
}

.listOfOrdersItemsLowerDetailsChild2 :nth-child(2) {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 0.6em;
  line-height: 12px;
  color: #1d2334;
}

.listOfOrdersItemsLowerDetailsChild2CouponDiv {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.6em;
  line-height: 150%;
  color: #44576a;
  background: #eff4f8;
  border-radius: 12px;
  padding: 0.6em 1em 0.6em 1em;
}

.listOfOrdersItemsLowerDetailsChild2ItemDetails {
  display: flex;
  margin-top: 4em;
}

.listOfOrdersItemsLowerDetailsChild2ItemDetailsNew {
  display: flex;
  margin-top: 1.2em;
}

.listOfOrdersItemsLowerDetailsChild2ItemDetails :nth-child(1) {
  width: 60%;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 0.7em;
  line-height: 14px;
  color: #1d2334;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.listOfOrdersItemsLowerDetailsChild2ItemDetailsNewDiv1 {
  width: 60%;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 0.7em;
  line-height: 14px;
  color: #1d2334;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.listOfOrdersItemsLowerDetailsChild2ItemDetailsNewDiv2 {
  display: flex;
  width: 40%;
  justify-content: space-between;
  align-items: center;
}

.listOfOrdersItemsLowerDetailsChild2ItemDetailsNewDiv2 :nth-child(1) {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.7em;
  line-height: 150%;
  text-decoration-line: line-through;
  color: var(--globalColorHeader);
  margin-top: auto;
  margin-bottom: auto;
}

.listOfOrdersItemsLowerDetailsChild2ItemDetailsNewDiv2 :nth-child(2) {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.7em;
  line-height: 150%;
  color: #44576a;
  margin-top: auto;
  margin-bottom: auto;
}

.listOfOrdersItemsLowerDetailsChild2ItemDetailsPrice {
  display: flex;
  width: 40%;
  justify-content: space-between;
  align-items: center;
}

.listOfOrdersItemsLowerDetailsChild2ItemDetailsPrice :nth-child(1) {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  text-decoration-line: line-through;
  color: var(--globalColorHeader);
  margin-top: auto;
  margin-bottom: auto;
}

.listOfOrdersItemsLowerDetailsChild2ItemDetailsPrice :nth-child(2) {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 150%;
  color: #44576a;
  margin-top: auto;
  margin-bottom: auto;
}

.listOfOrdersItemsLowerDetailsChild2ItemDetailsDiscount {
  display: flex;
  margin-top: 1em;
}

.listOfOrdersItemsLowerDetailsChild2ItemDetailsDiscount :nth-child(1) {
  width: 50%;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.7em;
  line-height: 14px;
  color: #1d2334;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.listOfOrdersItemsLowerDetailsChild2ItemDetailsDiscount :nth-child(2) {
  width: 50%;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 0.7em;
  line-height: 150%;
  color: #319b4b;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.listOfOrdersItemsLowerDetailsChild2ItemDetailsTotal {
  display: flex;
  margin-top: 1em;
}

.listOfOrdersItemsLowerDetailsChild2ItemDetailsTotalNew {
  display: flex;
  margin-top: 1em;
}

.listOfOrdersItemsLowerDetailsChild2ItemDetailsTotal :nth-child(1) {
  width: 60%;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.7em;
  line-height: 14px;
  color: #1d2334;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.listOfOrdersItemsLowerDetailsChild2ItemDetailsTotalNewDiv1 {
  width: 60%;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.7em;
  line-height: 14px;
  color: #1d2334;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.listOfOrdersItemsLowerDetailsChild2ItemDetailsTotalPrice {
  display: flex;
  width: 40%;
  justify-content: flex-end;
  align-items: center;
}

.listOfOrdersItemsLowerDetailsChild2ItemDetailsTotalNewDiv2 {
  display: flex;
  width: 40%;
  justify-content: space-between;
  align-items: center;
}

.listOfOrdersItemsLowerDetailsChild2ItemDetailsTotalPrice :nth-child(1) {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.7em;
  line-height: 150%;
  text-decoration-line: line-through;
  color: var(--globalColorHeader);
  margin-top: auto;
  margin-bottom: auto;
}

.listOfOrdersItemsLowerDetailsChild2ItemDetailsTotalNewDiv2 :nth-child(1) {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.7em;
  line-height: 150%;
  text-decoration-line: line-through;
  color: var(--globalColorHeader);
  margin-top: auto;
  margin-bottom: auto;
}

.listOfOrdersItemsLowerDetailsChild2ItemDetailsTotalPrice :nth-child(2) {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.7em;
  line-height: 150%;
  color: #44576a;
  margin-top: auto;
  margin-bottom: auto;
}

.listOfOrdersItemsLowerDetailsChild2ItemDetailsTotalNewDiv2 :nth-child(2) {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.7em;
  line-height: 150%;
  color: #44576a;
  margin-top: auto;
  margin-bottom: auto;
}

.listOfOrdersItemsLowerDetailsChild2ItemDetailsReorder {
  margin-top: 1em;
  display: flex;
  width: 25%;
  justify-content: space-between;
  align-items: center;
}

.listOfOrdersItemsLowerDetailsChild2ItemDetailsReorder p {
  margin-top: auto;
  margin-bottom: auto;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 17px;
  color: #1d2334;
  cursor: pointer;
}

.listOfOrdersItemsLowerDetailsChild2ItemDetailsDownload {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.2em;
}

.listOfOrdersItemsLowerDetailsChild2ItemDetailsDownload p {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
  line-height: 17px;
  color: #388bb9;
  cursor: pointer;
}

.cardContainer {
  padding-left: 1.3vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cardHeading {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 800;
  font-size: 0.7vw;
  line-height: 8px;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: 0.5px solid #d9d9d9;
  color: #fffcfc;
  padding-left: 0.5vw;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  margin-top: 1vw;
  padding-right: 0.6vw;
  margin-right: 6vw;
  width: fit-content;
}

.cardCategory {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 800;
  font-size: 0.88vw;
  line-height: 0.98em;
  /* identical to box height */
  margin-right: 11vw;
  letter-spacing: 1px;
  margin-top: 1.9vw;
  text-transform: uppercase;

  color: #fffcfc;
}

.cardName {
  background: linear-gradient(180deg, #fff 0%, #fff 49.48%, #fff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-top: 0.6vw;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.8em;
}

.cardContainer2 {
  padding-left: 1.3vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 2vw;
}

.cardContainer2Text1 {
  font-family: var(--globalFont);
  align-items: center;
  display: flex;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 9px;
  letter-spacing: 0.5px;
  color: #ffffff;
}

.cardContainer2Text2data {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.4em;
  line-height: 5px;
  letter-spacing: 0.5px;
  color: #ffffff;
}

.cardContainer2Text2data2 {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.6em;
  line-height: 9px;
  letter-spacing: 0.5px;

  color: #ffffff;
}

.ProgramNameIcon {
  background: #ffffff;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding-left: 1.5vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
  padding-right: 1.5vw;
  margin-right: 2vw;
}

.MyProgramChild2Div {
  display: flex;
  margin-top: 4vw;
  /* padding: 2vw; */
  justify-content: space-between;
}

.otherServiceText {
  display: flex;
  flex-direction: row;
}

.otherServiceUsed {
  width: 4vw;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin-left: 0.1vw;
  line-height: 150%;
  color: #b7ac8f;
}

.otherServiceUsed1 {
  width: 8vw;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #b7ac8f;
}

.otherServiceName {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #2d354e;
}

.subsOpener3 {
  border-bottom: 1px solid#E68D36;
  width: 89.5%;
  margin-left: 0.5vw;
}

.otherServiceLine {
  display: flex;
  flex-direction: row;
}

.othersBlock3 {
  margin-left: 1vw;
}

.swal2-container {
  z-index: 9999 !important;
}

.custom-swal-container {
  z-index: 9999 !important;
}

.subscriptionGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3vw;
}

.myCorporateCard {
  height: 12em;
  width: 23em;
}

.subscriptionTab {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid rgb(158, 158, 158);
  border-radius: 10px;
  justify-content: space-around;
  height: 2.5em;
}

.subscriptionPlanDetail {
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  font-size: 0.8em;
}

@media screen and (min-width: 1700px) {
  .myCorporateCard {
    height: 14em;
    width: 28em;
  }
}

@media screen and (max-width: 1170px) {
  .MyPackageBookingMainChild1 {
    margin-left: 3.6em;
    margin-right: 3.6em;
  }
  .MyPackageBookingMainChild2Subs {
    margin-left: 3.6em;
    margin-right: 3.6em;
  }

  .MyPackageBookingMainChild2DivsUpperChild1 {
    width: 40%;
    padding-top: 1em;
  }

  .MyPackageBookingMainChild2DivsUpperChild1 :nth-child(1) {
    font-size: 0.7em;
  }

  .MyPackageBookingMainChild2DivsUpperChild1 :nth-child(2) {
    font-size: 0.7em;
    padding-top: 0em;
  }
  .MyPackageBookingMainChild2DivsLowerChild1 {
    display: flex;
  }
  .myCorporateCard {
    height: 12em;
    width: 20em;
  }
  .YuvaHealthCard {
    width: 100%;
    height: 100%;
  }
  .cardName {
    margin-top: 1em;
    font-size: 1.2vw;
  }
  .cardContainer2 {
    margin-right: 2em;
    /* margin-top: 1.5em; */
  }
  .cardHeading {
    font-size: 0.7em;
    margin-top: 1.5em;
  }
  .cardCategory {
    font-weight: 800;
    font-size: 0.7em;
  }
  .subscriptionGrid {
    grid-template-columns: 1fr 1fr;
  }
  .css-heg063-MuiTabs-flexContainer {
    justify-content: center;
  }
  .lockMessage {
    font-size: 0.6em;
  }
  .cardContainer2Text2 {
    margin-top: 1vw;
  }
  .cardContainer2Text2data {
    font-size: 0.8vw;
  }
  .MyPackageBookingMainChild2DivsUpperChild4ss {
    width: 100%;
    padding-top: 0.9em;
    display: flex;
    flex-direction: row;
    position: relative;
    right: 0;
    height: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .MyPackageBookingMainChild2DivsUpperChild4Para {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: var(--globalFont);
    font-style: normal;
    font-weight: 400;
    font-size: 0.7em;
    width: 50%;
  }
  .MyPackageBookingMainChild2DivsUpperChild4AnchorDivss {
    display: flex;
    justify-content: space-between;
    width: 75%;
  }
  .MyPackageBookingMainChild2DivsUpperChild4Anchorlast {
    font-weight: 500;
    justify-content: flex-end;
    text-align: right;
    width: 100%;
    padding-right: 0em;
  }
  .MyPackageBookingMainChild2DivsUpperChild4Anchor {
    font-weight: 700;
    width: 0%;
  }
  .MyPackageBookingMainChild2 {
    margin-left: 2.6em;
    margin-right: 2.6em;
    background: #ffffff;
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    padding-top: 1em;
    margin-bottom: 5em;
  }
  .MyPackageBookingMainChild2DivsLowerChild2 {
    font-size: 0.8em;
    padding: 0.5em 2em 0.5em 2em;
  }
}
@media screen and (max-width: 870px) {
  .MyProgramMainChild2DivsLower {
    margin-top: 0.1em;
  }
  .cardCategory {
    width: 60%;
    font-weight: 800;
    font-size: 0.6em;
    line-height: 1em;
  }
  .myCorporateCard {
    height: 12em;
    width: 25em;
  }
  .MyPackageBookingMainChild2DivsUpper {
    flex-direction: column;
  }
  .MyPackageBookingMainChild2DivsUpperChild1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
  .MyPackageBookingMainChild2DivsLowerChild1 {
    display: flex;
    flex-direction: column;
    width: 70%;
  }
}

@media screen and (max-width: 650px) {
  .MyProgramMainChild2DivsLower {
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }
  .myCorporateCard {
    margin-top: 1em;
    height: 12em;
    width: 25em;
  }
  .cardHeading {
    margin-top: 4.5em;
  }
  .cardName {
    font-size: 0.6em;
  }
  .cardContainer2Text2data {
    font-size: 1.8vw;
  }
  .cardContainer2Text2data2 {
    font-size: 1.8vw;
    line-height: normal;
    letter-spacing: normal;
    padding-top: 5px;
  }
  .subscriptionGrid {
    grid-template-columns: 1fr;
  }
  .MyPackageBookingMainChild1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  .MyPackageBookingMainChild1 select {
    height: 2em;
    width: 100%;
    background: #f9f9f9;
    border: 1px solid #44576a;
    border-radius: 8px;
    font-family: var(--globalFont);
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 150%;
    text-align: left;
    color: #44576a;
    padding-left: 1em;
    margin-bottom: 2em;
  }
  .MyPackageBookingMainChild2DivsLowerChild1 {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .MyPackageBookingMainChild1 {
    margin-left: 1.6em;
    margin-right: 1.6em;
  }
  .MyPackageBookingMainChild2Subs {
    margin-left: 1.6em;
    margin-right: 1.6em;
  }
  .MyPackageBookingMainChild2DivsUpperChild1 :nth-child(1) {
    font-size: 0.7em;
  }

  .MyPackageBookingMainChild2DivsUpperChild1 :nth-child(2) {
    font-size: 0.7em;
    padding-top: 0em;
  }
  .MyPackageBookingMainChild2DivsLowerChild1 {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .MyPackageBookingMainChild2DivsLower {
    flex-direction: column;
    align-items: flex-start;
  }
  .expire {
    font-size: 1em;
  }
  .MyPackageBookingMainChild2DivsSubs {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .planMemberLockedButton {
    width: 100%;
  }
  .planMemberLockedButton p {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 1em;
  }
  .planLockIcon2 {
    width: 1em;
    height: 1.2em;
    margin-top: auto;
    margin-bottom: auto;
  }
  .subscriptionGrid {
    grid-template-columns: 1fr;
  }
  .css-heg063-MuiTabs-flexContainer {
    justify-content: center;
  }
  .lockMessage {
    font-size: 0.6em;
  }
  .MyPackageBookingMainChild2DivsUpperChild4ss {
  }
  .MyPackageBookingMainChild2DivsUpperChild4AnchorDivss {
    width: 100%;
  }
  .MyPackageBookingMainChild2DivsUpperChild4Para {
    width: 100%;
    margin-right: 0em;
  }
  .MyPackageBookingMainChild2DivsUpperChild4Anchorlast {
    justify-content: flex-end;
    text-align: right;
  }
  .MyPackageBookingMainChild2DivsLowerChild1_2 :nth-child(1) {
    text-align: center;
    word-wrap: break-word;
  }
  .MyPackageBookingMainChild2DivsLowerChild1_2 :nth-child(2) {
    text-align: center;
  }
  .planMemberAddMember {
    font-size: 0.8em;
  }
  .MyPackageBookingMainChild2DivsLowerChild2 {
    margin: 0 auto;
  }
  .MyPackageBookingMainChild1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  .MyPackageBookingMainChild1 select {
    height: 2em;
    width: 100%;
    background: #f9f9f9;
    border: 1px solid #44576a;
    border-radius: 8px;
    font-family: var(--globalFont);
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 150%;
    text-align: left;
    color: #44576a;
    padding-left: 1em;
    margin-bottom: 2em;
  }
}
