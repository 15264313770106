.aboutPackage {
  margin-left: 2em;
  margin-top: 1em;
  margin-right: 2em;
}

.aboutPackageHead {
  font-size: 3em;
  font-weight: 600;
}

.aboutPackageSubHeading {
  font-size: 1.375em;
  font-weight: 500;
  margin-top: 0.5em;
  color: #636363;
}

.aboutPackageLabTestHead {
  font-size: 3em;
  font-weight: 600;
  margin-top: 1em;
}

.aboutPackageLabGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.75em;
  font-size: 1em;
  margin-bottom: 2em;
}

.package-card-headnew {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
  align-items: center;
  background: #f8f8f8;
  padding: 1.25em;
  border-radius: 12px;
}

.package-card-head {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
  align-items: center;
  background: #f8f8f8;
  border: 1px solid #cfcfcf;
  padding: 1.25em;
  border-radius: 12px;
}

.ParamterCountDisplay {
  display: flex;
  align-items: baseline;
}

.dropdownImage {
  margin-top: 0.5em;
  cursor: pointer;
  width: 1em;
}

.dotPackage {
  width: 0.9vw;
  height: 0.9vw;
  margin-top: 0.4vw;
}

.Collapsiblen {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  padding-left: 2em;
  padding-top: 1em;
  padding-bottom: 1em;
  background: #f8f8f8;
}

.ParamterCountDisplayData {
  padding-left: 1vw;
}

.ParamterCountDisplaydata {
  margin-left: 0.85em;
}

.careerImg {
  display: flex;
  flex-direction: column;
  background: #e1eeff;
  border-radius: 12px;
  text-align: center;
  font-size: 1vw;
  padding-top: 1em;
  padding-bottom: 1em;
}

.WhyToBookWithUsContainer {
  margin-top: 2vw;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5.1vw;
  padding: 0.7vw;
  margin-right: 6.69vw;
  margin-bottom: 2vw;
}

.WhyToBookWithUshead {
  font-weight: 400;
  font-size: 0.88em;
  color: black;
  margin-top: 0.75em;
}

@media screen and (max-width: 879px) {
  .aboutPackageLabGrid {
    grid-template-columns: 1fr 1fr;
  }

  .WhyToBookWithUsContainer {
    grid-template-columns: 1fr 1fr;
  }

  .WhyToBookWithUshead {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 500px) {
  .aboutPackageHead {
    font-size: 1.75em;
  }

  .aboutPackageSubHeading{
    font-size: 0.95em;
  }

  .aboutPackageLabTestHead {
    font-size: 1.75em;
  }

  .aboutPackageLabGrid {
    grid-template-columns: 1fr;
  }

  .WhyToBookWithUsContainer {
    grid-template-columns: 1fr;
  }

  .WhyToBookWithUshead {
    font-size: 3em;
  }
}