.mainOtpBoxNew {
  width: 55vw;
  position: absolute;
  z-index: 1;
  top: 30%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  height: 28em;
}

.mainOtpBox1New {
  width: 35%;
  position: relative;
}

.mainOtpBox1New img {
  position: absolute;
  bottom: 0em;
  left: -1em;
  width: 190%;
  height: 71%;
}

.mainOtpBox2New {
  width: 65%;
}

.wrongOtpMessageNew {
  color: #ff001f;
  margin-top: 0.8rem;
  margin-left: 21%;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 150%;
}

.expireMessage {
  color: #ff001f;
  margin-top: 0.8rem;
  margin-left: 21%;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 150%;
}

.upperOtpNew {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upperOtpNew p {
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 2.3rem;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 150%;
  text-transform: capitalize;
  color: #44576a;
  padding-top: 1.8rem;
}

.upperOtpNew a {
  margin-top: auto;
  margin-bottom: auto;
  padding-right: 2.5rem;
  padding-top: 1.8rem;
  cursor: pointer;
}

.upperOtpNew {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.middleOtp1New {
  margin-top: 2.25rem;
  display: flex;
}

.middleOtpNew {
  width: 100%;
  margin-top: 2.25rem;
  display: flex;
  align-items: center;
}

.otpHeadingNew {
  width: 21%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  color: #44576a;
}

.otpBoxesNew {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.otpTimerNew {
  margin-top: 0.87rem;
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin-left: 21%;
}

.otpTimer1New {
}

.otpTimer2New {
  display: flex;
  justify-content: space-between;
}

.otpTimerNew p {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 0.8rem;
}

#timeShow {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 150%;
  text-transform: capitalize;
  color: #ff001f;
}

#timeShowMobile {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 150%;
  text-transform: capitalize;
  color: #ff001f;
}

.resendOtp {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 150%;
  text-transform: capitalize;
  color: #44576a;
  cursor: pointer;
}

.bottomOtpNew {
  margin-top: 1.68rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 2.3rem;
  width: 60%;
  margin-left: 21%;
}

.bottomOtpNew button {
  width: 100%;
  background: var(--globalColorHeader);
  border: 1px solid #14224a;
  border-radius: 10px;
  border: hidden;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 19px;
  color: #ffffff;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  cursor: pointer;
  z-index: 1;
}

.upperOtp1New {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1.5em;
  padding-top: 1em;
}

.upperOtp2New {
  height: 20%;
  display: flex;
  align-items: flex-end;
  width: 79%;
  margin-left: 21%;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 1.2em;
  line-height: 24px;
  color: var(--globalColorHeader);
}



.mainOtpBoxNewVerifyMobile {
  width: 55vw;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  height: 28em;
}

.loadingImage {
  width: 2, 5em;
  height: 2.5em;
}

@media screen and (max-width: 1200px) {
  .mainOtpBoxNewVerifyMobile {
    width: 75vw;
  }
  .upperOtp2New {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-left: 0%;
    font-weight: 600;
    color: var(--globalColorHeader);
    text-align: center;
  }
  .mainOtpBox1New {
    width: 0%;
    position: relative;
  }

  .mainOtpBox2New {
    width: 100%;
  }
  .otpHeadingNew {
    width: 0%;
    display: none;
  }

  .middleOtp1New {
    justify-content: center;
  }
  .expireMessage {
    margin-left: 15%;
  }
  .wrongOtpMessage {
    margin-left: 15%;
  }
}
@media screen and (max-width: 600px) {
  .mainOtpBoxNewVerifyMobile {
    width: 92vw;
  }
  .otpBoxesNew {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .expire {
    margin-right: -0.8em;
  }
  .expireMessage {
    margin-left: 6%;
  }
  .wrongOtpMessage {
    margin-left: 6%;
  }
  .mainOtpBoxNewVerifyMobile {
    top: 30%;
  }
}
