.AboutUsHeading {
  background-color: #e2eefe;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding: 5em 2em;
}

.AboutUsHeading p {
  font-size: 3em;
  font-weight: 700;
}

.AboutUsHeading img {
  width: 30em;
  height: 12em;
  border-radius: 10px;
}

.AboutUsHeading2 {
  display: flex;
  justify-content: center;
  margin-top: 2em;
  font-weight: 600;
  font-size: 2em;
}

.AboutUsSubHeading {
  font-weight: 200;
  font-size: 1.25em;
  margin: 1em 2em;
  text-align: center;
}

.NewAboutUSBody {
  display: flex;
  flex-direction: row;
}

.NewAboutImg {
  z-index: 99;
  width: 60vw;
  height: 27vw;
  margin-top: 4vw;
  border-radius: 0px 20px 20px 0px;
}

.NewAboutImgContent {
  width: 72vw;
  height: 31vw;
  margin-left: -13vw;
  margin-top: 4vw;
  background: #e2eefe;
  border-radius: 0px 0px 0px 32px;
}

.NewAboutImgContentHeading {
  font-weight: 500;
  margin-top: 3vw;
  font-size: 1.5em;
  line-height: 150%;
  margin-left: 19vw;
  width: 38vw;
  text-transform: capitalize;
}

.NewAboutImgContentDetails {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  width: 38vw;
  margin-top: 3vw;
  line-height: 150%;
  margin-left: 17vw;
  text-align: right;
}

.NewAboutImgContentDetails1 {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  margin-top: 2vw;
  margin-left: 17vw;
  font-size: 1em;
  width: 38vw;
  line-height: 150%;
  text-align: right;
}

.NewAboutImgContent2 {
  margin-top: 2em;
  border-radius: 0px 0px 0px 32px;
  padding-left: 2em;
  padding-right: 0.5em;
  z-index: 20;
}

.NewAboutImgContentHeading2 {
  font-style: normal;
  font-weight: 600;
  margin-top: 2em;
  font-size: 2em;
}

.NewAboutImgContentDetails2 {
  font-style: normal;
  font-weight: 300;
  margin-top: 2em;
  width: 60vw;
}

.NewAboutImgContentDetails12 {
  margin-top: 3em;
  font-weight: 300;
  width: 60vw;
}

.NewAboutImg2 {
  z-index: 99;
  width: 69vw;
  height: 34vw;
  margin-top: 1em;
  border-radius: 20px 0px 0px 20px;
}

.AboutUsLocationHeading {
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 2em;
  margin-top: 2em;
  margin-bottom: 1em;
}

.AboutUswrapper {
  display: grid;
  grid-template-columns: 2fr 2fr;
  margin-top: 1em;
}

.locationOne {
  display: flex;
  justify-content: center;
}

.gridInnerBlock {
  width: 30em;
  height: 15em;
  border-radius: 16px;
  background-color: white;
}

.locationName {
  display: flex;
  justify-content: center;
  padding-top: 6em;
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 1.5em;
}

@media screen and (min-width : 1600px) {
  .AboutUswrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1150px) {
  .NewAboutImg2 {
    margin-top: 2em;
  }

  .NewAboutImgContentHeading {
    font-size: 1.25em;
  }

  .NewAboutImgContentDetails {
    font-size: 0.85em;
  }

  .NewAboutImgContentDetails1 {
    font-size: 0.85em;
  }

  .NewAboutImgContentDetails2 {
    font-size: 0.85em;
  }

  .NewAboutImgContentDetails12 {
    font-size: 0.85em;
  }
}

@media screen and (max-width: 980px) {
  .NewAboutImgContent {
    height: 28vw;
  }

  .NewAboutImgContentHeading {
    font-size: 1em;
  }

  .NewAboutImgContentDetails {
    font-size: 0.65em;
  }

  .NewAboutImgContentDetails1 {
    font-size: 0.65em;
  }

  .NewAboutImg2 {
    margin-top: 4em;
  }

  .gridInnerBlock {
    width: 20em;
    height: 10em;
  }

  .locationName {
    padding-top: 4em;
  }
}

@media screen and (max-width: 900px) {
  .NewAboutImgContentHeading2 {
    margin-top: 1em;
    font-size: 1.5em;
  }
}

@media screen and (max-width: 780px) {
  .AboutUsHeading img {
    width: 22em;
    height: 10em;
    border-radius: 10px;
  }

  .NewAboutImgContent {
    height: 35vw;
  }

  .NewAboutImgContentDetails12 {
    margin-top: 2em;
  }
}

@media screen and (max-width: 680px) {
  .AboutUsHeading img {
    display: none;
  }
  
  .NewAboutUSBody {
    flex-direction: column;
    position: relative;
    background-color: #f0edf4;
  }

  .NewAboutImg {
    width: auto;
    height: 20em;
  }

  .NewAboutImgContent {
    position: absolute;
    z-index: 120;
    top: 200px;
    right: 0;
    width: 80vw;
  }

  .NewAboutImgContent {
    height: auto;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 1em;
    text-align: center;
  }

  .NewAboutImgContentHeading {
    margin-left: 0px;
    font-size: 1.25em;
    width: auto;
  }

  .NewAboutImgContentDetails {
    font-size: 0.8em;
    margin-left: 0px;
    width: auto;
    text-align: center;
  }

  .NewAboutImgContentDetails1 {
    font-size: 0.8em;
    margin-left: 0px;
    width: auto;
    text-align: center;
  }

  .NewAboutImgContentHeading2 {
    margin-top: 4em;
  }

  .NewAboutImgContentDetails2 {
    width: auto;
    font-size: 1em;
  }

  .NewAboutImgContentDetails12 {
    font-size: 1em;
    width: auto;
  }

  .NewAboutImg2 {
    z-index: 10;
    height: 110vw;
    width: 100vw;
    margin-top: -12em;
  }

  .AboutUswrapper {
    grid-template-columns: 1fr;
  }
}
