.productList {
  display: flex;
  flex-direction: column;
}

.productListHead {
  display: flex;
  align-items: center;
}

.productListTitle {
  color: var(--globalColorMainHeading);
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.productListViewAll {
  position: absolute;
  right: 2em;
  color: var(--globalColorButton);
  cursor: pointer;
}

.productListViewAll:hover {
  text-decoration: underline;
}

.productViewAllCards {
  margin-top: 2em;
  display: grid;
  column-gap: 2%;
  row-gap: 2em;
  grid-template-columns: repeat(3, 1fr);
}

.productListCards {
  margin-top: 2em;
  display: grid;
  column-gap: 2%;
  row-gap: 2em;
  grid-template-columns: repeat(4, 1fr);
}

.productImageCarousel {
  width: 40vw;
  height: 50%;
  margin-left: 4vw;
  margin-top: 2em;
}

.productImageCarousel img {
  height: 50%;
}

@media screen and (min-width: 1500px) {
  .productListCards {
    grid-template-columns: repeat(4, 1fr);
  }

  .productViewAllCards {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1000px) {
  .productListCards {
    align-items: center;
    grid-template-columns: repeat(3, 1fr);
  }
  .productViewAllCards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 900px) {
  .productViewAllCards {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 481px) and (max-width: 679px) {
  .productList {
    align-items: center;
  }

  .productViewAllCards {
    grid-template-columns: 1fr 1fr;
    column-gap: 10%;
  }

  .productListCards {
    grid-template-columns: 1fr 1fr;
  }

  .productListHead {
    position: relative;
  }

  .productListViewAll {
    top: 2em;
    right: -4em;
  }

  .productListTitle {
    font-weight: 600;
  }

  .productImageCarousel {
    height: auto;
    width: auto;
    display: flex;
    justify-content: center;
    margin-left: 0;
  }

  .productImageCarousel img {
    width: 100%;
  }

  .productImageSlider {
    width: 80%;
  }
}

@media screen and (max-width: 480px) {
  .productListCards {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-template-columns: 1fr;
  }

  .productViewAllCards {
    grid-template-columns: 1fr;
  }
}
