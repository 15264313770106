.ForgetPasswordMainWrapper {
  height: 90vh;
  background-image: url("../../../../assets/Login/BackgroundImage.png");
  background-size: 100% 120%;
  background-repeat: no-repeat;
}

.ForgetPasswordPart1 {
  display: flex;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("../../../../assets/Login/Background.png");
}
.loginScreenMainHeaderForgot {
  margin-left: 2vw;
}

.forgotPasswordP {
  font-size: 2.2em;
}

.ForgetPasswordPart1Image {
  width: 50%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-end;
}

.ForgetPasswordPart1Image_1 {
  width: 8em;
  height: 8em;
  padding-top: 2.1em;
  padding-right: 2.06em;
}

.ForgetPasswordPart1Image_2 {
  width: 4em;
  height: 4em;
  margin-bottom: 5.81em;
  margin-right: 3.2em;
}

.ForgetPasswordPart1Text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  color: #1d2334;
}

.ForgetPasswordPart1TextWrapper {
  width: 50%;
  padding-top: 3.6em;
  padding-left: 4.41em;
  padding-bottom: 3.8em;
}

.ForgetPasswordScreenMain {
  width: 35%;
  position: absolute;
  right: 5.3em;
  top: 12.6em;
  background: #ffffff;
  box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.08);
  border-radius: 40px;
  padding-bottom: 2em;
  padding-top: 2em;
}

.ForgetPasswordScreenMainHeader {
  margin-top: 2.7em;
  display: flex;
  justify-content: space-between;
  margin-right: 2vw;
  margin-left: 2em;
}

.ForgetPasswordScreenMainHeaderSignUp {
  display: flex;
  flex-direction: column;
}

.googleNew {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5em;
  margin-bottom: 1em;
}
#signInDiv {
  width: 100vw;
}
.orRegister {
  margin-top: 1vw;
  width: 80%;
  height: 6.5%;
  gap: 5%;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.horizontalLineRegister {
  border-top: 2px solid gray;
  width: 42%;
  width: 92%;
}
.formStarts1New {
  display: flex;
  flex-direction: column;
}

.ForgetPasswordForm {
  margin-top: 3em;
  margin-left: 2vw;
}

.boxNew {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.labelpara {
  width: 80%;
  margin-right: auto;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 24px;
  color: #1d2334;
  align-self: flex-start;
  margin-bottom: 1em;
}

.login_textbox_new {
  height: 3.4vw;
  background: #f3f0f0;
  border-radius: 0.6vw;
  width: 28vw;
  border: hidden;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 0.8em;
  line-height: 21px;
  color: #808080;
  padding-left: 1.125em;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.boxNew2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 2em;
}

.UserLoginButtonNew {
  display: flex;
}

.submitbuttonNewForgot {
  margin-top: 2em;
  width: 28vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
  outline: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.6vw;
  height: 3.4vw;
  color: white;
  cursor: pointer;
  gap: 2%;
  background: var(--globalColorHeader);
  border: 1px solid var(--globalColorHeader);
  box-shadow: 0px 4px 19px rgba(119, 147, 65, 0.3);
}

.horizontalLineRegister {
  border-top: 2px solid gray;
  width: 42%;
  width: 92%;
}

.forgotPassword {
  display: flex;
  margin-top: 1em;
  margin-left: 2vw;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}

.forgotPasswordText {
  color: #407bff;
}

@media screen and (min-width: 1440px) {
  .ForgetPasswordMainWrapper {
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  /* .ForgetPasswordPart1 {
      background: url("../../../assets/Login/EllipseMain.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    } */
}
