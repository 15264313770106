.ComingSoonMain {
  margin-left: auto;
  margin-right: auto;
  /* background-color: aqua; */
  width: 50%;
  margin-top: 2.18em;
  height: 29em;
  position: relative;
  margin-bottom: 3em;
}
.ComingSoonMain p {
  position: absolute;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 2.5vw;
  line-height: 150%;
  text-transform: capitalize;
  color: #44576a;
  top: 50%;
}
.ComingSoonMain img {
  width: 80%;
  /* background-color: #44576A; */
  position: absolute;
  right: 0;
  height: 100%;
}
@media screen and (max-width: 600px) {
  .ComingSoonMain img {
    width: 100%;
  }
  .ComingSoonMain {
    width: 80%;
  }
  .ComingSoonMain p {
    left: 11%;
    top: 90%;
    font-size: 2em;
  }
}
/* @media screen and (width:820px) {
   
    .ComingSoonMain p{
       left: 11%;
       top: 90%;
}
} */
/* */
