.relatedProductTitle {
  color: #1c71e1;
}

.relatedProductNext {
  color: black !important;
}

.slick-prev:before,
.slick-next:before {
  color: black;
  font-size: 25px;
  text-align: center;
}

.relatedProductSlider {
  display: block;
}

.relatedProductSliderSmall {
  display: none;
}

.nextArrow {
  display: block;
}

.nextArrowSmall {
  display: none;
}
