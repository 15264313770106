.FilterDirectory-panel {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  background: #fff;
  border: 1px solid #eaeaec;
  width: 900px;
  max-width: 100%;
  padding: 15px 0;
  position: absolute;
  top: 0;
  left: 10;
  z-index: 50000;
  display: block;
}

.FilterDirectory-titleBar {
  display: flex;
  justify-content: space-between;
  color: #adaeaf;
  padding: 0 18px 12px;
  border-bottom: 1px solid #eaeaec;
}

.FilterDirectory-titleBar-title {
  font-size: 16px;
}

.FilterDirectory-list {
  padding: 20px 18px 10px 0 !important;
  margin-right: 18px !important;
  margin-left: 10px !important;
  height: 465px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
}

.FilterDirectory-list li label {
  display: block;
  width: 100%;
  min-height: 17px;
  color: #282c3f;
}

.common-customCheckbox {
  position: relative;
  cursor: pointer;
}

.FilterDirectory-list li {
  margin: 0 10px 8px 0;
  font-size: 14px;
  padding-left: 10px;
  list-style-type: none;
}

.common-customCheckbox input {
  margin: 0 16px 0 0;
}