.totalItemsHeading {
  font-family: var(--globalFont);
  font-size: var(--globalFontSize22);
  font-weight: 700;
  line-height: 33px;
  margin-left: 2em;
}
.CartMainContentArea {
  background: #f8f8f8;
  border: 1px solid #cfcfcf;
  border-radius: 10px;
  padding-top: 2vw;
  width: 70%;
  height: fit-content;
  max-height: 70em;
  overflow-y: auto;
  padding-bottom: 3em;
}

.cartItems {
  display: flex;
  padding-top: 1.1em;
  padding-bottom: 1.1em;
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  margin-left: 2em;
  margin-right: 2em;
  padding-left: 1.1em;
  margin-top: 1.1em;
}
.cartItemsPart1 {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e4e4e4;
  border-radius: 10px;
}
.cartItemsPart2 {
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 1.2em;
  justify-content: center;
}
.CartItemsPriceArea {
  display: flex;
  align-items: center;
  width: 100%;
}
.CartItemsPriceArea p {
  margin-top: auto;
  margin-bottom: auto;
}
.cartItemsPart3 {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.quantitySelect {
  padding: 0.8em 1.5em 0.8em 1.5em;
}
.cartItemsPart4 {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CartItemsImage {
  width: 60%;
  height: 80%;
}
.cartItemsPart2ItemName {
  font-family: var(--globalFont);
  font-size: var(--globalFontSize20);
  font-weight: 500;
  line-height: 30px;
}
.DiscountedPrice {
  font-family: var(--globalFont);
  font-size: var(--globalFontSize24);
  font-weight: 400;
  line-height: 36px;
  color: black;
}
.originalPrice {
  font-family: var(--globalFont);
  font-size: var(--globalFontSize18);
  font-weight: 400;
  line-height: 36px;
  color: #6b6b6b;
  margin-left: 0.8em;
  text-decoration: line-through;
}

.TotalDiscountPercentage {
  font-family: var(--globalFont);
  font-size: 0.6em;
  font-weight: 500;
  color: black;
  padding: 0.2em 0.6em 0.2em 0.6em;
  background-color: #a5ccff;
  border-radius: 2.3px;
  margin-left: 0.5em;
}
@media screen and (max-width: 1300px) {
  .Cartmain {
    display: flex;
    flex-direction: column;
  }
  .CartMainContentArea {
    width: 100%;
    max-height: 50em;
    height: fit-content;
  }
  .CartItemsImage {
    width: 50%;
    height: 60%;
  }
  .cartItemsPart2ItemName {
    font-size: var(--globalFontSize14);
  }
  .DiscountedPrice {
    font-size: var(--globalFontSize18);
  }
  .originalPrice {
    font-size: var(--globalFontSize16);
  }
  .cartItemsPart3 {
    display: flex;
    justify-content: flex-start;
  }
  .cartItemsPart3SelectDiv {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .quantitySelect {
    width: 95%;
    color: #000000;
    padding-left: 0.3em;
  }
  .quantitySelect select:focus {
    border: none;
    outline: none;
  }
  .cartItems {
    height: 9em;
    margin-left: 0.5em;
    margin-right: 0.5em;
    position: relative;
    padding-left: 0.4em;
  }
  .cartItemsPart2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 65%;
  }
  .cartItemsPart4 {
    position: absolute;
    right: 1%;
    top: 2%;
  }
  .totalItemsHeading {
    margin-left: 0.5em;
  }
}

@media screen and (max-width: 800px) {
  .cartItemsPart3 {
    justify-content: right;
  }
  .CartListItemsNewRightp2-1 {
    margin-right: 2.2em;
  }
}

@media screen and (max-width: 500px) {
  .cartItems {
    flex-direction: column;
    height: auto;
  }

  .cartItemsPart1 {
    width: auto;
    background: none;
  }

  .quantitySelect {
    padding: 0.3em;
    width: auto;
    margin-right: 1em;
  }

  .cartItemsPart3 {
    width: auto;
  }

  .CartListItemsNewRightp2-1 {
    font-size: 1em;
    margin-left: 1.2em;
  }
}