.networkHero {
  background-color: #e2eefe;
  height: 30.125em;
  display: flex;
  justify-content: space-around;
  width: 100vw;
  align-items: center;
  padding-left: 3vw;
  padding-right: 2vw;
}

.networkHeroLeft {
  font-size: 3em;
  font-weight: 400;
}

.networkHeroText {
  display: flex;
  flex-direction: column;
}

.networkHeroTextTitle {
  font-size: 3.75vw;
  color: #1c71e1;
  position: relative;
}

.networkHeroTextTitleImg {
  position: absolute;
  bottom: 15px;
}

.networkHeroTextDesc {
  font-size: 3.75vw;
}

.networkHeroTextInput img {
  height: auto;
  width: auto;
}

.networkHeroRight img {
  width: auto;
}

@media screen and (min-width: 1600px) {
  .networkHero {
    height: 40em;
  }

  .networkHeroTextTitle {
    font-size: 1.5em;
  }

  .networkHeroTextDesc {
    font-size: 1.5em;
  }

  .networkHeroRight img {
    width: 20vw;
  }
}

@media screen and (max-width: 1000px) and (min-width: 880px) {
  .networkHeroRight img {
    width: 25vw;
  }
  .networkHeroTextTitleImg {
    position: absolute;
    bottom: 10px;
  }

  .networkHero {
    height: 30em;
  }
}

@media screen and (min-width: 680px) and (max-width: 879px) {
  .networkHeroRight img {
    width: 25vw;
  }

  .networkHeroTextTitleImg {
    position: absolute;
    bottom: 5px;
  }

  .networkHero {
    height: 30em;
  }
}

@media screen and (max-width: 679px) {
  .networkHeroRight {
    display: none;
  }

  .networkHeroRight img {
    display: none;
  }

  .networkHero {
    height: 20em;
  }

  .networkHeroTextTitle {
    font-size: 5vw;
    color: #1c71e1;
    position: relative;
  }

  .networkHeroTextTitleImg {
    position: absolute;
    bottom: 5px;
  }

  .networkHeroTextDesc {
    font-size: 5vw;
  }
}

@media screen and (max-width: 480px) {
  .networkHero {
    height: 20em;
  }
}
