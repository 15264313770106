.myReporttext {
  margin-top: 1.31vh;
  margin-bottom: 2vh;
  display: flex;
  justify-content: space-between;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1.7vw;
  line-height: 150%;
  /* identical to box height, or 36px */

  color: #e68d36;
}
.reportsDropdown {
  margin-right: 2vw;
}

.dropdownReport {
  box-sizing: border-box;
  background: #f9f9f9;
  padding-left: 3.16vw;
  padding-top: 0.66vh;
  padding-bottom: 0.66vh;
  padding-right: 3.41vw;
  color: #44576a;
  font-family: var(--globalFont);

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  border: 1px solid #44576a;
  border-radius: 8px;
}

.downloadBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.downloadItemName {
  margin-right: 1vw;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 150%;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;

  text-align: center;

  color: #44576a;
}

.downloadItemDate {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  display: flex;
  font-size: 0.5em;
  align-items: center;
  line-height: 150%;
  /* identical to box height, or 12px */

  text-align: center;

  color: #a0a0a0;
}

.mainBlockk {
}

.hraHeading {
  font-style: normal;
  font-weight: 600;
  font-size: 2em;
  margin-left: 2em;
  margin-top: 3vw;
  line-height: 150%;
  color: var(--globalColorHeader);
}
