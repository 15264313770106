.FrequentlyAskedQuestionDataContent {
  padding-top: 3vw;
  padding-left: 3vw;
}

.FrequentlyAskedQuestionGriditem {
  background: #f6f6f6;
  border-radius: 12px;
  margin-bottom: 2vw;
  padding-left: 1vw;
  padding-right: 2vw;
  width: 100%;
}

.FrequentlyAskedQuestionitemtitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: 150%;
  text-align: center;
  color: #1d2334;
  padding-top: 1.5vw;
}

.FrequentlyAskedQuestionitemcontent {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 200;
  font-size: 1vw;
  line-height: 150%;
  padding-top: 2vw;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.FrequentlyAskedQuestionitemcontent.show {
  height: auto;
  max-height: 30vw;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);

}

.FrequentlyAskedQuestionGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 5vw;
}

.FrequentlyAskedQuestionContainer {
  background: #ffffff;
  border-radius: 16px;
  margin-left: 3vw;
  margin-right: 3vw;
  margin-top: 2vw;
  margin-bottom: 3vw;
}
.FrequentlyQuestionIMageDiv{
  
}
.FrequentlyQuestionIMage {
  padding-top: 2.9vw;
  padding-right: 2vw;
  padding-left: 2vw;
  width: 100%;
}

.PlusSymbol {
  font-size: 2vw;
}

.FrequentlyAskedQuestionButton {
  margin: auto;
  display: flex;
  justify-content: center;
}

.FrequentlyAskedQuestionBtn {
  background: var(--globalColorHeader);
  border-radius: 6px;
  border: none;
  outline: none !important;
  padding-left: 4vw;
  padding-right: 4vw;
  padding-top: 0.7vw;
  padding-bottom: 0.7vw;
  color: white;
  margin-bottom: 2vw;
  outline: none !important;
  font-size: 1vw;
}

.FrequentlyAskedQuestionQuestionW {
  font-size: var(--globalFontSize18);
  text-align: left;
}

.FrequentlyAskedQuestionAnswerW {
  font-size: 1vw;
  padding-bottom: 1vw;
}
.GetExpertGuidanceHeadingFAQ{
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: var(--globalFontSize36);
  line-height: 65.1px;
  text-align: left;
  color: var(--globalColorHeader);
  padding-top: 2vw;
  margin-left: 3vw;
  padding-left: 3vw;
}
@media screen and (max-width:850px) {
  .FrequentlyQuestionIMageDiv{
    display: none;
  }
  .FrequentlyAskedQuestionDataContent{
    width: 100%;
  }
  .FrequentlyAskedQuestionGrid {
  grid-template-columns: none;

  }
  .FrequentlyAskedQuestionQuestionW {
    font-size: var(--globalFontSize36);
    text-align: left;
    line-height: 1em;
  }
  .FrequentlyAskedQuestionGriditem {
    margin-bottom: 2vw;
    padding-left: 1em;
    padding-right: 2vw;
   padding-top: 1em;
    padding-bottom: 1em;
  }
  .PlusSymbol {
    font-size: 5em;
  }
  .FrequentlyAskedQuestionAnswerW{
    font-size: var(--globalFontSize36);
padding-top: 1em;
line-height: 1.2em;
  }
}