.subscriptionHero {
  background-color: #e2eefe;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding-bottom: 3em;
}

.subscriptionHeroLeft {
  margin-top: 3em;
  margin-left: 2em;
}

.subscriptionHeroText {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.subscriptionHeroTextTitle {
  font-size: 3em;
  font-weight: 600;
}

.subscriptionHeroTextTitle span {
  color: #1c71e1;
}

.subscriptionHeroTextDesc {
  font-size: 1.5em;
}

.subscriptionHeroButton {
  padding: 1.5em 6.25vw;
  background-color: #1c71e1;
  color: white;
  width: fit-content;
  border-radius: 10px;
  font-size: 1em;
}

.subscriptionHeroRight {
  position: relative;
}

.subscriptionHeroRight img {
  width: auto;
  position: relative;
}

.subscriptionHeroRightText {
  display: flex;
  flex-direction: column;
  z-index: 100;
  color: white;
  position: absolute;
  font-size: 2em;
  right: 3em;
  top: 5em;
}

.subscriptionHeroRightTextHead {
  display: flex;
  flex-direction: column;
  z-index: 100;
  color: white;
  position: absolute;
  font-size: 1.25em;
  right: 3em;
  top: 7em;
}

.subscriptionHeroRightTextCost span {
  font-size: 2em;
}

.subscriptionHeroRightTextDiscount span {
  text-decoration: line-through;
  font-size: 0.75em;
}

.subscriptionHeroPrice {
  display: none;
}

.subscriptionHeroPriceText {
  text-decoration: line-through;
  font-size: 1.5em;
}

.subscriptionHeroPriceCost {
  font-size: 2em;
  margin-left: 0.5em;
  font-weight: 600;
}
@media screen and (max-width: 1000px) {
  .subscriptionHeroLeft {
    margin-top: 3em;
  }

  .subscriptionHeroRight {
    display: none;
  }
  .subscriptionHeroPrice {
    display: flex;
    align-items: baseline;
  }
}

@media screen and (min-width: 680px) and (max-width: 879px) {
  .subscriptionHeroButton {
    padding: 1.15em 3.25vw;
  }

  .subscriptionHeroRight {
    display: none;
  }

  .subscriptionHeroPrice {
    display: block;
  }
  /* .subscriptionHero {
    height: 30em;
  } */
}

@media screen and (min-width: 481px) and (max-width: 679px) {
  .subscriptionHeroRight {
    display: none;
  }

  .subscriptionHeroTextTitle {
    font-size: 2em;
  }

  .subscriptionHeroTextDesc {
    font-size: 1em;
  }

  .subscriptionHeroRight img {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .subscriptionHeroTextTitle {
    font-size: 1.75em;
  }

  .subscriptionHeroTextDesc {
    font-size: 1em;
  }

  .subscriptionHeroButton {
    padding: 1.5em 3.25vw;
  }
  .subscriptionHeroLeft {
    margin-top: 2em;
  }
}
