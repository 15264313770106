.AdvertisementBannerMobileMain {
  display: flex;
  flex-direction: column;
}

.AdvertisementBannerMobile1 {
  position: relative;
  background-color: #f2efea;
  z-index: 1;
}
.AdvertisementBannerMobile1Background {
  position: absolute;
  width: 75%;
  height: 100%;
  left: 0em;
  z-index: 2;
}
.AdvertisementBannerMobile1Logo {
  width: 40%;
  padding-top: 2.7em;
  padding-left: 1.1em;
  position: relative;
  z-index: 3;
}
.AdvertisementBannerMobile2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #f2efea;
}
.AdvertisementBannerMobile2 :nth-child(1) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1em;
  line-height: 150%;
  color: #0d1144;
  padding-left: 0.7em;
  padding-right: 0.7em;
  padding-top: 1.7em;
  text-align: center;
}
.AdvertisementBannerMobile2 :nth-child(2) {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.1em;
}
.AdvertisementBannerMobile2 :nth-child(3) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 2em;
  line-height: 150%;
  color: #a53f2b;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 1.1em;
  margin-bottom: 2em;
}
.AdvertisementBannerMobile2 :nth-child(4) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1em;
  line-height: 150%;
  color: #ffffff;
  position: absolute;
  bottom: 0;
  z-index: 1;
}
.AdvertisementBannerMobile2 :nth-child(5) {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.AdvertisementBannerMobile3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #52608e;
}
.AdvertisementBannerMobile3 :nth-child(1) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1em;
  line-height: 150%;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  padding-top: 0.8em;
  margin-bottom: 0.7em;
}
.AdvertisementBannerMobile3 :nth-child(2) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1em;
  line-height: 150%;
  background: #ffffff;
  border-radius: 6px;
  color: var(--globalColorHeader);

  padding: 0.5em 3.5em 0.5em 3.5em;
  margin-bottom: 1.5em;
}
.AdvertisementBannerMobile4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f2efea;
  position: relative;
}
.AdvertisementBannerMobile4 :nth-child(1) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 150%;
  text-align: center;
  text-transform: capitalize;
  color: var(--globalColorHeader);

  padding-top: 1.1em;
}
.AdvertisementBannerMobile4 :nth-child(2) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1em;
  line-height: 150%;
  text-align: center;
  text-transform: capitalize;
  color: #1d2334;
}
.AdvertisementBannerMobile4Comment {
  background-image: url("../../../assets/AdvertisementSection3CardsImages/commentDiv.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.1em;
  width: 70%;
  height: 25vh;
}
.AdvertisementBannerMobile4Comment :nth-child(1) {
  padding-top: 1.3em;
  margin-bottom: 0.7em;
}
.AdvertisementBannerMobile4Comment :nth-child(2) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  text-align: center;
  text-transform: capitalize;
  color: #f2efea;
  margin-bottom: 2.5em;
  padding-left: 2.7em;
  padding-right: 2.7em;
}
.AdvertisementBannerMobile5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #52608e;
  border-radius: 10px;
}
.AdvertisementBannerMobile5 :nth-child(1) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 150%;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  padding-top: 1.1em;
  margin-bottom: 0.7em;
}
.AdvertisementBannerMobile5 :nth-child(2) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.7em;
  line-height: 150%;
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  margin-bottom: 0.7em;
  padding: 1.3em 1.3em;
}
.AdvertisementBannerMobile5 :nth-child(3) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1em;
  line-height: 150%;
  text-align: center;
  color: var(--globalColorHeader);

  padding: 0.5em 5em 0.5em 5em;
  margin-bottom: 1.1em;
  border-radius: 5px;
}
.AdvertisementBannerMobile6 {
  display: flex;
  flex-direction: column;
}
.AdvertisementBannerMobile6 :nth-child(1) {
  margin-left: auto;
  margin-right: auto;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 150%;
  text-align: center;
  text-transform: capitalize;
  color: var(--globalColorHeader);

  padding-top: 1.3em;
}
.AdvertisementBannerMobile6 :nth-child(2) {
  margin-left: auto;
  margin-right: auto;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1em;
  line-height: 150%;
  text-align: center;
  text-transform: capitalize;
  color: #1d2334;
}
.AdvertisementBannerMobile7 {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 150%;
  text-transform: capitalize;
  color: #1d2334;
}

.AdvertisementBannerMobile7 :nth-child(1) {
  padding-left: 0.8em;
  padding-right: 0.8em;
  list-style: disc;
  margin-top: 1em;
}
.AdvertisementBannerMobile7 :nth-child(2) {
  padding-left: 0.8em;
  padding-right: 0.8em;
  list-style: disc;
  margin-top: 1em;
}
.AdvertisementBannerMobile7 :nth-child(3) {
  padding-left: 0.8em;
  padding-right: 0.8em;
  list-style: disc;
  margin-top: 1em;
}
.AdvertisementBannerMobile8 {
  position: relative;
}
.AdvertisementBannerMobile8Image {
  position: absolute;
  right: 1em;
  z-index: -1;
}

.AdvertisementBannerMobile8Child1 {
  background: #ffffff;
  box-shadow: 2px 0px 2px 2px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 1.1em;
  width: 65%;
  padding-top: 1.3em;
  margin-left: 1em;
  margin-top: 1em;
}
.AdvertisementBannerMobile8Child1 :nth-child(2) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.2em;
  line-height: 150%;
  text-transform: capitalize;
  color: #44576a;
  margin-top: 0.7em;
}
.AdvertisementBannerMobile8Child1 :nth-child(3) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.7em;
  line-height: 150%;
  text-transform: capitalize;
  color: #1d2334;
  margin-top: 0.7em;
  padding-bottom: 2.5em;
  padding-right: 1.1em;
}
.AdvertisementBannerMobile8Child2 {
  background: #ffffff;
  box-shadow: 2px 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 1.1em;
  width: 65%;
  padding-top: 1.3em;
  margin-left: 30%;
  position: relative;
  bottom: 2em;
}
.AdvertisementBannerMobile8Child2 :nth-child(2) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.2em;
  line-height: 150%;
  text-transform: capitalize;
  color: #44576a;
  margin-top: 0.7em;
}
.AdvertisementBannerMobile8Child2 :nth-child(3) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.7em;
  line-height: 150%;
  text-transform: capitalize;
  color: #1d2334;
  margin-top: 0.7em;
  padding-bottom: 2.5em;
  padding-right: 1.1em;
}
.AdvertisementBannerMobile9 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.AdvertisementBannerMobile9 button {
  background: #52608e;
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1em;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  padding: 0.5em 4.5em 0.5em 4.5em;
}
.AdvertisementBannerMobile10 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.AdvertisementBannerMobile10 :nth-child(1) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 150%;
  text-align: center;
  color: var(--globalColorHeader);

  padding-top: 1.1em;
}
.AdvertisementBannerMobile10 :nth-child(2) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1em;
  line-height: 150%;
  text-transform: capitalize;
  color: #1d2334;
  margin-top: 0.6em;
}
.AdvertisementBannerMobile10 :nth-child(3) {
  width: 50%;
  height: 50%;
  margin-top: 2em;
}
.AdvertisementBannerMobile10 :nth-child(4) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.7em;
  line-height: 150%;
  text-align: center;
  text-transform: capitalize;
  color: #1d2334;
  margin-top: 2em;
  padding: 1.1em 1.1em;
}
.AdvertisementBannerMobile11 {
  display: flex;
  flex-direction: column;
}
.AdvertisementBannerMobile11Child1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #fff8f8 0%, rgba(250, 250, 250, 0) 100%);
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 80px;
  padding: 1.8em 7.6em 1.8em 7.6em;
  margin-top: 2.06em;
  margin-left: 2.1em;
  margin-right: 2.1em;
}
.AdvertisementBannerMobile11Child1 p {
  margin-top: 1em;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  text-align: center;
  color: #1d2334;
}
.AdvertisementBannerMobile11Child2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #fff8f8 0%, rgba(250, 250, 250, 0) 100%);
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.05);
  border-radius: 80px;
  padding: 1.8em 7.6em 1.8em 7.6em;
  margin-top: 2.06em;
  margin-bottom: 2.06em;
  margin-left: 2.1em;
  margin-right: 2.1em;
}
.AdvertisementBannerMobile11Child2 p {
  margin-top: 1em;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  color: #1d2334;
  white-space: nowrap;
}
.AdvertisementBannerMobile11Child3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 4.9em;
  margin-right: 4.9em;
  background: #fffbfa;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}
.AdvertisementBannerMobile11Child3_1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2.06em;
}
.AdvertisementBannerMobile11Child3_1 :nth-child(1) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 2em;
  line-height: 150%;
  color: var(--globalColorHeader);
}
.AdvertisementBannerMobile11Child3_1 :nth-child(2) {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1em;
  line-height: 150%;
  color: #1d2334;
  margin-top: 0.6em;
}
.AdvertisementBannerMobile11Button {
  background: #52608e;
  border-radius: 10px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1em;
  line-height: 150%;
  color: #ffffff;
  padding: 0.5em 4.5em 0.5em 4.5em;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3em;
  white-space: nowrap;
  margin-bottom: 2em;
}
.AdvertisementForm {
  display: flex;
  flex-direction: column;
}
.AdvertisementBannerMobile1Inputs {
  background-color: transparent;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
  position: relative;
  z-index: 4;
}
.AdvertisementInput {
  background: #ffffff;
  border-radius: 6px;
  padding: 0.5em 1em 0.5em 3.1em;
  margin-top: 2.4em;
  outline: none !important;
  border: none;
}
.AdvertisementCallButton {
  background: #a53f2b;
  border-radius: 6px;
  padding: 0.5em 4.5em 0.5em 4.5em;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1.1em;
  line-height: 150%;
  color: #ffffff;
  margin-top: 2.4em;
  margin-bottom: 2.4em;
}
.forwardCircle {
  position: absolute;
  top: 50%;
  right: 0.5em;
  border-radius: 100%;
  width: 2.5em;
  height: 2.5em;
  background-color: transparent;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../../assets/AdvertisementSection3CardsImages/forward.png");
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
}
.backwardCircle {
  position: absolute;
  top: 50%;
  left: 0.5em;
  border-radius: 100%;
  width: 2.5em;
  height: 2.5em;
  background-color: transparent;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../../assets/AdvertisementSection3CardsImages/backward.png");
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
}
