.servicesMainContainer {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.servicesMainContainerHeading {
  font-family: var(--globalFont);
  font-size: var(--globalFontSize60);
  font-weight: 700;
  line-height: 73.14px;
  text-align: left;
  padding-bottom: 15px;
  padding-top: 34px;
}

.servicesArea {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.servicesIndividual {
  padding: 2em 1em;
  border: 1px solid #021526;
  border-radius: 20px;
  margin-bottom: 1.5em;
  min-height: 19em;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.servicesIndividual:hover {
  box-shadow: 5px 5px 5px #c2c2c2;
}

.servicesIndividualImageDiv {
  width: 40%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.servicesIndividualImageDiv :nth-child(1) {
  width: 100%;
  height: 100%;
}

.servicesIndividualImageDiv :nth-child(2) {
  position: absolute;
  z-index: 1;
  height: 40%;
}

.servicesIndividualServiceText {
  margin-top: 0.8em;
  font-family: var(--globalFont);
  font-size: var(--globalFontSize24);
  font-weight: 700;
  line-height: 29.26px;
  text-align: center;
}

.servicesIndividualServiceDesc {
  margin-top: 0.5em;
  font-family: var(--globalFont);
  font-size: var(--globalFontSize16);
  font-weight: 500;
  line-height: 1.5em;
  text-align: center;
}

@media screen and (max-width: 950px) {
  .servicesArea {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 680px) {
  .servicesArea {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 480px) {
  .servicesArea {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 400px) {
  .servicesMainContainerHeading {
    font-size: 2em;
  }
}
