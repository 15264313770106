.joinNetworkBody {
  margin-top: 2.5vh;
  margin-left: 6.67vw;
  margin-bottom: 7.5em;
  margin-right: 6.67vw;
  background: #ffffff;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding-left: 4.375vw;
}

.joinNetworkTitle {
  display: flex;
  align-items: center;
  text-align: center;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 150%;
  color: #2d2424;
  padding-top: 1.3125em;
}

.joinNetworkTitle img {
  width: 1.4vw;
  /* height: 2.5vh; */
  cursor: pointer;
}

.joinNetworkTitle div {
  margin-left: 2.3vw;
  font-size: 2vw;
  font-weight: 500;
}

.joinNetworkFormBody {
  margin-top: 1.3125em;
  display: flex;
  flex-direction: column;
}

.NetworkPageFormDropdownField {
  box-sizing: border-box;
  width: 65vw;
  height: 48px;
  font-size: 1.2vw;
  background: #ffffff;
  border: 1px solid #89a9bb;
  border-radius: 12px;
  margin-bottom: 3.125rem;
  padding-left: 2.375rem;
  margin-top: 3.7rem;
  margin-left: 0.5vw;
}
@media screen and (max-width: 600px) {
  .networkBodyMbl {
    background: #fae8d6;
    height: 38vw;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 4vw;
    padding-top: 9vw;
    line-height: 150%;
    /* or 36px */

    text-align: center;
    padding-right: 4vw;
    padding-left: 4vw;

    color: #a93c3c;
  }
  .NetworkPageFormDropdownField {
    width: 77vw;
    font-size: 12px;
    padding-left: 1.175rem;
    color: #6e6c6c;
    margin-bottom: 2.125rem;
    margin-top: 5vw;
  }
  .joinNetworkTitle {
    font-size: 24px;
  }
  .joinNetworkTitle img {
    width: 4.8vw;
    height: 2.5vh;
    cursor: pointer;
  }
  .joinNetworkTitle div {
    margin-left: 2.3vw;
    font-size: 4.8vw;
    font-weight: 500;
  }
}
