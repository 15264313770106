.dropdownEmr {
  /* border-radius: 6px;
border: 1px solid var(--primary-color-1, #1D2334);
background: #FFF; */
padding-left: 1em;
}

.ErmsTextNameAndAges {
  display: flex;
  justify-content: space-between;
  margin-top: 4.42vh;
  color: #000;
  text-align: center;
  font-size: 1.6em;
  font-family: var(--globalFont);
  line-height: 150%;
}
.ermsForm {
  margin-top: 8.42vh;
}
.ErmsTextName {
  font-size: 1em;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.ErmsTextAge {
  font-size: 1em;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.SearchBarEmr {
  padding-right: 1em;
}
.emrmSearch {
  border: 1px solid #52608e;
  border-radius: 6px;
  width: 100%;
}
.requestCiting {
  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
  width: 100%;
  border-radius: 6px;
  border: 1px solid var(--primary-color-11, #52608e);
  background: #fff;
  color: var(--primary-color-1, #1d2334);
  text-align: center;
  font-size: 20px;
  font-family: var(--globalFont);
  line-height: 150%;
}
.corporatesearchBar {
  margin-right: 3.52vw;
}
.MainTablePagination {
  /* background-color: aqua; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.dropDown {
  padding: 5px;
  border: 12px;
  margin-right: 1vw;
}
.SeeRows {
  margin-top: 1vh;
}
.errorMessageERM {
  margin-left: 5vw;
  color: red;
}
.EmrmMobileMain {
  margin-top: 0.5vh;
}
.SeeRowsMobile {
  margin-top: 2vh;
}
.HeadingEmrmMobile {
  color: var(--primary-color-2, #e68d36);
  margin-left: 10vw;
  font-size: 18px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.dropdownEmrMobile {
  /* margin-left: 2vh; */
  width: 100%;
}
.requestCitingMobile {
  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
  /* margin-left: 4vw; */
  border-radius: 6px;
  border: 1px solid var(--primary-color-11, #52608e);
  background: #fff;
  color: var(--primary-color-1, #1d2334);
  text-align: center;
  font-size: 20px;
  font-family: Montserrat;
  line-height: 150%;
  padding-left: 6.5vh;
  padding-right: 6.5vh;
  width: 100%;
}
.searchErmMobile {
  margin-top: 5vh;
}
.cardContainerErm {
  margin-left: 1.5vh;
  margin-right: 2vh;
  margin-top: 5vh;
}
.CardContentsErm {
  border-radius: 12px;
  border: 1px solid var(--primary-color-11, #e2e0e0);
  border-radius: 12px;
  margin-left: 3.6vw;
  margin-top: 4.13;
  margin-bottom: 3vh;
}

.CardContentsErmHeading {
  /* margin-left: 3.6vw; */
  margin-top: 3.6vh;
  margin-left: 3.6vw;
  color: var(--primary-color-2, #e68d36);
  font-size: 16px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.ThreeSubheadingErm {
  display: flex;
  flex-direction: column;
  margin-top: 1.73vh;

  margin-bottom: 2vh;
  margin-left: 3.6vw;
  color: var(--primary-color-111, #2d354e);
  font-size: 16px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.ThreeSubheadingErm2 {
  margin-top: 1vh;
  margin-bottom: 1vh;
}
.DownloadErmMobileReport {
  display: flex;
  justify-content: flex-end;
  margin-right: 3vh;
}
.DownloadErmMobileReportDownload {
  color: var(--secondary-color-2, #a53f2b);

  display: flex;
  justify-content: flex-end;
  margin-right: 0.9vh;
  margin-bottom: 2vh;
  font-size: 14px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.MainTablePaginationMobile {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: -4vh;
  margin-bottom: 3vh;
}
.bothSearchAndDropdownMobile {
  /* margin-left: 2vh; */
  margin-left: 10vw;
  margin-right: 10vw;
}
@media screen and (max-width: 750px) {
  .ErmsTextName {
    font-size: 0.6em;
  }
  .ErmsTextAge {
    font-size: 0.6em;
  }
  .ErmsTextNameAndAges {
    flex-direction: column;
  }
  .dropdownEmr {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 1em;
    padding-right: 1em;
  }
  .SearchBarEmr {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 1em;
    padding-left: 1em;
  }
  .requestCiting {
    width: 100%;
  }
  .emrmSearch{
    width: 100%;
  }
  .ErmsTextNameAndAge{
    padding-left: .5em;
  }
}
