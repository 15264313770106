.productCategoryBody {
  display: flex;
  margin: 1.5em 3vw;
  gap: 2vw;
  position: relative;
}

.productCategoryBodyLeftCategory {
  border: 0.5px solid #dadada;
  border-radius: 10px;
  box-shadow: 5px 5px 15px 0px #0000000d;
  padding: 20px 20px 8px 20px;
  width: 27vw;
}

.productCategoryBodyLeftCategoryHead {
  font-size: 1.75em;
  font-weight: 600;
}

.productCategoryBodyRightHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 65vw;
}

.productCategoryBodyRightTitle {
  font-size: 1.75em;
  font-weight: 600;
}

.productCategoryBodyRightHeadInput {
  border: 1px solid #807f7f;
  border-radius: 10px;
  padding: 1em;
}

.productCategoryBodyLeftCategoryBreak {
  height: 3px;
  width: 7vw;
  background-color: #1c71e1;
  margin-bottom: 1.5em;
}

.productCategoryBodyLeftCategoryBodyContent {
  border: 1px solid #dadada;
  border-radius: 10px;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 1.3vw;
  width: 24vw;
  color: #4c84d9;
  margin-bottom: 12px;
  font-size: 1em;
  font-weight: 400;
  display: flex;
  flex-direction: column;
}

.productCategoryBodyRightTitle {
  font-size: 3.75em;
}

.productCategoryBodyRightFilter {
  display: none;
  position: absolute;
  right: 5vw;
}

.filtersBodyLabels {
  display: flex;
  align-items: baseline;
  position: relative;
  width: -webkit-fill-available;
  margin-left: 1em;
}

.filtersBodyLabels div {
  position: absolute;
  right: 10px;
  border-radius: 50%;
  color: #253d4e;
  background-color: #e1eeff;
  height: 1.5em;
  width: 1.5em;
  text-align: center;
  font-size: 14px;
}

@media screen and (max-width: 477px) {
  .productCategoryBodyRightFilter div {
    margin-left: 10vw;
  }
}

@media screen and (max-width: 900px) {
  .productCategoryBodyLeftCategory {
    display: none;
  }

  .productCategoryBodyRightFilter {
    display: block;
  }

  .productCategoryBodyRightFilter div {
    display: flex;
    align-items: center;
    font-size: 1.2em;
    cursor: pointer;
    font-weight: 500;
    font-family: var(--globalFont);
    margin-top: 0.5em;
  }

  .productCategoryBodyRightTitle {
    font-size: 2em;
  }

  .productCategoryBodyRightHead {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: auto;
  }
}
