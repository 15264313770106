.ambulanceHero {
  background-color: #e2eefe;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  padding: 2em;
}
.AmbulanceServiceCoverPic {
  width: 40vw;
  height: 15em;
  border-radius: 10px;
}
.ambulanceHeroText {
  font-weight: 600;
}
.ambulanceHeroText :nth-child(1) {
  color: var(--globalColorHeader);
  font-size: 4em;
}

.ambulanceHeroText :nth-child(2) {
  font-size: 3em;
}

.AmbulanceServiceBody {
  padding: 2em 2em;
}

.AmbulanceServiceUnderText {
  font-size: var(--globalFontSize20);
}

.AmbulanceContainerText1 {
  font-size: var(--globalFontSize24);
  padding: 1vw 1vw 1vw 1vw;
  width: 20vw;
  margin-top: 4vw;
  margin-left: 2vw;
}
.AmbulanceGridContainer {
  display: grid;
}
.AmbulanceGriditemContainer {
  background: #ffffff;
  border-radius: 12px;
  width: 26vw;
  height: 7vw;
  margin-top: 7vw;
  margin-left: 2vw;
  align-items: center;
  display: grid;
}
.AmbulancContainerServicesOfferedText {
  font-size: var(--globalFontSize24);
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #e68d36;
  display: grid;
}
.AmbulanceServicesContainerGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  margin-top: 5vw;
}
.AmbulanceServicesContainerSection1 {
  position: relative;
  border-radius: 12px;
  /* border: 0.2px solid rgba(0, 0, 0, 1); */
  border-style: ridge;
}
.AmbulanceServicesTextHead {
  color: var(--globalColorHeader);
  padding: 1vw;
  font-size: var(--globalFontSize20);
  display: flex;
  align-items: center;
}
.AmbulanceServicesText {
  padding: 1vw;
  font-weight: 600;
  font-size: var(--globalFontSize20);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.EmojiContainer {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.EmojiContainer img {
  margin: 0;
  padding-right: 1vw;
}
.AmbulanceServicesSubTextHead {
  font-size: var(--globalFontSize18);
  padding-left: 1vw;
  font-weight: 400;
  width: 23vw;
}

.AmbulanceServicesTextHead img {
  margin-left: 1vw;
  margin-right: 1vw;
}
.AmbulanceServicesTextHead p {
  margin: 0;
}

@media screen and (max-width: 950px) {
  .AmbulanceServiceCoverPic {
    display: none;
  }
  .AmbulanceServicesSubTextHead {
    font-size: var(--globalFontSize16);
    width: fit-content;
  }

  .EmojiContainer {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .AmbulanceServicesContainerGrid {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  .AmbulanceServicesContainerSection1 {
    width: auto;
  }
  .ambulanceHeroText :nth-child(1) {
    color: var(--globalColorHeader);
    font-size: 2em;
  }

  .ambulanceHeroText :nth-child(2) {
    font-size: 1.5em;
  }
}
