.TellUsAboutYourselfBox {
  width: 59vw;
  background-color: #ffffff;
  margin-top: 1.25rem;
  border-radius: 16px;
}

.RelationsBox {
  width: 39vw;
  background-color: #ffffff;
  margin-top: 5rem;
  border-radius: 16px;
}

.crossButton {
  position: absolute;
  right: 5%;
  top: 0.4em;
}
.TellUsAboutYourselfMain {
  display: flex;
  justify-content: center;
}
.BoxOuter {
  background-color: rgb(255, 255, 255);
  margin-left: 2.875rem;
  margin-top: 1.875rem;
}
.TellAboutTitle {
  position: relative;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1em;
  line-height: 150%;
  text-transform: capitalize;
  color: #44576a;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cutButtonTitleIcon{
  margin-right: 1em;
}
.SelectDetail {
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
}
.tTextfieldDOB {
  width: 24vw !important;
  height: 2.625rem !important;
  border-radius: 12px !important ;
  border-color: black !important ;
  margin-left: 2.25rem !important;
}
.SelectCHeckBox {
  margin-top: 1.25rem;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  height: 2.625rem !important;
  width: 24vw !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  height: 2.625rem;
}
.BtnSave1 {
  display: flex;
  justify-content: flex-end;
  margin-right: 2.5rem;
}
.BtnSave {
  cursor: pointer;
  background: var(--globalColorButton);
  border-radius: 6px;
  width: 11.875rem;
  height: 2.625rem;

  color: #ffffff;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  border: hidden;
  outline: none !important;
  padding-left: 5.0625rem;
  padding-right: 5.0625rem;
  color: #ffffff;
  margin-bottom: 42px;
}

.TellUsAboutClose {
  z-index: 999;
  cursor: pointer;
  margin-left: 76vw;
  display: flex;
  position: absolute;
  margin-top: 3vw;
  /* height: 2vw; */
  background: white;
  border: transparent;
  outline: none !important;
}

.inputFieldRelations {
  margin-top: 2vw;
  margin-bottom: 1vw;
  width: 20vw;
  border-radius: 6px;
  padding: 0.7vw;
}

.BtnSaveRealtions {
  display: flex;
  justify-content: flex-end;
  margin-right: 2vw;
  margin-top: 0.6vw;
  margin-bottom: 2vw;
}
.ButtonSaveRealtions {
  background: var(--globalColorHeader);
  border-radius: 6px;
  height: 2.625rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  border: hidden;
  padding-left: 6.0625rem;
  padding-right: 6.0625rem;
  color: #ffffff;
  outline: none !important;
  border: none;
}

.relationsClose {
  z-index: 999;
  cursor: pointer;
  display: flex;
  position: absolute;
  margin-top: 3vw;
  /* height: 2vw; */
  background: white;
  border: transparent;
  outline: none !important;
}
.TellAboutinputselect {
  width: 24vw;
  height: 3vw;
  outline: none !important;
  margin-right: 2vw;
  margin-left: 1.3vw;
}
.validation_tellaboutyourself {
  color: red;
  margin-left: 5vw;
  margin-top: 0.5vw;
}
@media screen and (max-width: 980px) {
  .ButtonSaveRealtions {
    font-size: 0.8em;
    padding-left: 4em;
    padding-right: 4em;
  }
  .RelationsBox {
    width: 55vw;
  }
  
}
@media screen and (max-width: 700px) {
  .RelationsBox {
    width: 75vw;
  }
  .inputFieldRelations {
    width: 30vw;
  }
  
}
@media screen and (max-width: 500px) {
  .RelationsBox {
    width: 85vw;
  }
  .ButtonSaveRealtions {
    height: 2.5em;
    font-size: 0.8em;
    padding-left: 3em;
    padding-right: 3em;
  }
  .inputFieldRelations {
 font-size: 0.8em;
 margin-top: 1em;
 width: 35vw;

  }
}
@media screen and (max-width: 400px) {
  .RelationsBox {
    width: 95vw;
  }
  .TellAboutTitle {
    font-size: 0.8em;
  }
}
