.mainOtpBox {
  width: 55vw;
  position: absolute;
  z-index: 1;
  top: 31%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  border-radius: 16px;
  border: 1px solid rgb(111, 111, 111, 0.5);
}

.mainOtpBoxNewMobile {
  width: 55vw;
  position: absolute;
  z-index: 1;
  top: 70%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  height: 28em;
}

.mainOtpMobileBoxNew {
  width: 55vw;
  position: absolute;
  z-index: 1;
  top: 70%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  border-radius: 16px;
  border: 1px solid rgb(111, 111, 111, 0.5);
}

.wrongOtpMessage {
  color: #ff001f;
  margin-top: 0.8rem;
  margin-left: 21%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 150%;
}

.expireMessage {
  color: #ff001f;
  margin-top: 0.8rem;
  margin-left: 21%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 150%;
}
.expireMessageMobile {
  color: #ff001f;
  margin-top: 0.8rem;
  margin-left: 7%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 150%;
}
.upperOtp {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upperOtp p {
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 2.3rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 150%;
  text-transform: capitalize;
  color: #44576a;
  padding-top: 1.8rem;
}

.upperOtp a {
  margin-top: auto;
  margin-bottom: auto;
  padding-right: 2.5rem;
  padding-top: 1.8rem;
  cursor: pointer;
}

.upperOtp {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.middleOtp1 {
  margin-top: 2.25rem;
  display: flex;
  align-items: center;
}

.middleOtp {
  width: 100%;
  margin-top: 2.25rem;
  display: flex;
  align-items: center;
}

.otpHeading {
  width: 5vw;
  margin-left: 2.4375rem;

  display: flex;
  /* justify-content: center; */
}

.otpHeading p {
  margin-top: auto;
  margin-bottom: auto;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 150%;
  color: #44576a;
}

.otpBoxes {
  width: 25vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5rem;
}

.otpTimer {
  margin-top: 0.87rem;
  display: flex;
}

.otpTimer1 {
  width: 5vw;
  margin-left: 1.5rem;
}

.otpTimer2 {
  margin-left: 6.25rem;
  display: flex;
  justify-content: space-between;
}

.otpTimer p {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 0.8rem;
}

#timeShow {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 150%;
  text-transform: capitalize;
  color: #ff001f;
}

#timeShowMobile {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 150%;
  text-transform: capitalize;
  color: #ff001f;
}

.resendOtp {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 150%;
  text-transform: capitalize;
  color: #44576a;
}

.bottomOtp {
  margin-top: 1.68rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 3.34rem;
  margin-bottom: 2.3rem;
}

.bottomOtp button {
  width: 22vw;
  cursor: pointer;
  background: var(--globalColorHeader);
  border-radius: 6px;
  border: hidden;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 19px;
  color: #ffffff;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .mainOtpBox {
    width: 21.6875rem;
    height: 20.6875rem;
  }

  .upperOtp {
    margin-left: 0.9375rem;
    font-weight: 700;
  }

  .upperOtp p {
    margin-top: auto;
    margin-bottom: auto;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    padding-left: 0px;
    line-height: 150%;
    text-transform: capitalize;
    color: #44576a;
  }

  .middleOtp1 {
    display: block;
    margin-left: 1.4375rem;
  }

  .otpHeading {
    display: flex;
    margin-bottom: 0.875rem;
    /* justify-content: center; */
    margin-left: 0px;
  }

  .otpBoxes {
    width: 18.0625rem;
    height: 2.625rem;
    margin-left: 0px;
  }

  .otpTimer1 {
    width: 0px;
    margin-left: 1.4375rem;
    font-weight: 500;
  }

  .otpTimer p {
    margin-top: auto;
    margin-bottom: auto;
    font-weight: 700;
    font-size: 14px;
    font-family: "Montserrat";
    font-style: normal;
  }

  .otpTimer2 {
    width: 70vw;
    display: flex;
    justify-content: space-between;
    margin-left: 0px;
  }

  #timeShow {
    font-size: 14px;
  }

  .bottomOtp {
    margin-bottom: 3.125rem;
    display: flex;
    justify-content: center;
    padding-right: 0px;
  }

  .bottomOtp button {
    width: 77vw;
    margin-left: 0px;
    cursor: pointer;
  }

  .wrongOtpMessage {
    color: #ff001f;
    margin-top: 0.8rem;
    margin-left: 21%;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 150%;
  }
}

@media screen and (min-width: 900px) {
  .mainOtpBoxNewMobile {
    top: 50%;
  }
}
