.addAddressHead {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.addAddressStyle {
  position: absolute;
  top: 40em;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 70vw;
  padding: 1.875em 6.7vw;
  border-radius: 16px;
}
.errorMessageModalValidation{
  color: red;
}
@media screen and (min-width: 1440px) {

.addAddressStyle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 70vw;
  padding: 1.875em 6.7vw;
  border-radius: 16px;
}
}

.addAddressInputOption {
  color: #9a9a9a;
}
.addAddressCross {
  height: 0.875em;
  width: 0.875em;
  cursor: pointer;
}

.addAddressTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  color: var(--globalColorHeader);
  margin-bottom: 0.875em;
}

.addAddressLabel {

  margin-bottom: 1.75em;
  flex-wrap: wrap;
}

.addAddressText {
  display: flex;
  align-items: center;
  min-width: 10vw;
  margin-right: 7.35vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  color: #44576a;
  margin-bottom: 1em;
}

.addressInputField {
  background: #f3f0f0;
  border: 1px solid #e7e5e5;
  border-radius: 6px;
  padding: 0.625em 2vw 0.625em 2vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  width: 85%;
}

.addressInputFieldSelect {
  background: #f3f0f0;
  display: flex;
  align-items: center;
  border: 1px solid #e7e5e5;
  border-radius: 6px;
  padding: 0.625em 2vw 0.625em 2vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  width: 85%;
}
.addressInputFieldSelect::placeholder {
  color: #9a9a9a;
}
.defaultCityValue {
  background: #f3f0f0;
  border: 1px solid #e7e5e5;
  border-radius: 6px;
  padding: 0.625em 2vw 0.625em 2vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  width: 170%;
  color: #9a9a9a;
}
.addressInputSelectField {
  background: #f3f0f0;
  border: 1px solid #e7e5e5;
  border-radius: 6px;
  padding: 0.625em 2vw 0.625em 2vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  width: 222%;
}

.addressInputSelectFieldOption::placeholder {
  color: #9a9a9a;
}

.addAddressCityText {
  margin-right: 12.95vw;
}

.addAddressPinText {
  margin-right: 10.45vw;
}

.addAddressContactText {
  margin-right: 6.75vw;
}

.addAddressSaveText {
  margin-right: 11.58vw;
}

.mainAddAddressModal {
  overflow-y: scroll;
  border-radius: 16px;
}

.addAddressModalButton {
  display: flex;
  justify-content: center;
  border: none;
  margin-top: 2em;
}

.addAddressModalButton button {
  border-radius: 12px;
  padding: 1em 3vw 1em 3vw;
  background: var(--globalColorHeader);
  border: hidden;
  color: white;
}

.addAddressLabelMobile {
  margin-right: 0.2em;
}
.addressGrid{
display: flex;
flex-direction: column;
}
.addAddressModal{
  margin-bottom: 1em;
  width: 95%;
}
.addAddressInputSelectFieldModal{
  background: #f3f0f0;
  border: 1px solid #e7e5e5;
  border-radius: 6px;
  padding: 0.625em 2vw 0.625em 2vw;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 400;
  font-size: var(--globalFontSize16);
  line-height: 150%;
  width: 95%;
}

@media screen and (max-width: 1000px) {
.addAddressModal{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 2em;
}
.addAddressStyle {
  width: 90vw;
}
 .addAddressText{
margin-bottom: 1em;
 }
.addAddressInput{
  width: 100%;
}
  .addAddressInputSelectFieldModal{

  }
  
}
