.SearchInputModalMainContainer {
  position: absolute;
  left: 0px;
  padding-top: 0px;
  margin-top: 3.9vh;
  padding-bottom: 0px;
  margin-bottom: 0px;
  overflow: hidden;
  z-index: 99;
  background-color: white;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: black;
  border-radius: 0px 0px 12px 12px;
  width: 57vw;
}

.SearchModalInputMainSubText {
  color: #44576a;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 0.625em;
  line-height: 150%;
  margin-top: 1vw;
  margin-left: 1vw;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
}

@media screen and (max-width: 1000px) {
  .SearchInputModalMainContainer {
    width: 82vw;
  }
}

@media screen and (max-width: 680px) {
  .SearchInputModalMainContainer {
    /* width: 20em;  */
  }
}
