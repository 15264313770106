.pageConstruction {
  margin-top: 2.62em;
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 150%;
  text-align: center;
  text-transform: capitalize;
  color: #44576a;
}

.Construction {
  width: 30em;
  height: 22.35em;
  /* align-items: center; */
  /* margin-left: 38%; */
  margin-top: 2.87em;
  margin: auto;
}

.mainConstruction {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.takeme {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;

  margin-top: 2.52em;
  text-align: center;
  text-transform: capitalize;

  color: #44576a;
}
.homeConstruction {
  width: 10em;
  height: 3em;
  background: #44576a;
  border-radius: 12px;
  /* margin-left: 41.3em; */
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  color: #ffffff;
  /* align-items: center; */

  margin-bottom: 3.25em;
}
.reference {
  margin: auto;
}
@media screen and (max-width: 600px) {
  .mainConstructionMobile {
    /* background-color: aqua; */
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    align-items: center;
    /* width: 70%; */
    /* height: 50px; */
  }
  .ConstructionMobile {
    width: 100%;
    height: 100%;
  }
  .pageConstructionMobile {
    margin-top: 2.62em;
    font-family: var(--globalFont);
    font-style: normal;
    font-weight: 500;
    font-size: 1.5em;
    line-height: 150%;
    text-align: center;
    text-transform: capitalize;
    color: #44576a;
  }
  .homeConstructionMobile {
    width: 10em;
    height: 3em;
    background: #44576a;
    border-radius: 12px;
    /* margin-left: 41.3em; */
    font-family: var(--globalFont);
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    color: #ffffff;
    border: none;
    outline: none !important;
    cursor: pointer;
    /* align-items: center; */
    /* margin: auto auto; */
    margin-bottom: 3.25em;
  }
  .takemeMobile {
    font-family: var(--globalFont);
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;

    margin-top: 2.52em;
    text-align: center;
    text-transform: capitalize;

    color: #44576a;
  }
}
