.video-player-container {
  width: 100%;
  margin: 0 auto;
  background-color: #e2eefe;
  padding-bottom: 2em;
}

.video-player {
  width: 100%;
}

.MentalWellness_OuterContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 2em;
}

.MentalWellness_LearnHeading {
  color: #44576a;
  font-family: var(--globalFont);
  font-style: normal;
  font-size: 1.5rem;
}

.MentalWellness_SelfCareHeading {
  color: var(--globalColorMainHeading);
  font-size: 3.5rem;
  font-family: var(--globalFont);
  font-style: normal;
}

.MentalWellness_vedio_Image {
  width: 20em;
}

.MentalWellness_vedio_Image1 {
  position: absolute;
  top: 0;
  width: 20em;
  left: 2.5em;
}

.MentalWellness_vedio_Image2 {
  position: absolute;
  top: 12em;
  width: 4em;
  z-index: 2;
  left: 2.5em;
  cursor: pointer;
}

.MentalWellness_CareHeading {
  color: #44576a;
  font-size: 3.5rem;
  font-family: var(--globalFont);
  font-style: normal;
}

.MentalWellness_subHeading {
  font-family: var(--globalFont);
  font-style: normal;
  font-size: 1.25rem;
}

.MentalWellness_btn {
  display: flex;
  margin-top: 3vw;
}

.MentalWellness_btn_ConsultNow {
  color: #ffffff;
  background-color: var(--globalColorHeader);
  border-radius: 12px;
  font-size: 1rem;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  padding-left: 4vw;
  padding-right: 4vw;
  margin-right: 3vw;
}

.MentalWellness_btn_LearnNow {
  color: #ffffff;
  background-color: var(--globalColorButton);
  border-radius: 12px;
  font-size: 1rem;
  padding-top: 0.5vw;
  padding-bottom: 0.5vw;
  padding-left: 4vw;
  padding-right: 4vw;
}

.MentalWellness_grid-vedioImages {
  position: relative;
}

.ABoutOnMoodContainer_mentalWelness {
  background: #f2f4f8;
  padding-top: 2vw;
  padding-bottom: 2vw;
}

.ABoutOnMoodHeading_mentalWelness {
  display: flex;
  justify-content: center;
  font-style: normal;
  margin-bottom: 3vw;
  font-size: 2em;
  font-weight: 600;
}

.AboutHeading_mentalWelness {
  color: var(--globalColorHeader);
  font-style: normal;
  margin-right: 0.2em;
}


.mentalWelness_aboutgridcontainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.mentalWelness_aboutgridone {
  font-weight: 400;
  font-family: var(--globalFont);
  font-size: 1em;
  color: #1d2334;
  margin-left: 4vw;
}

.mentalwellness_yogaimage {
  width: 20em;
  margin-left: 6vw;
  margin-right: 3vw;
  margin-top: -3vw;
}

.mentalwellness_nineimage {
  width: 2.7em;
}

.Assessment_main_container {
  margin-left: 4vw;
  margin-right: 4vw;
  padding-bottom: 4vw;
}


.Self_assesment_grid_container {
  background: #ffffff;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
}

.Self_assesment_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 3.75rem;
  justify-content: center;
}

.Self_assesment_gridOne {
  background: var(--globalColorHeader);
  border-radius: 0px 0px 40px 40px;
  width: 7vw;
  height: 7vw;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}


.selfAssesmentGridBox {
  justify-content: center;
  display: flex;
}

.Self_assesment_subheading {
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-family: var(--globalFont);
  color: var(--globalColorHeader);
  margin-top: 1vw;
  font-size: 1em;
  text-align: center;
}

.Self_assesment_subheading2 {
  font-weight: 500;
  font-family: var(--globalFont);
  color: var(--globalColorHeader);
  margin-left: 0.5em;
  margin-right: 0.5em;
  color: #1d2334;
  text-align: center;
  margin-bottom: 1em;
  font-size: 0.85em;
}

.assessment_image1 {
  width: 2.2vw;
}

.four_layers_Mainheading {
  color: var(--globalColorButton);
  font-weight: 600;
  font-size: var(--globalFontSize60);
  margin-top: 1em;
  display: flex;
  justify-content: center;
}

.four_layers_Subheading {
  font-weight: 400;
  font-family: var(--globalFont);
  color: #1d2334;
  margin-left: 2em;
  margin-right: 2em;
  margin-top: 1em;
}

.four_layers_gridcontainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-left: 13vw;
  margin-right: 13vw;
  padding-bottom: 7vw;
}

.four_layers_gridcontainerone {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  width: 21vw;
  padding-bottom: 4vw;
}

.four_layers_gridcontainertwo {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
  width: 21vw;
  padding-bottom: 4vw;
  margin-top: 2vw;
  padding-top: 0.5vw;
}

.fourlayer_mainconatinergrid {
  display: flex;
  align-items: center;
}

.fourlayer_images {
  position: relative;
  top: 1.4vw;
  left: 1.9vw;
  width: 2.6vw;
  height: 4.5vw;
}

.fourlayer_imagestwo {
  position: relative;
  top: 1.4vw;
  left: 1.9vw;
  width: 4.5vw;
  height: 5vw;
}

.fourlayer_polygon {
  position: absolute;
  width: 9vw;
}

.images_layers_position {
  margin-top: 3vw;
  margin-left: 2vw;
  margin-bottom: 2vw;
}

.four_layers_bodyheading {
  color: #184eff;
  font-size: var(--globalFontSize18);
  margin-left: 2vw;
  margin-bottom: 1vw;
  margin-top: 3.5vw;
}

.four_layers_energyheading {
  color: #027762;
  font-size: var(--globalFontSize18);
  margin-left: 2vw;
  margin-bottom: 1vw;
  margin-top: 3.5vw;

}

.four_layers_wisdomheading {
  color: var(--globalColorHeader);
  font-size: var(--globalFontSize18);
  margin-left: 2vw;
  margin-bottom: 1vw;
  margin-top: 3.5vw;
}

.four_layers_mindheading {
  color: #9e00ff;
  font-size: var(--globalFontSize18);
  margin-left: 2vw;
  margin-bottom: 1vw;
  margin-top: 3.5vw;
}

.four_layers_boxsubheading {
  margin-left: 1.9em;
  font-size: 0.8em;
  color: #1d2334;
  margin-right: 1em;
}

.MentalAssessment_logo_box {
  background: #eeeeee;
  box-shadow: 0px -2px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 0px;
}

.MentalAssessment_logo_heading {
  font-size: 1rem;
  line-height: 150%;
  text-align: center;
  margin-top: 2vw;
  padding-bottom: 0.5vw;
}

.MentalAssessment_image_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1vw;
}

.MentalAssessment_image1 {
  width: 6em;
  margin-right: 2vw;
}

.MentalAssessment_image3 {
  width: 8em;
  margin-right: 2vw;
}

.MentalAssessment_image2 {
  width: 4em;
  margin-right: 2vw;
}


.play-button {
  padding: 10px 20px;
  font-size: 1rem;
}



.mentalWellness-recdesignContainer {
  position: relative;
}

.mentalWellness-rec1designContainer {
  position: absolute;
  left: 40vw;
  top: 15vw;
  z-index: -1;
  width: 31vw;
}

.mentalWellness-rec2designContainer {
  position: absolute;
  left: 35vw;
  top: 4vw;
  z-index: -1;
  width: 31vw;
}

.mentalWellness-rec3designContainer {
  position: absolute;
  left: 15vw;
  top: 15vw;
  z-index: -1;
  width: 31vw;
}

.MentalHealthMemberFirstHeading {
  display: flex;
  margin-top: 0.5vw;
  /* justify-content: center;
  align-items: center; */
}

.mentalwellnessValidation {
  color: red;
  margin-left: 2vw;
  margin-top: 1vw;
}

@media screen and (min-width: 1320px) {
  .MentalWellness_vedio_Image2 {
    position: absolute;
    top: 12em;
    z-index: 2;
    left: 2em;
    cursor: pointer;
    width: 5em;
  }
}

@media screen and (min-width: 1700px) {
  .MentalWellness_vedio_Image1 {
    width: 23em;
  }

  .MentalWellness_vedio_Image {
    width: 23em;
  }

  .MentalWellness_vedio_Image2 {
    width: 5em;
    top: 15em;
  }
}

.overlayMental {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(37, 44, 66, 0.5);
  z-index: 1;
}

.TalktodocmodalHeading {
  color: var(--globalColorHeader);
  position: relative;
  font-size: var(--globalFontSize24);
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeButtonMW {
  position: absolute;
  right: 2em;
  color: black;
}

.closeButtonMWMobile {
  position: absolute;
  right: 2em;

}

@media screen and (max-width : 850px) {
  .mentalWelness_aboutgridcontainer {
    display: grid;
    grid-template-columns: 1fr;
  }

  .mentalWellness-recdesignContainer {
    display: none;
  }

  .mentalwellness_yoga {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mentalwellness_yogaimage {
    margin-top: 0.5em;
  }

  .Self_assesment_grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width : 750px) {
  .MentalWellness_OuterContainer {
    grid-template-columns: 1fr;
    gap: 2em;
  }

  .Self_assesment_grid {
    gap: 1em;
  }
}

@media screen and (max-width : 500px) {
  .MentalWellness_vedio_Image {
    width: 15em;
  }

  .MentalWellness_vedio_Image1 {
    width: 15em;
  }

  .MentalWellness_vedio_Image2 {
    width: 3em;
  }

  .mentalwellness_yogaimage {
    width: 10em;
  }

  .mentalwellness_nineimage {
    width: 1em;
  }

  .four_layers_gridcontainer {
    grid-template-columns: 1fr;
    gap: 10px;
    margin-top: 0.75em;
  }

  .four_layers_gridcontainerone {
    width: auto;
  }

  .four_layers_gridcontainertwo {
    width: auto;
  }

  .MentalAssessment_image1 {
    width: 4em;
  }
  
  .MentalAssessment_image3 {
    width: 4em;
  }
  
  .MentalAssessment_image2 {
    width: 2em;
  }

  
}