.planContent {
  border: 0.5px solid #9c9c9c;
  border-radius: 10px;
  padding: 1.4em 2vw;
  margin: 2em 4vw;
}

.planContentButtons {
  display: flex;
  gap: 1.3vw;
  flex-wrap: wrap;
}

.planContentButton {
  border: 0.5px solid #9c9c9c;
  color: #8a8a8a;
  padding: 1em 1.85em;
  border-radius: 8px;
}

.planContentButton.clicked {
  background-color: blue;
  color: white;
}



.PlanTermsAndConditionsButton {
    color: #fff;
    background-color: var(--globalColorSubHeading);
    padding: 1rem 3rem 1rem 3rem;
    border-radius: 12px;
    font-size: var(--globalFontSize20);
    margin-top: 3vw;
    margin-bottom: 1vw;
  }

@media screen and (max-width: 600px) {
  .PlanTermsAndConditionsButton {
    padding: 1rem 9rem 1rem 8rem;
    font-size: var(--globalFontSizeMobile16);
  }
}

@media screen and (max-width: 380px) {
  .PlanTermsAndConditionsButton {
    padding: 1em 5em;
    font-size: 0.8em;
  }
}

