.CompleteCartMain {
  margin-top: 3vh;
  /* margin-left: 4.69vw; */
  margin-bottom: 6.11vh;
}

.CompleteImage {
  width: 23vw;
  margin-top: 3vh;
}

.CompleteImageZero {
  width: 8vw;
  margin-top: 3vh;
}

.justcolor {
  font-weight: 500;
}

.CompleteCart1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 padding-left: 1em;
 padding-right: 1em;
}

.CompleteText {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;
  color: var(--globalColorHeader);
  /* margin-top: 1.38vh; */
  margin-bottom: 2.38vh;
}

.CompleteSecondText {
  margin-top: 2.9vh;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 200%;
  /* or 32px */

  text-align: center;
  text-transform: capitalize;

  /* pp1 */

  color: #44576a;
}

.NewFailedPayment {
  background: #ffffff;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin-bottom: 8em;
  width: 80%;
  margin: 0 auto;
}

.CompleteMessageText {
  margin-top: 1vh;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 200%;
  /* or 32px */

  text-align: center;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  /* pp1 */
  color: var(--globalColorHeader);
}

.CompleteTextConfirm {
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 700;
  font-size: var(--globalFontSize22);
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;

  color: var(--globalColorHeader);
  /* margin-top: 1.38vh; */
  margin-bottom: 2.38vh;
}

.NewButtonFailed {
  margin-top: 2.79vh;
  margin-bottom: 5.79vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 32px;

  width: 40%;

  color: #ffffff;
  background: var(--globalColorHeader);
  box-shadow: 0px 4px 16px rgba(35, 221, 237, 0.16), 0px 4px 8px -2px #030c28;
  border-radius: 4px;

  /* Inside auto layout */
padding: 0.8em 2em;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.CompleteThirdText {
  margin-top: 3.2vh;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;

  text-transform: capitalize;

  color: #5067a7;
}

@media screen and (max-width: 950px) {
  .NewFailedPayment {
   
    margin-bottom: 5em;

  }

  .CompleteImage {
    width: 60vw;
   
  }
.CompleteCart1{
  margin-left: auto;
  margin-right: auto;
}
  .CompleteCartMain {
    margin-top: 2em;
    margin-bottom: 2em;
  }


  .CompleteImageZero {
    width: 30vw;
  }
  .NewButtonFailed{
    width: 70%;
  }
}
