.emrmBanner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2.3em;
}
.emrmBannerText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;
  padding-left: 2em;
}
.emrmBannerText :nth-child(1) {
  font-family: var(--globalFont);
  font-size: var(--globalFontSize32);
  font-weight: 700;
  color: var(--globalColorHeader);
}
.emrmBannerText :nth-child(2) {
  font-family: var(--globalFont);
  font-size: var(--globalFontSize28);
  font-weight: 500;
}
.emrmBannerImage {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2.3em;
}
.emrmBannerImage img {
  width: 80%;
}

.EMRM_BlockTwoSubHeading_Button button {
  border: none;
  border-radius: 6px;
  padding: 0.5625em 7vw;
  background-color: var(--globalColorHeader);
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  outline: none !important;
}
.EMRM_BlockTwoSubHeading_Button {
  width: 100%;
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    rgba(217, 217, 217, 0.96) 100%
  );
  padding-bottom: 2.6875em;
  display: flex;
  justify-content: center;
}
.EMRM_Grid_Container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.EMRM_Grid_Conatiner_Image {
  width: 51vw;
  margin-top: 10vw;
  margin-bottom: 3vw;
}
.EMRM_Grid_Conatiner_Text {
  background: #f2f4f8;
  font-size: 1.3vw;
  font-family: var(--globalFont);
  padding: 1.4vw;
  border-radius: 12px;
  color: #696767;
  margin-top: 3vw;
  margin-bottom: 3vw;
}
.EMRM_Grid_Conatiner_Text_span {
  color: var(--globalColorHeader);
}
.EMRM_TryNow_Heading_Button {
  width: 100%;

  padding-bottom: 2.6875em;
  display: flex;
  justify-content: center;
}
.EMRM_TryNow_Heading_Button button {
  border: none;
  border-radius: 6px;
  padding: 0.5625em 7vw;
  background-color: var(--globalColorHeader);
  font-family: var(--globalFont);
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  outline: none !important;
}
@media screen and (max-width: 1020px) {
  .emrmBannerText {
    padding-left: 0em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 2em;
    text-align: center;
  }
  .emrmBannerImage {
    width: 0%;
  }
  .EMRM_BlockTwoSubHeading_Button button {
    padding: 0.8em 1.3em;
    font-size: var(--globalFontSize14);
    margin-top: 1em;
  }
  .EMRMIMAGE {
    display: none;
  }
  .EMRM_Grid_Container {
    width: 100%;
    grid-template-columns: none;
  }
  .EMRMIMAGEText {
    width: 100%;
  }
  .EMRM_Grid_Conatiner_Text {
    width: 80%;
    margin: 0 auto;
    margin-top: 2em;
    margin-bottom: 2em;
    font-size: var(--globalFontSize14);
    padding: 1em;
  }
  .CashlessOPD_BlockFourHeading {
    font-family: var(--globalFont);
    font-size: var(--globalFontSize24);
    font-weight: 700;
    line-height: 33px;
    text-align: center;
  }
}

@media screen {
}
