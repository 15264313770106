.addAddressForm {
  margin-top: 2.625em;
  margin-left: 6.3vw;
  margin-right: 7vw;
}

.addAddressFormBody {
  background: #ffffff;
  border: 1px solid #2d354e;
  border-radius: 12px;
}

.addAddressFormTitle {
  margin-top: 2.625em;
  margin-left: 2.7vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  color: var(--globalColorHeader);
}

.addAddressFormValue {
  margin-top: 2.25em;
  margin-left: 2.7vw;
  margin-right: 5.8vw;
}

.addAddressFormLeft {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.addAddressFormBlockRight {
  display: flex;
  margin-bottom: 2.1875em;
  align-items: baseline;
  margin-left: 4.8vw;
}

.addAddressFormBlockRightPin {
  display: flex;
  margin-bottom: 2.1875em;
  align-items: baseline;
  margin-left: 4.8vw;
}

.addAddressTextForm {
  min-width: 10vw;
  margin-right: 7.35vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  color: #44576a;
}

.addAddressTextFormCity {
  min-width: 10vw;
  margin-right: 7vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  color: #44576a;
}

.addAddressFormBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.1875em;
}

.addAddressFormBlockMobile {
  display: flex;
  flex-direction: column;
}

.addAddressFormBlockRightMobile {
  display: flex;
  flex-direction: column;
}

.addAddressFormBlock1 {
  display: flex;
  align-items: baseline;
  margin-bottom: 2.1875em;
}

.addAddressInputField {
  background: #f3f0f0;
  border: 1px solid #e7e5e5;
  border-radius: 6px;
  padding: 0.625em 2vw 0.625em 2vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  /* width: 100%; */
  width: 19vw;
}

.addAddressInputFieldArea {
  background: #f3f0f0;
  border: 1px solid #e7e5e5;
  border-radius: 6px;
  padding: 0.625em 2vw 0.625em 2vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  /* width: 292%; */
  width: 60vw;
}

.addAddressInputSelectField {
  background: #f3f0f0;
  border: 1px solid #e7e5e5;
  border-radius: 6px;
  padding: 0.625em 2vw 0.625em 2vw;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 150%;
  /* width: 200%; */
  width: 19vw;
}



.addAddressConfirmDetails {
  cursor: pointer;
  margin-bottom: 2.625em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--globalColorHeader);
  box-shadow: 0px 4px 16px rgba(35, 221, 237, 0.16), 0px 4px 8px -2px #030c28;
  border-radius: 4px;
  margin-left: 26vw;
  padding: 1.25em 9.2vw;
  color: white;
}
.AddressListConfirmButtonDiv{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
  margin-bottom: 2em;
}
.AddressListConfirmButton{
  font-family: var(--globalFont);
  font-size: var(--globalFontSize12);
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  background: #1C71E1;
  color: #fff;
  padding: 1em 2em 1em 2em;
  border-radius: 9px;
  margin: 0 auto;
}
@media screen and (max-width: 950px) {
  .addAddressFormLeft {
    display: flex;
    flex-direction: column;
  }
  .addAddressFormTitle{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .addAddressTextForm{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items:center;
    margin-bottom: 1em;
  }
  .addAddressInput{
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
  }
  .addAddressFormBlock{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
 

  .addAddressInputSelectField {
    width: 100%;
  }
  .addAddressConfirmDetails {
    margin:0 auto;
  padding: 1em 9.2vw;
margin-bottom: 2em;
  }
  .addAddressText {
    margin-top: 1em;
  }
  .addAddressFormValue {
    margin-top: 0;
  }

  .addAddressInputFieldArea {
    width: 100%;
  }
  .addAddressInputField {
    background: #f3f0f0;
    border: 1px solid #e7e5e5;
    border-radius: 6px;
    padding: 0.625em 2vw 0.625em 2vw;
    font-family: var(--globalFont);
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 150%;
    width: 100%;
  }
}
